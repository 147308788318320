import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MappingService } from '../../reports/mapping/mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: 'app-msp-monthly-report',
  templateUrl: './msp-monthly-report.component.html',
  styleUrls: ['../../../app.component.css']
})
export class MspMonthlyReportComponent implements OnInit {

  regiform: FormGroup; // for Form Reference
  options: any[] = [];   // for Creating Options for Ongage List
  smsOfferMapOptions:any[] = [];   // for Creating Options for Ongage List
  offerGroupId: any;  // for storing network id value
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
 // data: any = {};  // for data in the List Details Table
  data: { headers: any[],  data: any[] ,config:any }; // Data Storing for Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  selectedLists: any[] = [];
  selectedListsValue: any[] = [];
  editMultiple = false;
  scrollHeight: any;
  showModel: any = '';
  offerGroupName: any = '';
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  exporting: boolean;
  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    this.filterData();
    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }
    // For Showing Filter Model
    letShowModel(column: any, value: any)
    {
      return value === column ? false : true;
    }
    // For Showing Filter Model
    setShowModelValue(columnValue: any)
    {
      return this.showModel = columnValue;
    }
    // For Hiding Filter Model
    hideDialog()
    {
      return this.showModel = '';
    }
    filterData(cols?: any)
    {
      // debugger;
      if (!this.isFilter)
      {
        this.loading = true;
        // Getting Data From Server
        if (!this.isFilter)
        { //debugger;
          this.subscription.push(this.mappingService.getMspMonthlyTarget().subscribe(responseData =>
          {
            if (responseData['error'] === 'Expired token')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
              return this.utilService.logedOut();
            }
            if (responseData['code'])
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              return null;
            }
            if (responseData['code']==='400')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Offer Group name already exist' });
              return null;
            }
            //debugger;
            this.data = responseData as { headers: any[], data: any[] ,config:any };
            // All Columns of Table
            this.allCols = this.data.headers;

            // Frized Columns
            this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
            this.tableConfig = this.data.config;
            //debugger;
            // All Columns for Filter Options
            this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

            // First Time Showed Columns
             // this.cols = localStorage.mtdMspReport ? JSON.stringify(localStorage.mtdMspReport) : this.data.headers;
              this.cols = localStorage.mtdMspReport ? JSON.parse(localStorage.mtdMspReport) : this.data.headers;
            this.loading = false;
            if (this.data.data.length !== 0)
            {
              this.filterOptions = this.utilService.getRequiredOptions(
                this.data.data,
                this.data.data[0]
              );
            } else
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
            }
          }, err =>
          {
            if (err)
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              this.loading = false;
              return null;
            }
          }));
        }

      }
    }
      // Update Filter Options
    updateFilterOptions(tableData, colName)
    {
      if (tableData !== null)
      {
        this.filterOptions = this.utilService.getRequiredOptions(
          tableData,
          tableData[0]
        );
        this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(tableData, colName);
      }
    }
        // Columns State Save
    saveColumnState(dt: any)
    {
        localStorage.mtdMspReport = JSON.stringify(dt.columns);
    }
    // For getting string length(Tooltip)
    showTooltip(text: any)
    {
      return text && (String(text).length > 9) ? text : '';
    }

    // Destroy all subscriptons
    ngOnDestroy(): void
    {
      this.subscription.forEach(e => e.unsubscribe());
    }

}
