<div class="form_frm ui-g-12" *ngIf="getTeamId()">
  <div class="ui-g-10 ui-g-offset-1">
    <form [formGroup]="complianceForm">
      <div class="listMapper ui-g-4">
        <p-dropdown
          formControlName="network"
          [options]="networkOptions"
          placeholder="Network Name"
          [filter]="true"
          (onChange)="getNetworkNameText($event)"
        ></p-dropdown>
      </div>

      <div class="listMapper ui-g-4" style="">
        <textarea
          [ngStyle]="{ width: '100%', 'max-width': '100%' }"
          [rows]="2"
          [cols]="28"
          formControlName="description"
          placeholder="Description"
          pInputTextarea
          autoResize="autoResize"
        ></textarea>
      </div>

      <div class="listMapper ui-g-2">
        <button
          class="submitButton"
          pButton
          type="button"
          (click)="insertNetworkUpdates()"
        >
          Submit
        </button>
      </div>
      <div class="listMapper ui-g-2">
        <button class="resetButton" pButton type="button" (click)="reset()">
          Reset
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="creativeLibraryTable ui-g-12"
  [ngStyle]="{
    'margin-top': getTeamId() ? '.5rem' : '-.5rem',
    position: 'relative'
  }"
>
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-7">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              [(ngModel)]="searchValue"
              (keyup)="updateData()"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <!-- <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates> -->

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'network_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign: col.field === 'created_date' ? 'right' : 'left'
          }"
        >
          <span *ngIf="col.field !== 'remarks'">{{ col.header }}</span>
          <div
            *ngIf="col.field === 'remarks'"
            style="text-align: center; width: 100%; height: 100%;"
          >
            {{ col.header }}
          </div>
          <p-sortIcon
            *ngIf="col.field !== 'remarks'"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [pTooltip]="rowData[col.field]"
          tooltipPosition="right"
          [tooltipDisabled]="col.field !== 'updates'"
          [ngStyle]="{
            textAlign: col.field === 'created_date' ? 'right' : 'left'
          }"
        >
          <span
            *ngIf="
              rowData[col.field] !== 'Undefined' &&
              col.header === 'Network Name'
            "
          >
            <button
              mat-button
              pTooltip="Logs"
              (click)="addNewTab('Logs - ', rowData['network_name'], rowData)"
            >
              <img src="assets/icons/logs_w.svg" [style]="{ width: '.8rem' }" />
            </button>
          </span>
          <span
            *ngIf="
              col.field !== 'created_date' &&
              col.field !== 'modified_date' &&
              col.field !== 'created_by' &&
              col.field !== 'remarks'
            "
          >
            {{ rowData[col.field] }}
          </span>

          <span
            *ngIf="
              col.field === 'created_date' || col.field === 'modified_date'
            "
          >
            <span *ngIf="rowData[col.field]">{{
              getDateFormat(rowData[col.field])
            }}</span>
            <span *ngIf="!rowData[col.field]">{{
              getDateFormat(rowData["created_date"])
            }}</span>
          </span>
          <span
            *ngIf="col.field === 'created_by' || col.field === 'modified_by'"
          >
            <span *ngIf="rowData[col.field]">{{ rowData[col.field] }}</span>
            <span *ngIf="!rowData[col.field]">{{ rowData["created_by"] }}</span>
          </span>
          <div *ngIf="col.field === 'remarks'" style="text-align: center;">
            <button
              (click)="updateComplianceDetails(rowData)"
              mat-button
              pTooltip="Edit"
              style="margin-left: 0.5rem;"
            >
              <img src="assets/icons/edit_w.svg" [style]="{ width: '.8rem' }" />
            </button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(data | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter1 || isGlobalFilter1
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (data !== undefined ? data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
