// import { ConfirmationService, ConfirmDialogModule } from 'primeng/primeng';
import { EspService } from "./homepage/reports/esp/espservices/esp.service";
import { NetworkService } from "./homepage/reports/network/networkservices/network.service";
import { MessageService } from "primeng/api";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";

import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";

import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TabViewModule } from "primeng/tabview";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { TooltipModule } from "primeng/tooltip";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToastModule } from "primeng/toast";
import { TreeTableModule } from "primeng/treetable";
import { PaginatorModule } from "primeng/paginator";
import { CheckboxModule } from "primeng/checkbox";
import { PickListModule } from "primeng/picklist";
import { FileUploadModule } from "primeng/fileupload";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { DialogModule } from "primeng/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { LoginComponent } from "./login/logincomponents/login/login.component";
import { NavigationComponent } from "./navigation/navigationcomponents/navigation.component";
import { HomepageComponent } from "./homepage/homepagecomponents/homepage.component";
import { TeamComponent } from "./homepage/reports/team/teamcomponents/team.component";
import { TeamtableComponent } from "./homepage/reports/team/teamtables/teamtable/teamtable.component";
import { OffertableComponent } from "./homepage/reports/team/teamtables/offertable/offertable.component";
import { MatTabsModule } from "@angular/material/tabs";
import { TeamService } from "./homepage/reports/team/teamservices/team.service";
import { DatesComponent } from "./utils/dates/dates.component";
import { ListtableComponent } from "./homepage/reports/team/teamtables/listtable/listtable.component";
import { SegmenttableComponent } from "./homepage/reports/team/teamtables/segmenttable/segmenttable.component";
import { CampaigntableComponent } from "./homepage/reports/team/teamtables/campaigntable/campaigntable.component";
import { NetworkComponent } from "./homepage/reports/network/networkcomponents/network.component";
import { EspComponent } from "./homepage/reports/esp/espcomponents/esp.component";
import { EsptableComponent } from "./homepage/reports/esp/esptables/esptable/esptable.component";
import { PlatformComponent } from "./homepage/reports/platform/platformcomponents/platform.component";
import { PlatformService } from "./homepage/reports/platform/platformservices/platform.service";
import { PlatformtableComponent } from "./homepage/reports/platform/platformtables/platformtable/platformtable.component";
import { Network1tableComponent } from "./homepage/reports/network/networktables/network1table/network1table.component";
import { AuthGuard } from "./auth/auth.guard";
import { MappingComponent } from "./homepage/reports/mapping/mappingcomponents/mapping.component";
import { ListComponent } from "./homepage/reports/mapping/list/list.component";
import { TriggerComponent } from "./homepage/reports/mapping/trigger/trigger.component";
import { DailyComponent } from "./homepage/reports/daily/dailycomponents/daily.component";
import { RevenueComponent } from "./homepage/reports/daily/revenue/revenue.component";
import { SubjectlineComponent } from "./homepage/reports/daily/subjectline/subjectline-components/subjectline.component";
import { CreativeComponent } from "./homepage/reports/daily/creative/creative-components/creative.component";
import { CreativetabletemplateComponent } from "./homepage/reports/daily/dailyutils/creativetabletemplate/creativetabletemplate.component";
import { SubjectlinetabletemplateComponent } from "./homepage/reports/daily/dailyutils/subjectlinetabletemplate/subjectlinetabletemplate.component";
import { EspnametableComponent } from "./homepage/reports/esp/esptables/espnametable/espnametable.component";
import { SubesptableComponent } from "./homepage/reports/esp/esptables/subesptable/subesptable.component";
import { ListorderingComponent } from "./homepage/reports/mapping/listordering/listordering.component";
import { DomainComponent } from "./homepage/reports/domain/domaincomponents/domain.component";
import { DomaintableComponent } from "./homepage/reports/domain/domaintables/domaintable/domaintable.component";
import { DailyService } from "./homepage/reports/daily/dailyservices/daily.service";
import { DomainService } from "./homepage/reports/domain/domainservices/domain.service";
import { MappingService } from "./homepage/reports/mapping/mappingservices/mapping.service";
import { CreativelibraryComponent } from "./homepage/reports/creativelibrary/creativelibrarycomponents/creativelibrary.component";
import { CreativelibrarytableComponent } from "./homepage/reports/creativelibrary/creativelibrarytables/creativelibrarytable/creativelibrarytable.component";
import { CreativelibraryService } from "./homepage/reports/creativelibrary/creativelibraryservices/creativelibrary.service";
import { CreativewindowComponent } from "./homepage/reports/creativelibrary/creativelibrarytables/creativewindow/creativewindow.component";
import { CreativelogsComponent } from "./homepage/reports/creativelibrary/creativelibrarytables/creativelogs/creativelogs.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { DailycreativeComponent } from "./homepage/reports/daily/creative/creative-tables/dailycreative/dailycreative.component";
import { CreativePerformanceComponent } from "./homepage/reports/daily/creative/creative-tables/creative-performance/creative-performance.component";
import { SubjectlinePerformanceComponent } from "./homepage/reports/daily/subjectline/subjectline-tables/subjectline-performance/subjectline-performance.component";
import { DailysubjectlineComponent } from "./homepage/reports/daily/subjectline/subjectline-tables/dailysubjectline/dailysubjectline.component";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { PublisherComponent } from "./homepage/reports/publisher/publishercomponents/publisher.component";
import { PublishertableComponent } from "./homepage/reports/publisher/publishertables/publishertable/publishertable.component";
import { PublisherCreativePerformanceComponent } from "./homepage/reports/publisher/publishertables/publisher-creative-performance/publisher-creative-performance.component";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { ComplianceComponent } from "./homepage/reports/compliance/compliancecomponents/compliance.component";
import { NetworkUpdatesComponent } from "./homepage/reports/compliance/compliancetables/network-updates/network-updates.component";
import { NetworkUpdatesLogsComponent } from "./homepage/reports/compliance/compliancetables/network-updates-logs/network-updates-logs.component";
import { ComplianceService } from "./homepage/reports/compliance/complianceservices/compliance.service";
import { DashboardComponent } from "./homepage/dashboard/dashboard.component";
import { LeadsComponent } from "./homepage/reports/leads/leadscomponents/leads.component";
import { LeadsService } from "./homepage/reports/leads/leadsservices/leads.service";
import { TableComponent } from "./utils/table/table.component";
import { WebsitesComponent } from "./homepage/reports/leads/leadstables/websites/websites.component";
import { WebsitesFilterComponent } from "./homepage/reports/leads/leadstables/websites-filter/websites-filter.component";
import { LeadsDetailsComponent } from "./homepage/reports/leads/leadstables/leads-details/leads-details.component";
import { DailyCountComponent } from "./homepage/reports/daily/daily-count/daily-count.component";
import { DailySegmentGrowthComponent } from "./homepage/reports/daily/daily-segment-growth/daily-segment-growth.component";
import { NetworkMappingComponent } from "./homepage/reports/mapping/network-mapping/network-mapping.component";
import { OfferMappingComponent } from "./homepage/reports/mapping/offer-mapping/offer-mapping.component";
import { NetworkGroupComponent } from "./homepage/reports/network-group/netwrokGroupComponents/network-group.component";
import { NetworkGroupTableComponent } from "./homepage/reports/network-group/networkGroupTables/network-group-table/network-group-table.component";
import { OfferGroupComponent } from "./homepage/reports/offer-group/offerGroupComponents/offer-group.component";
import { OfferGroupTableComponent } from "./homepage/reports/offer-group/offerGroupTables/offer-group-table/offer-group-table.component";
import { OfferComponent } from "./homepage/reports/offer/offerComponents/offer.component";
import { CreativeScoringComponent } from "./homepage/reports/creativelibrary/creativelibrarytables/creative-scoring/creative-scoring.component";
import { DropFieldsComponent } from "./homepage/reports/leads/leadstables/drop-fields/drop-fields.component";
import { SmsRevenueComponent } from "./homepage/reports/sms-revenue/sms-revenue.component";
import { AddSMSRevenueComponent } from "./homepage/reports/mapping/add-smsrevenue/add-smsrevenue.component";
import { ContentComponent } from "./homepage/reports/daily/content/content.component";
import { SenderIdComponent } from "./homepage/reports/msp/sender-id/sender-id.component";
import { MspcomponentComponent } from "./homepage/reports/msp/mspcomponent/mspcomponent.component";
import { SmsNetworkTableComponent } from "./homepage/reports/IndSmsNetwork/sms-network-table/sms-network-table.component";
import { SmsOfferComponent } from "./homepage/reports/IndSmsOffer/offerComponent/sms-offer/sms-offer.component";
import { SmsOfferTableComponent } from "./homepage/reports/IndSmsOffer/sms-offer-table/sms-offer-table.component";
import { SmsNetworkComponent } from "./homepage/reports/IndSmsNetwork/networkComponent/sms-network/sms-network.component";
import { MsptableComponent } from "./homepage/reports/msp/msptable/msptable.component";
import { MspReportComponent } from "./homepage/reports/msp/msp-report/msp-report.component";
import { MspServiceTableComponent } from "./homepage/reports/msp/msp-service-table/msp-service-table.component";
import { MspVerticalTableComponent } from "./homepage/reports/msp/msp-vertical-table/msp-vertical-table.component";
import { SmsOfferGroupComponent } from "./homepage/reports/IndSmsOfferGroup/smsOfferGroupComponent/sms-offer-group/sms-offer-group.component";
import { SmsVerticalComponent } from "./homepage/reports/sms-vertical/sms-vertical.component";
import { SmsContentComponent } from "./homepage/reports/smsContent/smsContentComponent/sms-content/sms-content.component";
import { SmsContentTableComponent } from "./homepage/reports/smsContent/smsContentTable/sms-content-table/sms-content-table.component";
import { SmsCampaginComponent } from "./homepage/reports/daily/smsCampaign/smsCampaignCompnent/sms-campagin/sms-campagin.component";
import { SmsCampaignTableComponent } from "./homepage/reports/daily/smsCampaign/sms-campaign-table/sms-campaign-table.component";
import { SmsCampaignMainComponent } from "./homepage/reports/daily/smsCampaign/sms-campaign-main/sms-campaign-main.component";
import { IndSmsDomainComponent } from "./homepage/reports/IndSmsDomain/indSmsDomainComponents/ind-sms-domain/ind-sms-domain.component";
import { IndSmsDomainTableComponent } from "./homepage/reports/IndSmsDomain/ind-sms-domain-table/ind-sms-domain-table.component";
import { OfferGroupMSpComponent } from "./homepage/reports/offer-group/offerGroupTables/offer-group-msp/offer-group-msp.component";
import { IndsmsVerticalComponentComponent } from "./homepage/reports/sms-vertical/smsVerticalComponent/indsms-vertical-component/indsms-vertical-component.component";
import { IndSmsVerticalComponent } from "./homepage/reports/IndSmsVertical/smsVerticalComponent/ind-sms-vertical/ind-sms-vertical.component";
import { SmsSubVerticalTableComponent } from "./homepage/reports/IndSmsVertical/sms-sub-vertical-table/sms-sub-vertical-table.component";
import { CustomReportComponent } from "./homepage/reports/custom-report/custom-report.component";
import { PartnersComponent } from "./homepage/reports/publisher/partners/partners.component";
import { OfferPublisherReportComponent } from "./homepage/reports/publisher/offer-publisher-report/offer-publisher-report.component";
import { AdvertiserReportComponent } from "./homepage/reports/publisher/advertiser-report/advertiser-report.component";
import { PublisherConversionReportComponent } from "./homepage/reports/publisher/publisher-conversion-report/publisher-conversion-report.component";
import { ChartModule } from "primeng";
import { MspDeliveredTargetComponent } from "./homepage/reports/MonthlyTarget/msp-delivered-target/msp-delivered-target.component";
import { DndNonDndTargetComponent } from "./homepage/reports/MonthlyTarget/dnd-non-dnd-target/dnd-non-dnd-target.component";
import { InputNumberModule } from "primeng/inputnumber";
import { MspMonthlyReportComponent } from './homepage/dashboard/msp-monthly-report/msp-monthly-report.component';
import { MtdVerticalPerformanceComponent } from './homepage/dashboard/mtd-vertical-performance/mtd-vertical-performance.component';
import { MtdReportComponent } from './homepage/dashboard/mtd-report/mtd-report.component';
import { TeamPerformanceComponent } from './homepage/reports/leads/team-performance/team-performance.component';
import { PhonexaSubmitLeadsComponent } from './homepage/reports/leads/phonexa-submit-leads/phonexa-submit-leads.component';
import { WebsiteWiseReportComponent } from './homepage/reports/leads/website-wise-report/website-wise-report.component';
import { GreenArrowComponent } from './homepage/reports/godseye/green-arrow/green-arrow.component';
import { OrganizationTableComponent } from './homepage/reports/godseye/organization-table/organization-table.component';
import { IndCampaignGreenArrowComponent } from './homepage/reports/godseye/ind-campaign-green-arrow/ind-campaign-green-arrow.component';
import { UsCampaignGreenArrowComponent } from './homepage/reports/godseye/us-campaign-green-arrow/us-campaign-green-arrow.component';
import { MailingListWiseReportComponent } from './homepage/reports/godseye/mailing-list-wise-report/mailing-list-wise-report.component';
import { CountryWiseOrganizationComponent } from './homepage/reports/godseye/country-wise-organization/country-wise-organization.component';
import { BdOffersReportComponent } from './homepage/reports/publisher/bd-offers-report/bd-offers-report.component';
import { ConversionLeadsComponent } from './homepage/reports/leads/conversion-leads/conversion-leads.component';
import { DropFieldRateComponent } from './homepage/reports/leads/leadstables/drop-field-rate/drop-field-rate.component';
import { DropFieldByWebsiteComponent } from './homepage/reports/leads/leadstables/drop-field-by-website/drop-field-by-website.component';
import { ChannelsWiseWebsiteComponent } from './homepage/reports/leads/leadstables/channels-wise-website/channels-wise-website.component';
import { PublisharWiseWebsiteComponent } from './homepage/reports/leads/leadstables/publishar-wise-website/publishar-wise-website.component';
import { SourceIdWisePubliserComponent } from './homepage/reports/leads/leadstables/source-id-wise-publiser/source-id-wise-publiser.component';
import { DropFieldBySourceIdComponent } from './homepage/reports/leads/leadstables/drop-field-by-source-id/drop-field-by-source-id.component';
import { MediaBuyingComponent } from './homepage/reports/leads/media-buying/media-buying.component';
import { MediaBuyingReportComponent } from './homepage/reports/leads/media-buying/media-buying-report/media-buying-report.component';
import { MediaBuyingDetailedReportComponent } from './homepage/reports/leads/media-buying/media-buying-detailed-report/media-buying-detailed-report.component';
import { LandingPageComponent } from './homepage/reports/leads/media-buying/landing-page/landing-page.component';
import { CatSmsComponent } from './homepage/reports/leads/cat-sms/cat-sms.component';
import { PhonexaLeadReportComponent } from './homepage/reports/daily/phonexa-lead-report/phonexa-lead-report.component';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "DD MMM, YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    HomepageComponent,
    TeamComponent,
    TeamtableComponent,
    OffertableComponent,
    DatesComponent,
    ListtableComponent,
    SegmenttableComponent,
    CampaigntableComponent,
    NetworkComponent,
    EspComponent,
    EsptableComponent,
    PlatformComponent,
    PlatformtableComponent,
    Network1tableComponent,
    MappingComponent,
    ListComponent,
    TriggerComponent,
    DailyComponent,
    RevenueComponent,
    SubjectlineComponent,
    CreativeComponent,
    CreativetabletemplateComponent,
    SubjectlinetabletemplateComponent,
    EspnametableComponent,
    SubesptableComponent,
    ListorderingComponent,
    DomainComponent,
    DomaintableComponent,
    CreativelibraryComponent,
    CreativelibrarytableComponent,
    CreativewindowComponent,
    CreativelogsComponent,
    PagenotfoundComponent,
    DailycreativeComponent,
    CreativePerformanceComponent,
    SubjectlinePerformanceComponent,
    DailysubjectlineComponent,
    PublisherComponent,
    PublishertableComponent,
    PublisherCreativePerformanceComponent,
    ComplianceComponent,
    NetworkUpdatesComponent,
    NetworkUpdatesLogsComponent,
    DashboardComponent,
    LeadsComponent,
    TableComponent,
    WebsitesComponent,
    WebsitesFilterComponent,
    LeadsDetailsComponent,
    DailyCountComponent,
    DailySegmentGrowthComponent,
    NetworkMappingComponent,
    OfferMappingComponent,
    NetworkGroupComponent,
    NetworkGroupTableComponent,
    OfferGroupComponent,
    OfferGroupTableComponent,
    OfferComponent,
    CreativeScoringComponent,
    DropFieldsComponent,
    SmsRevenueComponent,
    AddSMSRevenueComponent,
    ContentComponent,
    SenderIdComponent,
    MspcomponentComponent,
    SmsNetworkTableComponent,
    SmsOfferComponent,
    SmsOfferTableComponent,
    SmsNetworkComponent,
    MsptableComponent,
    MspReportComponent,
    MspServiceTableComponent,
    MspVerticalTableComponent,
    SmsOfferGroupComponent,
    SmsVerticalComponent,
    SmsContentComponent,
    SmsContentTableComponent,
    SmsCampaginComponent,
    SmsCampaignTableComponent,
    SmsCampaignMainComponent,
    IndSmsDomainComponent,
    IndSmsDomainTableComponent,
    OfferGroupMSpComponent,
    IndsmsVerticalComponentComponent,
    IndSmsVerticalComponent,
    SmsSubVerticalTableComponent,
    CustomReportComponent,
    PartnersComponent,
    OfferPublisherReportComponent,
    AdvertiserReportComponent,
    PublisherConversionReportComponent,
    MspDeliveredTargetComponent,
    DndNonDndTargetComponent,
    MspMonthlyReportComponent,
    MtdVerticalPerformanceComponent,
    MtdReportComponent,
    TeamPerformanceComponent,
    PhonexaSubmitLeadsComponent,
    WebsiteWiseReportComponent,
    GreenArrowComponent,
    OrganizationTableComponent,
    IndCampaignGreenArrowComponent,
    UsCampaignGreenArrowComponent,
    MailingListWiseReportComponent,
    CountryWiseOrganizationComponent,
    BdOffersReportComponent,
    ConversionLeadsComponent,
    DropFieldRateComponent,
    DropFieldByWebsiteComponent,
    ChannelsWiseWebsiteComponent,
    PublisharWiseWebsiteComponent,
    SourceIdWisePubliserComponent,
    DropFieldBySourceIdComponent,
    MediaBuyingComponent,
    MediaBuyingReportComponent,
    MediaBuyingDetailedReportComponent,
    LandingPageComponent,
    CatSmsComponent,
    PhonexaLeadReportComponent,
  ],
  entryComponents: [CreativewindowComponent, DomaintableComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    TableModule,
    MultiSelectModule,
    FormsModule,
    ButtonModule,
    HttpClientModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    MatMenuModule,
    TooltipModule,
    ScrollPanelModule,
    MatTabsModule,
    ToastModule,
    TreeTableModule,
    PaginatorModule,
    CheckboxModule,
    PickListModule,
    FileUploadModule,
    OverlayPanelModule,
    DialogModule,
    MatDialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ChartModule,
    InputNumberModule

  ],
  providers: [
    TeamService,
    NetworkService,
    EspService,
    PlatformService,
    DailyService,
    DomainService,
    MappingService,
    CreativelibraryService,
    ComplianceService,
    LeadsService,
    MessageService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    MatDatepickerModule,
    MatNativeDateModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
