import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from 'src/app/utils/util.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

export interface User {
  code: number;
  message: string;
  user_name: string;
  user_id: string;
  login_id: string;
  role_id: string;
  role_name: string;
  team_id: number;
  team_leader: string;
  jwt?: string;
  authorization: string;

}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  Error = '';
  subscription: Subscription;
  isMobile = false;
  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder, private utilService: UtilService) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required]
    });
    this.mobileView();
  }

  // For sending request for login
  login() {
    if (this.loginForm.value) {
      this.subscription = this.authService.login(this.loginForm.value)
        .subscribe(response => {
          this.Error = this.authService.errorMessage;
          if (response.jwt) {
            this.utilService.loginData = response;
            if(response.authorization === 'Admin'||response.authorization === 'Email')
            {
              localStorage.setItem('portalStatus', 'Email');
              this.router.navigate(['/homepage/email-dashboard']);
            }
            else if(response.authorization === 'GodEyes')
            {
              localStorage.setItem('portalStatus', 'GodEyes');
              this.router.navigate(['/homepage/green-arrow']);
            }
            else{
              localStorage.setItem('portalStatus' , response.authorization);
              this.router.navigate(['/homepage/sms-dashboard']);
            }
            this.Error = '';
            return ;
          }
        });
    }
  }

  // For changing view in mobile
  mobileView() {
    // console.log(window.matchMedia('(max-width: 893px)').matches);
    // console.log(window.innerWidth);
    if (window.matchMedia('(max-width: 893px)').matches) {
      // console.log(window.matchMedia('(max-width: 893px)').matches);
      this.isMobile = true;
    }
  }

  // For changing view dynamically
  onWindowResize(event) {
    if (event.target.matchMedia('(max-width: 893px)').matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }


  // For removing error message while typing
  removeError() {
    return this.Error = '';
  }

  // ngOnDestroy(): void {
  //   if (this.utilService.loginData !== undefined) {
  //     this.subscription.unsubscribe();
  //   }
  // }

}
