export class CreativeModel {
  creativeAllCols: any[] = [];
  creativeColFilterOptions: any[] = [];
  creativeCols: any[] = [];
  constructor() {
    // for Daily Creative Sheet Main Columns
    this.creativeAllCols = [
      { field: "creative", header: "Creative Name", type: false },
      { field: "esp_connection_title", header: "ESP Name", type: false },
      { field: "advertisers", header: "Networks", type: false },
      { field: "vertical_name", header: "Vertical", type: false },
      { field: "region_name", header: "Region", type: false },
      { field: "sent_count", header: "Sent", type: true },
      { field: "opens", header: "Open", type: true },
      { field: "opens_percent", header: "Open%", type: true },
      { field: "clicks", header: "Clicks", type: true },
      { field: "click_percent", header: "CTR", type: true },
      { field: "complaints", header: "Complaint", type: true },
      { field: "complaint_percent", header: "Complaint%", type: true },
      { field: "unsubscribe", header: "Unsub", type: true },
      { field: "unsub_percent", header: "Unsub%", type: true },
      { field: "no_of_times", header: "Creative Repeat", type: true },
    ];
    // for Daily Creative Sheet Main Columns Filter Options
    this.creativeColFilterOptions = [
      { field: "esp_connection_title", header: "ESP Name", type: false },
      { field: "advertisers", header: "Networks", type: false },
      { field: "vertical_name", header: "Vertical", type: false },
      { field: "region_name", header: "Region", type: false },
      { field: "sent_count", header: "Sent", type: true },
      { field: "opens", header: "Open", type: true },
      { field: "opens_percent", header: "Open%", type: true },
      { field: "clicks", header: "Clicks", type: true },
      { field: "click_percent", header: "CTR", type: true },
      { field: "complaints", header: "Complaint", type: true },
      { field: "complaint_percent", header: "Complaint%", type: true },
      { field: "unsubscribe", header: "Unsub", type: true },
      { field: "unsub_percent", header: "Unsub%", type: true },
      { field: "no_of_times", header: "Creative Repeat", type: true },
    ];
    // for Daily Creative Sheet Main Columns First Show
    this.creativeCols = [
      { field: "esp_connection_title", header: "ESP Name", type: false },
      { field: "advertisers", header: "Networks", type: false },
      { field: "vertical_name", header: "Vertical", type: false },
      { field: "region_name", header: "Region", type: false },
      { field: "sent_count", header: "Sent", type: true },
      { field: "opens", header: "Open", type: true },
      { field: "opens_percent", header: "Open%", type: true },
      { field: "clicks", header: "Clicks", type: true },
      { field: "click_percent", header: "CTR", type: true },
      { field: "complaints", header: "Complaint", type: true },
      { field: "complaint_percent", header: "Complaint%", type: true },
      { field: "unsubscribe", header: "Unsub", type: true },
      { field: "unsub_percent", header: "Unsub%", type: true },
      { field: "no_of_times", header: "Creative Repeat", type: true },
    ];
  }
}
