import { UtilService } from './../../utils/util.service';
import { Component, OnInit } from "@angular/core";
import 'simplebar';
import 'simplebar/dist/simplebar.css';

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"]
})
export class NavigationComponent implements OnInit
{

  isExpanded = false;
  hideThisOption = false;
  sideNavContentMinHeight: any;
  //portalStatus = this.utilService.portalStatus();


  nestedListState = {
    MAPPING: false,
    DAILY: false,
    COMPLIANCE: false,
    PUBLISHER:false,
    WEBSITE:false,
    NETWORK: false,
    OFFER: false,
    Performance:false,
    REVENUE:false,
    TARGET:false,
    GODSEYE:false
  };

  nestedListTree = {
    MAPPING: false,
    DAILY: false,
    COMPLIANCE: false,
    PUBLISHER:false,
    WEBSITE:false,
    NETWORK: false,
    OFFER: false,
    Performance:false,
    REVENUE:false,
    TARGET:false,
    GODSEYE:false
  };

  constructor(private utilService: UtilService)
  {
    // document.getElementById('sidenav').style.height = '300px';
  }

  ngOnInit()
  {
    this.hideThisOption = this.utilService.loginData.team_id === 2020 ? true : false;
    this.sideNavContentMinHeight = window.innerHeight - 42;
    this.utilService.scrollableTableHeight = window.innerHeight - 250;
  }
  portalStatus()
  {
    //debugger;
    return this.utilService.portalStatus();
  }

  expanded()
  {
    this.utilService.isExpanded = !this.utilService.isExpanded;
    return this.isExpanded = this.utilService.isExpanded;
  }

  sidebarEventHandler(event: any, key?: string, nestedTreeValue?: any)
  {
    if (!key)
    {
      this.closeAllNestedList();
      if (event.type === 'click')
      {
        this.updateNestedListTree(undefined);
      }
      return;
    }

    switch (event.type)
    {
      // expanded sidebar event
      case 'click':
        this.toggleNestedList(key);
        this.updateNestedListTree(nestedTreeValue);
        break;

      // collapsed sidebar event
      case 'mouseenter':
        this.openNestedList(key);
        break;
      case 'mouseleave':
        this.closeNestedList(key);
        break;
      case 'mouseover':
        this.openNestedList(key);
        break;
      default:
        break;
    }
  }

  // close all
  closeAllNestedList()
  {
    Object.keys(this.nestedListState).forEach(e => this.nestedListState[e] = false);
  }
  // open
  openNestedList(key: string)
  {
    this.closeAllNestedList();
    this.nestedListState[key] = true;
  }
  // close
  closeNestedList(key: string)
  {
    this.nestedListState[key] = false;
  }
  // toggle -> Fully Expanded Sidebar
  toggleNestedList(key: string)
  {
    Object.keys(this.nestedListState).filter(e => e !== key).forEach(e => this.nestedListState[e] = false);
    this.nestedListState[key] = !this.nestedListState[key];
  }
  // Handle background color
  updateNestedListTree(key: string)
  {
    if (key !== undefined)
    {
      Object.keys(this.nestedListTree).forEach(e => this.nestedListTree[e] = false);
      this.nestedListTree[key] = true;
    } else if (key === undefined)
    {
      Object.keys(this.nestedListTree).forEach(e => this.nestedListTree[e] = false);
    } else if (key === 'nothing')
    {
      return;
    }
  }
}
