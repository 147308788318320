import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { teamModel } from '../../../team/teammodels/model';
import { Subscription } from 'rxjs';
import { EspService } from '../../espservices/esp.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-espnametable',
  templateUrl: './espnametable.component.html',
  styleUrls: ['./espnametable.component.css']
})
export class EspnametableComponent implements OnInit
{

  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: teamModel[]; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription;
  fileName: any;
  tableName: any;
  teamId: any;
  listId: any;
  // getCorrectDate: number;  // Store Swaped Date
  constructor(
    private espService: EspService,
    private utilService: UtilService,
    private messageService: MessageService
  )
  {
    // Dates Array for Date Selection
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    // All Columns of Table
    this.allCols = [
      { field: "esp_name", header: "ESP Name" },
      { field: "sent_count", header: "Sent Count" },
      { field: "opens", header: "Opens" },
      { field: "opens_persent", header: "Open%" },
      { field: "portal_click", header: "Clicks" },
      { field: "ctr", header: "CTR" },
      { field: "network_click", header: "N/W Click" },
      { field: "click_persent", header: "N/W CTR" },
      { field: "unsubscribe", header: "Unsub" },
      { field: "unsub_persent", header: "Unsub%" },
      { field: "utr", header: "UTR" },
      { field: "conversion_count", header: "Conversions" },
      { field: "conversion_persent", header: "Conversion%" },
      { field: "revenue", header: "Revenue" },
      { field: "epc", header: "EPC" },
      { field: "epl", header: "EPL" },
      { field: "ecpm", header: "ECPM" }
    ];

    // Frized Columns
    this.frozenCols = [{ field: "esp_name", header: "ESP Name" }];

    // All Columns for Filter Options
    this.colFilterOptions = [
      { field: "sent_count", header: "Sent Count" },
      { field: "opens", header: "Opens" },
      { field: "opens_persent", header: "Open%" },
      { field: "portal_click", header: "Clicks" },
      { field: "ctr", header: "CTR" },
      { field: "network_click", header: "N/W Click" },
      { field: "click_persent", header: "N/W CTR" },
      { field: "unsubscribe", header: "Unsub" },
      { field: "unsub_persent", header: "Unsub%" },
      { field: "utr", header: "UTR" },
      { field: "conversion_count", header: "Conversions" },
      { field: "conversion_persent", header: "Conversion%" },
      { field: "revenue", header: "Revenue" },
      { field: "epc", header: "EPC" },
      { field: "epl", header: "EPL" },
      { field: "ecpm", header: "ECPM" }
    ];

    // First Time Showed Columns
    this.cols = [
      { field: "sent_count", header: "Sent Count" },
      { field: "opens", header: "Opens" },
      { field: "portal_click", header: "Clicks" },
      { field: "network_click", header: "N/W Click" },
      { field: "unsubscribe", header: "Unsub" },
      { field: "conversion_count", header: "Conversions" },
      { field: "revenue", header: "Revenue" },
      { field: "epc", header: "EPC" },
      { field: "epl", header: "EPL" },
      { field: "ecpm", header: "ECPM" }
    ];

    // Setting Default Date for Data
    this.teamId = this.utilService.loginData.team_id || '';
    this.startDate = this.requiredData.start_date || moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = this.requiredData.end_date || moment().subtract(1, 'day').format('YYYY-MM-DD');


    this.filterData();

  }

  // Filter Data Based on Date
  filterData(cols?: any)
  {

    this.updateStateCols(cols);
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Esp_Data_" + this.fileName;
    if (this.endDate && !this.isFilter)
    {
      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        team_id: this.teamId,
        start_date: this.startDate,
        end_date: this.endDate
      }

      // Getting Data From Server
      if (this.bodyObject && !this.isFilter)
      {
        this.subscription = this.espService.getEspData(this.bodyObject).subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData as teamModel[];
          this.loading = false;
          this.footerValues = { undefined };
          if (this.data.length !== 0)
          {
            this.getFooterData(this.data);
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data,
              this.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        });
      }

    }
  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }


  // Update Footer Values
  getFooterData(tableData)
  {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }


  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Getting Dates from Dates Component
  getDates(datesObj)
  {
    if (datesObj)
    {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }

  }

  // Columns State Save
  saveColumnState(columns: any)
  {
    if (localStorage.getItem('espTable'))
    {
      var sessionStored = JSON.parse(localStorage.getItem('espTable'));
      if (sessionStored['columnWidths'])
      {
        delete sessionStored['columnWidths'];
      }
      if (sessionStored['sortField'])
      {
        delete sessionStored['sortField'];
      }
      if (sessionStored['sortOrder'])
      {
        delete sessionStored['sortOrder'];
      }
      if (sessionStored['filters'])
      {
        delete sessionStored['filters'];
      }
      if (sessionStored['first'])
      {
        sessionStored['first'] = 0;
      }
      if (sessionStored['rows'])
      {
        delete sessionStored['rows'];
      }
      localStorage.espTable = JSON.stringify(sessionStored);
      const cols: any[] = [];
      columns.forEach(element => cols.push(element.field));
      sessionStored['columnOrder'] = cols;
      localStorage.espTable = JSON.stringify(sessionStored);
    } else
    {
      let sessionStored: any = {};
      const cols: any[] = [];
      columns.forEach(element => cols.push(element.field));
      sessionStored = { "first": 0, "rows": 10, 'columnOrder': cols };
      localStorage.espTable = JSON.stringify(sessionStored);
    }
  }


  // Update Cols State on Every Col Change
  updateStateCols(cols?: any)
  {
    // Setting Default Columns from State Key
    if (localStorage.getItem('espTable'))
    {
      const columns = JSON.parse(localStorage.getItem('espTable'));
      const colsData = this.allCols;
      this.cols = [];
      colsData.forEach(elements => columns.columnOrder.forEach(key =>
      {
        if (key === elements.field)
        {
          this.cols.push(elements);
        }
      }));
    }
    // Setting State Cols in Local Storage
    let sessionStored = JSON.parse(localStorage.getItem('espTable'));
    if (cols)
    {
      cols.forEach(element => cols.push(element.field));
      sessionStored = { "first": 0, "rows": 10, 'columnOrder': cols };
      localStorage.espTable = JSON.stringify(sessionStored);
    } else
    {
      let cols: any[] = [];
      this.cols.forEach(element => cols.push(element.field));
      sessionStored = { "first": 0, "rows": 10, 'columnOrder': cols };
      localStorage.espTable = JSON.stringify(sessionStored);
    }
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.unsubscribe();
  }

}
