import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UtilService } from '../utils/util.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: any;
  isAdmin =  false;
  portalStatus: any;
  constructor(private authService: AuthService, private utilService: UtilService, private router: Router) {
    this.userName = this.authService.currentUserValue.user_name;
    this.portalStatus = this.utilService.portalStatus();
    if(this.authService.currentUserValue.authorization === 'Admin'){
      this.isAdmin = true;
     // this.router.navigate(['/homepage/email-dashboard']);
    }
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
    // localStorage.removeItem('sessionData');
    // localStorage.removeItem('teamTabs');
    // localStorage.removeItem('networkTabs');
    // localStorage.removeItem('espTabs');
    // localStorage.removeItem('platformTabs');
    return this.router.navigate(['/login']);
  }

  isExpanded() {
    return this.utilService.isExpanded;
  }
  switch()
  {

    localStorage.portalStatus=localStorage.portalStatus ==='Email'?'Sms':'Email';

    this.portalStatus=localStorage.portalStatus;
    if(this.portalStatus==='Email'){
      this.router.navigate(['/homepage/email-dashboard']);
    }else{
      this.router.navigate(['/homepage/sms-dashboard']);
    }

  }


}
