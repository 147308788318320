<div class="p-grid footer_head">
  <div class="footer_height p-col-2">
    <div class="footer_logo">
      <img
        src="assets/img/logow.svg"
        [ngStyle]="{
          width: '75px',
          marginTop: '-.25rem'
        }"
        alt=""
      />
    </div>
  </div>
  <div class="footer_height p-col-10">
    <div class="footer_profile">COPYRIGHT RIGHTFUL LABS {{ currentYear }}</div>
  </div>
</div>
