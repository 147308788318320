import { SmsVerticalService } from './../IndSmsVertical/indSmsVerticalServices/sms-vertical.service';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { isString } from 'util';
import { FilterUtils } from 'primeng/utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-sms-vertical',
  templateUrl: './sms-vertical.component.html',
  styleUrls: ['./../../../app.component.css']
})
export class SmsVerticalComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: { headers: any[], data: any[],config:any }; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription;
  checkTeamId: any;
  teamOptions: any[] = [];
  tablesData: any[] = [];
  fileName: any;
  tableName: any;
  teamId: any;
  regionId: any;
  verticalId: any = '';
  showModel: any = '';
  minDate: Date = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate: Date = moment().toDate();
  selectDates: Date = moment().toDate();
  portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;
  getCorrectDate: number;  // Store Swaped Date
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  exporting:boolean;
  constructor(
    private smsVerticalService: SmsVerticalService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
     // Setting Default Date for Data
    // debugger;
     if (this.requiredData.date1)
     { //debugger;
       this.startDate = this.requiredData.date1;
       this.endDate = this.requiredData.date2;
     } else
     {
       this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
       this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
     }
     this.filterData();


    FilterUtils['filterNumber'] = (value, filter): boolean =>
    {
      //
      let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
      let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null))
      {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan))
      {
        return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
      } else if (Number(greaterThan))
      {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan))
      {
        return FilterUtils.lt(value, lessThan);
      } else
      {
        return value;
      }
    };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }

    // For Showing Filter Model
    letShowModel(column: any, value: any)
    {
      return value === column ? false : true;
    }
    // For Showing Filter Model
    setShowModelValue(columnValue: any)
    {
      return this.showModel = columnValue;
    }
    // For Hiding Filter Model
    hideDialog()
    {
      return this.showModel = '';
    }
    filterData(type?: any, cols?: any)
    {

      if (cols !== undefined && moment.isDate(cols))
      {
        this.startDate = moment(cols).format('YYYY-MM-DD');
      }
      if (cols !== undefined && isString(cols))
      {
        this.teamId = cols;
      }

      this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
      this.tableName = "Websites_" + this.fileName;
      if (this.endDate && !this.isFilter)
      {
        this.loading = true;
        // Passing Updated/Filtered Date
        this.bodyObject = {
          start_date: this.startDate,
          end_date: this.endDate,
        };

        // Getting Data From Server
        if (this.bodyObject && !this.isFilter)
        {
          this.subscription = this.smsVerticalService.getVerticalData(this.bodyObject).subscribe(responseData =>
          {
            if (responseData['error'] === 'Expired token')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
              return this.utilService.logedOut();
            }
            if (responseData['code'])
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              return null;
            }
            this.data = responseData as { headers: any[], data: any[] ,config:any};
            // All Columns of Table
            this.allCols = this.data.headers;

            // Frized Columns
            this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
            this.tableConfig = this.data.config;

            // All Columns for Filter Options
            this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

            // First Time Showed Columns
            this.cols = localStorage.smsVertical ? JSON.parse(localStorage.smsVertical) : this.data.headers.filter(col => col.type !== 'primary');
            this.loading = false;
            this.footerValues = { undefined };
            if (this.data.data.length !== 0)
            {
              this.getFooterData(this.data.data);
              this.filterOptions = this.utilService.getRequiredOptions(
                this.data.data,
                this.data.data[0]
              );
            } else
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
            }
          }, err =>
          {
            if (err)
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              this.loading = false;
              return null;
            }
          });
        }

      }
    }
    // Update Filter Options
    updateFilterOptions(tableData, colName)
    {
      if (tableData !== null)
      {
        this.getFooterData(tableData);
        this.filterOptions = this.utilService.getRequiredOptions(
          tableData,
          tableData[0]
        );
        this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
      }
    }

          // Update Footer Values
      getFooterData(tableData)
      {
        this.footerValues = this.utilService.getRequiredFooterData(tableData);
      }
        // Add New Tab in Tab Array
        addNewTab(type, header, startDate, endDate, data)
        {
          this.AddTabController.emit({ type, header, startDate, endDate, data });
        }

        // Getting Dates from Dates Component
        getDates(datesObj)
        {
          if (datesObj)
          {
            this.startDate = datesObj.start_date;
            this.endDate = datesObj.end_date;
            this.filterOptions = [];
            this.filterData();
          }
        }
          // For color coding
      dropOffBackgroundColorCoding(dropRatio: number)
      {
        if (dropRatio <= 5)
        {
          return '#1be56c';
        }
        else if (dropRatio > 5 && dropRatio <= 10)
        {
          return '#36e87e';
        }
        else if (dropRatio > 10 && dropRatio <= 15)
        {
          return '#4ceb8b';
        }
        else if (dropRatio > 15 && dropRatio <= 20)
        {
          return '#65ed9c';
        }
        else if (dropRatio > 20 && dropRatio <= 25)
        {
          return '#7cf0aa';
        }
        else if (dropRatio > 25 && dropRatio <= 30)
        {
          return '#9df4c0';
        }
        else if (dropRatio > 30 && dropRatio <= 35)
        {
          return '#b1f6cc';
        }
        else if (dropRatio > 35 && dropRatio <= 40)
        {
          return '#cffadf';
        }
        else if (dropRatio > 40 && dropRatio <= 45)
        {
          return '#dbffea';
        }
        else if (dropRatio > 45 && dropRatio <= 50)
        {
          return '#eafdf1';
        }
        else if (dropRatio > 50 && dropRatio <= 55)
        {
          return '#ffe3e3';
        }
        else if (dropRatio > 55 && dropRatio <= 60)
        {
          return '#ffdfdf';
        }
        else if (dropRatio > 60 && dropRatio <= 65)
        {
          return '#ffd2d2';
        }
        else if (dropRatio > 65 && dropRatio <= 70)
        {
          return '#ffc4c4';
        }
        else if (dropRatio > 70 && dropRatio <= 75)
        {
          return '#ffb1b1';
        }
        else if (dropRatio > 75 && dropRatio <= 80)
        {
          return '#ff9797';
        }
        else if (dropRatio > 80 && dropRatio <= 85)
        {
          return '#ff8484';
        }
        else if (dropRatio > 85 && dropRatio <= 90)
        {
          return '#ff7778';
        }
        else
        {
          return '#ff6161';
        }
      }
      dropOffColorCoding(dropRatio: number)
      {
        if (dropRatio <= 5)
        {
          return '#ffffff';
        }
        else if (dropRatio > 5 && dropRatio <= 10)
        {
          return '#ffffff';
        }
        else if (dropRatio > 10 && dropRatio <= 15)
        {
          return '#ffffff';
        }
        else if (dropRatio > 15 && dropRatio <= 20)
        {
          return '#ffffff';
        }
        else if (dropRatio > 20 && dropRatio <= 25)
        {
          return '#ffffff';
        }
        else if (dropRatio > 25 && dropRatio <= 30)
        {
          return '#444444';
        }
        else if (dropRatio > 30 && dropRatio <= 35)
        {
          return '#333333';
        }
        else if (dropRatio > 35 && dropRatio <= 40)
        {
          return '#222222';
        }
        else if (dropRatio > 40 && dropRatio <= 45)
        {
          return '#111111';
        }
        else if (dropRatio > 45 && dropRatio <= 50)
        {
          return '#000000';
        }
        else if (dropRatio > 50 && dropRatio <= 55)
        {
          return '#111111';
        }
        else if (dropRatio > 55 && dropRatio <= 60)
        {
          return '#222222';
        }
        else if (dropRatio > 60 && dropRatio <= 65)
        {
          return '#333333';
        }
        else if (dropRatio > 65 && dropRatio <= 70)
        {
          return '#444444';
        }
        else if (dropRatio > 70 && dropRatio <= 75)
        {
          return '#ffffff';
        }
        else if (dropRatio > 75 && dropRatio <= 80)
        {
          return '#ffffff';
        }
        else if (dropRatio > 80 && dropRatio <= 85)
        {
          return '#ffffff';
        }
        else if (dropRatio > 85 && dropRatio <= 90)
        {
          return '#ffffff';
        }
        else
        {
          return '#ffffff';
        }
      }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.smsVertical = JSON.stringify(dt.columns);
  }
      // Adding Column For Exporting in Csv
      exportData(dt)
      {

          // dt.columns.splice(0, 0, this.frozenCols[0]);
          // dt.exportCSV();
          // return dt.columns.splice(0, 1);
          this.exporting = true;
          const body = {
            ...this.tableConfig,
            reportName:this.tableConfig.export.reportName,
            user_id: this.utilService.loginData.user_id

        };
        this.utilService.requestForExportFile(this.tableConfig.export.exportLink,body).subscribe(res =>
          {
            this.exporting = false;
            if (res["code"] === 200)
            {
              this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'File Export Request has been sent successfully!!!' });
            }
          });


      }

      // For getting string length(Tooltip)
      showTooltip(text: any)
      {
        return text && (String(text).length > 9) ? text : '';
      }

      // Destroy all subscriptons
      ngOnDestroy(): void
      {
        this.subscription.unsubscribe();
      }

}
