import { CustomReportService } from "./customReportService/custom-report.service";
import { teamModel } from "src/app/homepage/reports/team/teammodels/model";
import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { MappingService } from "../mapping/mappingservices/mapping.service";
import { UtilService } from "src/app/utils/util.service";
import { MessageService } from "primeng/api";
import { FilterUtils } from "primeng/utils";
import { Papa } from "ngx-papaparse";
import { isString } from "util";
import { DomaintableComponent } from "../domain/domaintables/domaintable/domaintable.component";
import { MatDialog } from "@angular/material/dialog";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-custom-report",
  templateUrl: "./custom-report.component.html",
  styleUrls: ["../../../../app/app.component.css"],
})
export class CustomReportComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  regiform: FormGroup; // for Form Reference
  options: any[] = []; // for Creating Options for Ongage List
  fieldOptions: any[] = []; //for Creating Options for Field list
  fields: any[] = [];
  group: any[] = [];
  offerOptions: any[] = []; // for Creating Options for Ongage List
  baseReport: any; // for storing network id value
  checkFields: any;
  offerId: any; // for storing network id vaslue
  cols: any[]; // Array for Showed Columns
  allCols: any[]; // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {}; // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false; // for Column Filter Toggle
  isGlobalFilter = false; // for Global Filter Input
  loading: boolean; // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {}; // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = "";
  networkName: any = "";
  startDate: any; // Store Start Date
  endDate: any; // Store End Date
  dates: any[]; // for Dates Filter by Today, Yesterday etc
  minDate: Date = moment("01-05-2019", "DD-MM-YYYY").toDate();
  maxDate: Date = moment().subtract(1, "day").toDate();
  user_id: any = 0;
  fileName: any;
  teamId: any;
  tableName: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  footerValues: any = {}; // Data Storing for Column Footer
  revenue_id: any;
  num1: any;
  num2: any;
  userId: any = 0;
  deleteReason: any = "";
  fileType: string;
  permissibleMember: string;
  tableConfig: any;
  selectField: any = [];
  groupField: any = [];
  checked: boolean;
  exporting:boolean;
  constructor(
    private customReportService: CustomReportService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService
  ) {
    // Dates Array for Date Selection
    this.startDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "day").format("YYYY-MM-DD");
  }

  ngOnInit(): void {
    //debugger;
    this.scrollHeight = this.utilService.scrollableTableHeight + "px";
    this.startDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.regiform = this.fb.group({
      baseId: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
    });
    this.userId = this.utilService.loginData.user_id;
    this.getInputData();
    this.filterData();
    // this.fieldRecordData();
    FilterUtils["filterString"] = (value, filter): boolean => {
      let startString = document.getElementById(filter + "_starts_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_starts_with"))
            .value
        : null;
      let endString = document.getElementById(filter + "_ends_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_ends_with"))
            .value
        : null;

      if (value === undefined || value === null) {
        return false;
      }
      if (
        (startString === undefined && endString === undefined) ||
        (startString === null && endString === null)
      ) {
        return value;
      }
      if (startString && endString) {
        return (
          FilterUtils.startsWith(value, startString) &&
          FilterUtils.endsWith(value, endString)
        );
      } else if (startString) {
        return FilterUtils.startsWith(value, startString);
      } else if (endString) {
        return FilterUtils.endsWith(value, endString);
      } else {
        return value;
      }
    };

    // this.leads.valueChanges.subscribe(value=>this.revenue.setValue(Number(this.payout.value)*Number(value)));
    // this.payout.valueChanges.subscribe(value=>this.revenue.setValue(Number(this.leads.value)*Number(value)));
    // this.network_id.valueChanges.subscribe(value=>this.getInputOfferData(value));
    // this.getInputOfferData();

    this.baseId.valueChanges.subscribe((value: number) => {
      this.fieldRecordData({ baseId: value });
    });
  }
  getInputData() {
    this.subscription.push(
      this.customReportService
        .getCustomReportBaseOptions()
        .subscribe((ongagelist: any[]) => {
          this.options = ongagelist.map((e) => {
            return {
              label: `${e["name"]} `,
              value: e["baseId"],
            };
          });
        })
    );
  }
  // For Showing Filter Model
  letShowModel(column: any, value: any) {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any) {
    return (this.showModel = columnValue);
  }
  // For Hiding Filter Model
  hideDialog() {
    return (this.showModel = "");
  }

  getDates(datesObj) {
    if (datesObj) {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }
  portalStatus() {
    return this.utilService.portalStatus();
  }
  get baseId() {
    return this.regiform.get("baseId");
  }
  get start_date() {
    return this.regiform.get("start_date");
  }
  get end_date() {
    return this.regiform.get("end_date");
  }
  get selectFieldRecord() {
    return this.regiform.get("group1");
  }
  filterData(type?: any, cols?: any) {
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Websites_" + this.fileName;
    if (this.baseId.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Base Report not selected!",
      });
      return;
    }

    if (this.start_date.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Start Date not selected!",
      });
      return;
    }
    if (this.end_date.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "End Date Not selected!",
      });
      return;
    }
    //debugger;
    this.loading = true;
    // Passing Updated/Filtered Date
    this.bodyObject = {
      baseId: this.baseId.value,
      start_date: moment(this.start_date.value).format("YYYY-MM-DD"),
      end_date: moment(this.end_date.value).format("YYYY-MM-DD"),
      fieldCheck: this.fields
        .filter((value) => value.active)
        .map((value) => value.field),
      groupCheck: this.group
        .filter((value) => value.active)
        .map((value) => value.field),
    }; //debugger;

    this.loading = true;

    // Getting Data From Server
    if (!this.isFilter) {
      if (this.bodyObject && !this.isFilter) {
        this.subscription.push(
          this.customReportService
            .generateReportData(this.bodyObject)
            .subscribe(
              (responseData) => {
                //debugger;
                if (responseData["error"] === "Expired token") {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Loged out",
                    detail: "Session expired, please login again!!!",
                  });
                  return this.utilService.logedOut();
                }
                // if (responseData['code'])
                // {
                //   this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
                //   return null;
                // }
                //debugger;
                this.data = responseData as {
                  headers: any[];
                  data: any[];
                  config: any;
                };
                // All Columns of Table
                this.allCols = this.data.headers;

                // Frized Columns
                this.frozenCols = this.data.headers.filter(
                  (col) => col.type === "primary"
                );
                this.tableConfig = this.data.config;

                // All Columns for Filter Options
                this.colFilterOptions = this.data.headers.filter(
                  (col) => col.type !== "primary"
                );

                // First Time Showed Columns
                this.cols = this.data.headers.filter(
                  (col) => col.type !== "primary"
                );
                this.loading = false;
                this.footerValues = { undefined };
                if (this.data.data.length !== 0) {
                  this.getFooterData(this.data.data);
                  this.filterOptions = this.utilService.getRequiredOptions(
                    this.data.data,
                    this.data.data[0]
                  );
                } else {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Warning",
                    detail: "Data not found in the given date range",
                  });
                }
              },
              (err) => {
                if (err) {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Warning",
                    detail: "Something went wrong! Server not responding!",
                  });
                  this.loading = false;
                  return null;
                }
              }
            )
        );
      }
    }
  }
  // Field Record data
  fieldRecordData(body: any) {
    this.subscription.push(
      this.customReportService
        .customFieldHeads(body)
        .subscribe((fieldRecord: any) => {
          //debugger;

          this.fields = fieldRecord?.fields;
          this.group = fieldRecord?.groups;
          this.selectField = fieldRecord?.fields.field;
          this.groupField = fieldRecord?.fields.field;
          // this.fieldOptions = fieldRecord;
          //console.log(fieldRecord.groups);
        })
    );
  }
  valueChange(event) {
    event.active = event.active ? false : true;
  }

  disabledFieldCheckBox(item) {
    const value = this.group.filter((value) => value.baseId === item.baseId);
    if (value?.[0]?.active) {
      return true;
    } else {
      return false;
    }
  }

  disabledGroupCheckBox(item) {
    const value = this.fields.filter((value) => value.baseId === item.baseId);
    if (value?.[0]?.active) {
      return true;
    } else {
      return false;
    }
  }

  // For color coding
  dropOffBackgroundColorCoding(dropRatio: number) {
    if (dropRatio <= 5) {
      return "#1be56c";
    } else if (dropRatio > 5 && dropRatio <= 10) {
      return "#36e87e";
    } else if (dropRatio > 10 && dropRatio <= 15) {
      return "#4ceb8b";
    } else if (dropRatio > 15 && dropRatio <= 20) {
      return "#65ed9c";
    } else if (dropRatio > 20 && dropRatio <= 25) {
      return "#7cf0aa";
    } else if (dropRatio > 25 && dropRatio <= 30) {
      return "#9df4c0";
    } else if (dropRatio > 30 && dropRatio <= 35) {
      return "#b1f6cc";
    } else if (dropRatio > 35 && dropRatio <= 40) {
      return "#cffadf";
    } else if (dropRatio > 40 && dropRatio <= 45) {
      return "#dbffea";
    } else if (dropRatio > 45 && dropRatio <= 50) {
      return "#eafdf1";
    } else if (dropRatio > 50 && dropRatio <= 55) {
      return "#ffe3e3";
    } else if (dropRatio > 55 && dropRatio <= 60) {
      return "#ffdfdf";
    } else if (dropRatio > 60 && dropRatio <= 65) {
      return "#ffd2d2";
    } else if (dropRatio > 65 && dropRatio <= 70) {
      return "#ffc4c4";
    } else if (dropRatio > 70 && dropRatio <= 75) {
      return "#ffb1b1";
    } else if (dropRatio > 75 && dropRatio <= 80) {
      return "#ff9797";
    } else if (dropRatio > 80 && dropRatio <= 85) {
      return "#ff8484";
    } else if (dropRatio > 85 && dropRatio <= 90) {
      return "#ff7778";
    } else {
      return "#ff6161";
    }
  }
  dropOffColorCoding(dropRatio: number) {
    if (dropRatio <= 5) {
      return "#ffffff";
    } else if (dropRatio > 5 && dropRatio <= 10) {
      return "#ffffff";
    } else if (dropRatio > 10 && dropRatio <= 15) {
      return "#ffffff";
    } else if (dropRatio > 15 && dropRatio <= 20) {
      return "#ffffff";
    } else if (dropRatio > 20 && dropRatio <= 25) {
      return "#ffffff";
    } else if (dropRatio > 25 && dropRatio <= 30) {
      return "#444444";
    } else if (dropRatio > 30 && dropRatio <= 35) {
      return "#333333";
    } else if (dropRatio > 35 && dropRatio <= 40) {
      return "#222222";
    } else if (dropRatio > 40 && dropRatio <= 45) {
      return "#111111";
    } else if (dropRatio > 45 && dropRatio <= 50) {
      return "#000000";
    } else if (dropRatio > 50 && dropRatio <= 55) {
      return "#111111";
    } else if (dropRatio > 55 && dropRatio <= 60) {
      return "#222222";
    } else if (dropRatio > 60 && dropRatio <= 65) {
      return "#333333";
    } else if (dropRatio > 65 && dropRatio <= 70) {
      return "#444444";
    } else if (dropRatio > 70 && dropRatio <= 75) {
      return "#ffffff";
    } else if (dropRatio > 75 && dropRatio <= 80) {
      return "#ffffff";
    } else if (dropRatio > 80 && dropRatio <= 85) {
      return "#ffffff";
    } else if (dropRatio > 85 && dropRatio <= 90) {
      return "#ffffff";
    } else {
      return "#ffffff";
    }
  }
  // Update Filter Options
  updateFilterOptions(tableData, colName) {
    if (tableData !== null) {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[
        colName
      ] = this.utilService.getUniqueValuesObjectFromArray(
        this.data.data,
        colName
      );
    }
  }
  // Update Footer Values
  getFooterData(tableData) {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }
  // Columns State Save
  saveColumnState(dt: any) {
    // localStorage.customReport= JSON.stringify(dt.columns);
  }
  submit() {
    //debugger;
    if (this.baseId.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Base Report not selected!",
      });
      return;
    }

    if (this.start_date.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Start Date not selected!",
      });
      return;
    }
    if (this.end_date.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "End Date Not selected!",
      });
      return;
    }
    //this.Revenue.enable();
    const body = {
      baseId: this.baseId.value,
      start_date: moment(this.start_date.value).format("YYYY-MM-DD"),
      end_date: moment(this.end_date.value).format("YYYY-MM-DD"),
      fieldCheck: this.selectField,
      groupCheck: this.groupField,
    };
    //debugger;
    this.customReportService.generateReportData(body).subscribe((res) => {
      if (res["code"] === 200) {
        this.messageService.add({
          key: "tc",
          severity: "success",
          summary: "Success",
          detail: "Report Created successfully!!!",
        });
        this.regiform.reset();
        // this.baseReport = '';
        this.checkFields ='';
        // this.revenue_id='';
      }
      if (res["code"] === 201) {
        this.messageService.add({
          key: "tc",
          severity: "success",
          summary: "Success",
          detail: "Data Saved successfully!!!",
        });
        this.regiform.reset();
        // this.baseReport = '';
        this.checkFields ='';
        this.filterData();
        // this.revenue_id='';
      }
      if (res["code"] === 400) {
        this.messageService.add({
          key: "tc",
          severity: "error",
          summary: "Network Name already exist",
          detail: "Please edit to update details!!!",
        });
      }
    });
  }
  reset() {
    //debugger;
    this.regiform.reset();
    this.fieldRecordData(this.bodyObject);
  }
  // Adding Column For Exporting in Csv
  exportData(dt) {
    this.exporting = true;
    //debugger;
    // dt.columns.splice(dt.columns.length - 1, 1);
    // dt.exportCSV();
    // return dt.columns.splice(dt.columns.length - 1, 1, { field: 'edit', header: 'Edit' });
    const body = {
      ...this.tableConfig,
      reportName: this.tableConfig.export.reportName,
      user_id: this.utilService.loginData.user_id,
    };
    this.utilService
      .requestForExportFile(this.tableConfig.export.exportLink, body)
      .subscribe((res) => {
        this.exporting = false;
        //debugger
        if (res["code"] === 200) {
          this.messageService.add({
            key: "tc",
            severity: "success",
            summary: "Success",
            detail:
              "Your File Export Request has been generated successfully!!!",
          });
        }
      });
  }
  // For getting string length(Tooltip)
  showTooltip(text: any) {
    return text && String(text).length > 9 ? text : "";
  }

  // Destroy all subscriptons
  ngOnDestroy(): void {
    this.subscription.forEach((e) => e.unsubscribe());
  }
}
