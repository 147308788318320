import { environment } from './../../../../../environments/environment';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UtilService } from './../../../../utils/util.service';

@Injectable({
  providedIn: "root"
})
export class TeamService
{
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient, private utilService: UtilService)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Teams",
      isClosable: false
    });
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }
  getTeamData(body: any)
  {
    return this.http.post(this.baseURL + "GetData/team", body);
  }

  getOfferData(body: any)
  {
    if(this.utilService.portalStatus() === 'Email')
    {
      return body.offer_group_id ? this.http.post(this.baseURL + "GetData/offerGroup", body) : this.http.post(this.baseURL + "GetData/offers", body);
    }
    else{
      return body.offer_group_id ? this.http.post(this.baseURL + "sms/getofferGroup", body) : this.http.post(this.baseURL + "sms/getOffersReport", body);
      //return this.http.post(this.baseURL + "sms/getOffersReport", body);
    }
  }

  getListData(body: any)
  {
    return this.http.post(this.baseURL + "GetData/lists", body);
  }

  getSegmentData(body: any)
  {
    return this.http.post(this.baseURL + "GetData/segments", body);
  }

  getCampaignData(body: any)
  {
    return this.http.post(this.baseURL + "GetData/campaign", body);
  }


}

