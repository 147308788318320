import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NetworkUpdates } from '../compliancemodels/network-updates.model';
import { NetworkUpdatesLogs } from '../compliancemodels/network-updates-logs.model';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  networkUpdates = new NetworkUpdates();
  networkUpdatesLogs = new NetworkUpdatesLogs();
  constructor(private http: HttpClient) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Network Compliance",
      isClosable: false
    });
  }

  insertNetworkUpdatesData(body: any) {
    return this.http.post(this.baseURL + "creatives/compliance", body);
  }

  getNetworkUpdatesData() {
    return this.http.get(this.baseURL + "creatives/displayCompliance");
  }

  getComplianceLogsData(networkId: any) {
    return this.http.get(this.baseURL + "creatives/complianceLogs/" + networkId);
  }

  getNetworkOptions() {
    return this.http.get(this.baseURL + "creatives/complianceNetworks");
  }
}
