import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subjectlinetabletemplate',
  templateUrl: './subjectlinetabletemplate.component.html',
  styleUrls: ['./subjectlinetabletemplate.component.css']
})
export class SubjectlinetabletemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
