import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { LeadsService } from '../../leadsservices/leads.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-websites-filter',
  templateUrl: './websites-filter.component.html',
  styleUrls: ['./../../../../../app.component.css']
})
export class WebsitesFilterComponent implements OnInit
{
  webFilterForm: FormGroup;

  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;

  regionOptions: any[] = [];
  verticalOptions: any[] = [];

  constructor(
    private service: LeadsService,
    private fb: FormBuilder) { }

  ngOnInit()
  {
    this.webFilterForm = this.fb.group({
      region: ['', [Validators.required]],
      vertical: ['', [Validators.required]]
    });
    this.getRegionOptions();
    this.getVerticalOptions();
  }

  // For Getting Network Options
  getRegionOptions()
  {
    this.service.getRegionOptions().subscribe((data: any[]) =>
    {
      if (data.length != undefined)
      {
        data.forEach(optionRow =>
          this.regionOptions.push({
            label: optionRow.name, value: optionRow
          })
        );
      }
    });
  }

  // For Getting Network Options
  getVerticalOptions()
  {
    this.service.getVerticalOptions().subscribe((data: any[]) =>
    {
      if (data.length != undefined)
      {
        data.forEach(optionRow =>
          this.verticalOptions.push({
            label: optionRow.name, value: optionRow
          })
        );
      }
    });
  }

  // For adding new tab in tab array for websites
  submit()
  {
    this.addNewTab('Websites - ', `${this.vertical.value.name} (${this.region.value.name})`, moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD'), this.webFilterForm.value);
  }

  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });

  }


  // For form field getters
  get region()
  {
    return this.webFilterForm.get('region');
  }
  get vertical()
  {
    return this.webFilterForm.get('vertical');
  }

}
