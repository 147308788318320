<div class="heading">
  <div class="dailyRevenueReport tab">
    Advertiser Report
  </div>
</div>
<span *ngIf="data && data.data">
<p-table
#dt
[(columns)]="cols"
[value]="data.data "
[paginator]="true"
[rows]="20"
paginatorDropdownAppendTo="body"
[rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
[showCurrentPageReport]="true"
[globalFilterFields]="allCols"
[reorderableColumns]="true"
[resizableColumns]="true"
[scrollable]="true"
[scrollHeight]="scrollHeight"
[loading]="loading"
(onColReorder)="saveColumnState(dt)"
[exportFilename]="'Revenue_Report' + fileName || tableName"
[frozenColumns]="frozenCols"
frozenWidth="250px"

>
<ng-template pTemplate="caption" style="padding-top:2rem">
  <div class="ui-helper-clearfix" >
    <div class="p-grid">
      <div class="ui-g-2">
        <input
          field="text"
          pInputText
          size="50"
          placeholder="Search"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          class="tableGlobalSearch"
          (focus)="isGlobalFilter = !isGlobalFilter"
          (focusout)="isGlobalFilter = !isGlobalFilter"
          style="width: auto;"
        />
        <i class="tableGlobalSearch pi pi-search" style=""></i>
      </div>
      <app-dates
        class="ui-g-5"
        [getDates]="{ start_date: startDate, end_date: endDate }"
        (pullDates)="getDates($event)"
      ></app-dates>

      <div class="ui-g-5 filterExportToggle">
        <div class="ui-g-6 extra_input">
          <p-multiSelect
            class="columnFilter"
            [options]="colFilterOptions"
            [(ngModel)]="cols"
            optionLabel="header"
            selectedItemsLabel="{0} _ selected"
            [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
            defaultLabel="Choose Columns"
            (onClick)="saveColumnState(dt)"
          >
          </p-multiSelect>
        </div>

        <div class="ui-g-3 filter_c">
          <button
            field="button"
            pButton
            icon="pi pi-filter"
            iconPos="left"
            label="Filter"
            (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
          ></button>
        </div>

        <div class="ui-g-3 export_c">
          <button
            field="button"
            pButton
            iconPos="left"
            label="Export"
            (click)="exportData()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template pTemplate="colgroup" let-columns>
  <colgroup>
    <col
      *ngFor="let col of columns"
      pResizableColumn
      pReorderableColumn
      [pSortableColumn]="col.field"
      [ngStyle]="{
        width: col.type === 'primary' ? '200px' : '110px'
      }"
    />
  </colgroup>
</ng-template>
<ng-template pTemplate="header" let-columns>
  <tr>
    <th
      [pTooltip]="showTooltip(col.header)"
      *ngFor="let col of columns"
      [pSortableColumn]="col.field"
      pReorderableColumn
      pResizableColumn
      [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
      }"
    >
      <span>{{ col.header }}</span>
      <p-sortIcon
        [field]="col.field"
        ariaLabel="Activate to sort"
        ariaLabelDesc="Activate to sort in descending order"
        ariaLabelAsc="Activate to sort in ascending order"
      >
      </p-sortIcon>
    </th>
  </tr>
  <tr *ngIf="isFilter">
    <th class="columnFilterTH" *ngFor="let col of columns">
      <p-overlayPanel
        #op
        [showCloseIcon]="true"
        [dismissable]="true"
        appendTo="th"
      >
        <ng-container *ngIf="col?.type==='number'||col.type==='percent'||col.type ==='currency'"
          ><div [ngStyle]="{ float: 'left', display: 'contents' }">
            {{ col.header }}
          </div>
          <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
            <input
              [attr.id]="col.field + '_greater_than'"
              *ngIf="isFilter"
              (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
              (change)="
                getFooterData(dt.filteredValue);
                updateFilterOptions(dt.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Greater Than"
            />
          </div>
          <div style="margin-bottom: 0.25rem;">
            <input
              [attr.id]="col.field + '_less_than'"
              *ngIf="isFilter"
              (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
              (change)="
                getFooterData(dt.filteredValue);
                updateFilterOptions(dt.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Less Than"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="col.type === 'string' || col.type === 'primary'">
          <div [ngStyle]="{ float: 'left', display: 'contents' }">
            {{ col.header }}
          </div>
          <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
            <input
              [attr.id]="col.field + '_starts_with'"
              *ngIf="isFilter"
              (keyup)="dt.filter(col.field, col.field, 'filterString')"
              (change)="
                getFooterData(dt.filteredValue);
                updateFilterOptions(dt.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Starts With"
            />
          </div>
          <div style="margin-bottom: 0.25rem;">
            <input
              [attr.id]="col.field + '_ends_with'"
              *ngIf="isFilter"
              (keyup)="dt.filter(col.field, col.field, 'filterString')"
              (change)="
                getFooterData(dt.filteredValue);
                updateFilterOptions(dt.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Ends With"
            />
          </div>
        </ng-container>
        <div>
          <p-multiSelect
            appendTo="body"
            [filter]="isFilter"
            [options]="filterOptions[col.field]"
            [style]="{ width: '100%' }"
            defaultLabel=""
            (onChange)="dt.filter($event.value, col.field, 'in')"
            (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
          ></p-multiSelect>
        </div>
      </p-overlayPanel>
      <p-button
        class="openAdvanceFilterPanel"
        label="⟱"
        (click)="op.toggle($event)"
      ></p-button>
    </th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr>
    <td
      [pTooltip]="showTooltip(rowData[col.field])"
      *ngFor="let col of columns"
      [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary'
            ? 'left'
            : 'right',
        backgroundColor:
          col.field === 'BounceRate'
            ? dropOffBackgroundColorCoding(rowData[col.field])
            : 'none',
        color:
          col.field === 'BounceRate'
            ? dropOffColorCoding(rowData[col.field])
            : 'none'
      }"
    >

    <span>
      <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number'">{{
        rowData[col.field] || ""
      }}</span>
      <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' ">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
          (0 | currency: "USD":"symbol":"0.2-2")
      }}</span>
      <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
          (0 | currency: "USD":"symbol":"0.3-3")
      }}</span>
      <!-- <span *ngIf="col.type === 'currency' && col.field ==='cpc'">{{
        ((rowData["payout"] / footerValues["clicks"] ) | currency: "USD":"symbol":"0.2-2") ||
          (0 | currency: "USD":"symbol":"0.2-2")
      }}</span> -->
      <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
          (0 | currency: "USD":"symbol":"0.3-3")
      }}</span>
      <span *ngIf="col.type === 'percent'"
        >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
      >
      <span *ngIf="col.type === 'number'"
              >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
            >
    </span>
    </td>
  </tr>
</ng-template>

<ng-template pTemplate="footer" let-columns>
  <tr>
    <td
      *ngFor="let col of columns"
      [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary'
            ? 'left'
            : 'right'
      }"
    >
    <span >
      <span
          *ngIf="
            col.type !== 'primary' &&
            col.type !== 'currency' &&
            col.type !== 'percent' &&
            col.type !== 'string' &&
            col.type !== 'number' &&
            col.type !== 'actions'
          "
        >
          {{ footerValues[col.field] || 0 }}
      </span>
      <span>
          <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number' && col.type !== 'string' ">{{
            footerValues[col.field] || ""
          }}</span>
          <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' && col.field !== 'cpc' && col.field !== 'rpc' && col.field !== 'profit'">{{
            (footerValues[col.field] | currency: "USD":"symbol":"0.2-2") ||
              (0 | currency: "USD":"symbol":"0.2-2")
          }}</span>
          <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
            ((footerValues["revenue"] / footerValues["click"] )  | currency: "USD":"symbol":"0.3-3") ||
              (0 | currency: "USD":"symbol":"0.3-3")
          }}</span>
          <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
            ((footerValues["revenue"] / footerValues["sent"] ) | currency: "USD":"symbol":"0.3-3") ||
              (0 | currency: "USD":"symbol":"0.3-3")
          }}</span>
          <span *ngIf="col.type === 'currency' && col.field ==='profit'">{{
            ((footerValues["revenue"] - footerValues["payout"] ) | currency: "USD":"symbol":"0.3-3") ||
              (0 | currency: "USD":"symbol":"0.3-3")
          }}</span>
          <span *ngIf="col.type === 'currency' && col.field ==='cost'">{{
            (footerValues[col.field] | currency: "USD":"symbol":"0.2-2") ||
              (0 | currency: "USD":"symbol":"1.2-2")
          }}</span>
          <!-- <span *ngIf="col.type === 'percent'"
            >{{ footerValues[col.field] || 0 | number: "0.2-2" }}%</span
          > -->
          <span *ngIf="col.field === 'cr' && col.type === 'percent'">
            {{
              (footerValues["leads"] / footerValues["unique_click"] ) || 0 | percent: "1.2-2"
            }}
          </span>
        <span *ngIf="col.field === 'ctr' && col.type === 'percent'">
          {{
            (footerValues["unique_click"] / footerValues["sent"]) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'cpc' && col.type === 'currency'">
          {{
            (footerValues["payout"] / footerValues["clicks"]) || 0 | currency: "USD":"symbol":"0.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'rpc' && col.type === 'currency'">
          {{
            (footerValues["revenue"] / footerValues["clicks"]) || 0 | currency: "USD":"symbol":"0.2-2"
          }}
        </span>
        <span *ngIf="col.type === 'number'"
        >{{ footerValues[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
      >

    </span>
    </span>


      <span class="footerGrandTotal" *ngIf="col.type === 'primary'">
        Grand Total
      </span>

    </td>
  </tr>
</ng-template>
<ng-template pTemplate="paginatorleft">
  <span *ngIf="(data?.data | json) !== '[]'">
    Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
      (dt._first + dt._rows < dt._totalRecords
        ? dt._rows + dt._first
        : dt._totalRecords
      ) | json
    }}
    entries
    {{
      isFilter || isGlobalFilter
        ? "(" + dt._totalRecords + " filtered records)"
        : ""
    }}
    from
    {{ (data?.data !== undefined ? data?.data?.length : 0) | json }} records
  </span>
</ng-template>
</p-table>
</span>
<div *ngIf="data == undefined" class="progress-spinner">
<mat-spinner></mat-spinner>
</div>
<p-toast
[style]="{ marginTop: '50px', marginRight: '-15px' }"
styleClass="custom-toast"
position="top-right"
key="tc"
></p-toast>

<!-- <div class="heading">
  <div class="dailyRevenueReport tab">
    Hasoffer Platform
  </div>
  </div> -->
  <!-- <span *ngIf="data && data.hasoffer">
    <p-table
  #dt2
  class="dailyRevenueReport"
  [(columns)]="cols2"
  [value]="data.hasoffer"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols2"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
  [exportFilename]="'Revenue_Report' + fileName || tableName"
  [frozenColumns]="frozenCols2"
  frozenWidth="250px"
  >
  <ng-template pTemplate="caption" style="padding-top:2rem">
    <div class="ui-helper-clearfix" >
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt2.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter = !isGlobalFilter"
            (focusout)="isGlobalFilter = !isGlobalFilter"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>

        <div class="ui-g-5 filterExportToggle">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="columnFilter"
              [options]="colFilterOptions2"
              [(ngModel)]="cols2"
              optionLabel="header"
              selectedItemsLabel="{0} _ selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
              (onClick)="saveColumnState(dt2)"
            >
            </p-multiSelect>
          </div>

          <div class="ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter = !isFilter2; dt2.reset(); filterData(dt2)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export"
              (click)="exportData(dt, dt2)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
        [ngStyle]="{
          width: col.type === 'primary' ? '200px' : '110px'
        }"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign:
            col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
        }"
      >
        <span>{{ col.header }}</span>
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter2">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                *ngIf="isFilter2"
                (keyup)="dt2.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                *ngIf="isFilter2"
                (keyup)="dt2.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                *ngIf="isFilter2"
                (keyup)="dt2.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                *ngIf="isFilter2"
                (keyup)="dt2.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter2"
              [options]="filterOptions2[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt2.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions2(dt2.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>

      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.type === 'string' || col.type === 'primary'
              ? 'left'
              : 'right',
          backgroundColor:
            col.field === 'BounceRate'
              ? dropOffBackgroundColorCoding(rowData[col.field])
              : 'none',
          color:
            col.field === 'BounceRate'
              ? dropOffColorCoding(rowData[col.field])
              : 'none'
        }"
      >

      <span>
        <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number'">{{
          rowData[col.field] || ""
        }}</span>
        <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' ">{{
          (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
            (0 | currency: "USD":"symbol":"0.2-2")
        }}</span>
        <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
          (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
            (0 | currency: "USD":"symbol":"0.3-3")
        }}</span>
        <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
          (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
            (0 | currency: "USD":"symbol":"0.3-3")
        }}</span>
        <span *ngIf="col.type === 'percent'"
          >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
        >
        <span *ngIf="col.type === 'number'"
                >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
              >
      </span>
      </td>
    </tr>
    <tr  *ngIf="data['hasoffer'].length === 0 " class="dataNotFound">
      <div>
        <span style="font-weight: bold; box-shadow: 0 0 0px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem; color: #cdcfda;"> No Records Found</span>
      </div>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.type === 'string' || col.type === 'primary'
              ? 'left'
              : 'right'
        }"
      >
      <span >
        <span
            *ngIf="
              col.type !== 'primary' &&
              col.type !== 'currency' &&
              col.type !== 'percent' &&
              col.type !== 'string' &&
              col.type !== 'number' &&
              col.type !== 'actions'
            "
          >
            {{ footerValues2[col.field] || 0 }}
        </span>
        <span>
            <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number' && col.type !== 'string' ">{{
              footerValues2[col.field] || ""
            }}</span>
            <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' && col.field !== 'cpc' && col.field !== 'rpc' && col.field !== 'profit'">{{
              (footerValues2[col.field] | currency: "USD":"symbol":"0.2-2") ||
                (0 | currency: "USD":"symbol":"0.2-2")
            }}</span>
            <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
              ((footerValues2["revenue"] / footerValues2["click"] )  | currency: "USD":"symbol":"0.3-3") ||
                (0 | currency: "USD":"symbol":"0.3-3")
            }}</span>
            <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
              ((footerValues2["revenue"] / footerValues2["sent"] ) | currency: "USD":"symbol":"0.3-3") ||
                (0 | currency: "USD":"symbol":"0.3-3")
            }}</span>
            <span *ngIf="col.type === 'currency' && col.field ==='profit'">{{
              ((footerValues2["revenue"] - footerValues2["payout"] ) | currency: "USD":"symbol":"0.3-3") ||
                (0 | currency: "USD":"symbol":"0.3-3")
            }}</span>
            <span *ngIf="col.type === 'currency' && col.field ==='cost'">{{
              (footerValues2[col.field] | currency: "USD":"symbol":"0.2-2") ||
                (0 | currency: "USD":"symbol":"1.2-2")
            }}</span>
            <span *ngIf="col.field === 'cr' && col.type === 'percent'">
              {{
                (footerValues2["leads"] / footerValues2["unique_click"] ) || 0 | percent: "1.2-2"
              }}
            </span>
          <span *ngIf="col.field === 'ctr' && col.type === 'percent'">
            {{
              (footerValues2["unique_click"] / footerValues2["sent"]) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'cpc' && col.type === 'currency'">
            {{
              (footerValues2["payout"] / footerValues2["clicks"]) || 0 | currency: "USD":"symbol":"0.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'rpc' && col.type === 'currency'">
            {{
              (footerValues2["revenue"] / footerValues2["clicks"]) || 0 | currency: "USD":"symbol":"0.2-2"
            }}
          </span>
          <span *ngIf="col.type === 'number'"
          >{{ footerValues2[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
        >

      </span>
      </span>


        <span class="footerGrandTotal" *ngIf="col.type === 'primary'">
          Grand Total
        </span>

      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt2._totalRecords !== 0 ? dt2._first : 0) | json }}&#8210;{{
        (dt2._first + dt2._rows < dt2._totalRecords
          ? dt2._rows + dt2._first
          : dt2._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter2 || isGlobalFilter2
          ? "(" + dt2._totalRecords + " filtered records)"
          : ""
      }}
      from
      {{
        (data["hasoffer"] !== undefined
          ? data["hasoffer"].length
          : 0
        ) | json
      }}
      records
    </span>
  </ng-template>
    </p-table>
  </span> -->
  <!-- <div *ngIf="data.hasoffer == undefined" class="progress-spinner">
    <mat-spinner></mat-spinner>
  </div> -->
  <!-- <div *ngIf="data?.hasoffer?.length == 0" class="dataNotFound">
    <span style="font-weight: bold; box-shadow: 0 0 0px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem; color: #cdcfda;"> No Records Found</span>
  </div> -->
  <!-- <p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast> -->
