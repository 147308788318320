<div class="background">
  <div class="ui-g-12">
    <div class="ui-sm-12 ui-md-12 ui-lg-6">
      <img src="../../assets/img/brokenLogo.png" alt="" />
    </div>

    <div class="sideContent ui-lg-6 ui-sm-12 ui-md-12">
      <h1 class="four04Content">404</h1>
      <h2 class="oopsContent">Oops, Something is Broke...</h2>
      <p class="requestContent">
        Please click here to go back to previous page
      </p>
      <button routerLink="/login" class="backToValidPage">Go back</button>
    </div>
  </div>
</div>
