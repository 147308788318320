import { UtilService } from 'src/app/utils/util.service';
import { EspService } from './homepage/reports/esp/espservices/esp.service';
import { TeamService } from 'src/app/homepage/reports/team/teamservices/team.service';
import { Component, OnInit } from '@angular/core';
import { NetworkService } from './homepage/reports/network/networkservices/network.service';
import { PlatformService } from './homepage/reports/platform/platformservices/platform.service';

import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit
{
  title = 'WTS Portal';

  constructor(
    private teamService: TeamService,
    private networkService: NetworkService,
    private espService: EspService,
    private platformService: PlatformService,
    private utilService: UtilService,
    private router: Router
  )
  {
    if (localStorage.getItem('currentUser'))
    {
      this.utilService.loginData = JSON.parse(localStorage.getItem('currentUser'));
    } else
    {
      localStorage.setItem('currentUser', JSON.stringify(this.utilService.loginData));
    }

    if (localStorage.getItem('teamTabs'))
    {
      this.teamService.tabs = JSON.parse(localStorage.getItem('teamTabs'));
    } else
    {
      localStorage.setItem('teamTabs', JSON.stringify(this.teamService.tabs));
    }

    if (localStorage.getItem('platformespTabs'))
    {
      this.platformService.tabs = JSON.parse(localStorage.getItem('platformTabs'));
    } else
    {
      localStorage.setItem('platformTabs', JSON.stringify(this.platformService.tabs));
    }

    if (localStorage.getItem('espTabs'))
    {
      this.espService.tabs = JSON.parse(localStorage.getItem('espTabs'));
    } else
    {
      localStorage.setItem('espTabs', JSON.stringify(this.espService.tabs));
    }

    if (localStorage.getItem('networkTabs'))
    {
      this.networkService.tabs = JSON.parse(localStorage.getItem('networkTabs'));
    } else
    {
      localStorage.setItem('networkTabs', JSON.stringify(this.networkService.tabs));
    }

  }

  ngOnInit()
  {
    this.router.events.subscribe((evt) =>
    {
      if (!(evt instanceof NavigationEnd))
      {
        return;
      }

      const scrollToTop = window.setInterval(() =>
      {
        const pos = window.pageYOffset;
        if (pos > 0)
        {
          window.scrollTo(0, pos - 500); // how far to scroll on each step
        } else
        {
          window.clearInterval(scrollToTop);
        }
      }, 16); // how fast to scroll (this equals roughly 60 fps)
    });
  }

}
