import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MessageService } from "primeng/api";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { CreativelibraryService } from "../../creativelibraryservices/creativelibrary.service";
import { UtilService } from "src/app/utils/util.service";
import { teamModel } from "src/app/homepage/reports/team/teammodels/model";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { FilterUtils } from "primeng/utils";

@Component({
  selector: "app-creativelibrarytable",
  templateUrl: "./creativelibrarytable.component.html",
  styleUrls: ["../../../../../app.component.css"],
})
export class CreativelibrarytableComponent implements OnInit, OnDestroy {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  @ViewChild("upload") upload: any;
  creativeFiles: any = "";
  // platformOptions: any[] = [];
  verticalOptions: any[] = [];
  tempVerticalOption: any[] = [];
  regionOptions: any[] = [];
  tempRegionOptions: any[] = [];
  cols1: any[]; // Array for Showed Columns
  cols2: any[]; // Array for Showed Columns
  allCols1: any[]; // Array for All Columns
  allCols2: any[]; // Array for All Columns
  colFilterOptions1: any[]; // Array for Each Columns including with frozen
  colFilterOptions2: any[]; // Array for Each Columns including with frozen
  data: teamModel[]; // Data Storing for Table
  activeData: teamModel[] = [];
  inactiveData: teamModel[] = [];
  isFilter1 = false; // for Column Filter Toggle
  isFilter2 = false; // for Column Filter Toggle
  isGlobalFilter1 = false; // for Global Filter Input
  isGlobalFilter2 = false; // for Global Filter Input
  filterOptions: any = {}; // Data Storing for Column Unique Values
  activeFilterOptions: any = {}; // Data Storing for Column Unique Values
  inactiveFilterOptions: any = {}; // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[]; // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any; // Store Start Date
  endDate: any; // Store End Date
  loading: boolean; // for Lazy Loding
  subscription: Subscription[] = [];
  fileName: any;
  tableName: any;
  teamId: any;
  offerId: any = "";
  networkId: any = "";
  teamTable: any;
  items: any[];
  windowHeight: any;
  oldCreativeName: any;
  networkOptions: any[] = [];
  tempNetworkOptions: any[] = [];
  currentCreativeName: any;
  creativeId: any;
  networkValues: any[] = [];
  updateCreative = false;
  searchValue1: any = "";
  searchValue2: any = "";
  reason: any = "";
  deleteReason: any = "";
  creativeForm: FormGroup;
  minDate: Date = moment("01-05-2019", "DD-MM-YYYY").toDate();
  maxDate: Date = moment().toDate();
  selectedDate: any;
  showModel1: any = "";
  showModel2: any = "";
  userId: any = 0;
  advertisers_name: any = "";
  constructor(
    private service: CreativelibraryService,
    private utilService: UtilService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.scrollHeight = this.utilService.scrollableTableHeight + "px";
    this.creativeForm = this.fb.group({
      creativeName: ["", [Validators.required, this.validCreativeName]],
      networks: [""],
      vertical: [""],
      region: [""],
      whyUpdate: [""],
      // platform: ['', Validators.required],
      // creativeVersion: ['', Validators.required]
    });

    // All Columns of Table
    this.allCols1 = [
      { field: "creative_name", header: "Creative Name", type: false },
      { field: "advertisers", header: "Approved Networks", type: false },
      { field: "vertical_name", header: "Vertical Name", type: false },
      { field: "region_name", header: "Region Name", type: false },
      { field: "created_date", header: "Create Date", type: false },
      { field: "created_by", header: "Created By", type: false },
      { field: "modified_date", header: "Last Updated Date", type: false },
      { field: "modified_by", header: "Last Updated By", type: false },
      { field: "why_update", header: "Last Updated Reason", type: false },
    ];
    this.allCols2 = [
      { field: "creative_name", header: "Creative Name", type: false },
      { field: "advertisers", header: "Approved Networks", type: false },
      { field: "vertical_name", header: "Vertical Name", type: false },
      { field: "region_name", header: "Region Name", type: false },
      { field: "created_date", header: "Create Date", type: false },
      { field: "created_by", header: "Created By", type: false },
      { field: "modified_date", header: "Last Updated Date", type: false },
      { field: "modified_by", header: "Last Updated By", type: false },
      { field: "why_update", header: "Last Updated Reason", type: false },
    ];

    // { field: "vertical_name", header: "Vertical Name" ,type:false},
    // { field: "esp_connection_name", header: "Platform Name" ,type:false},
    // { field: "version", header: "Version" ,type:false},

    // Frized Columns
    this.frozenCols = [
      { field: "creative_name", header: "Creative Name", type: false },
    ];

    // All Columns for Filter Options
    this.colFilterOptions1 = [
      { field: "advertisers", header: "Approved Networks", type: false },
      { field: "vertical_name", header: "Vertical Name", type: false },
      { field: "region_name", header: "Region Name", type: false },
      { field: "created_date", header: "Create Date", type: false },
      { field: "created_by", header: "Created By", type: false },
      { field: "modified_date", header: "Last Updated Date", type: false },
      { field: "modified_by", header: "Last Updated By", type: false },
      { field: "why_update", header: "Last Updated Reason", type: false },
      { field: "remarks", header: "Actions", type: false },
    ];
    this.colFilterOptions2 = [
      { field: "advertisers", header: "Approved Networks", type: false },
      { field: "vertical_name", header: "Vertical Name", type: false },
      { field: "region_name", header: "Region Name", type: false },
      { field: "created_date", header: "Create Date", type: false },
      { field: "created_by", header: "Created By", type: false },
      { field: "modified_date", header: "Last Updated Date", type: false },
      { field: "modified_by", header: "Last Updated By", type: false },
      { field: "why_update", header: "Last Updated Reason", type: false },
      { field: "remarks", header: "Actions", type: false },
    ];

    // First Time Showed Columns
    this.cols1 = localStorage.creativeLibraryTable
      ? JSON.parse(localStorage.creativeLibraryTable)
      : [
          { field: "advertisers", header: "Approved Networks", type: false },
          { field: "vertical_name", header: "Vertical Name", type: false },
          { field: "region_name", header: "Region Name", type: false },
          { field: "created_date", header: "Create Date", type: false },
          { field: "created_by", header: "Created By", type: false },
          { field: "modified_date", header: "Last Updated Date", type: false },
          { field: "modified_by", header: "Last Updated By", type: false },
          { field: "remarks", header: "Actions", type: false },
        ];
    this.cols2 = localStorage.creativeLibraryTable
      ? JSON.parse(localStorage.creativeLibraryTable)
      : [
          { field: "advertisers", header: "Approved Networks", type: false },
          { field: "vertical_name", header: "Vertical Name", type: false },
          { field: "region_name", header: "Region Name", type: false },
          { field: "created_date", header: "Create Date", type: false },
          { field: "created_by", header: "Created By", type: false },
          { field: "modified_date", header: "Last Updated Date", type: false },
          { field: "modified_by", header: "Last Updated By", type: false },
          { field: "remarks", header: "Actions", type: false },
        ];

    // Setting Default Date for Data
    if (this.requiredData.data !== undefined) {
      this.teamId = this.utilService.loginData.team_id;
      // this.networkId = this.requiredData.data.network_id || '';
      // this.offerId = this.requiredData.data.offer_id || '';
      this.startDate = this.requiredData.date1;
      this.endDate = this.requiredData.date2;
      this.filterData();
    } else {
      this.teamId = this.utilService.loginData.team_id;
      this.startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
      this.filterData();
    }
    // if (!(this.teamId === 8 || this.teamId === 2020)) {
    //   this.colFilterOptions1.splice(this.colFilterOptions1.length - 1, 1);
    //   this.colFilterOptions2.splice(this.colFilterOptions1.length - 1, 1);
    //   this.cols1.splice(this.colFilterOptions1.length - 1, 1);
    //   this.cols2.splice(this.colFilterOptions1.length - 1, 1);
    // }
    this.userId = this.utilService.loginData.user_id;
    // this.filterData();
    this.getVerticalOptions();
    this.getRegionOptions();
    // this.getPlatformOptions();
    this.getNetworkOptions();

    // FilterUtils['filterNumber'] = (value, filter): boolean => {
    //
    //   let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
    //   let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

    //   if (value === undefined || value === null) {
    //     return false;
    //   }
    //   if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null)) {
    //     return value;
    //   }
    //   if (Number(greaterThan) && Number(lessThan)) {
    //     return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
    //   } else if (Number(greaterThan)) {
    //     return FilterUtils.gt(value, greaterThan);
    //   } else if (Number(lessThan)) {
    //     return FilterUtils.lt(value, lessThan);
    //   } else {
    //     return value;
    //   }
    // };

    FilterUtils["filterString"] = (value, filter): boolean => {
      let startString = document.getElementById(filter + "_starts_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_starts_with"))
            .value
        : null;
      let endString = document.getElementById(filter + "_ends_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_ends_with"))
            .value
        : null;

      if (value === undefined || value === null) {
        return false;
      }
      if (
        (startString === undefined && endString === undefined) ||
        (startString === null && endString === null)
      ) {
        return value;
      }
      if (startString && endString) {
        return (
          FilterUtils.startsWith(value, startString) &&
          FilterUtils.endsWith(value, endString)
        );
      } else if (startString) {
        return FilterUtils.startsWith(value, startString);
      } else if (endString) {
        return FilterUtils.endsWith(value, endString);
      } else {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any) {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any, tableNumber: number) {
    if (tableNumber === 1) {
      return (this.showModel1 = columnValue);
    } else if (tableNumber === 2) {
      return (this.showModel2 = columnValue);
    }
  }
  // For Hiding Filter Model
  hideDialog(tableNumber: number) {
    if (tableNumber === 1) {
      return (this.showModel1 = "");
    } else if (tableNumber === 2) {
      return (this.showModel2 = "");
    }
  }

  // For showing editing or update options
  getTeamId(): boolean {
    return (
      this.teamId === 8 ||
      this.teamId === 2020 ||
      this.userId === 46 ||
      this.userId === 127
    );
  }

  get creativeName() {
    return this.creativeForm.get("creativeName");
  }
  get networks() {
    return this.creativeForm.get("networks");
  }
  get vertical() {
    return this.creativeForm.get("vertical");
  }
  get region() {
    return this.creativeForm.get("region");
  }
  get whyUpdate() {
    return this.creativeForm.get("whyUpdate");
  }
  // get platform() {
  //   return this.creativeForm.get('platform');
  // }
  // get creativeVersion() {
  //   return this.creativeForm.get('creativeVersion');
  // }
  // get creativeFiles() {
  //   return this.creativeForm.get('creativeFiles');
  // }

  // Filter Data Based on Date
  filterData(cols?: any) {
    // this.updateStateCols(cols);
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Creatives_" + this.fileName;
    if (this.endDate && !this.isFilter1 && !this.isFilter2) {
      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        start_date: this.startDate,
        end_date: this.endDate,
        search_value: this.searchValue1 ? this.searchValue1 : this.searchValue2,
      };

      // Getting Data From Server
      if (this.bodyObject && !this.isFilter1 && !this.isFilter2) {
        this.subscription.push(
          this.service
            .getCreativeData({
              startDate: this.startDate,
              endDate: this.endDate,
              search_value: this.searchValue1
                ? this.searchValue1
                : this.searchValue2,
            })
            .subscribe(
              (responseData: any) => {
                if (responseData["error"] === "Expired token") {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Loged out",
                    detail: "Session expired, please login again!!!",
                  });
                  return this.utilService.logedOut();
                }
                if (responseData["code"]) {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Warning",
                    detail: "Something went wrong! Server not responding!",
                  });
                  return null;
                }
                // console.log(responseData);
                // this.data = responseData as teamModel[];
                this.activeData = [];
                this.inactiveData = [];
                // this.searchValue1 = '';
                // this.searchValue2 = '';
                responseData.forEach((element: any) => {
                  if (element.active === "1") {
                    this.activeData.push(element);
                  }
                  if (element.active === "0") {
                    this.inactiveData.push(element);
                  }
                });
                this.loading = false;
                this.footerValues = { undefined };
                if (this.activeData.length !== 0) {
                  // this.getFooterData(this.data);
                  if (this.activeData.length !== 0) {
                    this.activeFilterOptions =
                      this.utilService.getRequiredOptions(
                        this.activeData,
                        this.activeData[0]
                      );
                  }
                  if (this.inactiveData.length !== 0) {
                    this.inactiveFilterOptions =
                      this.utilService.getRequiredOptions(
                        this.inactiveData,
                        this.inactiveData[0]
                      );
                  }
                } else {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Warning",
                    detail: "Data not found in the given date range",
                  });
                }
              },
              (err) => {
                if (err) {
                  this.messageService.add({
                    key: "tc",
                    severity: "warn",
                    summary: "Warning",
                    detail: "Something went wrong! Server not responding!",
                  });
                  this.loading = false;
                  return null;
                }
              }
            )
        );
      }
    }
  }

  // For Updating Data According to Search Value
  updateData() {
    if (this.searchValue1 === "" || this.searchValue2 === "") {
      this.filterData();
    }
  }

  // Update Filter Options
  updateActiveFilterOptions(tableData, colName) {
    if (tableData !== null) {
      // this.getFooterData(tableData);
      this.activeFilterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.activeFilterOptions[colName] =
        this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }

  updateInactiveFilterOptions(tableData, colName) {
    if (tableData !== null) {
      // this.getFooterData(tableData);
      this.inactiveFilterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.inactiveFilterOptions[colName] =
        this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }

  // Update Footer Values
  // getFooterData(tableData) {
  //   this.footerValues = this.utilService.getRequiredFooterData(tableData);
  // }

  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data) {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Getting Dates from Dates Component
  getDates(datesObj) {
    if (datesObj) {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.colFilterOptions1 = [];
      this.colFilterOptions2 = [];
      // this.isFilter1 = false;
      // this.isFilter2 = false;
      this.filterData();
    }
  }

  // Columns State Save
  saveColumnState(dt: any) {
    localStorage.creativeLibraryTable = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt) {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For Converting Date Format
  getDateFormat(date: any) {
    return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD MMM, YYYY (hh:mm a)");
  }

  //Get Window Height
  // getWindowHeight(event) {
  //   this.windowHeight = event;
  // }

  // Upload File On Client End
  onUpload(event, upload) {
    const name = event.files[0].name;
    this.currentCreativeName = name.replace(/.zip/g, "");

    if (this.currentCreativeName.includes(" ")) {
      return this.messageService.add({
        key: "tc",
        severity: "warn",
        summary: "Invalid File Name",
        detail:
          "File name can only contains Numeric, Alphabet and Underscore!!!",
      });
    } else if (
      this.updateCreative &&
      this.currentCreativeName !== this.creativeName.value
    ) {
      return this.messageService.add({
        key: "tc",
        severity: "warn",
        summary: "Invalid Entry",
        detail: "Creative name & Creative Zip file name should be same!!!",
      });
    } else if (
      ".zip" !== event.files[0].name.substr(event.files[0].name.length - 4, 4)
    ) {
      this.upload.clear();
      return this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Invalid Entry",
        detail: "Please select a valid zip file!!!",
      });
    }

    this.creativeFiles = "";
    this.creativeFiles = event.files[0];
    upload.clear();
    this.messageService.add({
      key: "tc",
      severity: "success",
      summary: "File",
      detail: "Zip file selected!!!",
    });
  }

  // For Clearing Upload Field
  clearPrevious() {
    this.creativeFiles = "";
  }

  // For Getting Vertical Options
  getVerticalOptions() {
    this.service.getVerticalOptions().subscribe((data: any[]) => {
      if (data.length != undefined) {
        data.forEach((optionRow) =>
          this.verticalOptions.push({
            label: optionRow.vertical_name,
            value: optionRow.id,
          })
        );
        this.tempVerticalOption = [...data];
      }
    });
  }
  // For Getting Vertical Options
  getRegionOptions() {
    // debugger;
    this.service.getRegionOptions().subscribe((data: any[]) => {
      if (data.length != undefined) {
        data.forEach((optionRow) =>
          this.regionOptions.push({
            label: optionRow.region_name,
            value: optionRow.id,
          })
        );
        this.tempRegionOptions = [...data];
      }
    });
  }

  // For

  // For Getting Platform Options
  // getPlatformOptions() {
  //   this.service.getPlatformOptions().subscribe((data: any[]) => {
  //     if (data.length != undefined) {
  //       data.forEach(optionRow =>
  //         this.platformOptions.push({
  //           label: `${
  //             optionRow.name
  //             }(${optionRow.portal_name})`, value: optionRow.esp_account_id
  //         })
  //       );
  //     }
  //   })
  // }

  // For Getting Network Options
  getNetworkOptions() {
    this.service.getNetworkOptions().subscribe((data: any[]) => {
      if (data.length != undefined) {
        data.forEach((optionRow) =>
          this.networkOptions.push({
            label: `${optionRow.advertisers_name}(${optionRow.portal_name})`,
            value: optionRow.id,
          })
        );
        this.tempNetworkOptions = [...data];
      }
    });
  }

  // Upload Creative Data On Server
  insertCreativeDetails() {
    if (this.creativeFiles === "" && !this.updateCreative) {
      this.messageService.add({
        key: "tc",
        severity: "warn",
        summary: "Something Missing",
        detail: "All fields are mandatory!!!",
      });
      return;
    }

    if (this.whyUpdate.value === "" && this.updateCreative) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Something Missing",
        detail: "Reason for update mandatory!!!",
      });
      return;
    }

    if (this.updateCreative) {
      if (this.creativeName.value === "") {
        return this.messageService.add({
          key: "tc",
          severity: "warn",
          summary: "Something Missing",
          detail: "Creative name required!!!",
        });
      }
      if (this.creativeFiles === "" && this.networkValues.length === 0) {
        return this.messageService.add({
          key: "tc",
          severity: "warn",
          summary: "Something Missing",
          detail: "Creative Zip or Networks required!!!",
        });
      }
    }
    // if (this.updateCreative && this.creativeForm.invalid) {
    //   this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Something Missing', detail: 'All fields are mandatory, excepts zip upload!!!' });
    //   return;
    // }
    if (this.creativeFiles !== "") {
      if (
        this.currentCreativeName !== this.creativeName.value &&
        this.updateCreative
      ) {
        this.messageService.add({
          key: "tc",
          severity: "warn",
          summary: "Invalid Entry",
          detail: "Creative name & Creative Zip file name should be same!!!",
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("userId", this.userId);
    formData.append(
      "creativeName",
      this.creativeName.value || this.currentCreativeName
    );
    if (this.updateCreative) {
      formData.append("creativeId", this.creativeId);
      formData.append("oldName", this.oldCreativeName);
      formData.append("creativeFiles", this.creativeFiles);
      formData.append("why_update", this.whyUpdate.value);
      if (!this.whyUpdate.value) {
        this.messageService.add({
          key: "tc",
          severity: "warn",
          summary: "Invalid Entry",
          detail: "Reason for update required!!!",
        });
        return;
      }
    } else {
      formData.append("creativeFiles", this.creativeFiles);
    }
    formData.append(
      "advertisers",
      this.networks.value.length !== 0 ? this.networks.value : []
    );
    const networkNames = [];
    for (let i = 0; i < this.networks?.value?.length; i++) {
      for (let j = 0; j < this.tempNetworkOptions?.length; j++) {
        // console.log(this.tempNetworkOptions[j]?.advertisers_id,this.networks?.value[i]);
        // debugger;
        if (this.tempNetworkOptions[j]?.id === this.networks?.value[i]) {
          networkNames?.push(this.tempNetworkOptions[j]?.advertisers_name);
          break;
        }
      }
    }

    // console.log(networkNames);

    formData.append("advertisers_name", networkNames.join(","));

    formData.append("vertical", this.vertical.value || "");
    const verticalName = [];
    for(let i = 0; i <this.tempVerticalOption?.length; i++)
    {
      if(this.tempVerticalOption[i]?.id === this.vertical?.value){
        verticalName?.push(this.tempVerticalOption[i]?.vertical_name);
        break;
      }
    }
    formData.append("vertical_name", verticalName.join(","));
    formData.append("region", this.region.value || "");
    const regionName = [];
    for(let i = 0; i < this.tempRegionOptions?.length; i++){
      // console.log(this.tempRegionOptions[i]?.id,this.region?.value);
        // debugger;
        if (this.tempRegionOptions[i]?.id === this.region?.value) {
          regionName?.push(this.tempRegionOptions[i]?.region_name);
          break;
        }

    }

    // console.log(regionName);
    formData.append("region_name", regionName.join(","));
    // formData.append('platform', this.platform.value);
    // formData.append('creativeVersion', this.creativeVersion.value);
    if (!this.updateCreative) {
      this.subscription.push(
        this.service.postCreativeData(formData).subscribe(
          (response: any) => {
            if (response.code === 1) {
              this.reset();
              this.upload.clear();
              this.filterData();
              // this.getPlatformOptions();
              // this.getVerticalOptions();
              this.messageService.add({
                key: "tc",
                severity: "success",
                summary: "Insert Successful",
                detail: "Creative details inserted successfully!",
              });
            } else if (
              response.error ===
              "<p>The creativeName field must contain a unique value.</p>\n"
            ) {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Already Present",
                detail: "Creative details already present in database!",
              });
              return;
            } else {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Warning",
                detail: `${response.error}(${response.files})`,
              });
            }
          },
          (err) => {
            if (err) {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Warning",
                detail: "Something went wrong! Server not responding!",
              });
              return null;
            }
          }
        )
      );
    } else {
      this.subscription.push(
        this.service.updateCreativeData(formData).subscribe(
          (response: any) => {
            if (response.code === 1) {
              this.reset();
              this.upload.clear();
              this.filterData();
              // this.getPlatformOptions();
              // this.getVerticalOptions();
              this.messageService.add({
                key: "tc",
                severity: "success",
                summary: "Update Successful",
                detail: "Creative details updated successfully!",
              });
            } else if (
              response.error ===
              "<p>The creativeName field must contain a unique value.</p>\n"
            ) {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Already Present",
                detail: "Creative details already present in database!",
              });
              return;
            } else {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Warning",
                detail: `${response.error}(${response.files})`,
              });
            }
          },
          (err) => {
            if (err) {
              this.messageService.add({
                key: "tc",
                severity: "warn",
                summary: "Warning",
                detail: "Something went wrong! Server not responding!",
              });
              return null;
            }
          }
        )
      );
    }
  }

  // For filling pre values in input fields
  updateCreativeDetails(rowData: any) {
    if (rowData) {
      this.creativeName.setValue(rowData.creative_name);
      this.networkValues = JSON.parse(rowData.advertisers_id);
      this.creativeId = rowData.id;
      this.whyUpdate.setValue(rowData.why_update);
      this.oldCreativeName = rowData.creative_name;
      this.vertical.setValue(rowData.vertical_id);
      this.region.setValue(rowData.region_id);
      // this.creativeVersion.setValue(rowData.version);
      // this.verticalOptions = [{ label: rowData.vertical_name, value: rowData.vertical_id }];
      // this.platformOptions = [{ label: rowData.esp_connection_name, value: rowData.esp_connection_id }];
      // this.vertical.setValue(rowData.vertical_id);
      // this.platform.setValue(rowData.esp_connection_id);
      this.updateCreative = true;
      this.messageService.add({
        key: "tc",
        severity: "success",
        summary: "Selected",
        detail: "Values have selected successfully!",
      });
    }
  }

  // For Clearing Form Fields
  reset() {
    // this.creativeForm.reset();
    this.creativeName.setValue("");
    this.networks.setValue([]);
    this.vertical.setValue("");
    this.upload.clear();
    this.whyUpdate.setValue("");
    this.creativeFiles = "";
    this.region.setValue("");
    this.updateCreative = false;
  }

  // For Changing Creative Status
  changeStatus(rowData: any) {
    if (this.reason === "") {
      return this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Reason Required",
        detail: "Please provide a valid reason",
      });
    }
    let status = "";
    if (rowData.active === "1") {
      status = "0";
    } else {
      status = "1";
    }
    const body: any = {
      id: rowData.id,
      creative_name: rowData.creative_name,
      active: status,
      reason: this.reason,
      change_by: this.userId,
    };
    this.subscription.push(
      this.service.changeCreativeStatus(body).subscribe(
        (response: any) => {
          if (response.code === 200) {
            this.reason = "";
            this.messageService.add({
              key: "tc",
              severity: "success",
              summary: "Status Updated",
              detail: "Creative status updated successfully!",
            });
            this.filterData();
          } else {
            this.messageService.add({
              key: "tc",
              severity: "warn",
              summary: "Warning",
              detail: "Something went wrong! Server not responding!",
            });
          }
        },
        (err) => {
          if (err) {
            this.messageService.add({
              key: "tc",
              severity: "warn",
              summary: "Warning",
              detail: "Something went wrong! Server not responding!",
            });
            return null;
          }
        }
      )
    );
  }

  // For Deleting Creative
  deleteCreative(rowData: any) {
    if (this.deleteReason === "") {
      return this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Reason Required",
        detail: "Please provide a valid reason",
      });
    }

    const body: any = {
      id: rowData.id,
      creative_name: rowData.creative_name,
      reason: this.deleteReason,
      deleted_by: this.userId,
    };
    this.subscription.push(
      this.service.deleteCreative(body).subscribe(
        (response: any) => {
          if (response.code === 200) {
            this.deleteReason = "";
            this.messageService.add({
              key: "tc",
              severity: "success",
              summary: "Creative Deleted",
              detail: "Creative has been deleted successfully!",
            });
            this.filterData();
          } else {
            this.messageService.add({
              key: "tc",
              severity: "warn",
              summary: "Warning",
              detail: "Something went wrong! Server not responding!",
            });
          }
        },
        (err) => {
          if (err) {
            this.messageService.add({
              key: "tc",
              severity: "warn",
              summary: "Warning",
              detail: "Something went wrong! Server not responding!",
            });
            return null;
          }
        }
      )
    );
  }

  validCreativeName(control: AbstractControl) {
    if (control.value) {
      control.valueChanges.subscribe((value: any) => {
        if (
          value.includes(" ") ||
          value.includes(".") ||
          value.includes("/") ||
          value.includes("(") ||
          value.includes(")") ||
          value.includes("{") ||
          value.includes("}")
        ) {
          control.setValue("");
        }
      });
    }
  }

  onlyNumbers(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyCharacters(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : (event.charCode >= 65 && event.charCode <= 90) ||
          (event.charCode >= 97 && event.charCode <= 122) ||
          (event.charCode >= 48 && event.charCode <= 57) ||
          event.charCode === 95;
  }

  // For getting string length(Tooltip)
  showTooltip(text: any) {
    return text && String(text).length > 9 ? text : "";
  }

  // Destroy all subscriptons
  ngOnDestroy(): void {
    this.subscription.forEach((e) => e.unsubscribe());
  }
}
