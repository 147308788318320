<h2 style="text-align: center;" mat-dialog-title *ngIf="creativeData">
  <span
    style="text-shadow: 1px 1px 0px #cecece;
  padding:1rem;box-shadow: 1px 0px 5px #cecece; border-radius: 4rem;"
    >{{ creativeData.creative }}</span
  >
</h2>
<div class="creativeTemplate ui-g-12" style="">
  <div class="creativeWindow" [innerHTML]="creativeHtml"></div>
</div>

<h2 style="text-align: center;margin-bottom: -.75rem;" *ngIf="creativeData">
  <button
    (click)="hideCreativeWindow()"
    style="border-radius: 4rem;box-shadow: 1px 0px 5px #cecece;text-shadow: 1px 1px 0px #cecece;
        padding:1rem;width: 100%;margin-top: 1rem;background-color: #ffffff;border:none;"
  >
    <span style="">Close</span>
  </button>
</h2>

<button
  *ngIf="creativeData"
  style="position: absolute; top:1rem;right:1rem; background-color:#ffffff;color:#474747;"
  mat-fab
  (click)="hideCreativeWindow()"
>
  <mat-icon class="mat-18">close</mat-icon>
</button>
