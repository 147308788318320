import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';
import { environment } from './../../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SmsNetworkService {

  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(private http: HttpClient, private utilService: UtilService) {
        this.tabs.push({
        id: 0,
        header: "",
        type: "Network Performance",
        isClosable: false
      });
   }
   getSmsNetworkData(body: any)
   {
      return this.http.post(this.baseURL + "sms/getNetworksReport", body);
   }
}
