export class SubjectLineModel
{
    subjectLineAllCols: any[] = [];
    subjectLineColFilterOptions: any[] = [];
    subjectLineCols: any[] = [];
    constructor()
    {
        // for Daily Subject Line Sheet Main Columns
        this.subjectLineAllCols = [

            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "sent_count", header: "Sent", type: true },
            { field: "opens", header: "Open", type: true },
            { field: "opens_percent", header: "Open%", type: true },
            { field: "counter", header: "Subject Line Repeat", type: true }
        ];
        // for Daily Subject Line Sheet Main Columns Filter Options
        this.subjectLineColFilterOptions = [
            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "sent_count", header: "Sent", type: true },
            { field: "opens", header: "Open", type: true },
            { field: "opens_percent", header: "Open%", type: true },
            { field: "counter", header: "Subject Line Repeat", type: true }
        ];
        // for Daily Subject Line Sheet Main Columns First Show
        this.subjectLineCols = [

            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "sent_count", header: "Sent", type: true },
            { field: "opens", header: "Open", type: true },
            { field: "opens_percent", header: "Open%", type: true },
            { field: "counter", header: "Subject Line Repeat", type: true }
        ];
    }
}