<span *ngIf="data && data.data">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data.data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="30"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'advertiser_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary'
                ? 'left'
                : 'right'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col?.type==='number'||col.type==='percent'||col.type ==='currency'"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="col.type === 'string' || col.type === 'primary'">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary'
                ? 'left'
                : 'right'
          }"
        >

        <!-- <span *ngIf="col.type === 'primary'" >
        <button
        mat-button
        *ngIf="!rowData['offer_id']"
        pTooltip="Website"
        (click)="
          addNewTab(
            'Website - ',
            rowData['publisherName'],
            startDate,
            endDate,
            rowData
          )
        "
      >
        <img src="assets/icons/nav_website.svg" [style]="{ width: '.8rem' }" />
      </button>
        </span> -->
          <!-- <span
            *ngIf="
              rowData[col.field] !== 'Undefined' && col.header === 'Account Name'
            "
          >
            <button
              mat-button
              pTooltip="Teams (Affiliates)"
              (click)="
                addNewTab(
                  'Teams - ',
                  rowData['advertiser_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img src="assets/icons/team_w.svg" [style]="{ width: '.8rem' }" />
            </button>
            <button
              mat-button
              pTooltip="Offers"
              (click)="
                addNewTab(
                  'Offers - ',
                  rowData['advertiser_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img src="assets/icons/offer_w.svg" [style]="{ width: '.8rem' }" />
            </button>
            <button
              mat-button
              pTooltip="Campaigns"
              (click)="
                addNewTab(
                  'Campaigns - ',
                  rowData['advertiser_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/campaign_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
          </span> -->
          <span>
              <span>
                <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number' && col.type !== 'string'">{{
                  rowData[col.field] || ""
                }}</span>
                <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc'">{{
                  (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
                    (0 | currency: "USD":"symbol":"0.2-2")
                }}</span>
                <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
                  (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
                    (0 | currency: "USD":"symbol":"0.2-2")
                }}</span>
                <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
                  (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
                    (0 | currency: "USD":"symbol":"0.2-2")
                }}</span>

                <span *ngIf="col.type === 'percent'"
                  >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
                >
                <span *ngIf="col.type === 'number'"
                  >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
                >
                <span *ngIf="col.type === 'string'"
                  >{{ rowData[col.field] || 'N/A' }}</span>

            </span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary'
                ? 'left'
                : 'right'
          }"
        >
          <span
              *ngIf="
                col.type !== 'primary' &&
                col.type !== 'currency' &&
                col.type !== 'percent' &&
                col.type !== 'string' &&
                col.type !== 'number' &&
                col.type !== 'actions'
              "
            >
              {{ footerValues[col.field] || 0 }}
          </span>
          <span>
              <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.field !== 'bounceLeads' && col.field !== 'autoForm' && col.field !== 'epl'">{{
                footerValues[col.field] || ""
              }}</span>
              <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' && col.field !== 'epl'">{{
                (footerValues[col.field] | currency: "USD":"symbol":"0.2-2") ||
                  (0 | currency: "USD":"symbol":"1.2-2")
              }}</span>
              <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
                ((footerValues["revenue"] / footerValues["visitors"] ) | currency: "USD":"symbol":"0.2-2") ||
                  (0 | currency: "USD":"symbol":"1.2-2")
              }}</span>
              <span *ngIf="col.type === 'currency' && col.field ==='epl'">{{
                ((footerValues["revenue"] / footerValues["submitLeads"] ) | currency: "USD":"symbol":"0.2-2") ||
                  (0 | currency: "USD":"symbol":"1.2-2")
              }}</span>
              <span *ngIf="col.type === 'currency' && col.field ==='cost'">{{
                (footerValues[col.field] | currency: "USD":"symbol":"0.2-2") ||
                  (0 | currency: "USD":"symbol":"1.2-2")
              }}</span>
              <span *ngIf="col.field === 'submitRate' && col.type === 'percent'">
                {{
                  (footerValues["submitLeads"] / footerValues["visitors"]) || 0 | percent: "1.2-2"
                }}
              </span>

              <!-- <span *ngIf="col.type === 'percent'"
                >{{ footerValues[col.field] || 0 | number: "0.2-2" }}%</span
              > -->
              <span *ngIf="col.field === 'cr' && col.type === 'percent'">
                {{
                  (footerValues["leads"] / footerValues["unique_click"] ) || 0 | percent: "1.2-2"
                }}
              </span>
              <span *ngIf="col.field === 'autoFormRate'">
                {{
                  ((footerValues["submitLeads"] -  footerValues["fullForm"])/ footerValues["submitLeads"]) || 0 | percent: "1.2-2"
                }}
              </span>
              <span *ngIf="col.field === 'fullFormRate'">
                {{
                  ((footerValues["fullForm"] /  footerValues["submitLeads"])) || 0 | percent: "1.2-2"
                }}
              </span>
              <span *ngIf="col.field === 'bounceLeadsRate'">
                {{
                  ((footerValues["visitors"] - (footerValues["submitLeads"] + footerValues["dropField"] )) / footerValues["visitors"]) || 0 | percent: "1.2-2"
                }}
              </span>
              <span *ngIf="col.field === 'dropOffRate'">
                {{
                  ((footerValues["dropField"] /  (footerValues["submitLeads"] + footerValues["dropField"]))) || 0 | percent: "1.2-2"
                }}
              </span>
              <span *ngIf="col.field === 'bounceLeads'">
                {{
                  (footerValues["visitors"] - (footerValues["submitLeads"] + footerValues["dropField"] ) ) || 0| number: "0.0-0"
                }}
              </span>
              <span *ngIf="col.field === 'autoForm'">
                {{
                  (footerValues["submitLeads"] -  footerValues["fullForm"]) || 0 | number: "0.0-0"
                }}
              </span>


            <!-- <span *ngIf="col.type === 'number'"
                  >{{ footerValues[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
                > -->
        </span>


          <span class="footerGrandTotal" *ngIf="col.type === 'primary'">
            Grand Total
          </span>

        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(dt._value | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from {{ (data !== undefined ? data.data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
  </span>
  <div *ngIf="data.data == undefined" class="progress-spinner">
    <mat-spinner></mat-spinner>
  </div>
  <p-toast
    [style]="{ marginTop: '50px', margUSDight: '-15px' }"
    styleClass="custom-toast"
    position="top-right"
    key="tc"
  ></p-toast>

