import { SmsContentService } from './../../smsContentService/sms-content.service';
import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../../../../utils/util.service';

@Component({
  selector: 'app-sms-content',
  templateUrl: './sms-content.component.html',
  styleUrls: ['./sms-content.component.css']
})
export class SmsContentComponent implements OnInit {
  tabId = 0;
  tabs: any[] = [];
  activeIndex: number;
  checkPreviousTab = false;

  constructor(private service: SmsContentService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.activeIndex = 0;
    this.tabs = this.service.tabs;
  }
  addTabInArray(tabObject) {
    if (tabObject) {
      // Checking Whether The Tab is in the Array or Not
      const index = this.service.tabs.findIndex(x => (x.header === tabObject.header && x.type === tabObject.type));
      if (index !== -1) {
        // Update Tab in Tab Array
        this.service.tabs.splice(index, 1, {
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        this.activeIndex = index;
      } else {
        // Adding New Tab in Tab Array
        this.service.tabs.push({
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        localStorage.setItem("networkTabs", JSON.stringify(this.service.tabs));
        this.activeIndex = this.service.tabs.length - 1;
      }
    }
  }
     // Close Tab and remove from the Tab Array
     closeTab(tabIndex) {
      this.service.tabs.splice(tabIndex, 1);
      localStorage.setItem("networkTabs", JSON.stringify(this.service.tabs));
      this.activeIndex = this.service.tabs.length - 1;
    }

    // Adjust Viewport According to Sidenav Expand and Collapse
    isExpanded() {
      return this.utilService.isExpanded;
    }
    portalStatus()
    {
      return this.utilService.portalStatus();
    }

}
