import { UtilService } from './../../../../utils/util.service';
import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkService
{

  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient, private utilService: UtilService)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Network Performance",
      isClosable: false
    });
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }
  getNetworkData(body: any)
  {
        if(body.network_id){
          return this.http.post(this.baseURL + "GetData/networkGroup", body);
        }
        else{
          return this.http.post(this.baseURL + "GetData/network", body);
      }
  }



}
