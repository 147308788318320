import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';
import { environment } from './../../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MediaBuyingService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];


  constructor(private http: HttpClient, private utilService: UtilService) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Media Buying",
      isClosable: false
    });
  }
  getMediaBuying(body: any) {
    return this.http.post(this.baseURL + "Phonexa/mediaBuying",body);
  }
  getMediaBuyingDetailedReport(body: any){
    return this.http.post(this.baseURL + "Phonexa/mediaBuyingDetailedReport",body);
  }
  getLandingPageDetails(body: any){
    return this.http.post(this.baseURL + "Phonexa/mediaBuyingLandingPageReport",body);
  }
}
