<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Organization Performance'">
        <app-organization-table
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-organization-table>
      </p>
      <p *ngSwitchCase="'Geo - '">
        <app-country-wise-organization
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-country-wise-organization>
      </p>
      <p *ngSwitchCase="'Campaign - '">
        <app-ind-campaign-green-arrow
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-ind-campaign-green-arrow>
      </p>
      <p *ngSwitchCase="'Mailing List - '">
        <app-mailing-list-wise-report
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-mailing-list-wise-report>
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
