import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';
import { environment } from './../../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CatSmsService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(
    private http: HttpClient, private utilService: UtilService
  ) { }
  getCatSms(body: any) {
    return this.http.post(this.baseURL + "Phonexa/getCatSmsReport",body);
  }
}
