import { PhonexaLeadReportComponent } from './homepage/reports/daily/phonexa-lead-report/phonexa-lead-report.component';
import { MediaBuyingComponent } from './homepage/reports/leads/media-buying/media-buying.component';
import { BdOffersReportComponent } from './homepage/reports/publisher/bd-offers-report/bd-offers-report.component';
import { GreenArrowComponent } from './homepage/reports/godseye/green-arrow/green-arrow.component';
import { PhonexaSubmitLeadsComponent } from './homepage/reports/leads/phonexa-submit-leads/phonexa-submit-leads.component';
import { TeamPerformanceComponent } from './homepage/reports/leads/team-performance/team-performance.component';
import { MspDeliveredTargetComponent } from './homepage/reports/MonthlyTarget/msp-delivered-target/msp-delivered-target.component';
import { DndNonDndTargetComponent } from './homepage/reports/MonthlyTarget/dnd-non-dnd-target/dnd-non-dnd-target.component';
import { AdvertiserReportComponent } from './homepage/reports/publisher/advertiser-report/advertiser-report.component';
import { OfferPublisherReportComponent } from './homepage/reports/publisher/offer-publisher-report/offer-publisher-report.component';
import { PartnersComponent } from './homepage/reports/publisher/partners/partners.component';
import { IndSmsVerticalComponent } from './homepage/reports/IndSmsVertical/smsVerticalComponent/ind-sms-vertical/ind-sms-vertical.component';
import { IndSmsDomainComponent } from './homepage/reports/IndSmsDomain/indSmsDomainComponents/ind-sms-domain/ind-sms-domain.component';
import { SmsVerticalComponent } from './homepage/reports/sms-vertical/sms-vertical.component';
import { MsptableComponent } from './homepage/reports/msp/msptable/msptable.component';
import { SenderIdComponent } from './homepage/reports/msp/sender-id/sender-id.component';
import { ContentComponent } from './homepage/reports/daily/content/content.component';
import { AddSMSRevenueComponent } from './homepage/reports/mapping/add-smsrevenue/add-smsrevenue.component';
import { SmsRevenueComponent } from './homepage/reports/sms-revenue/sms-revenue.component';
import { CreativelibraryComponent } from './homepage/reports/creativelibrary/creativelibrarycomponents/creativelibrary.component';
import { DailyComponent } from './homepage/reports/daily/dailycomponents/daily.component';
import { EspComponent } from './homepage/reports/esp/espcomponents/esp.component';
import { NetworkComponent } from './homepage/reports/network/networkcomponents/network.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/logincomponents/login/login.component';
import { HomepageComponent } from './homepage/homepagecomponents/homepage.component';
import { TeamComponent } from './homepage/reports/team/teamcomponents/team.component';
import { PlatformComponent } from './homepage/reports/platform/platformcomponents/platform.component';
import { AuthGuard } from './auth/auth.guard';
import { MappingComponent } from './homepage/reports/mapping/mappingcomponents/mapping.component';
import { ListComponent } from './homepage/reports/mapping/list/list.component';
import { TriggerComponent } from './homepage/reports/mapping/trigger/trigger.component';
import { RevenueComponent } from './homepage/reports/daily/revenue/revenue.component';
import { SubjectlineComponent } from './homepage/reports/daily/subjectline/subjectline-components/subjectline.component';
import { CreativeComponent } from './homepage/reports/daily/creative/creative-components/creative.component';
import { ListorderingComponent } from './homepage/reports/mapping/listordering/listordering.component';
// import { DomainComponent } from './homepage/reports/domain/domaincomponents/domain.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PublisherComponent } from './homepage/reports/publisher/publishercomponents/publisher.component';
import { ComplianceComponent } from './homepage/reports/compliance/compliancecomponents/compliance.component';
import { DashboardComponent } from './homepage/dashboard/dashboard.component';
import { LeadsComponent } from './homepage/reports/leads/leadscomponents/leads.component';
import { DailyCountComponent } from './homepage/reports/daily/daily-count/daily-count.component';
import { DailySegmentGrowthComponent } from './homepage/reports/daily/daily-segment-growth/daily-segment-growth.component';
import { NetworkMappingComponent } from './homepage/reports/mapping/network-mapping/network-mapping.component';
import { OfferMappingComponent } from './homepage/reports/mapping/offer-mapping/offer-mapping.component';
import { NetworkGroupComponent } from './homepage/reports/network-group/netwrokGroupComponents/network-group.component';
import { OfferGroupComponent } from './homepage/reports/offer-group/offerGroupComponents/offer-group.component';
import { OfferComponent } from './homepage/reports/offer/offerComponents/offer.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { from } from 'rxjs';
import { MspcomponentComponent } from './homepage/reports/msp/mspcomponent/mspcomponent.component';
import { SmsOfferComponent } from './homepage/reports/IndSmsOffer/offerComponent/sms-offer/sms-offer.component';
import { SmsNetworkComponent } from './homepage/reports/IndSmsNetwork/networkComponent/sms-network/sms-network.component';
import { MspReportComponent } from './homepage/reports/msp/msp-report/msp-report.component';
import { MspServiceTableComponent } from './homepage/reports/msp/msp-service-table/msp-service-table.component';
import { SmsContentComponent } from './homepage/reports/smsContent/smsContentComponent/sms-content/sms-content.component';
import { SmsCampaginComponent } from './homepage/reports/daily/smsCampaign/smsCampaignCompnent/sms-campagin/sms-campagin.component';
import { CustomReportComponent } from './homepage/reports/custom-report/custom-report.component';
import { PublisherConversionReportComponent } from './homepage/reports/publisher/publisher-conversion-report/publisher-conversion-report.component';
import { PhonexaServiceService } from './homepage/reports/leads/phonexa-submit-leads/phonexaLeadsService/phonexa-service.service';
import { CatSmsComponent } from './homepage/reports/leads/cat-sms/cat-sms.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  {
    path: 'homepage', component: HomepageComponent, canActivate: [AuthGuard], children: [
      { path: 'email-dashboard', component: DashboardComponent },
     { path: 'sms-dashboard', component: DashboardComponent },
      {
        path: 'daily', component: DailyComponent, children: [
          { path: 'revenue', component: RevenueComponent },
          { path: 'sms-revenue', component:SmsRevenueComponent },
          { path: 'subjectline', component: SubjectlineComponent },
          { path: 'creative', component: CreativeComponent },
          { path: 'count', component: DailyCountComponent },
          { path: 'content', component:ContentComponent },
          { path: 'count-growth', component: DailySegmentGrowthComponent },
          { path: 'Phonexa-Lead', component:PhonexaLeadReportComponent },
          { path: 'sms-campagin', component:SmsCampaginComponent },
        ]
      },
      { path: 'team', component: TeamComponent },
      { path: 'platform', component: PlatformComponent },
      { path: 'deliveribility', component: EspComponent },
      { path: 'offer', component: OfferComponent },
      { path: 'offer-group', component: OfferGroupComponent },
      { path: 'network', component: NetworkComponent },
      { path: 'network-group', component: NetworkGroupComponent },
      {
        path: 'mapping', component: MappingComponent, children: [
          { path: 'list-mapping', component: ListComponent },
          { path: 'listOrder', component: ListorderingComponent },
          { path: 'network-mapping', component: NetworkMappingComponent },
          { path: 'offer-mapping', component: OfferMappingComponent },
          { path: 'trigger', component: TriggerComponent },
          { path: 'add-smsrevenue', component: AddSMSRevenueComponent },
        ]
      },
      {
        path: 'mspcomponent', component: MspcomponentComponent , children: [
          { path: 'sender-id', component: SenderIdComponent },
          { path: 'msp-report', component: MspReportComponent },
          { path: 'msp-service-table', component:MspServiceTableComponent }
        ]
      },
      { path: 'dnd-non-dnd-target', component: DndNonDndTargetComponent },
      { path: 'msp-delivered-target', component: MspDeliveredTargetComponent },
      { path: 'publisher', component: PublisherComponent },
      { path: 'offer-publisher-report', component: OfferPublisherReportComponent },
      { path: 'partners', component: PartnersComponent },
      { path: 'advertiser-report', component: AdvertiserReportComponent},
      { path: 'publisher-conversion-report', component:PublisherConversionReportComponent},
      { path: 'bd-offers-report', component:BdOffersReportComponent},
      { path: 'sms-network', component:SmsNetworkComponent},
      { path: 'sms-content', component:SmsContentComponent},
      { path: 'ind-sms-vertical', component:IndSmsVerticalComponent},
      { path: 'ind-sms-domain', component:IndSmsDomainComponent},
      { path: 'sms-offer', component:SmsOfferComponent},
      // { path: 'publisher', component: PublisherComponent },
      // { path: 'domain', component: DomainComponent },
      { path: 'creative-library', component: CreativelibraryComponent },
      { path: 'network-updates', component: ComplianceComponent },
      { path: 'leads', component: LeadsComponent },
      { path: 'phonexa-submit-leads', component: PhonexaSubmitLeadsComponent },
      { path: 'media-buying', component: MediaBuyingComponent },
      { path: 'cat-sms', component: CatSmsComponent },
      { path: 'custom-report', component: CustomReportComponent },
      { path: 'green-arrow', component: GreenArrowComponent}
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full', canActivate: [AuthGuard] },

  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
