import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
} from "@angular/core";
import { teamModel } from "../../teammodels/model";
import { TeamService } from "../../teamservices/team.service";
import { UtilService } from "src/app/utils/util.service";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";
import { FilterUtils } from "primeng/utils";

@Component({
  selector: "app-listtable",
  templateUrl: "./listtable.component.html",
  styleUrls: ["../../../../../app.component.css"],
})
export class ListtableComponent implements OnInit, OnDestroy {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any = {};
  @Input() tabInformation: any;
  cols: any[]; // Array for Showed Columns
  allCols: any[]; // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: teamModel[]; // Data Storing for Table
  isFilter = false; // for Column Filter Toggle
  isGlobalFilter = false; // for Global Filter Input
  filterOptions: any = {}; // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[]; // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any; // Store Start Date
  endDate: any; // Store End Date
  loading: boolean; // for Lazy Loding
  subscription: Subscription;
  fileName: any;
  tableName: any;
  teamId: any;
  espConnectionId: any = "";
  showModel: any = "";
  offer_id: any = "";
  // getCorrectDate: number;  // Store Swaped Date
  constructor(
    private teamService: TeamService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {
    // Dates Array for Date Selection
  }

  ngOnInit() {
    this.scrollHeight = this.utilService.scrollableTableHeight + "px";
    // All Columns of Table
    this.allCols = [
      { field: "list_name", header: "List Name", type: false },
      { field: "sent_count", header: "Sent Count", type: true },
      { field: "opens", header: "Opens", type: true },
      { field: "opens_persent", header: "Open%", type: true },
      { field: "portal_click", header: "Clicks", type: true },
      { field: "ctr", header: "CTR", type: true },
      { field: "network_click", header: "N/W Click", type: true },
      { field: "click_persent", header: "N/W CTR", type: true },
      { field: "unsubscribe", header: "Unsub", type: true },
      { field: "unsub_persent", header: "Unsub%", type: true },
      { field: "utr", header: "UTR", type: true },
      { field: "conversion_count", header: "Conversions", type: true },
      { field: "conversion_persent", header: "Conversion%", type: true },
      { field: "revenue", header: "Revenue", type: true },
      { field: "epc", header: "EPC", type: true },
      { field: "epl", header: "EPL", type: true },
      { field: "ecpm", header: "ECPM", type: true },
      { field: "hard_bounces", header: "Hard Bounce", type: true },
      { field: "hard_bounces_percent", header: "Hard Bounce%", type: true },
    ];

    // Frized Columns
    this.frozenCols = [
      { field: "list_name", header: "List Name", type: false },
    ];

    // All Columns for Filter Options
    this.colFilterOptions = [
      { field: "sent_count", header: "Sent Count", type: true },
      { field: "opens", header: "Opens", type: true },
      { field: "opens_persent", header: "Open%", type: true },
      { field: "portal_click", header: "Clicks", type: true },
      { field: "ctr", header: "CTR", type: true },
      { field: "network_click", header: "N/W Click", type: true },
      { field: "click_persent", header: "N/W CTR", type: true },
      { field: "unsubscribe", header: "Unsub", type: true },
      { field: "unsub_persent", header: "Unsub%", type: true },
      { field: "utr", header: "UTR", type: true },
      { field: "conversion_count", header: "Conversions", type: true },
      { field: "conversion_persent", header: "Conversion%", type: true },
      { field: "revenue", header: "Revenue", type: true },
      { field: "epc", header: "EPC", type: true },
      { field: "epl", header: "EPL", type: true },
      { field: "ecpm", header: "ECPM", type: true },
      { field: "hard_bounces", header: "Hard Bounce", type: true },
      { field: "hard_bounces_percent", header: "Hard Bounce%", type: true },
    ];

    // First Time Showed Columns
    this.cols = localStorage.listTable
      ? JSON.parse(localStorage.listTable)
      : [
          { field: "sent_count", header: "Sent Count", type: true },
          { field: "opens", header: "Opens", type: true },
          { field: "portal_click", header: "Clicks", type: true },
          { field: "network_click", header: "N/W Click", type: true },
          { field: "unsubscribe", header: "Unsub", type: true },
          { field: "conversion_count", header: "Conversions", type: true },
          { field: "revenue", header: "Revenue", type: true },
          { field: "epc", header: "EPC", type: true },
          { field: "epl", header: "EPL", type: true },
          { field: "ecpm", header: "ECPM", type: true },
        ];

    // Setting Default Date for Data
    if (this.requiredData.data !== undefined) {
      this.teamId = this.requiredData.data.team_id || "";
      this.espConnectionId = this.requiredData.data.esp_connection_id || "";
      this.offer_id = this.requiredData.data.offer_id || "";
      this.startDate = this.requiredData.date1;
      this.endDate = this.requiredData.date2;
      this.filterData();
    }
    FilterUtils["filterNumber"] = (value, filter): boolean => {
      //
      let lessThan = document.getElementById(filter + "_less_than")
        ? (<HTMLInputElement>document.getElementById(filter + "_less_than"))
            .value
        : null;
      let greaterThan = document.getElementById(filter + "_greater_than")
        ? (<HTMLInputElement>document.getElementById(filter + "_greater_than"))
            .value
        : null;

      if (value === undefined || value === null) {
        return false;
      }
      if (
        (Number(greaterThan) === undefined && Number(lessThan) === undefined) ||
        (Number(greaterThan) === null && Number(lessThan) === null)
      ) {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan)) {
        return (
          FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan)
        );
      } else if (Number(greaterThan)) {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan)) {
        return FilterUtils.lt(value, lessThan);
      } else {
        return value;
      }
    };

    FilterUtils["filterString"] = (value, filter): boolean => {
      let startString = document.getElementById(filter + "_starts_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_starts_with"))
            .value
        : null;
      let endString = document.getElementById(filter + "_ends_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_ends_with"))
            .value
        : null;

      if (value === undefined || value === null) {
        return false;
      }
      if (
        (startString === undefined && endString === undefined) ||
        (startString === null && endString === null)
      ) {
        return value;
      }
      if (startString && endString) {
        return (
          FilterUtils.startsWith(value, startString) &&
          FilterUtils.endsWith(value, endString)
        );
      } else if (startString) {
        return FilterUtils.startsWith(value, startString);
      } else if (endString) {
        return FilterUtils.endsWith(value, endString);
      } else {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any) {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any) {
    return (this.showModel = columnValue);
  }
  // For Hiding Filter Model
  hideDialog() {
    return (this.showModel = "");
  }

  // Filter Data Based on Date
  filterData(cols?: any) {
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "List_Data_" + this.fileName;
    if (this.endDate && !this.isFilter) {
      // Passing Updated/Filtered Date
      this.loading = true;
      this.bodyObject = {
        team_id: this.teamId,
        esp_connection_id: this.espConnectionId,
        offer_id: this.offer_id,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      // Getting Data From Server
      if (this.bodyObject && !this.isFilter) {
        this.subscription = this.teamService
          .getListData(this.bodyObject)
          .subscribe(
            (responseData) => {
              if (responseData["error"] === "Expired token") {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Loged out",
                  detail: "Session expired, please login again!!!",
                });
                return this.utilService.logedOut();
              }
              if (responseData["code"]) {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Something went wrong! Server not responding!",
                });
                return null;
              }
              // Setting Default Columns from State Key
              // if (localStorage.getItem('listTable'))
              // {
              //   const cols = JSON.parse(localStorage.getItem('listTable'));
              //   const colsData = this.cols;
              //   this.cols = [];
              //   colsData.forEach(elements => cols.columnOrder.forEach(key =>
              //   {
              //     if (key === elements.field)
              //     {
              //       this.cols.push(elements);
              //     }
              //   }));
              // }
              this.data = responseData as teamModel[];
              this.loading = false;
              this.footerValues = { undefined };
              if (this.data.length !== 0) {
                this.getFooterData(this.data);
                this.filterOptions = this.utilService.getRequiredOptions(
                  this.data,
                  this.data[0]
                );
              } else {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Data not found in the given date range",
                });
              }
            },
            (err) => {
              if (err) {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Something went wrong! Server not responding!",
                });
                this.loading = false;
                return null;
              }
            }
          );
      }
    }
  }
  // Update Filter Options
  updateFilterOptions(tableData, colName) {
    if (tableData !== null) {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[
        colName
      ] = this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }

  // Update Footer Values
  getFooterData(tableData) {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }

  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data) {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Getting Dates from Dates Component
  getDates(datesObj) {
    if (datesObj) {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }

  // Columns State Save
  saveColumnState(dt: any) {
    localStorage.listTable = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt) {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For getting string length(Tooltip)
  showTooltip(text: any) {
    return text && String(text).length > 9 ? text : "";
  }

  // Destroy all subscriptons
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
