import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod'
import { from } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class UtilService
{
  baseURL = environment.baseURL;
  isExpanded = false;
  team_id = "";
  scrollableTableHeight: any;
  loginData: any = {};
  constructor(private router: Router,private http: HttpClient, private authService: AuthService)
  {
  }
  portalStatus =()=> localStorage.portalStatus;// check Email and Sms portal Status (Updated : '21-08-2020' Developer : Hemlata)
  getRequiredOptions(dataArray: any[], keys: any)
  {
    let uniqueValuesArrayByKey: any = {};
    Object.keys(keys).forEach(key =>
    {
      uniqueValuesArrayByKey[key] = this.getUniqueValuesObjectFromArray(
        dataArray,
        key
      );
    });
    return uniqueValuesArrayByKey;
  }
  getUniqueValuesObjectFromArray(dataArray: any[], key: string)
  {
    return this.getUniqueValuesFromArray(dataArray, key).map(dataValue =>
    {
      return { label: dataValue, value: dataValue };
    });
  }
  getUniqueValuesFromArray(dataArray: any[], key: string)
  {
    return [...new Set(dataArray.map(uniqueValues => uniqueValues[key]))];
  }

  getRequiredFooterData(dataArray: any[])
  {
    let columnSumByKey: any = {};
    Object.keys(dataArray[0]).forEach(key =>
    {
      columnSumByKey[key] = this.getValuesSumByKey(
        dataArray,
        key
      );
    });
    return columnSumByKey;
  }
  requestForExportFile(api?:any,body?: any)
  {
    return this.http.post(this.baseURL + api, body);
  }

  getValuesSumByKey(dataArray: any[], key: string)
  {
    return dataArray.map(value => Number(value[key])).reduce((a, b) => (a || 0) + (b || 0));
  }

  logedOut()
  {
    this.authService.logout();
    setTimeout(() =>
    {
      return this.router.navigate(['/login']);
    }, 2);
  }

}
// ...this.tableConfig.export.body,
// accessLevel:this.tableConfig.export.accessLevel,
// fileType:this.fileType,
// reportName:this.tableConfig.export.reportName,
// user_id: this.utilService.loginData.user_id
