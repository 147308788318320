import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Platform",
      isClosable: false
    });
  }

  getPlatformData(body: any) {
    return this.http.post(this.baseURL + "GetData/platform", body);
  }

}
