import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SmsOfferService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(private http: HttpClient)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Offer Performance",
      isClosable: false
    });
  }
  getSmsOfferData(body: any)
  {
    //return body.offer_group_id ? this.http.post(this.baseURL + "sms/getOffersReport", body) : this.http.post(this.baseURL + "sms/getofferGroup", body);
    return this.http.post(this.baseURL + "sms/getOffersReport", body);
  }
}
