import { environment } from '../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GodsEyeService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(private http : HttpClient) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Organization Performance",
      isClosable: false
    });
   }

  getOrganizationData(body: any)
  {
    return this.http.post(this.baseURL + "GreenArrow/getOrganizationReport", body);
  }
  getOrganizationWiseReport(body: any)
  {
    return this.http.post(this.baseURL + "GreenArrow/getCampaignWiseReport", body);
  }
  getOrganizationDataCountryWise(body: any)
  {
    return this.http.post(this.baseURL + "GreenArrow/getCountryWiseReport", body);
  }
  getMailingListWiseReport(body: any)
  {
    return this.http.post(this.baseURL + "GreenArrow/getMailingListWiseReport", body);
  }
}
