import { Component, OnInit } from "@angular/core";
import { LeadsService } from "../leadsservices/leads.service";
import { UtilService } from "src/app/utils/util.service";
import { MediaBuyingService } from "./media-buying.service";

@Component({
  selector: 'app-media-buying',
  templateUrl: './media-buying.component.html',
  styleUrls: ['./media-buying.component.css']
})
export class MediaBuyingComponent implements OnInit {
  tabId = 0;
  tabs: any[] = [];
  activeIndex: number;
  checkPreviousTab = false;

  constructor(
    private mediaservice: MediaBuyingService,
    private utilService: UtilService
  ) {

  }

  ngOnInit(): void {
    this.activeIndex = 0;
    this.tabs = this.mediaservice.tabs;
  }
  addTabInArray(tabObject) {
    if (tabObject) {
      // Checking Whether The Tab is in the Array or Not
      const index = this.mediaservice.tabs.findIndex(
        (x) => x.header === tabObject.header && x.type === tabObject.type
      );
      if (index !== -1) {
        // Update Tab in Tab Array
        this.mediaservice.tabs.splice(index, 1, {
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data,
        });
        this.activeIndex = index;
      } else {
        // Adding New Tab in Tab Array
        this.mediaservice.tabs.push({
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data,
        });
        // localStorage.setItem(
        //   "mediaBuying",
        //   JSON.stringify(this.mediaservice.tabs)
        // );
        this.activeIndex = this.mediaservice.tabs.length - 1;
      }
    }
  }
   // Close Tab and remove from the Tab Array
   closeTab(tabIndex) {
    this.mediaservice.tabs.splice(tabIndex, 1);
    // localStorage.setItem("mediaBuying", JSON.stringify(this.mediaservice.tabs));
    this.activeIndex = this.mediaservice.tabs.length - 1;
  }

  // Adjust Viewport According to Sidenav Expand and Collapse
  isExpanded() {
    return this.utilService.isExpanded;
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }



}
