<span *ngIf="data && data.data && data.data.length">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data.data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'network_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign: col.field === 'network_name' ? 'left' : 'right'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: col.field === 'network_name' ? 'left' : 'right'
          }"
        >
          <span
            *ngIf="
              rowData[col.field] !== 'Undefined' &&
              col.header === 'Network Group Name'
            "
          >
            <button
              mat-button
              pTooltip="Advertisers"
              (click)="
                addNewTab(
                  'Advertisers - ',
                  rowData['network_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/advertiser_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <!-- <button
              mat-button
              *ngIf="networkId === ''"
              pTooltip="Lists"
              (click)="
                addNewTab(
                  'Lists - ',
                  rowData['network_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img src="assets/icons/list_w.svg" [style]="{ width: '.8rem' }" />
            </button> -->
            <!-- <button
              mat-button
              *ngIf="networkId === ''"
              pTooltip="Offers"
              (click)="
                addNewTab(
                  'Offers - ',
                  rowData['network_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/offer_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button> -->
            <!-- <button
              mat-button
              *ngIf="networkId === ''"
              pTooltip="Creatives"
              (click)="
                addNewTab(
                  'Creatives - ',
                  rowData['network_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/creative_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button> -->
            <!-- <button
              mat-button
              *ngIf="networkId !== ''"
              pTooltip="Campaigns"
              (click)="
                addNewTab(
                  'Campaigns - ',
                  rowData['network_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/campaign_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button> -->
          </span>

          <span
            *ngIf="
              col.field !== 'opens_persent' &&
              col.field !== 'ctr' &&
              col.field !== 'click_persent' &&
              col.field !== 'unsub_persent' &&
              col.field !== 'utr' &&
              col.field !== 'conversion_persent' &&
              col.field !== 'epc' &&
              col.field !== 'ecpm' &&
              col.field !== 'epl' &&
              col.field !== 'revenue' &&
              col.field !== 'hard_bounces_percent'
            "
          >
            {{ rowData[col.field] }}
          </span>

          <span *ngIf="col.field === 'opens_persent'">
            {{
              (rowData["opens"] / rowData["sent_count"] !== 1 / 0
                ? rowData["opens"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ctr'">
            {{
              (rowData["portal_click"] / rowData["opens"] !== 1 / 0
                ? rowData["portal_click"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'click_persent'">
            {{
              (rowData["network_click"] / rowData["opens"] !== 1 / 0
                ? rowData["network_click"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'unsub_persent'">
            {{
              (rowData["unsubscribe"] / rowData["sent_count"] !== 1 / 0
                ? rowData["unsubscribe"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'utr'">
            {{
              (rowData["unsubscribe"] / rowData["opens"] !== 1 / 0
                ? rowData["unsubscribe"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'conversion_persent'">
            {{
              (rowData["conversion_count"] / rowData["network_click"] !== 1 / 0
                ? rowData["conversion_count"] / rowData["network_click"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>

          <span *ngIf="col.field === 'epc'">
            {{
              (rowData["revenue"] / rowData["network_click"] !== 1 / 0
                ? rowData["revenue"] / rowData["network_click"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ecpm'">
            {{
              ((rowData["revenue"] / rowData["sent_count"]) * 1000 !== 1 / 0
                ? (rowData["revenue"] / rowData["sent_count"]) * 1000
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'epl'">
            {{
              (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
                ? rowData["revenue"] / rowData["conversion_count"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'revenue'">
            {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
          </span>
          <span *ngIf="col.field === 'hard_bounces_percent'">
            {{
              (rowData["hard_bounces"] / rowData["sent_count"] !== 1 / 0
                ? rowData["hard_bounces"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: col.field === 'network_name' ? 'left' : 'right'
          }"
        >
          <span
            *ngIf="
              col.field !== 'network_name' &&
              col.field !== 'opens_persent' &&
              col.field !== 'ctr' &&
              col.field !== 'click_persent' &&
              col.field !== 'unsub_persent' &&
              col.field !== 'utr' &&
              col.field !== 'conversion_persent' &&
              col.field !== 'epc' &&
              col.field !== 'ecpm' &&
              col.field !== 'epl' &&
              col.field !== 'revenue' &&
              col.field !== 'hard_bounces_percent'
            "
          >
            {{ footerValues[col.field] || 0 }}
          </span>

          <span class="footerGrandTotal" *ngIf="col.field === 'network_name'">
            Grand Total
          </span>

          <span *ngIf="col.field === 'opens_persent'">
            {{
              (footerValues["opens"] / footerValues["sent_count"] !== 1 / 0
                ? footerValues["opens"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ctr'">
            {{
              (footerValues["portal_click"] / footerValues["opens"] !== 1 / 0
                ? footerValues["portal_click"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'click_persent'">
            {{
              (footerValues["network_click"] / footerValues["opens"] !== 1 / 0
                ? footerValues["network_click"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'unsub_persent'">
            {{
              (footerValues["unsubscribe"] / footerValues["sent_count"] !==
              1 / 0
                ? footerValues["unsubscribe"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'utr'">
            {{
              (footerValues["unsubscribe"] / footerValues["opens"] !== 1 / 0
                ? footerValues["unsubscribe"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'conversion_persent'">
            {{
              (footerValues["conversion_count"] /
                footerValues["network_click"] !==
              1 / 0
                ? footerValues["conversion_count"] /
                  footerValues["network_click"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>

          <span *ngIf="col.field === 'epc'">
            {{
              (footerValues["revenue"] / footerValues["network_click"] !== 1 / 0
                ? footerValues["revenue"] / footerValues["network_click"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ecpm'">
            {{
              ((footerValues["revenue"] / footerValues["sent_count"]) * 1000 !==
              1 / 0
                ? (footerValues["revenue"] / footerValues["sent_count"]) * 1000
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'epl'">
            {{
              (footerValues["revenue"] / footerValues["conversion_count"] !==
              1 / 0
                ? footerValues["revenue"] / footerValues["conversion_count"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'revenue'">
            {{
              footerValues["revenue"] || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'hard_bounces_percent'">
            {{
              (footerValues["hard_bounces"] / footerValues["sent_count"] !==
              1 / 0
                ? footerValues["hard_bounces"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(data.data | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (data.data !== undefined ? data.data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</span>
<div *ngIf="data === undefined" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
