<!-- <section class="lognin-page">
  <div class="p-grid">
    <div class="ui-g-4p-offset-3">
      <div class="login-side">
        <img src="../assets/img/logo.svg" alt="" />
        <h1>Login</h1>
        <p>Please enter your login information</p>
        <form [formGroup]="loginForm">
          <span class="login-cred">
            <input
              pInputText
              type="text"
              formControlName="email"
              placeholder="email id"
            />
            <input
              pInputText
              type="password"
              formControlName="password"
              placeholder="password"
            />
          </span>
          <br />
          <div class="clr-radio-wrapper">
            <span>{{Error}}</span>
          </div>
          <div class="clr-radio-wrapper">
            <input
              type="radio"
              id="radio1"
              name="radio-basic"
              value="option1"
              class="clr-radio"
            />
            <label for="radio1">Remember Me</label>
          </div>
          <button (click)="login()" class="sign-in">Sign In</button>
        </form>
      </div>
    </div>
  </div>
</section> -->

<section
  [ngStyle]="{
    'background-color': '#fefefe'
  }"
  class="login-page"
>
  <div class="p-grid">
    <div
      [ngClass]="{
        'ui-g-4': !isMobile,
        'ui-g-offset-1': !isMobile,
        'ui-g-12': isMobile
      }"
    >
      <div [ngStyle]="{ margin: isMobile ? '3% 4% 0rem' : '30% 0 0' }">
        <img
          [ngClass]="{ 'login-logo-img': true }"
          src="assets/img/logo.png"
          alt=""
        />
        <h3>Enter your email and password.</h3>
        <form [formGroup]="loginForm">
          <span class="login-cred">
            <input
              [ngClass]="{
                'width-hundred': isMobile,
                'width-eighty-eight': !isMobile,
                'login-input': true
              }"
              pInputText
              type="text"
              formControlName="email"
              placeholder="Email Id"
              (input)="removeError()"
            />
            <input
              [ngClass]="{
                'width-hundred': isMobile,
                'width-eighty-eight': !isMobile,
                'login-input': true
              }"
              pInputText
              type="password"
              formControlName="password"
              placeholder="Password"
              (input)="removeError()"
            />
          </span>
          <br />
          <div [ngClass]="{ addError: Error !== '' }">
            <span
              class="Error"
              [ngStyle]="{ padding: Error ? '.5rem' : '0rem' }"
            >
              {{ Error }}
            </span>
          </div>
          <!-- <div class="clr-radio-wrapper">
                  <input type="radio" id="radio1" name="radio-basic" value="option1" class="clr-radio">
                  <label for="radio1">Remember Me</label>
                </div> -->

       <span style="position: relative; top: -13px;"><a href="https://hrms.rightfullabs.com/login/forgot_password">Forgot Password</a></span>
                <button
                [ngClass]="{
                  'width-hundred': isMobile,
                  'width-eighty-eight': !isMobile,
                  'sign-in': true
                }"
                (click)="login()"
              >
                Sign In
              </button>
        </form>
      </div>
    </div>
    <div
      [ngClass]="{
        'ui-g-7': !isMobile,
        'ui-g-12': isMobile,
        'login-animation': true
      }"
    >
      <video
        [ngStyle]="{ 'margin-top': isMobile ? '2rem' : '-0.15rem' }"
        autoplay="autoplay"
        loop
        height="100%"
        width="auto"
      >
        <source src="assets/img/video-animation-2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</section>
