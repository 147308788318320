<div *ngIf="getTeamId()" class="form_frm ui-g-12">
  <div
    [ngClass]="{
      'ui-g-10 ui-g-offset-1': true
    }"
  >
    <form [formGroup]="creativeForm">
      <div
        *ngIf="updateCreative"
        [ngClass]="{
          listMapper: true,
          'ui-g-6': updateCreative,
          'ui-g-3': !updateCreative
        }"
        style=""
      >
        <input
          formControlName="creativeName"
          id="float-input"
          type="text"
          size="30"
          (keypress)="onlyCharacters($event)"
          placeholder="Creative Name"
          pInputText
        />
      </div>

      <div
        [ngClass]="{
          listMapper: true,
          'ui-g-6': updateCreative,
          'ui-g-5': !updateCreative
        }"
      >
        <p-fileUpload
          #upload
          name="demo[]"
          (onSelect)="clearPrevious(); onUpload($event, upload)"
          [auto]="true"
          accept=".zip"
          maxFileSize="10000000"
          chooseLabel="Select Creative File"
        >
          <ng-template pTemplate="content">
            <div
              *ngIf="creativeFiles !== ''"
              style="
                text-align: center;
                background-color: #009944;
                color: #fefefe;
              "
            >
              <i
                class="pi pi-check"
                style="
                  width: 2rem;
                  margin: 0.75rem 0rem 1.5rem;
                  font-size: 1.5rem;
                "
              ></i>
              {{ this.creativeFiles.name }} -
              {{ this.creativeFiles.size / 1000 | number: "1.0-2" }} KB
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <div
        style="float: left;"
        [ngClass]="{
          listMapper: true,
          'ui-g-6': updateCreative,
          'ui-g-3': !updateCreative
        }"
      >
        <p-dropdown
          formControlName="vertical"
          [options]="verticalOptions"
          placeholder="Vertical Name"
          [filter]="true"
        ></p-dropdown>
      </div>

      <div
        style="float: left;"
        [ngClass]="{
          listMapper: true,
          'ui-g-6': updateCreative,
          'ui-g-4': !updateCreative
        }"
      >
        <p-dropdown
          formControlName="region"
          [options]="regionOptions"
          placeholder="Region Name"
          [filter]="true"
        ></p-dropdown>
      </div>

      <div
        [ngClass]="{
          listMapper: true,
          'ui-g-6': updateCreative,
          'ui-g-3': !updateCreative
        }"
      >
        <p-multiSelect
          defaultLabel="Choose Networks"
          formControlName="networks"
          [options]="networkOptions"
          [virtualScroll]="true"
          [(ngModel)]="networkValues"
          [filter]="true"
        >
        </p-multiSelect>
      </div>

      <div *ngIf="updateCreative" class="listMapper ui-g-6" style="">
        <textarea
          [ngStyle]="{ width: '100%', 'max-width': '100%' }"
          [rows]="2"
          [cols]="28"
          formControlName="whyUpdate"
          placeholder="Reason for update"
          pInputTextarea
          autoResize="autoResize"
        ></textarea>
      </div>

      <div
        [ngClass]="{
          listMapper: true,
          'ui-g-3': updateCreative,
          'ui-g-2': !updateCreative
        }"
      >
        <button
          class="submitButton"
          pButton
          type="button"
          (click)="insertCreativeDetails()"
        >
          Submit
        </button>
      </div>
      <div
        [ngClass]="{
          listMapper: true,
          'ui-g-3': updateCreative,
          'ui-g-2': !updateCreative
        }"
      >
        <button class="resetButton" pButton type="button" (click)="reset()">
          Reset
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="creativeLibraryTable ui-g-12"
  [ngStyle]="{
    'margin-top': getTeamId() ? '-.5rem' : '-1.5rem',
    position: 'relative'
  }"
>
  <div class="heading">
    <div [ngStyle]="{ 'max-width': '180px' }" class="dailyRevenueReport tab">
      Active Creatives
    </div>
  </div>
  <p-table
    #dt
    [(columns)]="cols1"
    [value]="activeData"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols1"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              [(ngModel)]="searchValue1"
              (keyup)="updateData()"
              (focus)="isGlobalFilter1 = !isGlobalFilter1"
              (focusout)="isGlobalFilter1 = !isGlobalFilter1"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions1"
                [(ngModel)]="cols1"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter1 = !isFilter1; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'creative_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign: 'left'
          }"
        >
          <span *ngIf="col.field !== 'remarks'">{{ col.header }}</span>
          <div
            *ngIf="col.field === 'remarks'"
            style="text-align: center; width: 100%; height: 100%;"
          >
            {{ col.header }}
          </div>
          <p-sortIcon
            *ngIf="col.field !== 'remarks'"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter1">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    updateActiveFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    updateActiveFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    updateActiveFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    updateActiveFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter1"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="
                  updateActiveFilterOptions(dt.filteredValue, col.field)
                "
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr *ngIf="rowData.active === '1'">
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: 'left'
          }"
        >
          <span
            *ngIf="
              rowData[col.field] !== 'Undefined' &&
              col.header === 'Creative Name'
            "
          >
            <button
              mat-button
              pTooltip="View Creative"
              (click)="
                addNewTab(
                  'Creative_View - ',
                  rowData['creative_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/creative_view_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <button
              mat-button
              pTooltip="Creative Performance"
              (click)="
                addNewTab(
                  'Creative - ',
                  rowData['creative_name'],
                  startDate,
                  endDate,
                  {
                    creative: rowData['creative_name']
                  }
                )
              "
            >
              <img
                src="assets/icons/creative_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <!-- <button
              mat-button
              pTooltip="Creative Scoring"
              (click)="
                addNewTab(
                  'Scoring - ',
                  rowData['creative_name'],
                  startDate,
                  endDate,
                  {
                    creative: rowData['creative_name']
                  }
                )
              "
            >
              <img
                src="assets/icons/rating_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button> -->
            <button
              mat-button
              pTooltip="Logs"
              (click)="
                addNewTab(
                  'Logs - ',
                  rowData['creative_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img src="assets/icons/logs_w.svg" [style]="{ width: '.8rem' }" />
            </button>
          </span>
          <span
            *ngIf="
              col.field !== 'created_date' &&
              col.field !== 'modified_date' &&
              col.field !== 'advertisers' &&
              col.field !== 'created_by' &&
              col.field !== 'modified_by' &&
              col.field !== 'remarks'"
          >
            {{ rowData[col.field] }}
          </span>
          <span *ngIf="col.field === 'advertisers'">
            {{ rowData[col.field] }}
          </span>
          <span
            *ngIf="
              col.field === 'created_date' || col.field === 'modified_date'
            "
          >
            <span *ngIf="rowData[col.field]">{{
              getDateFormat(rowData[col.field])
            }}</span>
            <span *ngIf="!rowData[col.field]">{{
              getDateFormat(rowData["created_date"])
            }}</span>
          </span>
          <span
            *ngIf="col.field === 'created_by' || col.field === 'modified_by'"
          >
            <span *ngIf="rowData[col.field]">{{ rowData[col.field] }}</span>
            <span *ngIf="!rowData[col.field]">{{ rowData["created_by"] }}</span>
          </span>
          <div *ngIf="col.field === 'remarks'" style="text-align: center;">
            <button mat-button pTooltip="Download" tooltipPosition="left">
              <a
                href="https://wts.rightfullabs.com/apis/creatives/{{
                  rowData['creative_name']
                }}.zip"
              >
                <img
                  src="assets/icons/download_w.svg"
                  [style]="{ width: '.8rem' }"
              /></a>
            </button>
            <button
              *ngIf="this.teamId === 8 || this.teamId === 2020"
              (click)="updateCreativeDetails(rowData)"
              mat-button
              pTooltip="Edit"
              style="margin-left: 0.5rem;"
            >
              <img src="assets/icons/edit_w.svg" [style]="{ width: '.8rem' }" />
            </button>
            <button
              *ngIf="this.teamId === 8 || this.teamId === 2020"
              (click)="op.show($event)"
              mat-button
              pTooltip="Inactive"
              style="margin-left: 0.5rem;"
            >
              <img
                src="assets/icons/power_off_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <button
              *ngIf="userId === 32 ||userId === 2 || userId === 46 || userId === 127"
              (click)="dc.show($event)"
              mat-button
              pTooltip="Delete"
              style="margin-left: 0.5rem;"
            >
              <img
                src="assets/icons/delete_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <p-overlayPanel
              #op
              [autoZIndex]="true"
              [dismissable]="true"
              appendTo="th"
            >
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                Inactive Reason
              </div>
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                <textarea
                  [rows]="1"
                  [cols]="30"
                  pInputTextarea
                  [(ngModel)]="reason"
                  autoResize="autoResize"
                ></textarea>
              </div>
              <div>
                <button
                  pButton
                  [ngStyle]="{
                    'background-color': '#e25750',
                    'border-color': '#e25750',
                    'margin-right': '1rem'
                  }"
                  (click)="changeStatus(rowData)"
                  type="button"
                  label="In-Activate"
                ></button>
                <button
                  pButton
                  (click)="op.hide()"
                  type="button"
                  label="Close"
                ></button>
              </div>
            </p-overlayPanel>
            <p-overlayPanel
              #dc
              [autoZIndex]="true"
              [dismissable]="true"
              appendTo="th"
            >
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                Delete Reason
              </div>
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                <textarea
                  [rows]="1"
                  [cols]="30"
                  pInputTextarea
                  [(ngModel)]="deleteReason"
                  autoResize="autoResize"
                ></textarea>
              </div>
              <div>
                <button
                  pButton
                  [ngStyle]="{
                    'background-color': '#e25750',
                    'border-color': '#e25750',
                    'margin-right': '1rem'
                  }"
                  (click)="deleteCreative(rowData)"
                  type="button"
                  label="Delete"
                ></button>
                <button
                  pButton
                  (click)="dc.hide()"
                  type="button"
                  label="Close"
                ></button>
              </div>
            </p-overlayPanel>
          </div>
        </td>
      </tr>
    </ng-template>

    <!-- <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: col.field === 'created_date'&&col.field==='modified_date' ? 'right' : 'left'
          }"
        >
          <span *ngIf="col.field !== 'creative_name'">
            {{ footerValues[col.field] || 0 }}
          </span>

          <span class="footerGrandTotal" *ngIf="col.field === 'creative_name'">
            Grand Total
          </span>
        </td>
      </tr>
    </ng-template> -->
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(activeData | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter1 || isGlobalFilter1
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (activeData !== undefined ? activeData.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</div>

<div
  *ngIf="inactiveData.length > 0 && (teamId === 8 || teamId === 2020)"
  class="creativeLibraryTable ui-g-12"
  [ngStyle]="{ 'margin-top': teamId === 8 ? '-.5rem' : '1rem' }"
>
  <div class="heading">
    <div [ngStyle]="{ 'max-width': '180px' }" class="dailyRevenueReport tab">
      Inactive Creatives
    </div>
  </div>
  <p-table
    #dt1
    [(columns)]="cols2"
    [value]="inactiveData"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols2"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-7">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              [(ngModel)]="searchValue2"
              (keyup)="updateData()"
              (focus)="isGlobalFilter2 = !isGlobalFilter2"
              (focusout)="isGlobalFilter2 = !isGlobalFilter2"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <!-- <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates> -->

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions2"
                [(ngModel)]="cols2"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="
                  isFilter2 = !isFilter2; dt1.reset(); filterData(dt1._columns)
                "
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt1)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'creative_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign: 'left'
          }"
        >
          <span *ngIf="col.field !== 'remarks'">{{ col.header }}</span>
          <div
            *ngIf="col.field === 'remarks'"
            style="text-align: center; width: 100%; height: 100%;"
          >
            {{ col.header }}
          </div>
          <p-sortIcon
            *ngIf="col.field !== 'remarks'"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter2">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    updateInactiveFilterOptions(dt1.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    updateInactiveFilterOptions(dt1.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  (keyup)="dt1.filter(col.field, col.field, 'filterString')"
                  (change)="
                    updateInactiveFilterOptions(dt1.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  (keyup)="dt1.filter(col.field, col.field, 'filterString')"
                  (change)="
                    updateInactiveFilterOptions(dt1.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter2"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt1.filter($event.value, col.field, 'in')"
                (onPanelHide)="
                  updateInactiveFilterOptions(dt1.filteredValue, col.field)
                "
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr *ngIf="rowData.active === '0'">
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: 'left'
          }"
        >
          <span
            *ngIf="
              rowData[col.field] !== 'Undefined' &&
              col.header === 'Creative Name'
            "
          >
            <button
              mat-button
              pTooltip="View Creative"
              (click)="
                addNewTab('Creative_View - ', rowData['creative_name'], rowData)
              "
            >
              <img
                src="assets/icons/creative_view_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <button
              mat-button
              pTooltip="Creative Performance"
              (click)="
                addNewTab('Creative - ', rowData['creative_name'], {
                  creative: rowData['creative_name']
                })
              "
            >
              <img
                src="assets/icons/creative_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <button
              mat-button
              pTooltip="Logs"
              (click)="addNewTab('Logs - ', rowData['creative_name'], rowData)"
            >
              <img src="assets/icons/logs_w.svg" [style]="{ width: '.8rem' }" />
            </button>
          </span>
          <span
            *ngIf="
              col.field !== 'created_date' &&
              col.field !== 'modified_date' &&
              col.field !== 'advertisers' &&
              col.field !== 'created_by' &&
              col.field !== 'modified_by' &&
              col.field !== 'remarks'
            "
          >
            {{ rowData[col.field] }}
          </span>
          <span *ngIf="col.field === 'advertisers'">
            {{ rowData[col.field] }}
          </span>
          <span
            *ngIf="
              col.field === 'created_date' || col.field === 'modified_date'
            "
          >
            <span *ngIf="rowData[col.field]">{{
              getDateFormat(rowData[col.field])
            }}</span>
            <span *ngIf="!rowData[col.field]">{{
              getDateFormat(rowData["created_date"])
            }}</span>
          </span>
          <span
            *ngIf="col.field === 'created_by' || col.field === 'modified_by'"
          >
            <span *ngIf="rowData[col.field]">{{ rowData[col.field] }}</span>
            <span *ngIf="!rowData[col.field]">{{ rowData["created_by"] }}</span>
          </span>
          <div *ngIf="col.field === 'remarks'" style="text-align: center;">
            <button mat-button pTooltip="Download" tooltipPosition="left">
              <a
                href="https://wts.rightfullabs.com/apis/creatives/{{
                  rowData['creative_name']
                }}.zip"
              >
                <img
                  src="assets/icons/download_w.svg"
                  [style]="{ width: '.8rem' }"
              /></a>
            </button>
            <!-- <button
              (click)="updateCreativeDetails(rowData)"
              mat-button
              pTooltip="Edit"
              style="margin-left: .5rem"
            >
              <img src="assets/icons/edit_w.svg" [style]="{ width: '.8rem' }" />
            </button> -->
            <button
              (click)="op1.show($event)"
              mat-button
              pTooltip="Active"
              style="margin-left: 0.5rem;"
            >
              <img
                src="assets/icons/power_on_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <p-overlayPanel
              #op1
              [autoZIndex]="true"
              [dismissable]="true"
              appendTo="th"
            >
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                Active Reason
              </div>
              <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                <textarea
                  [rows]="1"
                  [cols]="30"
                  pInputTextarea
                  [(ngModel)]="reason"
                  autoResize="autoResize"
                ></textarea>
              </div>
              <div>
                <button
                  pButton
                  [ngStyle]="{
                    'background-color': '#68b76c',
                    'border-color': '#68b76c',
                    'margin-right': '1rem'
                  }"
                  (click)="changeStatus(rowData)"
                  type="button"
                  label="Activate"
                ></button>
                <button
                  pButton
                  (click)="op1.hide()"
                  type="button"
                  label="Close"
                ></button>
              </div>
            </p-overlayPanel>
          </div>
        </td>
      </tr>
    </ng-template>

    <!-- <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: col.field === 'created_date'&&col.field==='modified_date' ? 'right' : 'left'
          }"
        >
          <span *ngIf="col.field !== 'creative_name'">
            {{ footerValues[col.field] || 0 }}
          </span>

          <span class="footerGrandTotal" *ngIf="col.field === 'creative_name'">
            Grand Total
          </span>
        </td>
      </tr>
    </ng-template> -->
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(inactiveData | json) !== '[]'">
        Showing {{ (dt1._totalRecords !== 0 ? dt1._first : 0) | json }}&#8210;{{
          (dt1._first + dt1._rows < dt1._totalRecords
            ? dt1._rows + dt1._first
            : dt1._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter2 || isGlobalFilter2
            ? "(" + dt1._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (inactiveData !== undefined ? inactiveData.length : 0) | json }}
        records
      </span>
    </ng-template>
  </p-table>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
