export class RevenueModel {
    revenueAllCols: any[] = [];
    revenueColFilterOptions: any[] = [];
    revenueCols: any[] = [];
    phonexaRevenueAllCols: any[] = [];
    phonexaRevenueColFilterOptions: any[] = [];
    phonexaRevenueCols: any[] = [];
    oldRevenueAllCols: any[] = [];
    oldRevenueColFilterOptions: any[] = [];
    oldRevenueCols: any[] = [];
    otherOldRevenueAllCols: any[] = [];
    otherOldRevenueColFilterOptions: any[] = [];
    otherOldRevenueCols: any[] = [];
    constructor() {
        // for Daily Revenue Sheet Main Columns
        this.revenueAllCols = [
            { field: "report_date", header: "Date", type: false },
            { field: "mailing_id", header: "Mailing ID", type: false },
            { field: "mailing_name", header: "Mailing Name", type: false },
            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "delivery_timestamp", header: "Schedule Time", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_id", header: "Segment ID", type: true },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "conversion", header: "Conversions", type: true },
            { field: "supress_unsub", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true },
            { field: "network_click", header: "N/W Clicks", type: true },
            { field: "revenue", header: "Revenue", type: true },
            { field: "epc", header: "EPC", type: true },
            { field: "open_rate", header: "Open%", type: true },
            { field: "click_rate", header: "CTR", type: true },
            { field: "hard_bounces", header: "Hard Bounce", type: true },
            { field: "hard_bounces_percent", header: "Hard Bounce%", type: true }
        ];
        // for Daily Revenue Sheet Main Columns Filter Options
        this.revenueColFilterOptions = [
            // { field: "mailing_id", header: "Mailing ID", type: false },
            { field: "report_date", header: "Date", type: false },
            { field: "mailing_name", header: "Mailing Name", type: false },
            { field: "delivery_timestamp", header: "Schedule Time", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_id", header: "Segment ID", type: true },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "conversion", header: "Conversions", type: true },
            { field: "supress_unsub", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true },
            { field: "network_click", header: "N/W Clicks", type: true },
            { field: "revenue", header: "Revenue", type: true },
            { field: "epc", header: "EPC", type: true },
            { field: "open_rate", header: "Open%", type: true },
            { field: "click_rate", header: "CTR", type: true },
            { field: "hard_bounces", header: "Hard Bounce", type: true },
            { field: "hard_bounces_percent", header: "Hard Bounce%", type: true }
        ];
        // for Daily Revenue Sheet Main Columns First Show
        this.revenueCols = [
            { field: "report_date", header: "Date", type: false },
            { field: "mailing_id", header: "Mailing ID", type: false },
            { field: "mailing_name", header: "Mailing Name", type: false },
            { field: "delivery_timestamp", header: "Schedule Time", type: false },
            { field: "esp_connection_title", header: "ESP Name", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_id", header: "Segment ID", type: true },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "conversion", header: "Conversions", type: true },
            { field: "supress_unsub", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true },
            { field: "network_click", header: "N/W Clicks", type: true },
            { field: "revenue", header: "Revenue", type: true },
            { field: "epc", header: "EPC", type: true }
        ];

        // for Daily Phonexa Revenue Sheet Main Columns
        this.phonexaRevenueAllCols = [
          { field: "mailing_id", header: "Mailing ID", type: true },
          { field: "segment_id", header: "Segment Id", type: true },
          { field: "list_id", header: "List Id", type: true },
          { field: "conversions", header: "Conversion ", type: true },
          { field: "revenue", header: "Revenue", type: true }
      ];
          // for Daily Phonexa Sheet Main Columns Filter Options
          this.phonexaRevenueColFilterOptions = [
            { field: "mailing_id", header: "Mailing ID", type: true },
            { field: "segment_id", header: "Segment Id", type: true },
            { field: "list_id", header: "List Id", type: true },
            { field: "conversions", header: "Conversion ", type: true },
            { field: "revenue", header: "Revenue", type: true }
        ];

            // for Daily Phonexa Revenue Sheet Main Columns First Show
          this.phonexaRevenueCols = [
            { field: "mailing_id", header: "Mailing ID", type: true },
            { field: "segment_id", header: "Segment Id", type: true },
            { field: "list_id", header: "List Id", type: true },
            { field: "conversions", header: "Conversion ", type: true },
            { field: "revenue", header: "Revenue", type: true }
        ];
        // for Daily Revenue Sheet Old Columns
        this.oldRevenueAllCols = [

            { field: "name", header: "List/Segment Name", type: false },
            { field: "segment_id", header: "List Id/Segment Id", type: false },
            { field: "conversion", header: "Conversion", type: false },
            { field: "revenue", header: "Revenue", type: true }
        ];
        // for Daily Revenue Sheet Old Columns Filter Options
        this.oldRevenueColFilterOptions = [
            { field: "segment_id", header: "List Id/Segment Id", type: false },
            { field: "conversion", header: "Conversion", type: false },
            { field: "revenue", header: "Revenue", type: true }
        ];
        // for Daily Revenue Sheet Old Columns First Show
        this.oldRevenueCols = [

            { field: "name", header: "List/Segment Name", type: false },
            { field: "segment_id", header: "List Id/Segment Id", type: false },
            { field: "conversion", header: "Conversion", type: false },
            { field: "revenue", header: "Revenue", type: true }
        ];

        // for Daily Revenue Sheet Other Old Revenue Columns
        this.otherOldRevenueAllCols = [
            { field: "affiliate", header: "Affiliate", type: false },
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "conversion", header: "Conversion", type: true },
            { field: "revenue", header: "Revenue", type: true }
        ];
        // for Daily Revenue Sheet Other Old Revenue Columns Filter Options
        this.otherOldRevenueColFilterOptions = [
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "conversion", header: "Conversion", type: true },
            { field: "revenue", header: "Revenue", type: true }
        ];
        // for Daily Revenue Sheet Other Old Revenue Columns First Show
        this.otherOldRevenueCols = [
            { field: "affiliate", header: "Affiliate", type: false },
            { field: "advertiser", header: "Advertiser", type: false },
            { field: "offer", header: "Offer", type: false },
            { field: "conversion", header: "Conversion", type: true },
            { field: "revenue", header: "Revenue", type: true }
        ];
    }
}
