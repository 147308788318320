<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <span [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Creatives'">
        <app-creativelibrarytable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creativelibrarytable>
      </p>
      <p *ngSwitchCase="'Creative_View - '">
        <app-creativewindow
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creativewindow>
      </p>
      <p *ngSwitchCase="'Logs - '">
        <app-creativelogs
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creativelogs>
      </p>
      <p *ngSwitchCase="'Creative - '">
        <app-creative-performance
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creative-performance>
      </p>
      <p *ngSwitchCase="'Scoring - '">
        <app-creative-scoring
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creative-scoring>
      </p>
    </span>
  </mat-tab>
</mat-tab-group>
