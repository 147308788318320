import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {

  portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;
  baseURL = environment.baseURL;
  constructor(private httpClient: HttpClient, private utilService: UtilService) { }
  getCustomReportBaseOptions()
  {
    return this.httpClient.get(this.baseURL + "sms/customReportBase");
  }
  generateReportData(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/customizeReport",body);
  }
  getCustomeReportData(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/getCustomizeReport",body);
  }
  customFieldHeads(body)
  {
    return this.httpClient.post(this.baseURL + "sms/customFieldHeads",body);
  }
}
