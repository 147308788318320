import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../../utils/util.service';
import { environment } from './../../../../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class PhonexaServiceService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Team Performance",
      isClosable: false
    });
   }
  //  getTeamPerformance(body: any) {
  //   return this.http.post(this.baseURL + "Phonexa/getPhonexaSubmitLeadsReport",body);
  // }
     getTeamPerformance(body: any) {
    return this.http.post(this.baseURL + "Phonexa/teamPerformanceReport",body);
  }
  getWebsiteWiseReport(body: any){
    return this.http.post(this.baseURL + "Phonexa/websiteWisePublisherReport",body);
  }
}
