import { environment } from "./../../../../../environments/environment.prod";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UtilService } from "./../../../../utils/util.service";

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  phonexaLead: any[] = [];
  // tabs: any[] = JSON.parse(localStorage.getItem('leadsTabs')) || [];
  constructor(private http: HttpClient, private utilService: UtilService) {
    if (this.tabs.length < 1) {
      this.tabs.push({
        id: 0,
        header: "",
        type: "Websites",
        isClosable: false,
      });
    }
    if (this.phonexaLead.length < 1) {
      this.phonexaLead.push({
        id: 0,
        header: "",
        type: "Phonexa Submit Leads Report",
        isClosable: false,
      });
    }
  }

  getRegionOptions() {
    return this.http.get(this.baseURL + "WebSiteDeatails/region");
  }
  getVerticalOptions() {
    return this.http.get(this.baseURL + "WebSiteDeatails/vertical");
  }

  getWebsitesData(body: any) {
    return this.http.post(this.baseURL + "Phonexa/websiteReport", body);
  }
  getLeadsData(body: any) {
    return this.http.post(this.baseURL + "Phonexa/websiteDropLeadsReport", body);
  }
  // getLeadsData(body: any) {
  //   return this.http.post(this.baseURL + "Phonexa/websiteDropLeadsReport", body);
  // }
  getDropFieldsData(body: any) {
    return this.http.post(this.baseURL + "Phonexa/websiteDropFieldReport", body);
  }
  getDropFieldWiseSource(body: any){
    return this.http.post(this.baseURL + "Phonexa/websiteDropFieldReport", body);
  }
  getChannelWiseWebsite(body: any)
  {
    return this.http.post(this.baseURL + "Phonexa/phonexaChannelWiseReport", body);
  }
  getSourceIdWisePublisher(body: any)
  {
    return this.http.post(this.baseURL + "Phonexa/phonexaChannelWisePublisherSourceIdReport", body);
  }
  getPublisharWiseWebsite(body: any)
  {
    return this.http.post(this.baseURL + "Phonexa/phonexaChannelWisePublisherReport", body);
  }
  // getTeamPerformance(body: any) {
  //   return this.http.post(this.baseURL + "Phonexa/getPhonexaSubmitLeadsReport",body);
  // }
  getConversionLeadsData(body: any) {
    return this.http.post(this.baseURL + "Phonexa/websiteConversionLeadsReport", body);
  }
  getDropFieldRateData(body: any) {
    return this.http.post(this.baseURL + "Phonexa/dropRateFieldReport", body);
  }
}
