export class CreativePerformanceModel
{
    allCols: any[] = [];
    colFilterOptions: any[] = [];
    cols: any[] = [];
    constructor()
    {
        // for Daily Creative Sheet Main Columns
        this.allCols = [
            { field: "mailing_id", header: "Mailing ID", type: false },
            { field: "mailing_name", header: "Offer Name", type: false },
            { field: "advertisers", header: "Networks", type: false },
            { field: "vertical_name", header: "Vertical", type: false },
            { field: "region_name", header: "Region", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "date_name", header: "Date", type: false },
            { field: "day_name", header: "Day", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "unsubscribes", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true },
            { field: "open_rate", header: "Open%", type: true },
            { field: "click_rate", header: "Click%", type: true },
            { field: "hard_bounces", header: "Hard Bounce", type: true },
            { field: "hard_bounces_percent", header: "Hard Bounce%", type: true }
        ];
        // for Daily Creative Sheet Main Columns Filter Options
        this.colFilterOptions = [
            { field: "mailing_name", header: "Offer Name", type: false },
            { field: "advertisers", header: "Networks", type: false },
            { field: "vertical_name", header: "Vertical", type: false },
            { field: "region_name", header: "Region", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "date_name", header: "Date", type: false },
            { field: "day_name", header: "Day", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "unsubscribes", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true },
            { field: "open_rate", header: "Open%", type: true },
            { field: "click_rate", header: "Click%", type: true },
            { field: "hard_bounces", header: "Hard Bounce", type: true },
            { field: "hard_bounces_percent", header: "Hard Bounce%", type: true }
        ];
        // for Daily Creative Sheet Main Columns First Show
        this.cols = [
            { field: "mailing_name", header: "Offer Name", type: false },
            { field: "advertisers", header: "Networks", type: false },
            { field: "vertical_name", header: "Vertical", type: false },
            { field: "region_name", header: "Region", type: false },
            { field: "list_name", header: "List Name", type: false },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "from_name", header: "From Name", type: false },
            { field: "email_message_subject", header: "Subject Line", type: false },
            { field: "date_name", header: "Date", type: false },
            { field: "day_name", header: "Day", type: false },
            { field: "sent", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "unsubscribes", header: "Unsub", type: true },
            { field: "failed", header: "Failed", type: true },
            { field: "complaints", header: "Complaints", type: true }

        ];
    }
}
