import { MessageService } from 'primeng/api';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['../../app.component.css']
})
export class DatesComponent implements OnInit
{
  @Output() pullDates = new EventEmitter();
  @Input() getDates;

  minDate = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate = moment().toDate();

  dates: any = {};
  // dates1: any = {};
  // getCorrectDate = true;
  selectedDates: any = {};
  constructor()
  {
    // this.selectedDates['start_date'] = moment().format('dd/mm/yy');
  }

  ngOnInit()
  {
    //debugger;
    this.dates = [
      { label: 'Today', value: { id: 1, start_date: moment().toDate(), end_date: moment().toDate() } },
      { label: 'Yesterday', value: { id: 2, start_date: moment().subtract(1, 'day').toDate(), end_date: moment().subtract(1, 'day').toDate() } },
      { label: 'This Week', value: { id: 3, start_date: moment().day(1).toDate(), end_date: moment().toDate() } },
      { label: 'Last Week', value: { id: 4, start_date: moment().day(1).subtract(1, 'week').toDate(), end_date: moment().day(0).toDate() } },
      { label: 'This Month', value: { id: 5, start_date: moment().startOf('month').toDate(), end_date: moment().toDate() } },
      { label: 'Last Month', value: { id: 6, start_date: moment().startOf('month').subtract(1, 'month').toDate(), end_date: moment().startOf('month').subtract(1, 'day').toDate() } },
      { label: 'This Year', value: { id: 7, start_date: moment().startOf('year').toDate(), end_date: moment().subtract(1, 'day').toDate() } },
      { label: 'Custom Date', value: { id: 8, starst_date: '', end_date: '' } }
    ];

    // this.dates1 = [...this.dates];
    this.selectedDates = { id: 8 };
    this.checkInputDates();
  }

  // Getting Dates From Parent Component and Fixing Show Case Issue
  checkInputDates()
  {
    if (this.getDates)
    {
      const parentStartDate = this.getDates.start_date;
      const parentEndDate = this.getDates.end_date;

      for (let i = 0; i < this.dates.length; i++)
      {
        let arrayStartDate = moment(this.dates[i].value.start_date).format('YYYY-MM-DD');
        let arrayEndDate = moment(this.dates[i].value.end_date).format('YYYY-MM-DD');

        if (arrayStartDate === parentStartDate && arrayEndDate === parentEndDate)
        {
          this.selectedDates = { ...this.dates[i].value };
          break;
        }
      }

      if (this.selectedDates.id === 8)
      {
        this.dates.splice(7, 1, { label: 'Custom Date', value: { id: 8, start_date: moment(this.getDates.start_date, 'YYYY-MM-DD').toDate(), end_date: moment(this.getDates.end_date, 'YYYY-MM-DD').toDate() } });
        this.selectedDates = { ...this.dates[7].value };
      }
    }
  }

  // Select Value Custom If User Manually Select a Date
  setDateOptionToCustom()
  {
    const immuteDates = [
      { label: 'Today', value: { id: 1, start_date: moment().toDate(), end_date: moment().toDate() } },
      { label: 'Yesterday', value: { id: 2, start_date: moment().subtract(1, 'day').toDate(), end_date: moment().subtract(1, 'day').toDate() } },
      { label: 'This Week', value: { id: 3, start_date: moment().day(1).toDate(), end_date: moment().toDate() } },
      { label: 'Last Week', value: { id: 4, start_date: moment().day(1).subtract(1, 'week').toDate(), end_date: moment().day(0).toDate() } },
      { label: 'This Month', value: { id: 5, start_date: moment().startOf('month').toDate(), end_date: moment().toDate() } },
      { label: 'Last Month', value: { id: 6, start_date: moment().startOf('month').subtract(1, 'month').toDate(), end_date: moment().startOf('month').subtract(1, 'day').toDate() } },
      { label: 'This Year', value: { id: 7, start_date: moment().startOf('year').toDate(), end_date: moment().subtract(1, 'day').toDate() } }
    ];
    // this.dates.splice(0, 7, ...immuteDates, { label: 'Custom Date', value: { id: 8, start_date: this.selectedDates.start_date, end_date: this.selectedDates.end_date } });
    this.dates = [...immuteDates, { label: 'Custom Date', value: { id: 8, start_date: this.selectedDates.start_date, end_date: this.selectedDates.end_date } }];
    this.selectedDates = { ...this.dates[7].value };
  }

  // On date change
  dateChange(forDate: string, date: any)
  {
    switch (forDate)
    {
      case 'startDate': this.selectedDates.start_date = date.value._d; break;
      case 'endDate': this.selectedDates.end_date = date.value._d; break;
      // case 'optionChange': this.dateChangeHandler(); break;
    }

    this.setDateOptionToCustom();
  }

  // Sending Dates To Parent Component
  dateChangeHandler()
  {

    if (this.selectedDates && this.selectedDates.id > 0 || (this.selectedDates.start_date && this.selectedDates.end_date))
    {
      this.pullDates.emit(
        {
          start_date: moment(this.selectedDates.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.selectedDates.end_date).format('YYYY-MM-DD')
        }
      );
    }
  }
}
