import { User } from './../../../../login/logincomponents/login/login.component';
import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { RevenueModel } from '../dailymodels/revenueModel';
import { HttpClient } from '@angular/common/http';
import { SubjectLineModel } from '../dailymodels/subjectlineModel';
import { CreativeModel } from '../dailymodels/creativeModel';
import { CreativePerformanceModel } from '../dailymodels/creativePerformance';
import { SubjectLinePerformanceModel } from '../dailymodels/subjectLinePerformance';
import { UtilService } from './../../../../utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class DailyService
{
  baseURL = environment.baseURL;
  revenueModel = new RevenueModel();
  subjectLineModel = new SubjectLineModel();
  creativeModel = new CreativeModel();
  creativePerformance = new CreativePerformanceModel();
  subjectLinePerformance = new SubjectLinePerformanceModel();
  creativeTabs: any[] = [];
  subjectLineTabs: any[] = [];
  tabs: any[] = [];
 // portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;

  constructor(private http: HttpClient, private utilService: UtilService)
  {
    this.creativeTabs.push({
      id: 0,
      header: "",
      type: "Creative Report",
      isClosable: false
    });
    this.subjectLineTabs.push({
      id: 0,
      header: "",
      type: "Subject Line Report",
      isClosable: false
    });
    this.tabs.push({
      id: 0,
      header: "",
      type: "Campaign Performance",
      isClosable: false
    });
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }
  getTeamList()
  {
    return this.http.get(this.baseURL + "team/teamList");
  }
  getDailyReportData(body: any)
  {
      return this.http.post(this.baseURL + "getData/daily_report", body);
  }
  getDailyRevenueReportData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getDailyRevenueReport", body);
  }
  getDailyPhonexaLeadReport(body: any)
  {
    return this.http.post(this.baseURL + "Phonexa/leadsReport", body);
  }
  getDailyCountData(body: any)
  {
      return this.http.post(this.baseURL + "segmentCountReport", body);
  }
  getDailySmsCampaignData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getCampaignReport", body );
  }
  getSmsCampaignDataByContent(body: any)
  {
    return this.http.post(this.baseURL + "sms/getContentReport", body );
  }
  getDailyCountGrowthData(body: any)
  {
    return this.http.post(this.baseURL + "SegmentCountReport/growth", body);
  }
  getDailySubjectLineData(body: any)
  {
    return this.http.post(this.baseURL + "ongageReport/subject_line_summary/", body);
    // return this.http.get(this.baseURL + "ongageReport/subject_line_summary/" + body.start_date + "/" + body.end_date);
  }
  getDailySubjectLinePerformance(body: any)
  {
    return this.http.post(this.baseURL + "ongageReport/subject_line_performance/", body);
    // return this.http.get(this.baseURL + "ongageReport/subject_line_performance/" + body.start_date + "/" + body.end_date + "/" + `${body.subject_line}` + "/" + body.portal_id);
  }
  getDailyCreativeData(body: any)
  {
    return this.http.post(this.baseURL + "ongageReport/creative_summary/", body);
    // return this.http.get(this.baseURL + "ongageReport/creative_summary/" + body.start_date + "/" + body.end_date);
  }
  getDailyCreativePerformance(body: any)
  {
    // if (body.team_id)
    // {
    return this.http.post(this.baseURL + "GetData/creative_performance_report/", body);
    // }
    // if (!body.team_id)
    // {
    //   return this.http.get(this.baseURL + "ongageReport/creative_performance/" + body.start_date + "/" + body.end_date + "/" + body.creative + "/" + body.esp_connection_id);
    // }
  }
  getContentData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getContentReport", body);
  }
  requestForExportFile(body: any)
  {
    return this.http.post(this.baseURL + "sms/getContentReport", body);
  }
  updateDeliveredCount(body: any)
  {
    return this.http.post(this.baseURL + "sms/setDeliveredCount", body);
  }
  updateRevenueOnline(body: any)
  {
    //debugger;
    return this.http.post(this.baseURL + "sms/setCampaignPayoutCount", body);
  }
}

