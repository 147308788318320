import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from 'file-saver';
import { DailyService } from '../dailyservices/daily.service';
import { isString } from 'util';
import { MappingService } from '../../mapping/mappingservices/mapping.service';
import { MatDialog } from '@angular/material/dialog';
import { DomaintableComponent } from '../../domain/domaintables/domaintable/domaintable.component';
import { FilterUtils } from 'primeng/utils';
declare const ExcelJS: any;
@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class RevenueComponent implements OnInit, OnDestroy
{
  @Output() AddTabController = new EventEmitter();
  requiredData: any;
  // @Input() tabInformation: any;
  cols1: any[];   // Array for Showed Columns
  cols2: any[];   // Array for Showed Columns
  cols3: any[];   // Array for Showed Columns
  cols4: any[];   // Array for Showed Columns
  allCols1: any[];  // Array for All Columns
  allCols2: any[];  // Array for All Columns
  allCols3: any[];  // Array for All Columns
  allCols4: any[];  // Array for All Columns
  colFilterOptions1: any[]; // Array for Each Columns including with frozen
  colFilterOptions2: any[]; // Array for Each Columns including with frozen
  colFilterOptions3: any[]; // Array for Each Columns including with frozen
  colFilterOptions4: any[]; // Array for Each Columns including with frozen
  data: any = {}; // Data Storing for Table
  isFilter1 = false;  // for Column Filter Toggle
  isFilter2 = false;  // for Column Filter Toggle
  isFilter3 = false;  // for Column Filter Toggle
  isFilter4 = false;  // for Column Filter Toggle
  isGlobalFilter1 = false;  // for Global Filter Input
  isGlobalFilter2 = false;  // for Global Filter Input
  isGlobalFilter3 = false;  // for Global Filter Input
  isGlobalFilter4 = false;  // for Global Filter Input
  filterOptions1: any = {};  // Data Storing for Column Unique Values
  filterOptions2: any = {};  // Data Storing for Column Unique Values
  filterOptions3: any = {};  // Data Storing for Column Unique Values
  filterOptions4: any = {};  // Data Storing for Column Unique Values
  footerValues1: any = {}; // Data Storing for Column Footer
  footerValues2: any = {}; // Data Storing for Column Footer
  footerValues3: any = {}; // Data Storing for Column Footer
  footerValues4: any = {}; // Data Storing for Column Footer
  frozenCols1: any[]; // for Frized Columns
  frozenCols2: any[]; // for Frized Columns
  frozenCols4: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  checkTeamId: any;
  teamOptions: any[] = [];
  fileName: any;
  tableName: any;
  teamId: any;
  offerId: any = '';
  networkId: any = '';
  teamTable: any;
  items: any[];
  showModel1: any = '';
  showModel2: any = '';
  showModel3: any = '';
  showModel4: any = '';
  minDate: Date = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate: Date = moment().subtract(1, 'day').toDate();
  selectDates: Date = moment().subtract(1, 'day').toDate();
  portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;
  // getCorrectDate: number;  // Store Swaped Date
  constructor(
    private dailyService: DailyService,
    private utilService: UtilService,
    private mappingService: MappingService,
    private messageService: MessageService,
    private matDialog: MatDialog
  )
  {
    // Dates Array for Date Selection
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.checkTeamId = this.utilService.loginData.team_id;
  }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    // All Columns of Table
    this.allCols1 = this.dailyService.revenueModel.revenueAllCols;
    this.allCols2 = this.dailyService.revenueModel.oldRevenueAllCols;
    this.allCols3 = this.dailyService.revenueModel.otherOldRevenueAllCols;
    this.allCols4 = this.dailyService.revenueModel.phonexaRevenueAllCols

    // Frized Columns
    this.frozenCols1 = [
      // { field: "report_date", header: "Date", type: false },
      { field: "mailing_id", header: "Mailing ID", type: false }
    ];
     this.frozenCols4 = [{ field: "team", header: "Team", type: false  }];

    // All Columns for Filter Options
    this.colFilterOptions1 = this.dailyService.revenueModel.revenueColFilterOptions;
    this.colFilterOptions2 = this.dailyService.revenueModel.oldRevenueColFilterOptions;
    this.colFilterOptions3 = this.dailyService.revenueModel.otherOldRevenueColFilterOptions;
    this.colFilterOptions4= this.dailyService.revenueModel.phonexaRevenueColFilterOptions;

    // First Time Showed Columns
    this.cols1 = localStorage.dailyRevenue ? JSON.parse(localStorage.dailyRevenue) : this.dailyService.revenueModel.revenueCols;
    this.cols2 = this.dailyService.revenueModel.oldRevenueCols;
    this.cols3 = this.dailyService.revenueModel.otherOldRevenueCols;
    this.cols4 = this.dailyService.revenueModel.phonexaRevenueCols;

    // Setting Default Date for Data

    this.teamId = this.utilService.loginData.team_id !== 2020 ? this.utilService.loginData.team_id : 1;
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    if (this.utilService.loginData.team_id === 2020)
    {
      this.getTeamOptions();
    }
    this.filterData();


    FilterUtils['filterNumber'] = (value, filter): boolean =>
    {
      let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
      let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null))
      {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan))
      {
        return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
      } else if (Number(greaterThan))
      {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan))
      {
        return FilterUtils.lt(value, lessThan);
      } else
      {
        return value;
      }
    };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any, tableNumber: number)
  {
    if (tableNumber === 1)
    {
      return this.showModel1 = columnValue;
    } else if (tableNumber === 2)
    {
      return this.showModel2 = columnValue;
    } else if (tableNumber === 3)
    {
      return this.showModel3 = columnValue;
    }
  }
  // For Hiding Filter Model
  hideDialog(tableNumber: number)
  {
    if (tableNumber === 1)
    {
      return this.showModel1 = '';
    } else if (tableNumber === 2)
    {
      return this.showModel2 = '';
    } else if (tableNumber === 3)
    {
      return this.showModel3 = '';
    }
  }

  // Filter Data Based on Date
  filterData(type?: any, cols?: any)
  {
    if (type === 'date')
    {
      this.startDate = moment(cols.value._d).format('YYYY-MM-DD');
    }
    if (type === 'team')
    {
      this.teamId = cols;
    }
    // if (cols !== undefined && !moment.isDate(cols)) { this.updateStateCols(cols) }
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Team_Data_" + this.fileName;
    if (!this.isFilter1 && !this.isFilter2 && !this.isFilter3 && !this.isFilter4)
    {
      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        team_id: this.teamId,
        // date: this.startDate,
        start_date: this.startDate,
        end_date: this.endDate
      }

      // Getting Data From Server
      if (this.bodyObject && (!this.isFilter1 && !this.isFilter2 && !this.isFilter3 && !this.isFilter4))
      {
        this.subscription.push(this.dailyService.getDailyReportData(this.bodyObject).subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData;
          this.loading = false;
          this.footerValues1 = { undefined };
          this.footerValues2 = { undefined };
          this.footerValues3 = { undefined };
          this.footerValues4 = { undefined };
          if (this.data.current_revenue.length !== 0)
          {
            this.getFooterData1(this.data.current_revenue);
            this.filterOptions1 = this.utilService.getRequiredOptions(
              this.data.current_revenue,
              this.data.current_revenue[0]
            );
            this.filterOptions1.delivery_timestamp.forEach(e => { e.label = moment(e.label, 'YYYY-MM-DD h:mm:ss').format('h:mm:ss a'); });
            this.getFooterData2(this.data.old_revenue);
            this.filterOptions2 = this.utilService.getRequiredOptions(
              this.data.old_revenue,
              this.data.old_revenue[0]
            );
            this.getFooterData3(this.data.other_revenue);
            this.filterOptions3 = this.utilService.getRequiredOptions(
              this.data.other_revenue,
              this.data.other_revenue[0]
            );
            this.getFooterData4(this.data.phonexa_revenue);
            this.filterOptions4 = this.utilService.getRequiredOptions(
              this.data.phonexa_revenue,
              this.data.phonexa_revenue[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }))
      }

    }
  }

  // Update Filter Options
  updateFilterOptions1(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData1(tableData);
      this.filterOptions1 = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions1[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.current_revenue, colName);
      this.filterOptions1.delivery_timestamp.forEach(e => { e.label = moment(e.label, 'YYYY-MM-DD h:mm:ss').format('h:mm:ss a'); });
    }
  }
  updateFilterOptions2(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData2(tableData);
      this.filterOptions2 = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions2[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.old_revenue, colName);
    }
  }
  updateFilterOptions3(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData3(tableData);
      this.filterOptions3 = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions3[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.other_revenue, colName);
    }
  }
  updateFilterOptions4(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData4(tableData);
      this.filterOptions4 = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions4[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.other_revenue, colName);
    }
  }


  // Update Footer Values
  getFooterData1(tableData)
  { //debugger;
    if (tableData)
    {
      this.footerValues1 = this.utilService.getRequiredFooterData(tableData);
    }
  }
  getFooterData2(tableData)
  {
    if (tableData)
    {
      this.footerValues2 = this.utilService.getRequiredFooterData(tableData);
    }
  }
  getFooterData3(tableData)
  {
    if (tableData)
    {
      this.footerValues3 = this.utilService.getRequiredFooterData(tableData);
    }
  }
  getFooterData4(tableData)
  {
    if (tableData)
    {
      this.footerValues4 = this.utilService.getRequiredFooterData(tableData);
    }
  }


  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Getting Dates from Dates Component
  getDates(datesObj)
  {
    if (datesObj)
    {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      // this.isFilter = false;
      this.filterData();
    }
  }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.dailyRevenue = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt1, dt2, dt3, dt4)
  {
    dt1.columns.splice(0, 0, this.frozenCols1[0]);

    const workbook = new ExcelJS.Workbook();
    const sheet1 = workbook.addWorksheet('Daily Revenue Report');
    const columns1 = dt1.columns.map(e => { return { header: e.header, key: e.field } });
    sheet1.columns = columns1;
    // sheet1.addRows(dt1.filteredValue || dt1.value);

    // Changing Schedule DateTime to Time for Export
    dt1.columns.forEach(col =>
    {
      if (dt1.filteredValue)
      {
        if (col.field === 'delivery_timestamp')
        {
          dt1.filteredValue.forEach(row => row.delivery_timestamp = moment(row.delivery_timestamp, 'YYYY-MM-DD h:mm:ss').format('h:mm a'));
          sheet1.addRows(dt1.filteredValue);
          this.isFilter1 = false;
          this.filterData();
          this.isFilter1 = true;
        }
      } else
      {
        if (col.field === 'delivery_timestamp')
        {
          dt1.value.forEach(row => row.delivery_timestamp = moment(row.delivery_timestamp, 'YYYY-MM-DD h:mm:ss').format('h:mm a'))
          sheet1.addRows(dt1.value);
          this.filterData();
        }
      }
    });
    // Changing Schedule DateTime to Time for Export
    // sheet1.addRows(dt1.filteredValue || dt1.value);

    const sheet2 = workbook.addWorksheet('Old Conversions & Revenue Report');
    const columns2 = dt2.columns.map(e => { return { header: e.header, key: e.field } });
    sheet2.columns = columns2;
    sheet2.addRows(dt2.filteredValue || dt2.value);

    const sheet3 = workbook.addWorksheet('Other Conversions & Revenue Report');
    const columns3 = dt3.columns.map(e => { return { header: e.header, key: e.field } });
    sheet3.columns = columns3;
    sheet3.addRows(dt3.filteredValue || dt3.value);

    const sheet4 = workbook.addWorksheet('Phonexa Revenue');
    const columns4 = dt4.columns.map(e => { return { header: e.header, key: e.field } });
    sheet4.columns = columns4;
    sheet4.addRows(dt4.filteredValue || dt4.value);
    workbook.xlsx.writeBuffer().then((data) =>
    {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(blob, `Revenue_Report_${this.startDate}.xlsx`);
    });

    // Changing Schedule Time to DateTime for Export
    // dt1.columns.forEach(col => {
    //   if (dt1.filteredValue) {
    //     if (col.field === 'delivery_timestamp') {
    //       dt1.filteredValue.forEach(row => row.delivery_timestamp = moment(row.delivery_timestamp, 'h:mm a').format('YYYY-MM-DD h:mm:ss'));
    //       sheet1.addRows(dt1.filteredValue);
    //     }
    //   } else {
    //     if (col.field === 'delivery_timestamp') {
    //       dt1.value.forEach(row => row.delivery_timestamp = moment(row.delivery_timestamp, 'h:mm a').format('YYYY-MM-DD h:mm:ss'))
    //       sheet1.addRows(dt1.value);
    //     }
    //   }
    // });
    // Changing Schedule Time to DateTime for Export

    return dt1.columns.splice(0, 1);
  }
  // For Team Id 2020
  getTeamOptions()
  {
    this.subscription.push(this.mappingService.getTeamList().subscribe((arrayData: any[]) =>
    {
      const teamList = arrayData;
      if (teamList !== undefined)
      {
        this.teamOptions = teamList.map(element => { return { label: element.team_name, value: element.id } });
      }
    }));
  }

  // For Date Show
  convertedDate(date)
  {
    return moment(date, 'YYYY-MM-DD h:mm:ss').format('h:mm a');
  }

  // For Showing Domain Report
  openDomainReport(rowData: any)
  {
    this.matDialog.open(DomaintableComponent,
      {
        data: rowData,
        height: '100vh',
        maxWidth: '100vw',
        width: '100%',
        autoFocus: false,
        disableClose: true
      });
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }

}
