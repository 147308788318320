<mat-sidenav-container class="nav-container" autosize>
  <mat-sidenav
    [ngStyle]="{
      width: isExpanded ? '160px' : '55px',
      position: 'fixed',
      'padding-top': '36px',
      'min-height': '150px',
      overflow: 'inherit',
      height: '100%'
    }"
    id="sidenav"
    #sidenav
    class="example-sidenav"
    mode="side"
    opened="true"
  >
    <mat-nav-list
      data-simplebar
      [ngStyle]="{ height: '100%', 'overflow-x': 'hidden' }"
    >
      <mat-list-item

      >
        <h4 *ngIf="isExpanded">STATS</h4>
        <button mat-icon-button (click)="expanded()">
          <mat-icon *ngIf="!isExpanded">chevron_right</mat-icon>
          <mat-icon *ngIf="isExpanded">chevron_left</mat-icon>
        </button>
      </mat-list-item>

      <!-- Collapse -->
      <div *ngIf="!isExpanded" (mouseleave)="sidebarEventHandler($event)">
        <mat-list-item
          *ngIf="portalStatus() === 'Email'"
          (mouseenter)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="email-dashboard"
        >
          <img
            matIcon
            src="assets/icons/nav_dashboard.svg"
            pTooltip="Dashboard"
          />
        </mat-list-item>
        <mat-list-item
          *ngIf="portalStatus() === 'Sms'"
          (mouseenter)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-dashboard"
        >
          <img
            matIcon
            src="assets/icons/nav_dashboard.svg"
            pTooltip="Dashboard"
          />
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['DAILY'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'DAILY')"
        >
          <img matIcon src="assets/icons/nav_daily.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['DAILY']">
            <mat-list-item *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/revenue"
              >Daily Revenue Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <!-- <mat-list-item *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/sms-revenue"
              >Revenue&nbsp;&nbsp;&nbsp;</mat-list-item
            > -->
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/subjectline"
              >Daily Subject Line Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/creative"
              >Daily Creative Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/sms-campagin"
              >Campaign Performance&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="sms-content"
              >Content Performance&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/count"
              >Daily Count Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/Phonexa-Lead"
              >Daily Phonexa Lead&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
              *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'DAILY', 'DAILY')"
              routerLinkActive="active"
              routerLink="daily/count-growth"
              >Segment Count Growth Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
          </mat-nav-list>
        </mat-list-item>

        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['REVENUE'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'REVENUE')"
        >
          <img matIcon src="assets/icons/sms-24px.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['REVENUE']">
            <mat-list-item
              (click)="sidebarEventHandler($event, 'REVENUE', 'REVENUE')"
              routerLinkActive="active"
              routerLink="mapping/add-smsrevenue"
              >Add&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
              (click)="sidebarEventHandler($event, 'REVENUE', 'REVENUE')"
              routerLinkActive="active"
              routerLink="daily/sms-revenue"
              >Revenue Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
          </mat-nav-list>
        </mat-list-item>

        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="ind-sms-domain"
        >
          <img
            matIcon
            src="assets/icons/nav_domain.svg"
            pTooltip="Domain"
          />
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-network"
        >
          <img
            matIcon
            src="assets/icons/nav_network.svg"
            pTooltip="Network"
          />
        </mat-list-item>
        <!-- <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-offer"
        >
          <img
            matIcon
            src="assets/icons/nav_offer.svg"
            pTooltip="Offer"
          />
        </mat-list-item> -->

        <mat-list-item
          *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="team"
        >
          <img
            matIcon
            src="assets/icons/nav_team.svg"
            pTooltip="Team Wise Report"
          />
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="platform"
        >
          <img
            matIcon
            src="assets/icons/nav_platform.svg"
            pTooltip="Platform Wise Report"
          />
        </mat-list-item>
        <mat-list-item
          *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="deliveribility"
        >
          <img
            matIcon
            src="assets/icons/nav_domain.svg"
            pTooltip="Deliveribility Reports"
          />
        </mat-list-item>
        <!-- <mat-list-item
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="network"
        >
          <img
            matIcon
            src="assets/icons/nav_network.svg"
            pTooltip="Network Wise Report"
          />
        </mat-list-item> -->

        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['NETWORK'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'NETWORK')"
        >
          <img matIcon src="assets/icons/nav_network.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['NETWORK']">
            <mat-list-item
              (click)="sidebarEventHandler($event, 'NETWORK', 'NETWORK')"
              routerLinkActive="active"
              routerLink="network"
              >Network Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'NETWORK', 'NETWORK')"
              routerLinkActive="active"
              routerLink="network-group"
              >Network Group Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>


        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['OFFER'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'OFFER')"
        >
          <img matIcon src="assets/icons/nav_offer.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['OFFER']">
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'OFFER', 'OFFER')"
              routerLinkActive="active"
              routerLink="offer"
              >Offer Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'OFFER', 'OFFER')"
              routerLinkActive="active"
              routerLink="sms-offer"
              >Offer Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'OFFER', 'OFFER')"
              routerLinkActive="active"
              routerLink="offer-group"
              >Offer Group Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'OFFER', 'OFFER')"
              routerLinkActive="active"
              routerLink="offer-group"
              >Offer Group Performance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>

        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['MAPPING'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'MAPPING')"
        >
          <img matIcon src="assets/icons/nav_mapping.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['MAPPING']">
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'MAPPING', 'MAPPING')"
              routerLinkActive="active"
              routerLink="mapping/list-mapping"
              >List Mapping&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'MAPPING', 'MAPPING')"
              routerLinkActive="active"
              routerLink="mapping/listOrder"
              >List Ordering&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'MAPPING')"
              routerLinkActive="active"
              routerLink="mapping/network-mapping"
              >Networks Mapping&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Email'"
              (click)="sidebarEventHandler($event, 'MAPPING')"
              routerLinkActive="active"
              routerLink="mapping/offer-mapping"
              >Offers Mapping&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
            *ngIf="portalStatus() === 'Sms'"
              (click)="sidebarEventHandler($event, 'MAPPING')"
              routerLinkActive="active"
              routerLink="mapping/offer-mapping"
              >Offers Mapping&nbsp;&nbsp;&nbsp;</mat-list-item
            >
          </mat-nav-list>
        </mat-list-item>
        <!-- <mat-list-item
          (mouseenter)="sidebarEventHandler($event)"

          routerLinkActive='active'
          routerLink="domain"
        >
          <img
            matIcon
            src="assets/icons/nav_domain.svg"
            pTooltip="Domain Wise Report"
          />
        </mat-list-item> -->
        <!-- <mat-list-item
          (mouseenter)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="creative_library"
        >
          <img
            matIcon
            src="assets/icons/nav_creative_library.svg"
            pTooltip="Creatives"
          />
        </mat-list-item> -->
        <mat-list-item
          *ngIf="portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['MSP'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'MSP')"
        >
          <img matIcon src="assets/icons/msp.svg" />
          <mat-nav-list class="hoverNavList" *ngIf="nestedListState['MSP']">
            <mat-list-item
              (click)="sidebarEventHandler($event, 'MSP', 'MSP')"
              routerLinkActive="active"
              routerLink="mspcomponent/msp-report"
              >MSP Report&nbsp;&nbsp;&nbsp;</mat-list-item
            >
            <mat-list-item
              (click)="sidebarEventHandler($event, 'MSP', 'MSP')"
              routerLinkActive="active"
              routerLink="mspcomponent/sender-id"
              >Sender Id&nbsp;&nbsp;&nbsp;</mat-list-item
            >

          </mat-nav-list>
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
        [ngStyle]="{
          'background-color':
            nestedListTree['TARGET'] === true ? '#dbebff' : 'transparent'
        }"
        (mouseover)="sidebarEventHandler($event, 'TARGET')"
      >
        <img matIcon src="assets/icons/mtd_w.svg" />
        <mat-nav-list class="hoverNavList" *ngIf="nestedListState['TARGET']">
          <mat-list-item
            (click)="sidebarEventHandler($event, 'TARGET', 'TARGET')"
            routerLinkActive="active"
            routerLink="dnd-non-dnd-target"
            >DND & Non DND target&nbsp;&nbsp;&nbsp;</mat-list-item
          >
          <mat-list-item
            (click)="sidebarEventHandler($event, 'TARGET', 'TARGET')"
            routerLinkActive="active"
            routerLink="msp-delivered-target"
            >MSP Delivered Target&nbsp;&nbsp;&nbsp;</mat-list-item
          >

        </mat-nav-list>
      </mat-list-item>

        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="ind-sms-vertical"
        >
          <img
            matIcon
            src="assets/icons/vertical.svg"
            pTooltip="Vertical"
          />
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="custom-report"
        >
          <img
            matIcon
            src="assets/icons/custom_Report.svg"
            pTooltip="Custom Report"
          />
        </mat-list-item>




        <mat-list-item *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['COMPLIANCE'] === true ? '#dbebff' : 'transparent'
          }"
          (mouseover)="sidebarEventHandler($event, 'COMPLIANCE')"
        >
          <img matIcon src="assets/icons/nav_creative_library.svg" />
          <mat-nav-list
            class="hoverNavList"
            *ngIf="nestedListState['COMPLIANCE']"
          >
            <mat-list-item
              (click)="sidebarEventHandler($event, 'COMPLIANCE', 'COMPLIANCE')"
              routerLinkActive="active"
              routerLink="creative-library"
              >Creative Library&nbsp;&nbsp;&nbsp;
            </mat-list-item>
            <mat-list-item
              (click)="sidebarEventHandler($event, 'COMPLIANCE', 'COMPLIANCE')"
              routerLinkActive="active"
              routerLink="network-updates"
              >N/W Compliance&nbsp;&nbsp;&nbsp;
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>

        <mat-list-item *ngIf="portalStatus() === 'Email'"
        [ngStyle]="{
          'background-color':
            nestedListTree['PUBLISHER'] === true ? '#dbebff' : 'transparent'
        }"
        (mouseover)="sidebarEventHandler($event, 'PUBLISHER')"
      >
        <img matIcon src="assets/icons/nav_publisher.svg" />
        <mat-nav-list
          class="hoverNavList"
          *ngIf="nestedListState['PUBLISHER']"
        >
        <mat-list-item
        (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
        routerLinkActive="active"
        routerLink="bd-offers-report"
        > Offer Library  &nbsp;&nbsp;&nbsp;
      </mat-list-item>
          <mat-list-item
            (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
            routerLinkActive="active"
            routerLink="publisher"
            >Publisher Report&nbsp;&nbsp;&nbsp;
          </mat-list-item>
          <mat-list-item
            (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
            routerLinkActive="active"
            routerLink="partners"
            >Partners Report&nbsp;&nbsp;&nbsp;
          </mat-list-item>
          <mat-list-item
          (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
          routerLinkActive="active"
          routerLink="offer-publisher-report"
          >Offers Report &nbsp;&nbsp;&nbsp;
        </mat-list-item>
        <mat-list-item
          (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
          routerLinkActive="active"
          routerLink="advertiser-report"
          >Advertiser Report &nbsp;&nbsp;&nbsp;
        </mat-list-item>

        <mat-list-item
        (click)="sidebarEventHandler($event, 'PUBLISHER', 'PUBLISHER')"
        routerLinkActive="active"
        routerLink="publisher-conversion-report"
        >Conversion Report &nbsp;&nbsp;&nbsp;
      </mat-list-item>

        </mat-nav-list>
      </mat-list-item>


  <mat-list-item *ngIf="portalStatus() === 'Email'"
  [ngStyle]="{
    'background-color':
      nestedListTree['WEBSITE'] === true ? '#dbebff' : 'transparent'
  }"
  (mouseover)="sidebarEventHandler($event, 'WEBSITE')"
>
<img matIcon src="assets/icons/pingtree.svg" />
<mat-nav-list
class="hoverNavList"
*ngIf="nestedListState['WEBSITE']"
>
<mat-list-item
(click)="sidebarEventHandler($event, 'WEBSITE', 'WEBSITE')"
routerLinkActive="active"
routerLink="leads"
target="_blank"
>Website Conversion Report&nbsp;&nbsp;&nbsp;
</mat-list-item>
<mat-list-item
(click)="sidebarEventHandler($event, 'WEBSITE', 'WEBSITE')"
routerLinkActive="active"
routerLink="phonexa-submit-leads"
>Team Performance&nbsp;&nbsp;&nbsp;
</mat-list-item>
<mat-list-item
(click)="sidebarEventHandler($event, 'WEBSITE', 'WEBSITE')"
routerLinkActive="active"
routerLink="media-buying"
>Media Buying&nbsp;&nbsp;&nbsp;
</mat-list-item>
<mat-list-item
(click)="sidebarEventHandler($event, 'WEBSITE', 'WEBSITE')"
routerLinkActive="active"
routerLink="cat-sms"
>Cat SMS&nbsp;&nbsp;&nbsp;
</mat-list-item>
</mat-nav-list>
</mat-list-item>


<mat-list-item *ngIf="portalStatus() === 'GodEyes' || portalStatus() === 'Email'"
[ngStyle]="{
  'background-color':
    nestedListTree['GODSEYE'] === true ? '#dbebff' : 'transparent'
}"
(mouseover)="sidebarEventHandler($event, 'GODSEYE')"
>
<img matIcon src="assets/icons/godseye.svg" />
<mat-nav-list
class="hoverNavList"
*ngIf="nestedListState['GODSEYE']"
>
<mat-list-item
(click)="sidebarEventHandler($event, 'GODSEYE', 'GODSEYE')"
routerLinkActive="active"
routerLink="green-arrow"
>Green Arrow&nbsp;&nbsp;&nbsp;
</mat-list-item>
</mat-nav-list>
</mat-list-item>




        <!-- <mat-list-item *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="publisher"
        >
          <img
            matIcon
            src="assets/icons/nav_publisher.svg"
            pTooltip="Publisher"
          />
        </mat-list-item> -->
        <!-- <mat-list-item *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="leads"
        >
          <img
            matIcon
            src="assets/icons/nav_website.svg"
            pTooltip="Website Leads Report"
          />
        </mat-list-item> -->
      </div>

      <!-- // Expanded -->

      <div *ngIf="isExpanded">
        <mat-list-item *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="email-dashboard"
        >
          <img matIcon src="assets/icons/nav_dashboard.svg" />
          <p style="font-weight: 500;" matLine>
            Dashboard
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-dashboard"
        >
          <img matIcon src="assets/icons/nav_dashboard.svg" />
          <p style="font-weight: 500;" matLine>
            Dashboard
          </p>
        </mat-list-item>

        <mat-list-item>
          <h4>REPORTS</h4>
        </mat-list-item>

        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['DAILY'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'DAILY')"
        >
          <img matIcon src="assets/icons/nav_daily.svg" />
          <p style="font-weight: 500;" matLine>
            Daily
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['DAILY']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['DAILY']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['DAILY']">
          <mat-list-item routerLinkActive="active" routerLink="daily/revenue" *ngIf="portalStatus() === 'Email'"
            >Revenue</mat-list-item
          >
          <!-- <mat-list-item routerLinkActive="active" routerLink="daily/sms-revenue" *ngIf="portalStatus() === 'Sms'"
            >Revenue</mat-list-item
          > -->
          <mat-list-item
          *ngIf="portalStatus() === 'Email'"
            routerLinkActive="active"
            routerLink="daily/subjectline"
            >Subject Line</mat-list-item
          >
          <mat-list-item routerLinkActive="active" routerLink="daily/creative" *ngIf="portalStatus() === 'Email'"
            >Creative</mat-list-item
          >
          <mat-list-item routerLinkActive="active" routerLink="daily/count" *ngIf="portalStatus() === 'Email'"
            >Count</mat-list-item
          >
          <mat-list-item routerLinkActive="active" routerLink="daily/Phonexa-Lead" *ngIf="portalStatus() === 'Email'"
          >Phonexa Lead</mat-list-item
        >
          <mat-list-item routerLinkActive="active" routerLink="daily/sms-campagin" *ngIf="portalStatus() === 'Sms'"
            >Campaign</mat-list-item
          >
          <mat-list-item routerLinkActive="active" routerLink="sms-content" *ngIf="portalStatus() === 'Sms'"
            >Content</mat-list-item
          >
          <mat-list-item
            routerLinkActive="active"
            routerLink="daily/count-growth" *ngIf="portalStatus() === 'Email'"
            >Count Growth</mat-list-item
          >
        </mat-nav-list>
         <!-- <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="mapping/add-smsrevenue"
        >
          <img matIcon src="assets/icons/sms-24px.svg" />
          <p style="font-weight: 500;" matLine>
            Add Revenue
          </p>
        </mat-list-item> -->

        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['REVENUE'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'REVENUE')"
        >
          <img matIcon src="assets/icons/sms-24px.svg" />
          <p style="font-weight: 500;" matLine>
            Revenue
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['REVENUE']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['REVENUE']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>

        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['REVENUE']">
          <mat-list-item routerLinkActive="active" routerLink="mapping/add-smsrevenue"
            >Add</mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="daily/sms-revenue"
            >Revenue Report</mat-list-item>
        </mat-nav-list>
        <mat-list-item *ngIf="portalStatus() === 'Sms'"
        (click)="sidebarEventHandler($event)"
        routerLinkActive="active"
        routerLink="ind-sms-domain"
      >
        <img matIcon src="assets/icons/nav_domain.svg" />
        <p style="font-weight: 500;" matLine>
          Domain
        </p>
      </mat-list-item>

        <mat-list-item *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-network"
        >
          <img matIcon src="assets/icons/nav_network.svg" />
          <p style="font-weight: 500;" matLine>
            Network
          </p>
        </mat-list-item>
        <!-- <mat-list-item *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="sms-offer"
        >
          <img matIcon src="assets/icons/nav_offer.svg" />
          <p style="font-weight: 500;" matLine>
            Offer
          </p>
        </mat-list-item> -->

        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="team"
        >
          <img matIcon src="assets/icons/nav_team.svg" />
          <p style="font-weight: 500;" matLine>
            Teams
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="platform"
        >
          <img matIcon src="assets/icons/nav_platform.svg" />
          <p style="font-weight: 500;" matLine>
            Platform
          </p>
        </mat-list-item>
        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="deliveribility"
        >
          <img matIcon src="assets/icons/nav_domain.svg" />
          <p style="font-weight: 500;" matLine>
            Deliveribility
          </p>
        </mat-list-item>
        <!-- <mat-list-item
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="network"
        >
          <img matIcon src="assets/icons/nav_network.svg" />
          <p style="font-weight: 500;" matLine>
            Network
          </p>
        </mat-list-item> -->

        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['NETWORK'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'NETWORK')"
        >
          <img matIcon src="assets/icons/nav_network.svg" />
          <p style="font-weight: 500;" matLine>
            Network
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['NETWORK']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['NETWORK']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['NETWORK']">
          <mat-list-item routerLinkActive="active" routerLink="network"
            >Network
          </mat-list-item>
          <mat-list-item  routerLinkActive="active" routerLink="network-group"
            >Network Groups
          </mat-list-item>
        </mat-nav-list>

        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['OFFER'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'OFFER')"
        >
          <img matIcon src="assets/icons/nav_offer.svg" />
          <p style="font-weight: 500;" matLine>
            Offer
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['OFFER']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['OFFER']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['OFFER']">
          <mat-list-item routerLinkActive="active" routerLink="offer" *ngIf="portalStatus() === 'Email'"
            >Offers
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="sms-offer" *ngIf="portalStatus() === 'Sms'"
            >Offers
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="offer-group" *ngIf="portalStatus() === 'Email'"
            >Offer Groups
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="offer-group" *ngIf="portalStatus() === 'Sms'"
            >Offer Groups
          </mat-list-item>
        </mat-nav-list>

        <mat-list-item
        *ngIf="portalStatus() === 'Email' || portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['MAPPING'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'MAPPING')"
        >
          <img matIcon src="assets/icons/nav_mapping.svg" />
          <p style="font-weight: 500;" matLine>
            Mapping
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['MAPPING']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['MAPPING']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['MAPPING']">
          <mat-list-item
          *ngIf="portalStatus() === 'Email'"
            routerLinkActive="active"
            routerLink="mapping/list-mapping"
            >List
          </mat-list-item>
          <mat-list-item
          *ngIf="portalStatus() === 'Email'"
            routerLinkActive="active"
            routerLink="mapping/listOrder"
            >List Ordering
          </mat-list-item>
          <mat-list-item
          *ngIf="portalStatus() === 'Email'"
            routerLinkActive="active"
            routerLink="mapping/network-mapping"
            >Networks</mat-list-item
          >
          <mat-list-item
          *ngIf="portalStatus() === 'Email'"
            routerLinkActive="active"
            routerLink="mapping/offer-mapping"
            >Offers</mat-list-item
          >
          <mat-list-item
          *ngIf="portalStatus() === 'Sms'"
            routerLinkActive="active"
            routerLink="mapping/offer-mapping"
            >Offers</mat-list-item
          >
        </mat-nav-list>
        <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['MSP'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'MSP')"
        >
          <img matIcon src="assets/icons/msp.svg" />
          <p style="font-weight: 500;" matLine>
            MSP
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['MSP']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['MSP']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['MSP']">
          <mat-list-item
            routerLinkActive="active"
            routerLink="mspcomponent/msp-report"
            >MSP Report</mat-list-item>
          <mat-list-item
            routerLinkActive="active"
            routerLink="mspcomponent/sender-id"
            >Sender ID</mat-list-item>
        </mat-nav-list>
       <mat-list-item
        *ngIf="portalStatus() === 'Sms'"
          [ngStyle]="{
            'background-color':
              nestedListTree['TARGET'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'TARGET')"
        >
          <img matIcon src="assets/icons/mtd.svg" />
          <p style="font-weight: 500;" matLine>
            MTD Target
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['TARGET']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['TARGET']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list class="clickedNavList" *ngIf="nestedListState['TARGET']">
          <mat-list-item
            routerLinkActive="active"
            routerLink="dnd-non-dnd-target"
            >DND & Non DND Target</mat-list-item>
          <mat-list-item
            routerLinkActive="active"
            routerLink="msp-delivered-target"
            >MSP Delivered Target</mat-list-item>
        </mat-nav-list>
        <mat-list-item *ngIf="portalStatus() === 'Sms'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="ind-sms-vertical"
        >
          <img matIcon src="assets/icons/vertical.svg" />
          <p style="font-weight: 500;" matLine>
            Vertical
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="portalStatus() === 'Sms'"
        (click)="sidebarEventHandler($event)"
        routerLinkActive="active"
        routerLink="custom-report"
      >
        <img matIcon src="assets/icons/custom_Report.svg" />
        <p style="font-weight: 500;" matLine>
          Custom Report
        </p>
      </mat-list-item>

        <!-- <mat-list-item
          (click)="sidebarEventHandler($event)"

          routerLinkActive='active'
          routerLink="domain"
        >
          <img matIcon src="assets/icons/nav_domain.svg" />
          <p style="font-weight: 500" matLine>
            Domain
          </p>
        </mat-list-item> -->
        <!-- <mat-list-item
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="creative_library"
        >
          <img matIcon src="assets/icons/nav_creative_library.svg" />
          <p style="font-weight: 500" matLine>
            Creatives
          </p>
        </mat-list-item> -->

        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['COMPLIANCE'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'COMPLIANCE')"
        >
          <img matIcon src="assets/icons/nav_creative_library.svg" />
          <p style="font-weight: 500;" matLine>
            Creative
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['COMPLIANCE']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['COMPLIANCE']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list
          class="clickedNavList"
          *ngIf="nestedListState['COMPLIANCE']"
        >
          <mat-list-item routerLinkActive="active" routerLink="creative-library"
            >Creative Library
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="network-updates"
            >N/W Compliance
          </mat-list-item>
        </mat-nav-list>


        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['PUBLISHER'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'PUBLISHER')"
        >
          <img matIcon src="assets/icons/nav_publisher.svg" />
          <p style="font-weight: 500;" matLine>
            Publisher
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['PUBLISHER']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['PUBLISHER']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list
          class="clickedNavList"
          *ngIf="nestedListState['PUBLISHER']"
        >
          <mat-list-item routerLinkActive="active" routerLink="bd-offers-report"
          > Offer Library
        </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="publisher"
            >Publisher Report
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="partners"
            >Partners Report
          </mat-list-item>
          <mat-list-item routerLinkActive="active" routerLink="offer-publisher-report"
          >Offers Report
        </mat-list-item>
        <mat-list-item routerLinkActive="active" routerLink="advertiser-report"
          >Advertiser Report
        </mat-list-item>
        <mat-list-item routerLinkActive="active" routerLink="publisher-conversion-report"
          >Conversion Report
        </mat-list-item>

        </mat-nav-list>


        <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          [ngStyle]="{
            'background-color':
              nestedListTree['WEBSITE'] === true ? '#dbebff' : 'transparent'
          }"
          (click)="sidebarEventHandler($event, 'WEBSITE')"
        >
          <img matIcon src="assets/icons/nav_pingtree.svg" />
          <p style="font-weight: 500;" matLine>
            Pingtree
          </p>
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="!nestedListState['WEBSITE']"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            [ngStyle]="{ position: 'absolute', right: '5px' }"
            *ngIf="nestedListState['WEBSITE']"
            >keyboard_arrow_up</mat-icon
          >
        </mat-list-item>
        <mat-nav-list
        class="clickedNavList"
        *ngIf="nestedListState['WEBSITE']"
      >
        <mat-list-item routerLinkActive="active" routerLink="leads" target="_blank"
          >Website Conversion Report
        </mat-list-item>
        <mat-list-item routerLinkActive="active" routerLink="phonexa-submit-leads"
          >Team Performance
        </mat-list-item>
        <mat-list-item routerLinkActive="active" routerLink="media-buying"
        >Media Buying
      </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="cat-sms"
      >Cat SMS
    </mat-list-item>

      </mat-nav-list>
      <mat-list-item
      *ngIf="portalStatus() === 'GodEyes' || portalStatus() === 'Email'"
        [ngStyle]="{
          'background-color':
            nestedListTree['GODSEYE'] === true ? '#dbebff' : 'transparent'
        }"
        (click)="sidebarEventHandler($event, 'GODSEYE')"
      >
        <img matIcon src="assets/icons/godseye.svg" />
        <p style="font-weight: 500;" matLine>
          Gods Eye
        </p>
        <mat-icon
          [ngStyle]="{ position: 'absolute', right: '5px' }"
          *ngIf="!nestedListState['GODSEYE']"
          >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          [ngStyle]="{ position: 'absolute', right: '5px' }"
          *ngIf="nestedListState['GODSEYE']"
          >keyboard_arrow_up</mat-icon
        >
      </mat-list-item>
      <mat-nav-list
      class="clickedNavList"
      *ngIf="nestedListState['GODSEYE']"
    >
      <mat-list-item routerLinkActive="active" routerLink="green-arrow"
        >Green Arrow
      </mat-list-item>

    </mat-nav-list>

        <!-- <mat-list-item
        *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="publisher"
        >
          <img matIcon src="assets/icons/nav_publisher.svg" />
          <p style="font-weight: 500;" matLine>
            Publisher
          </p>
        </mat-list-item> -->
        <!-- <mat-list-item
          *ngIf="portalStatus() === 'Email'"
          (click)="sidebarEventHandler($event)"
          routerLinkActive="active"
          routerLink="leads"
        >
          <img matIcon src="assets/icons/nav_website.svg" />
          <p style="font-weight: 500;" matLine>
            Website Leads
          </p>
        </mat-list-item> -->

      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    [ngStyle]="{
      'min-width': isExpanded ? '1208px' : '1208px',
      'min-height': 'calc(100vh - 60px)',
      'padding-top': '40px'
    }"
  >
    <div class="nav-sidenav-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer class="footer-panel"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
