
<div class="heading">
<div class="dailyRevenueReport tab">
  Offers Report
</div>
</div>
<span *ngIf="data && data.data">
<p-table
class="dailyRevenueReport"
#dt1
[(columns)]="cols1"
[value]="data.data"
[paginator]="true"
[rows]="20"
paginatorDropdownAppendTo="body"
[rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
[showCurrentPageReport]="true"
[globalFilterFields]="allCols1"
[reorderableColumns]="true"
[resizableColumns]="true"
[scrollable]="true"
[scrollHeight]="scrollHeight"
[loading]="loading"
(onColReorder)="saveColumnState(dt1)"
[exportFilename]="'Offer_Report' + fileName || tableName"
[frozenColumns]="frozenCols1"
frozenWidth="250px"
>
<ng-template pTemplate="caption" style="padding-top:2rem">
  <div class="ui-helper-clearfix" >
    <div class="p-grid">
      <div class="ui-g-2">
        <input
          field="text"
          pInputText
          size="50"
          placeholder="Search"
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          class="tableGlobalSearch"
          (focus)="isGlobalFilter = !isGlobalFilter"
          (focusout)="isGlobalFilter = !isGlobalFilter"
          style="width: auto;"
        />
        <i class="tableGlobalSearch pi pi-search" style=""></i>
      </div>
      <app-dates
        class="ui-g-5"
        [getDates]="{ start_date: startDate, end_date: endDate }"
        (pullDates)="getDates($event)"
      ></app-dates>

      <div class="ui-g-5 filterExportToggle">
        <div class="ui-g-6 extra_input">
          <p-multiSelect
            class="columnFilter"
            [options]="colFilterOptions1"
            [(ngModel)]="cols1"
            optionLabel="header"
            selectedItemsLabel="{0} _ selected"
            [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
            defaultLabel="Choose Columns"
            (onClick)="saveColumnState(dt1)"
          >
          </p-multiSelect>
        </div>

        <div class="ui-g-3 filter_c">
          <button
            field="button"
            pButton
            icon="pi pi-filter"
            iconPos="left"
            label="Filter"
            (click)="isFilter1 = !isFilter1; dt1.reset(); filterData(dt1)"
          ></button>
        </div>

        <div class="ui-g-3 export_c">
          <button
            field="button"
            pButton
            iconPos="left"
            label="Export"
            (click)="exportData(dt1)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template pTemplate="colgroup" let-columns>
  <colgroup>
    <col
      *ngFor="let col of columns"
      pResizableColumn
      pReorderableColumn
      [pSortableColumn]="col.field"
      [ngStyle]="{
        width: col.type === 'primary' ? '200px' : '110px'
      }"
    />
  </colgroup>
</ng-template>
<ng-template pTemplate="header" let-columns>
  <tr>
    <th
      [pTooltip]="showTooltip(col.header)"
      *ngFor="let col of columns"
      [pSortableColumn]="col.field"
      pReorderableColumn
      pResizableColumn
      [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
      }"
    >
      <span>{{ col.header }}</span>
      <p-sortIcon
        [field]="col.field"
        ariaLabel="Activate to sort"
        ariaLabelDesc="Activate to sort in descending order"
        ariaLabelAsc="Activate to sort in ascending order"
      >
      </p-sortIcon>
    </th>
  </tr>
  <tr *ngIf="isFilter1">
    <th class="columnFilterTH" *ngFor="let col of columns">
      <p-overlayPanel
        #op
        [showCloseIcon]="true"
        [dismissable]="true"
        appendTo="th"
      >
        <ng-container *ngIf="col?.type==='number'||col.type==='percent'||col.type ==='currency'"
          ><div [ngStyle]="{ float: 'left', display: 'contents' }">
            {{ col.header }}
          </div>
          <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
            <input
              [attr.id]="col.field + '_greater_than'"
              *ngIf="isFilter1"
              (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
              (change)="
                getFooterData1(dt1.filteredValue);
                updateFilterOptions1(dt1.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Greater Than"
            />
          </div>
          <div style="margin-bottom: 0.25rem;">
            <input
              [attr.id]="col.field + '_less_than'"
              *ngIf="isFilter1"
              (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
              (change)="
                getFooterData1(dt1.filteredValue);
                updateFilterOptions1(dt1.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Less Than"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="col?.type==='string'||col.type==='primary'">
          <div [ngStyle]="{ float: 'left', display: 'contents' }">
            {{ col.header }}
          </div>
          <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
            <input
              [attr.id]="col.field + '_starts_with'"
              (keyup)="dt1.filter(col.field, col.field, 'filterString')"
              (change)="
                getFooterData1(dt1.filteredValue);
                updateFilterOptions1(dt1.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Starts With"
            />
          </div>
          <div style="margin-bottom: 0.25rem;">
            <input
              [attr.id]="col.field + '_ends_with'"
              (keyup)="dt1.filter(col.field, col.field, 'filterString')"
              (change)="
                getFooterData1(dt1.filteredValue);
                updateFilterOptions1(dt1.filteredValue, col.field)
              "
              pInputText
              type="text"
              placeholder="Ends With"
            />
          </div>
        </ng-container>
        <div>
          <p-multiSelect
            appendTo="body"
            [filter]="isFilter1"
            [options]="filterOptions1[col.field]"
            [style]="{ width: '100%' }"
            defaultLabel=""
            (onChange)="dt1.filter($event.value, col.field, 'in')"
            (onPanelHide)="updateFilterOptions1(dt1.filteredValue, col.field)"
          ></p-multiSelect>
        </div>
      </p-overlayPanel>
      <p-button
        class="openAdvanceFilterPanel"
        label="⟱"
        (click)="op.toggle($event)"
      ></p-button>
    </th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr>
    <td
      [pTooltip]="showTooltip(rowData[col.field])"
      *ngFor="let col of columns"
      [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary'
            ? 'left'
            : 'right',
        backgroundColor:
          col.field === 'BounceRate'
            ? dropOffBackgroundColorCoding(rowData[col.field])
            : 'none',
        color:
          col.field === 'BounceRate'
            ? dropOffColorCoding(rowData[col.field])
            : 'none'
      }"
    >

    <span>
      <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number'">{{
        rowData[col.field] || ""
      }}</span>
      <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='epc' ">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.2-2") ||
          (0 | currency: "USD":"symbol":"0.2-2")
      }}</span>
      <span *ngIf="col.type === 'currency' && col.field ==='epc'">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
          (0 | currency: "USD":"symbol":"0.3-3")
      }}</span>
      <!-- <span *ngIf="col.type === 'currency' && col.field ==='cpc'">{{
        ((rowData["payout"] / footerValues1["clicks"] ) | currency: "INR":"symbol":"0.2-2") ||
          (0 | currency: "INR":"symbol":"0.2-2")
      }}</span> -->
      <span *ngIf="col.type === 'currency' && col.field ==='eprs'">{{
        (rowData[col.field] | currency: "USD":"symbol":"0.3-3") ||
          (0 | currency: "USD":"symbol":"0.3-3")
      }}</span>
      <span *ngIf="col.type === 'percent'"
        >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
      >
      <span *ngIf="col.type === 'number'"
              >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
            >
    </span>
    </td>
  </tr>
</ng-template>

<ng-template pTemplate="footer" let-columns>
  <tr>
    <td *ngFor="let col of columns" [ngStyle]="{
        textAlign:
          col.type === 'string' || col.type === 'primary'
            ? 'left'
            : 'right'
      }">
        <span *ngIf="
          col.type !== 'primary' &&
          col.type !== 'currency' &&
          col.type !== 'number' &&
          col.type !== 'percent' &&
          col.type !== 'string' &&
          col.type !== 'actions'
          ">
          {{ footerValues1[col.field] || 0 }}
        </span>
      <span class="footerGrandTotal" *ngIf="col.type === 'primary'">
        Grand Total
      </span>
      <span *ngIf="col.type === 'currency' && col.field !=='eprs' && col.field !=='rpc' && col.field !=='epc' && col.field !== 'cpc' && col.field !== 'revenue' && col.field !== 'rpc' && col.field !== 'profit'">{{
        (footerValues1[col.field] | currency: "USD":"symbol":"0.2-2") ||
          (0 | currency: "USD":"symbol":"0.2-2")
      }}</span>
        <span *ngIf="col.type==='percent' && col.field === 'opens_persent'">
          {{
            (footerValues1["opens"] / footerValues1["sent_count"] !== 1 / 0
              ? footerValues1["opens"] / footerValues1["sent_count"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.type==='percent' && col.field === 'ctr'">
          {{
            (footerValues1["portal_click"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["portal_click"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
      </span>
      <span *ngIf="col.type==='currency' && col.field === 'cpc'">
        {{
          (footerValues1["payout"] / footerValues1["clicks"] ) || 0 | percent: "1.2-2"
        }}
    </span>
    <span *ngIf="col.type==='percent' && col.field === 'cr'">
      {{
        (footerValues1["revenue"] / footerValues1["clicks"] ) || 0 | percent: "1.2-2"
      }}
  </span>
  <span *ngIf="col.type==='currency' && col.field === 'profit'">
    {{
      (footerValues1["revenue"] / footerValues1["payout"] ) || 0 | percent: "1.2-2"
    }}
</span>
    <span *ngIf="col.type==='currency' && col.field === 'rpc'">
      {{
        (footerValues1["revenue"] / footerValues1["clicks"] ) || 0 | percent: "1.2-2"
      }}
  </span>
        <span *ngIf="col.type==='percent' && col.field === 'click_persent'">
          {{
            (footerValues1["network_click"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["network_click"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
      </span>
        <span *ngIf="col.type==='percent' && col.field === 'unsub_persent'">
          {{
            (footerValues1["unsubscribe"] / footerValues1["sent_count"] !== 1 / 0
              ? footerValues1["unsubscribe"] / footerValues1["sent_count"]
              : 0) || 0 | percent: "1.2-2"
          }}
      </span>
        <span *ngIf="col.type==='percent' && col.field === 'utr'">
          {{
            (footerValues1["unsubscribe"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["unsubscribe"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
      </span>
        <span *ngIf="col.type==='percent' && col.field === 'conversion_persent'">
          {{
            (footerValues1["conversion_count"] /
              footerValues1["network_click"] !==
            1 / 0
              ? footerValues1["conversion_count"] / footerValues1["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.type==='currency' && col.field === 'epc'">
          {{
            (footerValues1["revenue"] / footerValues1["network_click"] !== 1 / 0
              ? footerValues1["revenue"] / footerValues1["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.type==='currency' && col.field === 'ecpm'">
          {{
            ((footerValues1["revenue"] / footerValues1["sent_count"]) * 1000 !==
            1 / 0
              ? (footerValues1["revenue"] / footerValues1["sent_count"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.type==='currency' && col.field === 'epl'">
          {{
            (footerValues1["revenue"] / footerValues1["conversion_count"] !==
            1 / 0
              ? footerValues1["revenue"] / footerValues1["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.type==='currency' && col.field === 'revenue'">
          {{ footerValues1["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.type==='percent' && col.field === 'hard_bounces_percent'">
          {{
            (footerValues1["hard_bounces"] / footerValues1["sent_count"] !== 1 / 0
              ? footerValues1["hard_bounces"] / footerValues1["sent_count"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.type === 'number'"
        >{{ footerValues1[col.field] || 0 | number: "0.0-0":"en-USD" }}</span
      >
    </td>
  </tr>
</ng-template>
<ng-template pTemplate="paginatorleft">
  <span *ngIf="(data | json) !== '[]'">
    Showing {{ (dt1._totalRecords !== 0 ? dt1._first : 0) | json }}&#8210;{{
      (dt1._first + dt1._rows < dt1._totalRecords
        ? dt1._rows + dt1._first
        : dt1._totalRecords
      ) | json
    }}
    entries
    {{
      isFilter1 || isGlobalFilter1
        ? "(" + dt1._totalRecords + " filtered records)"
        : ""
    }}
    from
    {{
      (data["data"] !== undefined
        ? data["data"].length
        : 0
      ) | json
    }}
    records
  </span>
</ng-template>
  </p-table>
</span>
<div *ngIf="data == undefined" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>

<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>




