import { teamModel } from './../../../team/teammodels/model';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComplianceService } from '../../complianceservices/compliance.service';
import { UtilService } from 'src/app/utils/util.service';
import { FilterUtils } from 'primeng/utils';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-network-updates-logs',
  templateUrl: './network-updates-logs.component.html',
  styleUrls: ['./../../../../../app.component.css']
})
export class NetworkUpdatesLogsComponent implements OnInit
{
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: teamModel[]; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  tableName: any;
  teamTable: any;
  items: any[];
  windowHeight: any;
  oldCreativeName: any;
  currentCreativeName: any;
  networkId: any;
  creativeName: any;
  updateCreative = false;
  showModel: any = '';
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;

  constructor(private service: ComplianceService, private utilService: UtilService, private messageService: MessageService) { }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    // All Columns of Table
    this.allCols = this.service.networkUpdatesLogs.allCols;

    // { field: "vertical_name", header: "Vertical Name" },
    // { field: "esp_connection_name", header: "Platform Name" },
    // { field: "version", header: "Version" },

    // Frized Columns
    // this.frozenCols = [{ field: "creative_name", header: "Creative Name" }];

    // All Columns for Filter Options
    this.colFilterOptions = this.service.networkUpdatesLogs.colFilterOptions;

    // First Time Showed Columns
    this.cols = this.service.networkUpdatesLogs.cols;


    // Setting Default Date for Data
    if (this.requiredData.data !== undefined)
    {
      this.networkId = this.requiredData.data.network_id;
      this.creativeName = this.requiredData.data.creative_name;
      this.filterData();
    }
    // this.getVerticalOptions();
    // this.getPlatformOptions();
    // FilterUtils['filterNumber'] = (value, filter): boolean => {
    // 
    //   let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
    //   let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

    //   if (value === undefined || value === null) {
    //     return false;
    //   }
    //   if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null)) {
    //     return value;
    //   }
    //   if (Number(greaterThan) && Number(lessThan)) {
    //     return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
    //   } else if (Number(greaterThan)) {
    //     return FilterUtils.gt(value, greaterThan);
    //   } else if (Number(lessThan)) {
    //     return FilterUtils.lt(value, lessThan);
    //   } else {
    //     return value;
    //   }
    // };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }

  // Filter Data Based on Date
  filterData(cols?: any)
  {

    // this.updateStateCols(cols);
    this.tableName = "Creative_Logs_" + this.creativeName;
    if (!this.isFilter)
    {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.service.getComplianceLogsData(this.networkId).subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          console.log(responseData);
          this.data = responseData as teamModel[];
          this.loading = false;
          this.footerValues = { undefined };
          if (this.data.length !== 0)
          {
            // this.getFooterData(this.data);
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data,
              this.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: "Something went wrong! Server not responding!" });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }));
      }

    }
  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      // this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }


  // Update Footer Values
  // getFooterData(tableData) {
  //   this.footerValues = this.utilService.getRequiredFooterData(tableData);
  // }


  // Add New Tab in Tab Array
  addNewTab(type, header, data)
  {
    this.AddTabController.emit({ type, header, data });
  }

  // Getting Dates from Dates Component
  // getDates(datesObj) {
  //   if (datesObj) {
  //     this.startDate = datesObj.start_date;
  //     this.endDate = datesObj.end_date;
  //     this.isFilter = false;
  //     this.filterData();
  //   }

  // }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For Converting Date Format
  getDateFormat(date: any)
  {
    return moment(date, 'YYYY-MM-DD h:m:s').format('DD MMM, YYYY (hh:mm a)');
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }

}
