import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class MappingService
{
  portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;
  // properties: Property;
  baseURL = environment.baseURL;
  constructor(private httpClient: HttpClient, private utilService: UtilService) { }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }
  getTeamList()
  {
    return this.httpClient.get(this.baseURL + "team/teamList");
  }
  getListOrderingDetails(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/getListByTeamId", body);
  }
  updateListOrderingDetails(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/set_sorting", body);
  }
  updateListActiveStatus(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/set_list_status", body);
  }
  updateOfferActiveStatus(body:any)
  { //debugger;
    return this.httpClient.post(this.baseURL + "sms/setOfferMethod", body);
  }
  updateNetworkMapping(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/updateNetworkMapping", body);
  }
  updateNetworkName(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/renameNetworkName", body);
  }
  updateOfferMapping(body: any)
  {
    if(this.portalStatus() === "Email"){
    return this.httpClient.post(this.baseURL + "ongage/updateOfferMapping", body);
    }
    if(this.portalStatus() === "Sms")
    {
      return this.httpClient.post(this.baseURL + "sms/updateOfferMapping", body);
    }

  }
  updateOfferGroupName(body: any)
  {
    if(this.portalStatus() === "Email")
    {
      return this.httpClient.post(this.baseURL + "ongage/renameOfferGroupName", body);
    }
    if(this.portalStatus() === "Sms")
    {
      return this.httpClient.post(this.baseURL + "sms/renameOfferGroupName", body);
    }

  }
  getAdvertisersListInNetwork(body: any)
  {
    return this.httpClient.post(this.baseURL + 'ongage/networkAdvertiserData', body);
  }
  getNetworkOfferIDRevenue(body: any)
  {
    return this.httpClient.post(this.baseURL + 'sms/getRevenueDetailsById', body);
  }
  getAdvertisersListInOffer(body: any)
  {
    if(this.portalStatus() === "Email")
    {
      return this.httpClient.post(this.baseURL + 'ongage/offerGroupOffersData', body);
    }
    if(this.portalStatus() === "Sms")
    {
      return this.httpClient.post(this.baseURL + 'sms/setOfferGroupOffersData', body);
    }

  }
  getOngageList()
  {
    return this.httpClient.get(this.baseURL + "ongage/ongageList");
  }
  getListCategory()
  {
    return this.httpClient.get(this.baseURL + "ongage/list_category");
  }
  getListDetails()
  {
    return this.httpClient.get(this.baseURL + "ongage/list_details");
  }
  getRevenueDetails(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/getRevenue",body);
  }
  getNetworkMappingDetails()
  {
      return this.httpClient.get(this.baseURL + "ongage/networkMapping");
}
  getSmsNetworkOptions()
  {
    return this.httpClient.get(this.baseURL + "sms/getNetworks");
  }
  getSmsOfferOptions(body: any)
  {

    return this.httpClient.post(this.baseURL + "sms/getOffers",body);
  }

  addupdateRevenu(body: any){
    return this.httpClient.post(this.baseURL + "sms/putRevenue",body);
  }

  getOfferMappingDetails()
  {
    if(this.portalStatus() === "Email")
    {
      return this.httpClient.get(this.baseURL + "ongage/offerMapping");
    }
    if(this.portalStatus() ==="Sms")
    {
      return this.httpClient.get(this.baseURL + "sms/getOfferMapping");
    }

  }
  getNetworkOptions()
  {
    return this.httpClient.get(this.baseURL + "getCakeData/get_advertiser");
  }
  getOfferOptions()
  {

      return this.httpClient.get(this.baseURL + "getCakeData/get_offers");

  }
  getSmsOfferMapOptions()
  {
    return this.httpClient.get(this.baseURL + "sms/getOffersMap");
  }
  sendFormToServer(body: any)
  {
    return this.httpClient.post(this.baseURL + "ongage/setTeamListMaping",
      body,
      { headers: { "Content-Type": "application/json" } }
    );
  }
  deleteRevenueData(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/deleteRevenue",body);
  }
  putMonthlyDndNonDndTarget(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/putMonthlyDndNonDndTarget",body);
  }
  getMonthlyDndNonDndTarget()
  {
    return this.httpClient.get(this.baseURL + "sms/getMonthlyDndNonDndTarget");
  }
  getMspName()
  {
    return this.httpClient.get(this.baseURL + "sms/mspName");
  }
  putMspDeliveredTarget(body: any){

    return this.httpClient.post(this.baseURL + "sms/putMspDeliveredTarget",body);
  }
  mspDeliveredTarget(body: any)
  {
    return this.httpClient.post(this.baseURL + "sms/getMspDeliveredTarget",body);
  }
  mspTarget()
  {
    return this.httpClient.get(this.baseURL + "sms/getMonthlyTarget");
  }
  getMspMonthlyTarget()
  {
    return this.httpClient.get(this.baseURL + "sms/getMspMonthlyTarget");
  }
  getVerticalMonthlyReport()
  {
    return this.httpClient.get(this.baseURL + "sms/getVerticalMonthlyReport");
  }

}
