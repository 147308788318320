import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreativelibraryService } from '../../creativelibraryservices/creativelibrary.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-creative-scoring',
  templateUrl: './creative-scoring.component.html',
  styleUrls: ['./../../../../../app.component.css']
})
export class CreativeScoringComponent implements OnInit
{
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: { headers: any[], allHeaders: any[], data: any[] }; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription;
  fileName: any;
  creativeName: any;
  espConnectionId: any;
  tableName: any;

  scoringHint = [
    { above: ">= 2", below: "< 100", score: 5 },
    { above: ">= 1", below: "< 2", score: 4.5 },
    { above: "> -1", below: "< 1", score: 4 },
    { above: "> -2", below: "<= -1", score: 3.5 },
    { above: "> -3", below: "<= -2", score: 3 },
    { above: "> -4", below: "<= -3", score: 2.5 },
    { above: "> -5", below: "<= -4", score: 2 },
    { above: "> -6", below: "<= -5", score: 1.5 },
    { above: "> -7", below: "<= -6", score: 1 },
    { above: "> -8", below: "<= -7", score: 0.5 },
    { above: "> -100", below: "<= -8", score: 0 },
  ]
  // getCorrectDate: number;  // Store Swaped Date
  constructor(
    private creativeLibraryService: CreativelibraryService,
    private utilService: UtilService,
    private messageService: MessageService
  )
  {
    // Dates Array for Date Selection
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    // All Columns of Table
    // this.allCols = this.data.headers;

    // Frized Columns
    // this.frozenCols = this.data.headers.slice(0, 1);

    // All Columns for Filter Options
    // this.colFilterOptions = this.data.headers;

    // First Time Showed Columns
    // this.cols = this.data.headers.filter(data => data !== this.frozenCols[0]);


    // Setting Default Date for Data

    this.creativeName = this.requiredData.data.creative || '';
    this.espConnectionId = this.requiredData.data.esp_connection_id || '';
    this.startDate = this.requiredData.date1;
    this.endDate = this.requiredData.date2;
    this.filterData();




    FilterUtils['filterNumber'] = (value, filter): boolean =>
    {
      // 
      let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
      let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null))
      {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan))
      {
        return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
      } else if (Number(greaterThan))
      {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan))
      {
        return FilterUtils.lt(value, lessThan);
      } else
      {
        return value;
      }
    };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // // For Showing Filter Model
  // setShowModelValue(columnValue: any)
  // {
  //   return this.showModel = columnValue;
  // }
  // // For Hiding Filter Model
  // hideDialog()
  // {
  //   return this.showModel = '';
  // }


  // Filter Data Based on Date
  filterData(cols?: any)
  {

    // this.updateStateCols(cols);
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Websites_" + this.fileName;
    if (this.endDate && !this.isFilter)
    {
      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        creative_name: this.creativeName,
        esp_connection_id: this.espConnectionId,
        start_date: this.startDate,
        end_date: this.endDate
      };
      // Getting Data From Server
      if (this.bodyObject && !this.isFilter)
      {
        this.subscription = this.creativeLibraryService.getCreativeScoring(this.bodyObject).subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData as { headers: any[], allHeaders: any[], data: any[] };
          // All Columns of Table
          this.allCols = this.data.allHeaders;

          // Frized Columns
          this.frozenCols = this.data.allHeaders.filter(col => col.type === 'primary');

          // All Columns for Filter Options
          this.colFilterOptions = this.data.allHeaders.filter(col => col.type !== 'primary');

          // First Time Showed Columns
          this.cols = this.cols = localStorage.creativeScoring ? JSON.parse(localStorage.creativeScoring) : this.data.headers.filter(col => col.type !== 'primary');

          this.loading = false;
          this.footerValues = { undefined };
          if (this.data.data.length !== 0)
          {
            this.getFooterData(this.data.data);
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data.data,
              this.data.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        });
      }

    }
  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
    }
  }


  // Update Footer Values
  getFooterData(tableData)
  {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }


  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Getting Dates from Dates Component
  getDates(datesObj)
  {
    if (datesObj)
    {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }

  // For color coding
  // dropOffBackgroundColorCoding(dropRatio: number)
  // {
  //   if (dropRatio <= 5)
  //   {
  //     return '#1be56c';
  //   }
  //   else if (dropRatio > 5 && dropRatio <= 10)
  //   {
  //     return '#36e87e';
  //   }
  //   else if (dropRatio > 10 && dropRatio <= 15)
  //   {
  //     return '#4ceb8b';
  //   }
  //   else if (dropRatio > 15 && dropRatio <= 20)
  //   {
  //     return '#65ed9c';
  //   }
  //   else if (dropRatio > 20 && dropRatio <= 25)
  //   {
  //     return '#7cf0aa';
  //   }
  //   else if (dropRatio > 25 && dropRatio <= 30)
  //   {
  //     return '#9df4c0';
  //   }
  //   else if (dropRatio > 30 && dropRatio <= 35)
  //   {
  //     return '#b1f6cc';
  //   }
  //   else if (dropRatio > 35 && dropRatio <= 40)
  //   {
  //     return '#cffadf';
  //   }
  //   else if (dropRatio > 40 && dropRatio <= 45)
  //   {
  //     return '#dbffea';
  //   }
  //   else if (dropRatio > 45 && dropRatio <= 50)
  //   {
  //     return '#eafdf1';
  //   }
  //   else if (dropRatio > 50 && dropRatio <= 55)
  //   {
  //     return '#ffd2d6';
  //   }
  //   else if (dropRatio > 55 && dropRatio <= 60)
  //   {
  //     return '#fdc5ca';
  //   }
  //   else if (dropRatio > 60 && dropRatio <= 65)
  //   {
  //     return '#fdb8be';
  //   }
  //   else if (dropRatio > 65 && dropRatio <= 70)
  //   {
  //     return '#fc96a1';
  //   }
  //   else if (dropRatio > 70 && dropRatio <= 75)
  //   {
  //     return '#fa7684';
  //   }
  //   else if (dropRatio > 75 && dropRatio <= 80)
  //   {
  //     return '#fa5f70';
  //   }
  //   else if (dropRatio > 80 && dropRatio <= 85)
  //   {
  //     return '#f94256';
  //   }
  //   else if (dropRatio > 85 && dropRatio <= 90)
  //   {
  //     return '#f82a41';
  //   }
  //   else if (dropRatio > 90 && dropRatio <= 95)
  //   {
  //     return '#f81c34';
  //   }
  //   else 
  //   {
  //     return '#f7021d';
  //   }
  // }

  // For CTR difference (Footer Value)
  getDifference(footerData: any)
  {
    const creativeRate = footerData['clicks'] / footerData['opens'] * 100 || 0;
    const segmentRate = footerData['seg_clicks'] / footerData['seg_opens'] * 100;
    const difference = creativeRate - segmentRate;
    return difference;
  }

  // For creative scoring (Footer Value)
  getScore(footerData: any)
  {
    const creativeRate = footerData['clicks'] / footerData['opens'] * 100 || 0;
    const segmentRate = footerData['seg_clicks'] / footerData['seg_opens'] * 100;
    const difference = creativeRate - segmentRate;
    if (creativeRate === 0)
    {
      return "N/A";
    } else if (difference >= 2)
    {
      return 5;
    } else if (difference < 2 && difference >= 1)
    {
      return 4.5;
    } else if (difference < 1 && difference > -1)
    {
      return 4;
    } else if (difference <= -1 && difference > -2)
    {
      return 3.5;
    }
    else if (difference <= -2 && difference > -3)
    {
      return 3;
    }
    else if (difference <= -3 && difference > -4)
    {
      return 2.5;
    }
    else if (difference <= -4 && difference > -5)
    {
      return 2;
    }
    else if (difference <= -5 && difference > -6)
    {
      return 1.5;
    }
    else if (difference <= -6 && difference > -7)
    {
      return 1;
    }
    else if (difference <= -7 && difference > -8)
    {
      return 0.5;
    }
    else if (difference <= -8)
    {
      return 0;
    }
  }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.creativeScoring = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.unsubscribe();
  }
}
