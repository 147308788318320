import { teamModel } from 'src/app/homepage/reports/team/teammodels/model';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MappingService } from '../mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';
import { Papa } from 'ngx-papaparse';
import { isString } from 'util';

@Component({
  selector: 'app-add-smsrevenue',
  templateUrl: './add-smsrevenue.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class AddSMSRevenueComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  regiform: FormGroup; // for Form Reference
  options: any[] = [];   // for Creating Options for Ongage List
  offerOptions: any[] = [];   // for Creating Options for Ongage List
  networkId: any;  // for storing network id value
  offerId: any;  // for storing network id vaslue
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {};  // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = '';
  networkName: any = '';
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  minDate: Date = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate: Date = moment().subtract(1, 'day').toDate();
  user_id: any = 0;
  // revenue: any;
  // remarks: any;
  // leads: any;
  fileName: any;
  teamId: any;
  tableName: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  footerValues: any = {}; // Data Storing for Column Footer
  revenue_id:any;
  num1:any;
  num2:any;
  userId: any = 0;
  deleteReason: any = '';
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  exporting: boolean;

  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private Papa: Papa
  ) {
      // Dates Array for Date Selection
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    this.startDate = moment().subtract(1,'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1,'day').format('YYYY-MM-DD');
    this.regiform = this.fb.group({
      network_id: ["", Validators.required],
      revenue: ["", Validators.required],
      leads:["",Validators.required],
      payout:["",Validators.required],
      remarks:["",Validators.required],
      offer_id: ["", Validators.required],
      report_date: ["", Validators.required]
    });

    this.userId = this.utilService.loginData.user_id;
    this.getInputData();

    this.filterData();

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };


    this.leads.valueChanges.subscribe(value=>this.revenue.setValue(Number(this.payout.value)*Number(value)));
    this.payout.valueChanges.subscribe(value=>this.revenue.setValue(Number(this.leads.value)*Number(value)));
    this.network_id.valueChanges.subscribe(value=>this.getInputOfferData(value));
    // this.getInputOfferData();
  }



  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }
  // For Deleting Revenue
  deleteRevenue(rowData: any)
  {
    if (this.deleteReason === '')
    {
      return this.messageService.add({ key: 'tc', severity: 'error', summary: 'Reason Required', detail: 'Please provide a valid reason' });
    }
    // debugger
    const body: any = {
      id: rowData.id,
      reason: this.deleteReason,
      user_id: this.userId,
    };
    this.subscription.push(this.mappingService.deleteRevenueData(body).subscribe((response: any) =>
    {
      if (response.code === 200)
      {
        this.deleteReason = '';
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Revenue Deleted', detail: 'Revenue has been deleted successfully!' });
        this.filterData();
      } else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
      }
    }, err =>
    {
      if (err)
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
        return null;
      }
    }));
  }
  // Getting Dates from Dates Component
  getDates(datesObj)
  {
    if (datesObj)
    {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }

  get network_id()
  {
    return this.regiform.get("network_id");
  }
  get report_date()
  {
    return this.regiform.get("report_date");
  }
  get revenue()
  {
    return this.regiform.get("revenue");
  }
  get offer_id()
  {
    return this.regiform.get("offer_id");
  }
  get leads()
  {
    return this.regiform.get("leads");
  }
  get remarks()
  {
    return this.regiform.get("remarks");
  }
  get payout()
  {
    return this.regiform.get("payout");
  }

  getInputData()
  {
    this.subscription.push(this.mappingService.getSmsNetworkOptions().subscribe((ongagelist: any[]) =>
    {
      if (ongagelist)
      {
        this.options = ongagelist.map(e =>
        {
          return {
            label: `${e["network_name"]} (${e["network_id"]})`,
            value:  e["network_id"]
            //label: `${e["advertisers_name"]} (${e["portal_name"]})`,
           // value: { network_id: e["network_id" ], portal_id: e["network_id"] }
          };
        });
      }
    }));

  }

  getInputOfferData(network_id:any)
  {
    this.subscription.push(this.mappingService.getSmsOfferOptions({network_id:network_id}).subscribe((ongagelist: any[]) =>
    {
      if (ongagelist)
      {
        this.offerOptions = ongagelist.map(e =>
        {
          return {
            label: `${e["offer_name"]} (${e["offer_id"]})`,
            value:  e["offer_id"]
            //value: { offer_id: e["offer_id"], portal_id: e["offer_id"] }
          };
        });
      }
    }));
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }

  filterData(type?: any, cols?: any)
  {
    if (cols !== undefined && moment.isDate(cols))
    {
      this.startDate = moment(cols).format('YYYY-MM-DD');
    }
    if (cols !== undefined && isString(cols))
    {
      this.teamId = cols;
    }

    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Websites_" + this.fileName;
    if (this.endDate && !this.isFilter)
    {

      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        team_id: this.teamId,
        start_date: this.startDate,
        end_date: this.endDate,
      };


     if (!this.isFilter)
     {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        if (this.bodyObject && !this.isFilter)
      {
        this.subscription.push(this.mappingService.getRevenueDetails({start_date:this.startDate,end_date:this.endDate}).subscribe(responseData =>
          {
            if (responseData['error'] === 'Expired token')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
              return this.utilService.logedOut();
            }
            if (responseData['code'])
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              return null;
            }
            this.data = responseData as { headers: any[], data: any[],config:any };
            // All Columns of Table
            this.allCols = this.data.headers;

            // Frized Columns
            this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
            this.tableConfig = this.data.config;

            // All Columns for Filter Options
            this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

            // First Time Showed Columns
            this.cols = localStorage.addSmsRevenue ? JSON.parse(localStorage.addSmsRevenue) : this.data.headers.filter(col => col.type !== 'primary');
            this.loading = false;
            this.footerValues = { undefined };
            if (this.data.data.length !== 0)
            {
              this.getFooterData(this.data.data);
              this.filterOptions = this.utilService.getRequiredOptions(
                this.data.data,
                this.data.data[0]
              );
            } else
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
            }
          }, err =>
          {
            if (err)
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              this.loading = false;
              return null;
            }
          }));
        }
      }

    }

    }




  }


  // For setting fields value
  editRevenueDetails(revenueDetails: any)
  {
      //debugger;
        this.revenue_id = revenueDetails.id;
        this.network_id.setValue(revenueDetails.network_id);
        this.offer_id.setValue(revenueDetails.offer_id);
        this.leads.setValue(revenueDetails.leads);
        this.payout.setValue(revenueDetails.payout);
        this.revenue.setValue(revenueDetails.revenue);
        this.remarks.setValue(revenueDetails.remarks);
        //this.report_date.setValue(revenueDetails.report_date);
        this.report_date.setValue(moment(revenueDetails.report_date,'YYYY-MM-DD').toDate());
        //this.user_id = this.utilService.loginData.user_id;
        //this.Revenue.disable();

  }
  // For color coding
  dropOffBackgroundColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#1be56c';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#36e87e';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#4ceb8b';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#65ed9c';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#7cf0aa';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#9df4c0';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#b1f6cc';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#cffadf';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#dbffea';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#eafdf1';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#ffe3e3';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#ffdfdf';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#ffd2d2';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#ffc4c4';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffb1b1';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ff9797';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ff8484';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ff7778';
    }
    else
    {
      return '#ff6161';
    }
  }
  dropOffColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#ffffff';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#ffffff';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#ffffff';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#ffffff';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#ffffff';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#444444';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#333333';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#222222';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#111111';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#000000';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#111111';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#222222';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#333333';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#444444';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffffff';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ffffff';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ffffff';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ffffff';
    }
    else
    {
      return '#ffffff';
    }
  }
 // Update Filter Options
 updateFilterOptions(tableData, colName)
 {
   if (tableData !== null)
   {
     this.getFooterData(tableData);
     this.filterOptions = this.utilService.getRequiredOptions(
       tableData,
       tableData[0]
     );
     this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
   }
 }

  // Update Footer Values
  getFooterData(tableData)
  {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.addSmsRevenue = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    // dt.columns.splice(dt.columns.length - 1, 1);
    // dt.exportCSV();
    // return dt.columns.splice(dt.columns.length - 1, 1, { field: 'edit', header: 'Edit' });
    this.exporting = true;
    const body = {
      ...this.tableConfig,
      reportName:this.tableConfig.export.reportName,
      user_id: this.utilService.loginData.user_id
  };
  this.utilService.requestForExportFile(this.tableConfig.export.exportLink,body).subscribe(res =>
    {
      this.exporting = false;
      if (res["code"] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Your File Export Request has been generated successfully!!!' });
      }
    });
  }

  // For renaming network name
  renameNetworkName(dataObject: any)
  {
    this.mappingService.updateNetworkName({ network_name: this.networkName, network_id: dataObject.network_id, user_id: this.utilService.loginData.user_id }).subscribe((response: any) =>
    {
      if (response['code'] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Network name has been successfully changed!!!' });
        this.networkName = '';
        this.filterData();
        return;
      }
      if ((response['code'] === 400) || (response['code'] === 401))
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Something went wrong', detail: 'Please try again!!!' });
        return null;
      }
    })

  }
  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }
  submit()
  {
    if (this.network_id.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Network not selected!' });
      return;
    }

    if (this.offer_id.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Offer not selected!' });
      return;
    }
    if (this.report_date.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Report Date Not selected!' });
      return;
    }
     if(this.leads.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Leads required!!!' });
      return;
    }
    if (this.payout.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Payout Required!' });
      return;
    }

    if (this.revenue.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Revenue required!!!' });
      return;
    }

    if(this.remarks.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Remarks required!!!' });
      return;
    }

    //this.Revenue.enable();
    const body = {
      network_id: this.network_id.value,
      offer_id: this.offer_id.value,
      revenue: this.revenue.value,
      leads:this.leads.value,
      payout:this.payout.value,
      remarks:this.remarks.value,
      report_date: moment(this.report_date.value).format(),
      user_id: this.utilService.loginData.user_id,
      id:this.revenue_id?this.revenue_id:''
    };
    //debugger;
    this.mappingService.addupdateRevenu(body).subscribe(res =>
    {
      if (res["code"] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data updated successfully!!!' });
        this.regiform.reset();
        this.networkId = '';
       this.revenue_id='';
      }
      if(res["code"] === 201)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data Saved successfully!!!' });
        this.regiform.reset();
        this.networkId = '';
        this.filterData();
        this.revenue_id='';
      }
      if (res["code"] === 400)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Network Name already exist', detail: 'Please edit to update details!!!' });
      }
    });
  }

  reset()
  {
    this.regiform.reset();
    this.revenue.enable();

    this.offer_id.enable();
    this.revenue_id='';
    this.getInputData();
  }


  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }
}
