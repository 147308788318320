<div class="form_frm ui-g-12" style="min-height: 380px;">
  <div class="ui-g-10 ui-g-offset-1">
    <form [formGroup]="webFilterForm">
      <div class="listMapper ui-g-5">
        <p-dropdown
          formControlName="region"
          [options]="regionOptions"
          placeholder="Region Name"
          [filter]="true"
        ></p-dropdown>
      </div>

      <div class="listMapper ui-g-5">
        <p-dropdown
          formControlName="vertical"
          [options]="verticalOptions"
          placeholder="Vertical Name"
          [filter]="true"
        ></p-dropdown>
      </div>

      <div class="listMapper ui-g-2">
        <button class="submitButton" pButton type="button" (click)="submit()">
          Submit
        </button>
      </div>
      <!-- <div class="listMapper ui-g-2">
        <button class="resetButton" pButton type="button" (click)="reset()">
          Reset
        </button>
      </div> -->
    </form>
  </div>
</div>
