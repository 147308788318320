import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OfferService
{
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Offer Performance",
      isClosable: false
    });
  }

  getOfferPerformance(body: any)
  {
    return this.http.post(this.baseURL + "getData/offers", body);
  }
}
