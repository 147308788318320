<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header" >
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Content - '">
        <app-sms-campaign-table
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-sms-campaign-table>
      </p>

      <p *ngSwitchCase="'Campaign Performance'">
        <app-sms-campaign-main
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-sms-campaign-main>
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
