<ng-container *ngIf="data$ | async ; else loadingData">
  <div *ngIf="portalStatus() === 'Sms'">
    <!-- Table  -->
    <div class="heading">
      <div class="listMapper tab">
        MTD Report
      </div>
    </div>
    <div class="listMapperTable" style="
    margin-top: 0rem;
">
    <p-table
     #dt
    [(columns)]="cols"
    [value]="data?.data"
    [paginator]="false"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="false"
    [scrollHeight]="false"
    [loading]="loading"
    [frozenColumns]="frozenCols"
    (onColReorder)="saveColumnState(dt)">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" pResizableColumn pReorderableColumn [pSortableColumn]="col.field"
              [ngStyle]="{
                width: col.type === 'primary' ? '200px' : '150px'
              }" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr></tr>
          <tr>
            <th [pTooltip]="showTooltip(col.header)" *ngFor="let col of columns" [pSortableColumn]="col.field"
              pReorderableColumn pResizableColumn [ngStyle]="{
                textAlign: col.type === 'primary'
                ? 'left'
                : 'right',
                    fontWeight: col.type === 'primary' ? 'bold' : 'bold'
              }">
              {{ col.header }}
              <p-sortIcon *ngIf="col.type !== 'any'" [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr *ngIf="isFilter">
            <th class="columnFilterTH" *ngFor="let col of columns">
              <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" appendTo="th">
                <ng-container *ngIf="col.type === 'number'">
                  <div [ngStyle]="{ float: 'left', display: 'contents' }">
                    {{ col.header }}
                  </div>
                  <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                    <input [attr.id]="col.field + '_greater_than'" *ngIf="isFilter"
                      (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                      (change)="updateFilterOptions(dt.filteredValue, col.field)" pInputText type="text"
                      placeholder="Greater Than" />
                  </div>
                  <div style="margin-bottom: 0.25rem;">
                    <input [attr.id]="col.field + '_less_than'" *ngIf="isFilter"
                      (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                      (change)="updateFilterOptions(dt.filteredValue, col.field)" pInputText type="text"
                      placeholder="Less Than" />
                  </div>
                </ng-container>
                <ng-container *ngIf="col.type === 'string' || col.type === 'primary'">
                  <div [ngStyle]="{ float: 'left', display: 'contents' }">
                    {{ col.header }}
                  </div>
                  <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                    <input [attr.id]="col.field + '_starts_with'" *ngIf="isFilter"
                      (keyup)="dt.filter(col.field, col.field, 'filterString')"
                      (change)="updateFilterOptions(dt.filteredValue, col.field)" pInputText type="text"
                      placeholder="Starts With" />
                  </div>
                  <div style="margin-bottom: 0.25rem;">
                    <input [attr.id]="col.field + '_ends_with'" *ngIf="isFilter"
                      (keyup)="dt.filter(col.field, col.field, 'filterString')"
                      (change)="updateFilterOptions(dt.filteredValue, col.field)" pInputText type="text"
                      placeholder="Ends With" />
                  </div>
                </ng-container>
                <div>
                  <p-multiSelect appendTo="body" [filter]="isFilter" [options]="filterOptions[col.field]"
                    [style]="{ width: '100%' }" defaultLabel="" (onChange)="dt.filter($event.value, col.field, 'in')"
                    (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"></p-multiSelect>
                </div>
              </p-overlayPanel>
              <p-button class="openAdvanceFilterPanel" label="⟱" (click)="op.toggle($event)"></p-button>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" style="max-height: 78px;min-height:87px;">
          <tr>
            <td [pTooltip]="showTooltip(rowData[col.field])" *ngFor="let col of columns" [ngStyle]="{
                textAlign: col.type === 'primary'
                    ? 'left'
                    : col.type === 'any'
                    ? 'center'
                    : 'right',
                    fontWeight: col.type === 'primary' ? 'bold' : 'normal'

              }">
              <!-- <span *ngIf="col.type !== 'any'">
                {{ rowData[col.field] }}
              </span> -->
              <span *ngIf="col.type === 'any' && rowData['offer_group_name']">
                <button mat-button pTooltip="Rename Offer Group Name" (click)="
                    op.show($event); offerGroupName = rowData['offer_group_name']
                  ">
                  <img src="assets/icons/rename_w.svg" [style]="{ width: '16px' }" />
                </button>
                <button mat-button pTooltip="Edit">
                  <img src="assets/icons/edit_w.svg" [style]="{ width: '14px', 'margin-left': '.3rem' }" />
                </button>

              </span>
              <p-overlayPanel #op [autoZIndex]="true" [dismissable]="true" appendTo="body">
                <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                  Rename Offer Group Name
                </div>
                <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
                  <textarea [rows]="1" [cols]="30" pInputTextarea autoResize="autoResize"></textarea>
                </div>
                <div>
                  <button pButton [ngStyle]="{
                      'background-color': '#e25750',
                      'border-color': '#e25750',
                      'margin-right': '1rem'
                    }" type="button" label="Rename"></button>
                  <button pButton (click)="op.hide()" type="button" label="Close"></button>
                </div>
              </p-overlayPanel>
              <span *ngIf="
              col.type !== 'currency' &&
                col.type !== 'number' &&
                 col.type !== 'string'">{{
                rowData[col.field] || ""
              }}</span>
              <span *ngIf="col.type === 'currency'">{{
                    (rowData[col.field] | currency: "INR":"symbol":"0.2-2") ||
                      (0 | currency: "INR":"symbol":"0.2-2")
                  }}</span>
              <!-- <span *ngIf="col.type === 'percent'">{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span> -->
              <span *ngIf="col.type === 'number'">{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span>
              <span *ngIf="col.type === 'string'">{{ rowData[col.field] || 'N/A' }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
          <span *ngIf="(dt._value | json) !== '[]'">
            Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
              (dt._first + dt._rows < dt._totalRecords
                ? dt._rows + dt._first
                : dt._totalRecords
              ) | json
            }}
            entries
            {{
              isFilter || isGlobalFilter
                ? "(" + dt._totalRecords + " filtered records)"
                : ""
            }}
            from
            {{ (data.data !== undefined ? data.data.length : 0) | json }} records
          </span>
        </ng-template>
    </p-table>
    </div>
    <div *ngIf="data?.data?.length == 0" class="dataNotFound">
      <span style="font-weight: bold; box-shadow: 0 0 0px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem; color: #cdcfda;"> No Records Found</span>
    </div>
    <div *ngIf="data?.data == undefined" class="progress-spinner">
      <mat-spinner></mat-spinner>
    </div>
    <app-msp-monthly-report></app-msp-monthly-report>
    <app-mtd-vertical-performance></app-mtd-vertical-performance>
  </div>

  <div *ngFor="let item of (data$|async) | keyvalue">
    <div>
      <div class="heading">
        <div class="listMapper tab" style="max-width: 280px;">
          {{ item.key }}
        </div>
      </div>
      <div style="box-shadow: 0 0 7px #cecece;">
        <ul style="padding-top: .75rem;padding-bottom: .75rem;margin:.25rem;list-style-type: '🔔'">
          <li *ngFor="let element of item.value" style="padding-left: 1rem;">
            <!-- <span style="font-weight: bold;">{{ element.name }} </span> -->
            <span *ngIf="portalStatus() === 'Email'" style="font-weight: bold;">{{ element.name }} </span>
            <span *ngIf="portalStatus() === 'Sms'" style="font-weight: bold;">{{ element.network_name }}
            </span>{{ "," }}
            <span *ngIf="portalStatus() === 'Sms'" style="font-weight: bold;">{{ element.offer_name }} </span>
            {{ " " + element.message + " (" }}
            <span style="color: #1A3976; font-weight: bold;">{{ getDateFormat(element.created_date) }}
            </span>
            {{ "," }}
            <span style="color: #f26822; font-weight: bold;">
              {{ element.created_by }}
            </span>
            {{ ")" }}
          </li>
        </ul>
      </div>

    </div>

  </div>


</ng-container>

<div *ngIf="data?.data?.length < 1"
  style="font-weight: bold; box-shadow: 0 0 7px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem;">
  No update found in last 7 days...
</div>

<ng-template #loadingData>
  <div
    style="font-weight: bold; box-shadow: 0 0 7px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem;">
    Data Loading...
  </div>
</ng-template>
<!-- <div *ngIf="portalStatus() === 'Sms'">
  <p-chart type="bar" [data]="dataSource" width="auto" height="250px" [options]="optionsObject"></p-chart><br>
  <p-chart type="donut" [data]="dataSource" width="500px" height="250px" [options]="optionsObject"></p-chart><br>
  <p-chart type="line" [data]="dataSource" width="500px" height="250px" [options]="optionsObject"></p-chart>
</div> -->

<!-- <ng-container *ngIf="data2$ | async as data">
  <div
    *ngIf="data.length < 1"
    style="font-weight: bold; box-shadow: 0 0 7px #cecece;border-radius: 8px;text-align: center;padding: 1rem;font-size: 1.5rem;"
  >
    No update found in last 7 days...
  </div>
</ng-container> -->
