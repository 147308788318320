import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { teamModel } from '../../team/teammodels/model';
import { Subscription } from 'rxjs';
import { MappingService } from '../mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { isArray } from 'util';
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class ListComponent implements OnInit, OnDestroy
{
  regiform: FormGroup; // for Form Reference
  ongageList: any = {};    // for List Store
  teamList: any = {};   // for Team Store
  listCategory: any = {};  // for List Category Store
  options: any[] = [];   // for Creating Options for Ongage List
  teamOptions: any[];  // for Creating Options for Team List
  listCategoryOptions: any[]; // for Creating Options for List Category
  selectedTeam: any;   // for Selected List of (MultipleSelect) Input
  multiple = false;   // for Checking Which input to Show(MultipleSelect||SingleDropdown)
  isDisabled: boolean; // for Desabling Filed if User Choose Edit Option
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  data: teamModel[];  // for data in the List Details Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  selectedLists: any[] = [];
  selectedListsValue: any[] = [];
  editMultiple = false;
  scrollHeight: any;
  showModel: any = '';
  footerValues: any = {}; // Data Storing for Column Footer
  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';

    this.regiform = this.fb.group({
      team_id: ["", Validators.required],
      list_id: ["", Validators.required],
      category_id: ["", Validators.required]
    });

    // All Columns of Table
    this.allCols = [
      { field: "list_id", header: "List Id", type: false },
      { field: "name", header: "List Name", type: false },
      { field: "portal_name", header: "Portal Name", type: false },
      { field: "team_name", header: "Team Name", type: false },
      { field: "category_name", header: "Category Name", type: false },
      { field: "active", header: "Active", type: false },
      { field: "edit", header: "Edit", type: false }
    ];

    // All Columns of Table
    this.cols = localStorage.list ? JSON.parse(localStorage.list) : [
      { field: "list_id", header: "List Id", type: false },
      { field: "name", header: "List Name", type: false },
      { field: "portal_name", header: "Portal Name", type: false },
      { field: "team_name", header: "Team Name", type: false },
      { field: "category_name", header: "Category Name", type: false },
      { field: "active", header: "Active", type: false },
      { field: "edit", header: "Edit", type: false }
    ];
    this.filterData();
    // FilterUtils['filterNumber'] = (value, filter): boolean => {
    //
    //   let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
    //   let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

    //   if (value === undefined || value === null) {
    //     return false;
    //   }
    //   if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null)) {
    //     return value;
    //   }
    //   if (Number(greaterThan) && Number(lessThan)) {
    //     return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
    //   } else if (Number(greaterThan)) {
    //     return FilterUtils.gt(value, greaterThan);
    //   } else if (Number(lessThan)) {
    //     return FilterUtils.lt(value, lessThan);
    //   } else {
    //     return value;
    //   }
    // };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {

      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }
  get list_id()
  {
    return this.regiform.get("list_id");
  }
  get team_id()
  {
    return this.regiform.get("team_id");
  }
  get category_id()
  {
    return this.regiform.get("category_id");
  }

  getInputData()
  {
    this.subscription.push(this.mappingService.getOngageList().subscribe(ongagelist =>
    {
      this.ongageList = ongagelist;
      if (this.ongageList)
      {
        this.options = this.ongageList.map(e =>
        {
          return {
            label: `${e["name"]} (${e["id"]},${e["portal_name"]})`,
            value: e["id"]
          };
        });
      }
    }));
  }

  filterData()
  {
    this.subscription.push(this.mappingService.getOngageList().subscribe(ongagelist =>
    {
      this.ongageList = ongagelist;
      if (this.ongageList)
      {
        this.options = this.ongageList.map(e =>
        {
          return {
            label: `${e["name"]} (${e["id"]},${e["portal_name"]})`,
            value: e["id"]
          };
        });
      }
    }));

    this.subscription.push(this.mappingService.getTeamList().subscribe(teamlist =>
    {
      this.teamList = teamlist;
      if (this.teamList)
      {
        this.teamOptions = this.teamList.map(e =>
        {
          return {
            label: e["team_name"],
            value: e["id"]
          };
        });
      }
    }));

    this.subscription.push(this.mappingService.getListCategory().subscribe(listCategory =>
    {
      this.listCategory = listCategory;
      if (this.listCategory)
      {
        this.listCategoryOptions = this.listCategory.map(e =>
        {
          return {
            label: e["category_name"],
            value: e["category_id"]
          };
        });
      }
    }));

    if (!this.isFilter)
    {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.mappingService.getListDetails().subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData as teamModel[];
          this.loading = false;
          if (this.data.length !== 0)
          {
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data,
              this.data[0]
            );
            this.filterOptions.active.forEach(e => { e.label = e.label === '1' ? 'Yes' : 'No'; });
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }));
      }

    }
  }

  editListDetails(listDetails: any)
  {
    if (this.selectedLists.length !== 0)
    {
      this.list_id.setValue('');
      this.multiple = true;
      this.isDisabled = false;
      this.list_id.enable();
      this.options = [];
      this.selectedLists.forEach(row => this.options.push({ label: `${row.name} (${row.list_id}, ${row.portal_name})`, value: row.list_id }));
      const listValueArray = this.options.map(a => a.value);
      const teamValue = this.selectedLists[this.selectedLists.length - 1]?.team_id;
      this.list_id.setValue(listValueArray);
      this.team_id.setValue(teamValue);
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Lists & Team Selected' });
    } else
    {
      this.multiple = false;
      this.isDisabled = true;
      this.list_id.disable();
      this.options.push({ label: `${listDetails.name} (${listDetails.list_id}, ${listDetails.portal_name})`, value: listDetails.list_id });
      this.list_id.setValue(listDetails.list_id);
      this.team_id.setValue(listDetails.team_id);
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'List & Team Selected' });
    }
    return;
  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(tableData, colName);
      this.filterOptions.active.forEach(e => { e.label = e.label === '1' ? 'Yes' : 'No'; });
    }
  }

  // Select Toggle for Changing Single or Multiple Select
  selectToggle()
  {
    if (!this.isDisabled)
    {
      if (this.multiple)
      {
        this.list_id.setValue('');
      } else
      {
        this.list_id.setValue('');
      }
    }
  }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.list = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(dt.columns.length - 1, 1);
    dt.exportCSV();
    return dt.columns.splice(dt.columns.length - 1, 1, { field: 'edit', header: 'Edit' });
  }

  submit()
  {
    if (this.regiform.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Team Name or List Category not selected!' });
      return;
    }
    this.list_id.enable();
    const body = {
      team_id: this.team_id.value,
      created_by: this.utilService.loginData.user_id,
      category_id: this.category_id.value,
      list_id: Array.isArray(this.list_id.value) ? this.list_id.value : [`${this.list_id.value}`]
    };
    this.mappingService.sendFormToServer(body).subscribe(res =>
    {
      if (res["code"] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Updated' });
        this.regiform.reset();
        this.filterData();
      }
    });
    this.regiform.reset();
    this.isDisabled = false;
  }

  reset()
  {
    this.regiform.reset();
    this.isDisabled = false;
    this.list_id.enable();
    this.getInputData();
  }

  getActiveStatus(binary: any)
  {
    if (binary === '1')
    {
      return 'Yes';
    } else
    {
      return 'No';
    }
  }

  updateActiveStatus(data: any)
  {
    const body = {
      list_id: data.list_id,
      name: data.name,
      created_by: this.utilService.loginData.user_id,
      active: data.active === '1' ? '0' : '1'
    };
    this.subscription.push(this.mappingService.updateListActiveStatus(body).subscribe(responseData =>
    {
      if (responseData['code'] === 200)
      {

        if (data.active === '1')
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'List status updated to inactive' });
        } else
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'List status updated to active' });
        }
        this.filterData();
        return;
      } else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
        return;
      }
    }));
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }
    // Update Footer Values
    getFooterData(tableData)
    {
      this.footerValues = this.utilService.getRequiredFooterData(tableData);
    }
  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }

}
