export class NetworkUpdatesLogs {
    allCols: any[] = [];
    colFilterOptions: any[] = [];
    cols: any[] = [];
    constructor() {
        // for Daily Creative Sheet Main Columns
        this.allCols = [
            { field: "network_name", header: "Network Name", type: false },
            { field: "updates", header: "Description", type: false },
            { field: "created_date", header: "Create Date", type: false },
            { field: "created_by", header: "Created By", type: false },
        ];
        // for Daily Creative Sheet Main Columns Filter Options
        this.colFilterOptions = [
            { field: "updates", header: "Description", type: false },
            { field: "created_date", header: "Create Date", type: false },
            { field: "created_by", header: "Created By", type: false },
        ];
        // for Daily Creative Sheet Main Columns First Show
        this.cols = [
            { field: "network_name", header: "Network Name", type: false },
            { field: "updates", header: "Description", type: false },
            { field: "created_date", header: "Create Date", type: false },
            { field: "created_by", header: "Created By", type: false },
        ];
    }
}
