<div class="heading">
  <div class="dailyRevenueReport tab">
    Daily Revenue Report
  </div>
</div>
<p-table
  class="dailyRevenueReport"
  #dt1
  [columns]="cols1"
  [value]="data.current_revenue"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols1"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
  (onColReorder)="saveColumnState(dt1)"
  [frozenColumns]="frozenCols1"
  frozenWidth="130px"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter1 = !isGlobalFilter1"
            (focusout)="isGlobalFilter1 = !isGlobalFilter1"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>
        <!-- <app-dates
          class="ui-g-5"
          [getDates]="{ start_date: startDate, end_date: endDate }"
          (pullDates)="getDates($event)"
        ></app-dates> -->

        <div class="ui-g-5">
          <span class="">
            <span class="dailyRevenueReport customDateSelector1">Date </span>
            <!-- <p-calendar
              class="dailyRevenueReport customDateSelector2"
              dateFormat="dd/mm/yy"
              (onSelect)="filterData($event)"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [(ngModel)]="selectDates"
              placeholder="End Date"
            ></p-calendar> -->
            <app-dates
              class="ui-g-5"
              [getDates]="{ start_date: startDate, end_date: endDate }"
              (pullDates)="getDates($event)"
            ></app-dates>
            <!-- <mat-form-field class="dailyRevenueReport customDateSelector2">
              <input
                matInput
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="end__date"
                (dateChange)="filterData('date', $event)"
                [(ngModel)]="selectDates"
              />
              <mat-datepicker-toggle matSuffix [for]="end__date">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #end__date></mat-datepicker>
            </mat-form-field> -->
            <span
              *ngIf="checkTeamId === 2020"
              class="dailyRevenueReport customDateSelector3"
              >Team
            </span>
            <p-dropdown
              *ngIf="checkTeamId === 2020"
              class="dailyRevenueReport customDateSelector4"
              [options]="teamOptions"
              (onChange)="filterData('team', $event.value)"
              [filter]="true"
            ></p-dropdown>
          </span>
        </div>

        <div class="ui-g-5 filterExportToggle">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="dailyRevenueReport columnFilter"
              [options]="colFilterOptions1"
              [(ngModel)]="cols1"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
              (onClick)="saveColumnState(dt1)"
            >
            </p-multiSelect>
          </div>

          <div class="dailyRevenueReport ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter1 = !isFilter1; dt1.reset(); filterData(dt1)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export All"
              (click)="exportData(dt1, dt2, dt3, dt4)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
        [ngStyle]="{
          width: col.field === 'mailing_name' ? '200px' : '110px'
        }"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign:
            col.field === 'report_date' ||
            col.field === 'mailing_id' ||
            col.field === 'mailing_name' ||
            col.field === 'offer' ||
            col.field === 'advertiser' ||
            col.field === 'list_name' ||
            col.field === 'segment_name' ||
            col.field === 'from_name' ||
            col.field === 'email_message_subject'
              ? 'left'
              : 'right'
        }"
      >
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter1">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData1(dt1.filteredValue);
                  updateFilterOptions1(dt1.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                (keyup)="dt1.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData1(dt1.filteredValue);
                  updateFilterOptions1(dt1.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                (keyup)="dt1.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData1(dt1.filteredValue);
                  updateFilterOptions1(dt1.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                (keyup)="dt1.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData1(dt1.filteredValue);
                  updateFilterOptions1(dt1.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter1"
              [options]="filterOptions1[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt1.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions1(dt1.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'mailing_id' ||
            col.field === 'mailing_name' ||
            col.field === 'offer' ||
            col.field === 'advertiser' ||
            col.field === 'list_name' ||
            col.field === 'segment_name' ||
            col.field === 'from_name' ||
            col.field === 'email_message_subject'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            rowData[col.field] !== 'Undefined' && col.header === 'Mailing ID'
          "
        >
          <button
            mat-button
            pTooltip="Deliveribility Report"
            (click)="openDomainReport(rowData)"
          >
            <img src="assets/icons/domain_w.svg" [style]="{ width: '.8rem' }" />
          </button>
        </span>

        <span
          *ngIf="
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'delivery_timestamp' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue' &&
            col.field !== 'hard_bounces_percent'
          "
        >
          {{ rowData[col.field] }}
        </span>

        <span *ngIf="col.field === 'delivery_timestamp'">
          {{ convertedDate(rowData[col.field]) }}
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (rowData["opens"] / rowData["sent"] !== 1 / 0
              ? rowData["opens"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'click_rate'">
          {{
            (rowData["clicks"] / rowData["opens"] !== 1 / 0
              ? rowData["clicks"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (rowData["network_click"] / rowData["opens"] !== 1 / 0
              ? rowData["network_click"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (rowData["unsubscribe"] / rowData["sent"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (rowData["unsubscribe"] / rowData["opens"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (rowData["conversion_count"] / rowData["network_click"] !== 1 / 0
              ? rowData["conversion_count"] / rowData["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (rowData["revenue"] / rowData["network_click"] !== 1 / 0
              ? rowData["revenue"] / rowData["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((rowData["revenue"] / rowData["sent"]) * 1000 !== 1 / 0
              ? (rowData["revenue"] / rowData["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
              ? rowData["revenue"] / rowData["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.field === 'hard_bounces_percent'">
          {{
            (rowData["hard_bounces"] / rowData["sent"] !== 1 / 0
              ? rowData["hard_bounces"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'mailing_id' ||
            col.field === 'mailing_name' ||
            col.field === 'mailing_id' ||
            col.field === 'delivery_timestamp' ||
            col.field === 'advertiser' ||
            col.field === 'list_name' ||
            col.field === 'segment_name' ||
            col.field === 'from_name' ||
            col.field === 'email_message_subject'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'report_date' &&
            col.field !== 'mailing_id' &&
            col.field !== 'mailing_name' &&
            col.field !== 'delivery_timestamp' &&
            col.field !== 'advertiser' &&
            col.field !== 'esp_connection_title' &&
            col.field !== 'list_name' &&
            col.field !== 'segment_id' &&
            col.field !== 'segment_name' &&
            col.field !== 'from_name' &&
            col.field !== 'email_message_subject' &&
            col.field !== 'offer' &&
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue' &&
            col.field !== 'hard_bounces_percent'
          "
        >
          {{ footerValues1[col.field] || 0 }}
        </span>

        <span class="footerGrandTotal" *ngIf="col.field === 'report_date'">
          Grand Total
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (footerValues1["opens"] / footerValues1["sent"] !== 1 / 0
              ? footerValues1["opens"] / footerValues1["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (footerValues1["clicks"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["clicks"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (footerValues1["network_click"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["network_click"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (footerValues1["unsubscribe"] / footerValues1["sent"] !== 1 / 0
              ? footerValues1["unsubscribe"] / footerValues1["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (footerValues1["unsubscribe"] / footerValues1["opens"] !== 1 / 0
              ? footerValues1["unsubscribe"] / footerValues1["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (footerValues1["conversion_count"] /
              footerValues1["network_click"] !==
            1 / 0
              ? footerValues1["conversion_count"] /
                footerValues1["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epc'">
          {{
            (footerValues1["revenue"] / footerValues1["network_click"] !== 1 / 0
              ? footerValues1["revenue"] / footerValues1["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((footerValues1["revenue"] / footerValues1["sent"]) * 1000 !== 1 / 0
              ? (footerValues1["revenue"] / footerValues1["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (footerValues1["revenue"] / footerValues1["conversion_count"] !==
            1 / 0
              ? footerValues1["revenue"] / footerValues1["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ footerValues1["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.field === 'hard_bounces_percent'">
          {{
            (footerValues1["hard_bounces"] / footerValues1["sent"] !== 1 / 0
              ? footerValues1["hard_bounces"] / footerValues1["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt1._totalRecords !== 0 ? dt1._first : 0) | json }}&#8210;{{
        (dt1._first + dt1._rows < dt1._totalRecords
          ? dt1._rows + dt1._first
          : dt1._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter1 || isGlobalFilter1
          ? "(" + dt1._totalRecords + " filtered records)"
          : ""
      }}
      from
      {{
        (data["current_revenue"] !== undefined
          ? data["current_revenue"].length
          : 0
        ) | json
      }}
      records
    </span>
  </ng-template>
</p-table>
<div class="heading">
  <div class="dailyRevenueReport tab">
    Daily phonexa Revenue Report
  </div>
</div>
<p-table
  class="dailyRevenueReport"
  #dt4
  [columns]="cols4"
  [value]="data.phonexa_revenue"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols4"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
  (onColReorder)="saveColumnState(dt4)"
  [frozenColumns]="frozenCols4"
  frozenWidth="130px"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt4.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter4 = !isGlobalFilter4"
            (focusout)="isGlobalFilter4 = !isGlobalFilter4"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>

        <div class="ui-g-5 filterExportToggle">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="dailyRevenueReport columnFilter"
              [options]="colFilterOptions4"
              [(ngModel)]="cols4"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
              (onClick)="saveColumnState(dt4)"
            >
            </p-multiSelect>
          </div>

          <div class="dailyRevenueReport ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter4 = !isFilter4; dt4.reset(); filterData(dt4)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export All"
              (click)="exportData(dt1, dt2, dt3, dt4)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
        [ngStyle]="{
          width: col.field === 'mailing_name' ? '200px' : '110px'
        }"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign:
          col.field === 'report_date' ||
          col.field === 'mailing_id' ||
          col.field === 'team' ||
          col.field === 'segment_id' ||
          col.field === 'list_id'
              ? 'left'
              : 'right'
        }"
      >
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter4">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                (keyup)="dt4.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData4(dt4.filteredValue);
                  updateFilterOptions4(dt4.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                (keyup)="dt4.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData4(dt4.filteredValue);
                  updateFilterOptions4(dt4.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                (keyup)="dt4.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData4(dt4.filteredValue);
                  updateFilterOptions4(dt4.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                (keyup)="dt4.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData4(dt4.filteredValue);
                  updateFilterOptions4(dt4.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter4"
              [options]="filterOptions4[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt4.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions4(dt4.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
          col.field === 'report_date' ||
          col.field === 'mailing_id' ||
          col.field === 'team' ||
          col.field === 'list_id' ||
          col.field === 'segment_id'
              ? 'left'
              : 'right'
        }"
      >
        <!-- <span
        >
          <button
            mat-button
            pTooltip="Deliveribility Report"
            (click)="openDomainReport(rowData)"
          >
            <img src="assets/icons/domain_w.svg" [style]="{ width: '.8rem' }" />
          </button>
        </span> -->

        <span
          *ngIf="
          col.field === 'team' ||
          col.field === 'report_date' ||
          col.field === 'mailing_id' ||
          col.field === 'segment_id' ||
          col.field === 'list_id' ||
          col.field === 'conversions'
          "
        >
          {{ rowData[col.field] }}
        </span>

        <span *ngIf="col.field === 'delivery_timestamp'">
          {{ convertedDate(rowData[col.field]) }}
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (rowData["opens"] / rowData["sent"] !== 1 / 0
              ? rowData["opens"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'click_rate'">
          {{
            (rowData["clicks"] / rowData["opens"] !== 1 / 0
              ? rowData["clicks"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (rowData["network_click"] / rowData["opens"] !== 1 / 0
              ? rowData["network_click"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (rowData["unsubscribe"] / rowData["sent"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (rowData["unsubscribe"] / rowData["opens"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (rowData["conversion_count"] / rowData["network_click"] !== 1 / 0
              ? rowData["conversion_count"] / rowData["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (rowData["revenue"] / rowData["network_click"] !== 1 / 0
              ? rowData["revenue"] / rowData["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
              ? rowData["revenue"] / rowData["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.field === 'hard_bounces_percent'">
          {{
            (rowData["hard_bounces"] / rowData["sent"] !== 1 / 0
              ? rowData["hard_bounces"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
          col.field === 'report_date' ||
          col.field === 'mailing_id' ||
          col.field === 'team' ||
          col.field === 'list_id' ||
          col.field === 'segment_id'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
          col.field === 'conversions'"
        >
          {{ footerValues4[col.field] || 0 }}
        </span>
        <span class="footerGrandTotal" *ngIf="col.field === 'team'">
          Grand Total
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ footerValues4["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt4._totalRecords !== 0 ? dt4._first : 0) | json }}&#8210;{{
        (dt4._first + dt4._rows < dt4._totalRecords
          ? dt4._rows + dt4._first
          : dt4._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter4 || isGlobalFilter4
          ? "(" + dt4._totalRecords + " filtered records)"
          : ""
      }}
      from
      {{
        (data["phonexa_revenue"] !== undefined
          ? data["phonexa_revenue"].length
          : 0
        ) | json
      }}
      records
    </span>
  </ng-template>
</p-table>

<div class="heading">
  <div class="dailyRevenueReport tab">
    Old Conversions & Revenue Report
  </div>
</div>
<p-table
  class="dailyRevenueReport"
  #dt2
  [(columns)]="cols2"
  [value]="data.old_revenue"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols2"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt2.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter2 = !isGlobalFilter2"
            (focusout)="isGlobalFilter2 = !isGlobalFilter2"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>
        <!-- <app-dates
          class="ui-g-5"
          [getDates]="{ start_date: startDate, end_date: endDate }"
          (pullDates)="getDates($event)"
        ></app-dates> -->

        <div class="ui-g-5 filterExportToggle ui-g-offset-5">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="dailyRevenueReport columnFilter"
              [options]="colFilterOptions2"
              [(ngModel)]="cols2"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
            >
            </p-multiSelect>
          </div>

          <div class="dailyRevenueReport ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter2 = !isFilter2; dt2.reset(); filterData(dt2)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export All"
              (click)="exportData(dt1, dt2, dt3, dt4)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
        [ngStyle]="{ width: col.field === 'team_name' ? '130px' : '110px' }"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign: col.field === 'name' ? 'left' : 'right'
        }"
      >
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter2">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                (keyup)="dt2.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                (keyup)="dt2.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                (keyup)="dt2.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                (keyup)="dt2.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData2(dt2.filteredValue);
                  updateFilterOptions2(dt2.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter2"
              [options]="filterOptions2[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt2.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions2(dt2.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign: col.field === 'name' ? 'left' : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue'
          "
        >
          {{ rowData[col.field] }}
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (rowData["opens"] / rowData["sent"] !== 1 / 0
              ? rowData["opens"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (rowData["clicks"] / rowData["opens"] !== 1 / 0
              ? rowData["clicks"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (rowData["network_click"] / rowData["opens"] !== 1 / 0
              ? rowData["network_click"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (rowData["unsubscribe"] / rowData["sent"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (rowData["unsubscribe"] / rowData["opens"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (rowData["conversion_count"] / rowData["network_click"] !== 1 / 0
              ? rowData["conversion_count"] / rowData["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (rowData["revenue"] / rowData["network_click"] !== 1 / 0
              ? rowData["revenue"] / rowData["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((rowData["revenue"] / rowData["sent"]) * 1000 !== 1 / 0
              ? (rowData["revenue"] / rowData["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
              ? rowData["revenue"] / rowData["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'name' || col.field === 'segment_id'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'segment_id' &&
            col.field !== 'name' &&
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue'
          "
        >
          {{ footerValues2[col.field] || 0 }}
        </span>

        <span class="footerGrandTotal" *ngIf="col.field === 'name'">
          Grand Total
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (footerValues2["opens"] / footerValues2["sent"] !== 1 / 0
              ? footerValues2["opens"] / footerValues2["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (footerValues2["clicks"] / footerValues2["opens"] !== 1 / 0
              ? footerValues2["clicks"] / footerValues2["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (footerValues2["network_click"] / footerValues2["opens"] !== 1 / 0
              ? footerValues2["network_click"] / footerValues2["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (footerValues2["unsubscribe"] / footerValues2["sent"] !== 1 / 0
              ? footerValues2["unsubscribe"] / footerValues2["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (footerValues2["unsubscribe"] / footerValues2["opens"] !== 1 / 0
              ? footerValues2["unsubscribe"] / footerValues2["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (footerValues2["conversion_count"] /
              footerValues2["network_click"] !==
            1 / 0
              ? footerValues2["conversion_count"] /
                footerValues2["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (footerValues2["revenue"] / footerValues2["network_click"] !== 1 / 0
              ? footerValues2["revenue"] / footerValues2["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((footerValues2["revenue"] / footerValues2["sent"]) * 1000 !== 1 / 0
              ? (footerValues2["revenue"] / footerValues2["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (footerValues2["revenue"] / footerValues2["conversion_count"] !==
            1 / 0
              ? footerValues2["revenue"] / footerValues2["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ footerValues2["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt2._totalRecords !== 0 ? dt2._first : 0) | json }}&#8210;{{
        (dt2._first + dt2._rows < dt2._totalRecords
          ? dt2._rows + dt2._first
          : dt2._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter2 || isGlobalFilter2
          ? "(" + dt2._totalRecords + " filtered records)"
          : ""
      }}
      from
      {{
        (data["old_revenue"] !== undefined ? data["old_revenue"].length : 0)
          | json
      }}
      records
    </span>
  </ng-template>
</p-table>

<div class="heading">
  <div class="dailyRevenueReport tab">
    Other Conversions & Revenue Report
  </div>
</div>
<p-table
  class="dailyRevenueReport"
  #dt3
  [(columns)]="cols3"
  [value]="data.other_revenue"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols3"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt3.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter3 = !isGlobalFilter3"
            (focusout)="isGlobalFilter3 = !isGlobalFilter3"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>
        <!-- <app-dates
          class="ui-g-5"
          [getDates]="{ start_date: startDate, end_date: endDate }"
          (pullDates)="getDates($event)"
        ></app-dates> -->

        <div class="ui-g-5 filterExportToggle ui-g-offset-5">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="dailyRevenueReport columnFilter"
              [options]="colFilterOptions3"
              [(ngModel)]="cols3"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
            >
            </p-multiSelect>
          </div>

          <div class="dailyRevenueReport ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter3 = !isFilter3; dt3.reset(); filterData(dt3)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export All"
              (click)="exportData(dt1, dt2, dt3)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign:
            col.field === 'affiliate' ||
            col.field === 'advertiser' ||
            col.field === 'offer'
              ? 'left'
              : 'right'
        }"
      >
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter3">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                (keyup)="dt3.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData3(dt3.filteredValue);
                  updateFilterOptions3(dt3.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                (keyup)="dt3.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData3(dt3.filteredValue);
                  updateFilterOptions3(dt3.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                (keyup)="dt3.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData3(dt3.filteredValue);
                  updateFilterOptions3(dt3.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                (keyup)="dt3.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData3(dt3.filteredValue);
                  updateFilterOptions3(dt3.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter3"
              [options]="filterOptions3[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt3.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions3(dt3.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'affiliate' ||
            col.field === 'advertiser' ||
            col.field === 'offer'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue'
          "
        >
          {{ rowData[col.field] }}
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (rowData["opens"] / rowData["sent"] !== 1 / 0
              ? rowData["opens"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (rowData["clicks"] / rowData["opens"] !== 1 / 0
              ? rowData["clicks"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (rowData["network_click"] / rowData["opens"] !== 1 / 0
              ? rowData["network_click"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (rowData["unsubscribe"] / rowData["sent"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (rowData["unsubscribe"] / rowData["opens"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (rowData["conversion_count"] / rowData["network_click"] !== 1 / 0
              ? rowData["conversion_count"] / rowData["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (rowData["revenue"] / rowData["network_click"] !== 1 / 0
              ? rowData["revenue"] / rowData["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((rowData["revenue"] / rowData["sent"]) * 1000 !== 1 / 0
              ? (rowData["revenue"] / rowData["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
              ? rowData["revenue"] / rowData["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'affiliate' ||
            col.field === 'advertiser' ||
            col.field === 'offer'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'affiliate' &&
            col.field !== 'advertiser' &&
            col.field !== 'offer' &&
            col.field !== 'open_rate' &&
            col.field !== 'click_rate' &&
            col.field !== 'click_persent' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue'
          "
        >
          {{ footerValues3[col.field] || 0 }}
        </span>

        <span class="footerGrandTotal" *ngIf="col.field === 'affiliate'">
          Grand Total
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (footerValues3["opens"] / footerValues3["sent"] !== 1 / 0
              ? footerValues3["opens"] / footerValues3["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (footerValues3["clicks"] / footerValues3["opens"] !== 1 / 0
              ? footerValues3["clicks"] / footerValues3["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_persent'">
          {{
            (footerValues3["network_click"] / footerValues3["opens"] !== 1 / 0
              ? footerValues3["network_click"] / footerValues3["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (footerValues3["unsubscribe"] / footerValues3["sent"] !== 1 / 0
              ? footerValues3["unsubscribe"] / footerValues3["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (footerValues3["unsubscribe"] / footerValues3["opens"] !== 1 / 0
              ? footerValues3["unsubscribe"] / footerValues3["opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (footerValues3["conversion_count"] /
              footerValues3["network_click"] !==
            1 / 0
              ? footerValues3["conversion_count"] /
                footerValues3["network_click"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (footerValues3["revenue"] / footerValues3["network_click"] !== 1 / 0
              ? footerValues3["revenue"] / footerValues3["network_click"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((footerValues3["revenue"] / footerValues3["sent"]) * 1000 !== 1 / 0
              ? (footerValues3["revenue"] / footerValues3["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (footerValues3["revenue"] / footerValues3["conversion_count"] !==
            1 / 0
              ? footerValues3["revenue"] / footerValues3["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ footerValues3["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt3._totalRecords !== 0 ? dt3._first : 0) | json }}&#8210;{{
        (dt3._first + dt3._rows < dt3._totalRecords
          ? dt3._rows + dt3._first
          : dt3._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter3 || isGlobalFilter3
          ? "(" + dt3._totalRecords + " filtered records)"
          : ""
      }}
      from
      {{
        (data["other_revenue"] !== undefined
          ? data["other_revenue"].length
          : 0
        ) | json
      }}
      records
    </span>
  </ng-template>
</p-table>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
