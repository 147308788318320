import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublisherModel } from '../publishermodels/publisher.model';
import { PublisherCreative } from '../publishermodels/publisher-creative.model';

@Injectable({
  providedIn: 'root'
})
export class PublisherService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  publisherModel = new PublisherModel();
  publisherCreative = new PublisherCreative();
  constructor(private http: HttpClient) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Publisher Report",
      isClosable: false
    });
  }

  uploadCsvData(body: any) {
    return this.http.post(this.baseURL + "publisherReport/import_csv_data", body);
  }

  getPublisherData(body: any) {
    return this.http.post(this.baseURL + "publisherReport/publisher_report", body);
  }

  getPublisherCreativeData(body: any) {
    return this.http.post(this.baseURL + "publisherReport/publisher_creative_report", body);
  }
  getPartnerData(body: any) {
    return this.http.post(this.baseURL + "PublisherReport/partnerReport", body);
  }
  offerWisePortalReport(body: any) {
    return this.http.post(this.baseURL + "PublisherReport/offerReport", body);
  }
  getAdvertiserData(body: any){
    return this.http.post(this.baseURL + "PublisherReport/advertiserReport", body);
  }
  getConversionData(body: any){
    return this.http.post(this.baseURL + "PublisherReport/conversionReport", body);
  }
  getBdOfferData(body: any){
    return this.http.post(this.baseURL + "PublisherReport/getOffers", body);
  }


}
