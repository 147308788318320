<div class="heading">
  <div class="dailyRevenueReport tab">
    List Ordering
  </div>
  <button
    pButton
    class="dailyRevenueReport Submit"
    (click)="updateOrderedList()"
  >
    Submit Ordered List
  </button>
</div>
<div
  [ngStyle]="{
    'padding-top': '2rem',
    'padding-bottom': '3rem',
    'box-shadow': '0 2px 21px #b5b5b596'
  }"
>
  <p-pickList
    [source]="unorderedList"
    [target]="orderedList"
    sourceHeader="Current Ordered Lists"
    targetHeader="Ordered Lists"
    [responsive]="true"
    filterBy="list_name,list_id,portal_name"
    dragdrop="true"
    sourceFilterPlaceholder="Search by Team, List or Platform"
    targetFilterPlaceholder="Search by Team, List or Platform"
    [sourceStyle]="{ height: '600px' }"
    [targetStyle]="{ height: '600px' }"
  >
    <ng-template let-listData pTemplate="item">
      <div class="ui-helper-clearfix">
        <!-- <p-checkbox
          name="listOrdering"
          label="{{ listData.list_name }} (
          {{ listData.list_id }}
          , 
          {{ listData.portal_name }}
          )"
          [value]="listData.list_id"
          [(ngModel)]="unorderedList"
        >
          {{
            listData.list_name +
              " (" +
              listData.list_id +
              ", " +
              listData.portal_name +
              ")"
          }}
        </p-checkbox> -->
        <div>
          {{ listData.list_name }} ({{ listData.list_id }},&ngsp;{{
            listData.portal_name
          }})
        </div>
      </div>
    </ng-template>
  </p-pickList>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
