<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <span [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Network Compliance'">
        <app-network-updates
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-network-updates>
      </p>

      <p *ngSwitchCase="'Logs - '">
        <app-network-updates-logs
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-network-updates-logs>
      </p>
    </span>
  </mat-tab>
</mat-tab-group>
