import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UtilService } from '../utils/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: any;
  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.currentYear = moment().year();
  }

  isExpanded() {
    return this.utilService.isExpanded;
  }
}
