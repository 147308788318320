<div class="p-grid header_head">
  <div class="p-col-1">
    <div class="header_logo">
      <img src="assets/img/logow.svg" alt="" />
    </div>
  </div>
  <!-- Switch -->
  <div class="p-col-1 p-offset-9" *ngIf="isAdmin">
      <button class="switch"
      (click)="switch()"
          [pTooltip]="portalStatus==='Email'?'Switch to SMS ':'Switch to Email'"
        >
          <span>{{portalStatus==='Email'?'India-SMS':'US-Email'}}</span>
        </button>
  </div>
  <!-- Switch -->
  <div class="p-col-1">
    <div class="header_profile">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        [ngStyle]="{
          'text-transform': 'capitalize'
        }"
      >
        {{ userName }}
        <mat-icon style="color: #f26822;" class="mat-18"
          >power_settings_new</mat-icon
        >
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item>Profile</button>
        <button mat-menu-item>Setting</button> -->
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </div>
</div>
