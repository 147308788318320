import { teamModel } from 'src/app/homepage/reports/team/teammodels/model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MappingService } from '../mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';


@Component({
  selector: 'app-network-mapping',
  templateUrl: './network-mapping.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class NetworkMappingComponent implements OnInit
{
  regiform: FormGroup; // for Form Reference
  options: any[] = [];   // for Creating Options for Ongage List
  networkId: any;  // for storing network id value
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {};  // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = '';
  networkName: any = '';
  portalScope = JSON.parse(localStorage.getItem('currentUser')).authorization;
  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';

    this.regiform = this.fb.group({
      network_name: ["", Validators.required],
      advertiser_id: ["", Validators.required],
    });


    this.getInputData();
    this.filterData();
    // FilterUtils['filterNumber'] = (value, filter): boolean => {
    //
    //   let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
    //   let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

    //   if (value === undefined || value === null) {
    //     return false;
    //   }
    //   if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null)) {
    //     return value;
    //   }
    //   if (Number(greaterThan) && Number(lessThan)) {
    //     return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
    //   } else if (Number(greaterThan)) {
    //     return FilterUtils.gt(value, greaterThan);
    //   } else if (Number(lessThan)) {
    //     return FilterUtils.lt(value, lessThan);
    //   } else {
    //     return value;
    //   }
    // };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }

  get advertiser_id()
  {
    return this.regiform.get("advertiser_id");
  }
  get network_name()
  {
    return this.regiform.get("network_name");
  }

  getInputData()
  {
    this.subscription.push(this.mappingService.getNetworkOptions().subscribe((ongagelist: any[]) =>
    {
      if (ongagelist)
      {
        this.options = ongagelist.map(e =>
        {
          return {
            label: `${e["advertisers_name"]} (${e["portal_name"]})`,
            value: { advertiser_id: e["advertisers_id"], portal_id: e["portal_id"] }
          };
        });
      }
    }));
  }

  filterData()
  {

    if (!this.isFilter)
    {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.mappingService.getNetworkMappingDetails().subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData as { headers: any[], data: any[] };
          // All Columns of Table
          this.allCols = this.data.headers;

          // Frized Columns
          // this.frozenCols = this.data.headers.filter(col => col.type === 'primary');

          // All Columns for Filter Options
          this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

          // First Time Showed Columns
          this.cols = localStorage.networkMapping ? JSON.stringify(localStorage.networkMapping) : this.data.headers;
          this.loading = false;
          if (this.data.data.length !== 0)
          {
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data.data,
              this.data.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }));
      }

    }
  }


  // For setting fields value
  editNetworkDetails(networkDetails: any)
  {
    this.mappingService.getAdvertisersListInNetwork({ network_id: networkDetails.network_id }).subscribe((response: any[]) =>
    {
      if (response)
      {
        this.advertiser_id.setValue(response);
        this.network_name.setValue(networkDetails.network_name);
        this.networkId = networkDetails.network_id;
        this.network_name.disable();
        return this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Advertisers and Network Name Selected' });
      }
      return this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Something went wrong!!!' });
    })

  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(tableData, colName);
    }
  }


  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.networkMapping = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(dt.columns.length - 1, 1);
    dt.exportCSV();
    return dt.columns.splice(dt.columns.length - 1, 1, { field: 'edit', header: 'Edit' });
  }

  // For renaming network name
  renameNetworkName(dataObject: any)
  {
    this.mappingService.updateNetworkName({ network_name: this.networkName, network_id: dataObject.network_id, user_id: this.utilService.loginData.user_id }).subscribe((response: any) =>
    {
      if (response['code'] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Network name has been successfully changed!!!' });
        this.networkName = '';
        this.filterData();
        return;
      }
      if ((response['code'] === 400) || (response['code'] === 401))
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Something went wrong', detail: 'Please try again!!!' });
        return null;
      }
    })

  }


  submit()
  {
    if (this.advertiser_id.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Advertisers not selected!' });
      return;
    }
    if (this.network_name.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Network Name required!!!' });
      return;
    }

    this.network_name.enable();
    const body = {
      network_id: this.networkId,
      network_name: this.network_name.value,
      created_by: this.utilService.loginData.user_id,
      advertiser_id: this.advertiser_id.value
    };
    this.mappingService.updateNetworkMapping(body).subscribe(res =>
    {
      if (res["code"] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data updated successfully!!!' });
        this.regiform.reset();
        this.networkId = '';
        this.filterData();
      }
      if (res["code"] === 400)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Network Name already exist', detail: 'Please edit to update details!!!' });
      }
    });
  }

  reset()
  {
    this.regiform.reset();
    this.network_name.enable();
    this.getInputData();
  }


  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }
}
