<div class="ui-g-3">
  <p-dropdown
    class="optionalDateSelector"
    [options]="dates"
    (onChange)="dateChangeHandler()"
    [(ngModel)]="selectedDates"
  ></p-dropdown>
</div>
<div class="ui-g-9 customDateBox">
  <div class="ui-g-4">
    <span class="">
      <!-- <p-calendar
        class="customDateSelector1"
        dateFormat="dd/mm/yy"
        (onSelect)="setDateOptionToCustom()"
        [minDate]="minDate"
        [maxDate]="selectedDates.end_date || maxDate"
        [(ngModel)]="selectedDates.start_date"
        placeholder="Start Date"
      ></p-calendar> -->
      <mat-form-field class="customDateSelector1">
        <input
          matInput
          [min]="minDate"
          [max]="selectedDates.end_date || maxDate"
          [matDatepicker]="start__date"
          (dateChange)="dateChange('startDate', $event)"
          [(ngModel)]="selectedDates.start_date"
        />
        <mat-datepicker-toggle matSuffix [for]="start__date">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #start__date></mat-datepicker>
      </mat-form-field>
    </span>
  </div>
  <div class="ui-g-4">
    <span class="">
      <!-- <p-calendar
        class="customDateSelector2"
        dateFormat="dd/mm/yy"
        (onSelect)="setDateOptionToCustom()"
        [minDate]="selectedDates.start_date"
        [maxDate]="maxDate"
        [(ngModel)]="selectedDates.end_date"
        placeholder="End Date"
      ></p-calendar> -->
      <mat-form-field class="customDateSelector2">
        <input
          matInput
          [min]="selectedDates.start_date || minDate"
          [max]="maxDate"
          [matDatepicker]="end__date"
          (dateChange)="dateChange('endDate', $event)"
          [(ngModel)]="selectedDates.end_date"
        />
        <mat-datepicker-toggle matSuffix [for]="end__date">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #end__date></mat-datepicker>
      </mat-form-field>
    </span>
  </div>
  <div class="ui-g-4">
    <button
      class="dateSelectorButton"
      field="button"
      pButton
      icon="pi pi-eye"
      label="View"
      [disabled]="!selectedDates.start_date || !selectedDates.end_date"
      (click)="dateChangeHandler()"
    ></button>
  </div>
</div>
<p-toast
  [style]="{ marginTop: '80px' }"
  position="top-right"
  key="tc"
></p-toast>
