import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mspcomponent',
  templateUrl: './mspcomponent.component.html',
  styleUrls: ['./mspcomponent.component.css']
})
export class MspcomponentComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }


}
