import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-offer-group',
  templateUrl: './sms-offer-group.component.html',
  styleUrls: ['./sms-offer-group.component.css']
})
export class SmsOfferGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
