<div class="heading">
  <div class="listMapper tab">
    List Mapping
  </div>
</div>
<div class="form_frm ui-g-12">
  <form [formGroup]="regiform">
    <div *ngIf="multiple" class="listMapper ui-g-4" style="margin-left: 4%;">
      <p-multiSelect
        defaultLabel="Choose Multiple Lists"
        formControlName="list_id"
        [options]="options"
        [virtualScroll]="true"
        [filter]="true"
      >
      </p-multiSelect>
    </div>

    <div *ngIf="!multiple" class="listMapper ui-g-4" style="margin-left: 4%;">
      <p-dropdown
        formControlName="list_id"
        [options]="options"
        placeholder="Select List Name"
        [filter]="true"
      ></p-dropdown>
    </div>

    <div class="listMapper multiCheck ui-g-1">
      <p-checkbox
        [disabled]="isDisabled"
        label="Multiple"
        (click)="selectToggle()"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="multiple"
        binary="false"
      ></p-checkbox>
      <div *ngIf="this.list_id.invalid"></div>
    </div>

    <div class="listMapper ui-g-2">
      <p-dropdown
        formControlName="team_id"
        [options]="teamOptions"
        placeholder="Select Team"
        [filter]="true"
      ></p-dropdown>
    </div>

    <div class="listMapper ui-g-2">
      <p-dropdown
        formControlName="category_id"
        [options]="listCategoryOptions"
        placeholder="Select Category"
        [filter]="true"
      ></p-dropdown>
    </div>

    <div class="listMapper ui-g-1">
      <button class="submitButton" pButton type="button" (click)="submit()">
        Submit
      </button>
    </div>
    <div class="listMapper ui-g-1">
      <button class="resetButton" pButton type="button" (click)="reset()">
        Reset
      </button>
    </div>
  </form>
</div>

<!-- Table  -->
<div class="listMapperTable">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    exportFilename="ListDetails"
    [(selection)]="selectedLists"
    dataKey="list_id"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="30"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <!-- <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates> -->

          <div class="ui-g-5 ui-g-offset-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="allCols"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'edit' ? '100px' : 'auto'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngIf="editMultiple"
          style="width: 3rem; text-align: center !important;"
        >
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
              col.field === 'active' || col.field === 'edit'
                ? 'center'
                : 'left',
            width:
              col.field === 'active' || col.field === 'edit' ? '100px' : 'auto'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            *ngIf="col.field !== 'edit'"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
          <span *ngIf="col.field === 'edit'">
            <button
              mat-button
              (click)="editMultiple = !editMultiple; selectedLists = []"
            >
              <img src="assets/icons/edit_w.svg" [style]="{ width: '16px' }" />
            </button>
          </span>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th
          class="columnFilterTH"
          *ngIf="editMultiple"
          style="width: 3rem; text-align: center !important;"
        ></th>
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col?.type==='number'||col.type==='percent'|| col.type ==='currency'"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="col.type === 'string' || col.type === 'primary'">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngIf="editMultiple"
          style="width: 3rem; text-align: center !important;"
        >
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.field === 'active' || col.field === 'edit'
                ? 'center'
                : 'left',
            width:
              col.field === 'active' || col.field === 'edit' ? '100px' : 'auto'
          }"
        >
          <span *ngIf="col.field === 'edit'">
            <button
              mat-button
              pTooltip="Edit"
              (click)="editListDetails(rowData)"
            >
              <img src="assets/icons/edit_w.svg" [style]="{ width: '16px' }" />
            </button>
          </span>

          <span *ngIf="col.field !== 'edit' && col.field !== 'active'">{{
            rowData[col.field]
          }}</span>

          <span
            *ngIf="col.field === 'active'"
            [ngStyle]="{
              color: rowData[col.field] === '1' ? '#fefefe' : '#fefefe',
              'background-color':
                rowData[col.field] === '1' ? '#22bb33' : '#bb2124',
              height: '100%',
              'border-radius': '4px'
            }"
          >
            <button
              mat-button
              style="
                width: 70px;
                border-radius: 4px;
                padding: 0rem;
                margin: auto;
              "
              pTooltip="Change Status"
              (click)="updateActiveStatus(rowData)"
            >
              {{ getActiveStatus(rowData[col.field]) }}
            </button>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(dt._value | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from {{ (data !== undefined ? data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
