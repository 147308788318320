<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Offer Group Performance'">
        <app-offer-group-table
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-offer-group-table>
      </p>
      <p *ngSwitchCase="'MSP - '">
        <app-offer-group-msp
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-offer-group-msp>
      </p>
      <p *ngSwitchCase="'Offers - '">
        <app-offertable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-offertable>
      </p>
      <p *ngSwitchCase="'Advertisers - '">
        <app-network1table
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-network1table>
      </p>
      <p *ngSwitchCase="'Segments - '">
        <app-segmenttable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-segmenttable>
      </p>
      <p *ngSwitchCase="'Campaigns - '">
        <app-campaigntable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-campaigntable>
      </p>
      <p *ngSwitchCase="'Creatives - '">
        <app-creative-performance
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creative-performance>
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
