import { User } from './../../../../login/logincomponents/login/login.component';
import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { UtilService } from './../../../../utils/util.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MspService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];


  constructor(private http: HttpClient, private utilService: UtilService) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "MSP Report",
      isClosable: false
    });
  }
  getSenderId(body: any)
  {
      return this.http.post(this.baseURL + "sms/getSenderIdReport", body);
  }
  getMspData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getMspReport", body);
  }
  getSmsServiceData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getMspReport", body);
  }
  getMspVerticalData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getVerticalReport", body);
  }
  getVerticalData(body: any)
  {
    return this.http.post(this.baseURL + "sms/getVerticalReport", body);
  }
}
