<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Websites'">
        <app-websites
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-websites>
      </p>
      <p *ngSwitchCase="'Channel - '">
        <app-channels-wise-website
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-channels-wise-website>
      </p>
      <p *ngSwitchCase="'Drop Leads - '">
        <app-leads-details
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-leads-details>
      </p>
      <p *ngSwitchCase="'Conversion Leads - '">
        <app-conversion-leads
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-conversion-leads>
      </p>
      <p *ngSwitchCase="'Drop Fields - '">
        <app-drop-fields
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-drop-fields>
      </p>
      <p *ngSwitchCase="'Drop Fields Website - '">
        <app-drop-field-by-website
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-drop-field-by-website>
      </p>
      <p *ngSwitchCase="'Drop Field Rate - '">
        <app-drop-field-rate
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-drop-field-rate>
      </p>
      <p *ngSwitchCase="'Publisher - '">
        <app-publishar-wise-website
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-publishar-wise-website>
      </p>
      <p *ngSwitchCase="'Source Id - '">
        <app-source-id-wise-publiser
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        >
      </app-source-id-wise-publiser>
      </p>
        <p *ngSwitchCase="'Website Drop Field - '">
          <app-drop-field-by-source-id
            [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
            [requiredData]="{
              date1: tab.startDate,
              date2: tab.endDate,
              data: tab.data
            }"
            (AddTabController)="addTabInArray($event)"
          ></app-drop-field-by-source-id>
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
