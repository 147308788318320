import { Component, OnInit, Input, Output, EventEmitter, Inject, Optional } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CreativelibraryService } from '../../creativelibraryservices/creativelibrary.service';
import { escapeRegExp } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-creativewindow',
  templateUrl: './creativewindow.component.html',
  styleUrls: ['./creativewindow.component.css']
})
export class CreativewindowComponent implements OnInit
{
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any = {};
  @Input() tabInformation: any;
  creativeHtml: any;
  iframeURL: SafeResourceUrl;
  creativeId: any;
  creativeName: any;
  creativeVersion: any;
  creativeVertical: any;
  creativePortal: any;
  filePath: any;

  constructor(
    private sanitizer: DomSanitizer,
    private service: CreativelibraryService,
    @Optional() private dialogRef: MatDialogRef<CreativewindowComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public creativeData: any
  )
  {

  }

  ngOnInit()
  {
    // Setting Default Date for Data
    if (this.requiredData.data !== undefined)
    {
      this.creativeId = this.requiredData.data.id;
      this.creativeName = this.requiredData.data.creative_name;
      this.filePath = this.requiredData.data.file_path ? this.requiredData.data.file_path : this.creativeName;
      // this.creativeVersion = this.requiredData.data.version;
      // this.creativeVertical = this.requiredData.data.vertical_name;
      // this.creativePortal = this.requiredData.data.esp_connection_name;
      this.showCreativeWindow();
    } else
    {
      this.filePath = this.creativeData.file_path ? this.creativeData.file_path : this.creativeData.creative;
      this.showCreativeWindow();
    }
  }

  // For Showing Creative Dynamically
  showCreativeWindow()
  {
    this.service.getCreativeHtml(this.filePath).subscribe((responseHtml: any) =>
    {
      if (responseHtml.creative)
      {
        const htmlString = responseHtml.creative as string;
        const tempString = `src="https://wts.rightfullabs.com/apis/creatives/extracted/${this.filePath}/http`;
        const tempHTML = htmlString.replace(/src="/g, `src="https://wts.rightfullabs.com/apis/creatives/extracted/${this.filePath}/`);
        const htmlContent = tempHTML.replace(new RegExp(escapeRegExp(tempString), 'g'), 'src="http');
        this.creativeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
      } else
      {
        this.creativeHtml = "Creative HTML file not present.(Creative HTML file name should be the same as zip file name which you have uploaded. If not so, then kindly update.)"
      }
    });


  }

  // For Closing Creative Window
  hideCreativeWindow()
  {
    this.dialogRef.close();
  }

}
