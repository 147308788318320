import { LocationStrategy } from '@angular/common';
import { User } from './../login/logincomponents/login/login.component';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
  //   if (this.utilService.loginData.team_id) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const currentUser: User = this.authService.currentUserValue;


    if (currentUser && currentUser.jwt)
    {
      if (!state.url.startsWith('/login'))
      {
        return true;
      } else if (state.url.startsWith('/login')) {
                if (localStorage.portalStatus === 'Email') {
                  this.router.navigate(['/homepage/email-dashboard']);
                } else {
                  this.router.navigate(['/homepage/sms-dashboard']);
                }
                return false;
              }
      return true;
    } else
    {
      if (!state.url.startsWith('/login'))
      {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }

  }

}

