<div class="heading">
  <div class="listMapper tab">
    MTD Vertical Performance
  </div>
</div>

<!-- Table  -->
<div class="listMapperTable" style="
margin-top: 0rem;
">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data.data"
    [paginator]="false"
    [rows]="5"
    paginatorDropdownAppendTo="body"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="false"
    [scrollHeight]="false"
    [loading]="loading"
    [frozenColumns]="frozenCols"
    (onColReorder)="saveColumnState(dt)"

  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.type === 'any' ? '100px' : '150px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr></tr>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
            col.type === 'string' || col.type === 'primary'
                ? 'left'
                : col.type === 'any'
                ? 'center'
                : 'right',
                fontWeight: col.type === 'primary' ? 'bold' : 'bold'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            *ngIf="col.type !== 'any'"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type === 'number'"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="updateFilterOptions(dt.filteredValue, col.field)"
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="updateFilterOptions(dt.filteredValue, col.field)"
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container
              *ngIf="col.type === 'string' || col.type === 'primary'"
            >
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="updateFilterOptions(dt.filteredValue, col.field)"
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="updateFilterOptions(dt.filteredValue, col.field)"
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" style="max-height: 78px;min-height:87px;"  >
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
            col.type === 'string' || col.type === 'primary'
                ? 'left'
                : col.type === 'any'
                ? 'center'
                : 'right',
                fontWeight: col.type === 'primary' ? 'bold' : 'normal'

          }"
        >
          <!-- <span *ngIf="col.type !== 'any'">
            {{ rowData[col.field] }}
          </span> -->
          <span *ngIf="col.type === 'any' && rowData['offer_group_name']">
            <button
              mat-button
              pTooltip="Rename Offer Group Name"
              (click)="
                op.show($event); offerGroupName = rowData['offer_group_name']
              "
            >
              <img
                src="assets/icons/rename_w.svg"
                [style]="{ width: '16px' }"
              />
            </button>
            <button
              mat-button
              pTooltip="Edit"
            >
              <img
                src="assets/icons/edit_w.svg"
                [style]="{ width: '14px', 'margin-left': '.3rem' }"
              />
            </button>

          </span>
          <p-overlayPanel
            #op
            [autoZIndex]="true"
            [dismissable]="true"
            appendTo="body"
          >
            <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
              Rename Offer Group Name
            </div>
            <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
              <textarea
                [rows]="1"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
              ></textarea>
            </div>
            <div>
              <button
                pButton
                [ngStyle]="{
                  'background-color': '#e25750',
                  'border-color': '#e25750',
                  'margin-right': '1rem'
                }"
                type="button"
                label="Rename"
              ></button>
              <button
                pButton
                (click)="op.hide()"
                type="button"
                label="Close"
              ></button>
            </div>
          </p-overlayPanel>
          <span *ngIf="
          col.type !== 'currency' &&
           col.type !== 'percent' &&
            col.type !== 'number' &&
             col.type !== 'string'">{{
            rowData[col.field] || ""
          }}</span>
              <span *ngIf="col.type === 'currency'">{{
                (rowData[col.field] | currency: "INR":"symbol":"0.2-2") ||
                  (0 | currency: "INR":"symbol":"0.2-2")
              }}</span>
              <span *ngIf="col.type === 'percent'"
                >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
              >
              <span *ngIf="col.type === 'number'"
                  >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
                >
                <span *ngIf="col.type === 'string'"
                  >{{ rowData[col.field] || 'N/A' }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(dt._value | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (data.data !== undefined ? data.data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>

</div>
<div *ngIf="data.data == undefined" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="data.data.length == 0" class="dataNotFound">
  <span style="font-weight: bold; box-shadow: 0 0 0px #cecece;border-radius: 8px;text-align: center;padding: 0rem;font-size: 1.5rem; color: #cdcfda;"> No Records Found</span>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>

