<div class="form_frm ui-g-12">
  <div class="listMapper ui-g-4 ui-g-offset-1">
    <p-fileUpload
      #upload
      name="demo[]"
      (onSelect)="clearPrevious(); readCSV($event, upload)"
      [auto]="true"
      accept=".csv"
      maxFileSize="10000000"
      chooseLabel="Select CSV File"
    >
      <ng-template pTemplate="content">
        <div
          *ngIf="csvFile !== ''"
          style="text-align: center; background-color: #009944; color: #fefefe;"
        >
          <i
            class="pi pi-check"
            style="width: 2rem; margin: 0.75rem 0rem 1.5rem; font-size: 1.5rem;"
          ></i>
          {{ this.csvFile.name }} -
          {{ this.csvFile.size / 1000 | number: "1.0-2" }} KB
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="listMapper ui-g-2">
    <p-calendar
      [inputStyle]="{ width: '100%' }"
      [style]="{ width: '100%' }"
      dateFormat="dd/mm/yy"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [(ngModel)]="selectedDate"
      placeholder="Select Report Date"
    ></p-calendar>
  </div>
  <div class="listMapper ui-g-2">
    <button
      class="submitButton"
      pButton
      type="button"
      (click)="
        uploadData(csvFileName, csvFileData, userName, csvRecordCreateDate)
      "
    >
      Submit
    </button>
  </div>
  <div class="listMapper ui-g-2">
    <button class="resetButton" pButton type="button" (click)="reset()">
      Reset
    </button>
  </div>
</div>
<div class="creativeLibraryTable ui-g-12" [ngStyle]="{ 'margin-top': '2rem' }">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="30"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.field === 'publisher_name' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
              col.field === 'publisher_name' ||
              col.field === 'mailing_name' ||
              col.field === 'offer_name' ||
              col.field === 'network_name' ||
              col.field === 'list_name' ||
              col.field === 'segment_name' ||
              col.field === 'from_line' ||
              col.field === 'subject_line' ||
              col.field === 'report_day' ||
              col.field === 'creative_name' ||
              col.field === 'created_by'
                ? 'left'
                : 'right'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.field === 'publisher_name' ||
              col.field === 'mailing_name' ||
              col.field === 'offer_name' ||
              col.field === 'network_name' ||
              col.field === 'list_name' ||
              col.field === 'segment_name' ||
              col.field === 'from_line' ||
              col.field === 'subject_line' ||
              col.field === 'report_day' ||
              col.field === 'creative_name' ||
              col.field === 'created_by'
                ? 'left'
                : 'right'
          }"
        >
          <span
            *ngIf="
              rowData[col.field] !== 'Undefined' &&
              col.header === 'Publisher Name'
            "
          >
            <button
              mat-button
              pTooltip="Creatives Performance"
              (click)="
                addNewTab(
                  'Creatives - ',
                  rowData['publisher_name'],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/creative_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
          </span>
          <span
            *ngIf="
              col.field !== 'open_rate' &&
              col.field !== 'click_rate' &&
              col.field !== 'unsub_rate' &&
              col.field !== 'utr' &&
              col.field !== 'conversion_rate' &&
              col.field !== 'epc' &&
              col.field !== 'ecpm' &&
              col.field !== 'epl' &&
              col.field !== 'revenue' &&
              col.field !== 'hard_bounces_percent'
            "
          >
            {{ rowData[col.field] }}
          </span>

          <span [hidden]="true" *ngIf="col.field === 'publisher_name'">
            {{ rowData[col.field] }}
          </span>

          <span *ngIf="col.field === 'date_name'">
            {{ getDateFormat(rowData["date_name"]) }}
          </span>

          <span *ngIf="col.field === 'open_rate'">
            {{
              (rowData["opens"] / rowData["sent_count"] !== 1 / 0
                ? rowData["opens"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ctr'">
            {{
              (rowData["portal_click"] / rowData["opens"] !== 1 / 0
                ? rowData["portal_click"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'click_rate'">
            {{
              (rowData["clicks"] / rowData["opens"] !== 1 / 0
                ? rowData["clicks"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'unsub_rate'">
            {{
              (rowData["unsub"] / rowData["sent_count"] !== 1 / 0
                ? rowData["unsub"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'utr'">
            {{
              (rowData["unsub"] / rowData["opens"] !== 1 / 0
                ? rowData["unsub"] / rowData["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'conversion_rate'">
            {{
              (rowData["conversion"] / rowData["network_clicks"] !== 1 / 0
                ? rowData["conversion"] / rowData["network_clicks"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>

          <span *ngIf="col.field === 'epc'">
            {{
              (rowData["revenue"] / rowData["network_clicks"] !== 1 / 0
                ? rowData["revenue"] / rowData["network_clicks"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ecpm'">
            {{
              ((rowData["revenue"] / rowData["sent_count"]) * 1000 !== 1 / 0
                ? (rowData["revenue"] / rowData["sent_count"]) * 1000
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'epl'">
            {{
              (rowData["revenue"] / rowData["conversion"] !== 1 / 0
                ? rowData["revenue"] / rowData["conversion"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'revenue'">
            {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
          </span>
          <span *ngIf="col.field === 'hard_bounces_percent'">
            {{
              (rowData["hard_bounces"] / rowData["sent_count"] !== 1 / 0
                ? rowData["hard_bounces"] / rowData["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.field === 'publisher_name' ||
              col.field === 'mailing_name' ||
              col.field === 'offer_name' ||
              col.field === 'network_name' ||
              col.field === 'list_name' ||
              col.field === 'segment_name' ||
              col.field === 'from_line' ||
              col.field === 'subject_line' ||
              col.field === 'report_day' ||
              col.field === 'creative_name' ||
              col.field === 'created_by'
                ? 'left'
                : 'right'
          }"
        >
          <span
            *ngIf="
              col.field !== 'publisher_name' &&
              col.field !== 'open_rate' &&
              col.field !== 'click_rate' &&
              col.field !== 'unsub_rate' &&
              col.field !== 'utr' &&
              col.field !== 'conversion_rate' &&
              col.field !== 'epc' &&
              col.field !== 'ecpm' &&
              col.field !== 'epl' &&
              col.field !== 'revenue' &&
              col.field !== 'hard_bounces_percent' &&
              col.field !== 'mailing_id' &&
              col.field !== 'mailing_name' &&
              col.field !== 'list_name' &&
              col.field !== 'segment_name' &&
              col.field !== 'from_line' &&
              col.field !== 'subject_line' &&
              col.field !== 'hard_bounces_percent' &&
              col.field !== 'creative_name' &&
              col.field !== 'report_date' &&
              col.field !== 'report_day' &&
              col.field !== 'offer_name' &&
              col.field !== 'network_name' &&
              col.field !== 'created_by'
            "
          >
            {{ footerValues[col.field] || 0 }}
          </span>

          <span class="footerGrandTotal" *ngIf="col.field === 'publisher_name'">
            Grand Total
          </span>

          <span *ngIf="col.field === 'open_rate'">
            {{
              (footerValues["opens"] / footerValues["sent_count"] !== 1 / 0
                ? footerValues["opens"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ctr'">
            {{
              (footerValues["portal_click"] / footerValues["opens"] !== 1 / 0
                ? footerValues["portal_click"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'click_rate'">
            {{
              (footerValues["clicks"] / footerValues["opens"] !== 1 / 0
                ? footerValues["clicks"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'unsub_rate'">
            {{
              (footerValues["unsub"] / footerValues["sent_count"] !== 1 / 0
                ? footerValues["unsub"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'utr'">
            {{
              (footerValues["unsub"] / footerValues["opens"] !== 1 / 0
                ? footerValues["unsub"] / footerValues["opens"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'conversion_rate'">
            {{
              (footerValues["conversion"] / footerValues["network_clicks"] !==
              1 / 0
                ? footerValues["conversion"] / footerValues["network_clicks"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>

          <span *ngIf="col.field === 'epc'">
            {{
              (footerValues["revenue"] / footerValues["network_clicks"] !==
              1 / 0
                ? footerValues["revenue"] / footerValues["network_clicks"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'ecpm'">
            {{
              ((footerValues["revenue"] / footerValues["sent_count"]) * 1000 !==
              1 / 0
                ? (footerValues["revenue"] / footerValues["sent_count"]) * 1000
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'epl'">
            {{
              (footerValues["revenue"] / footerValues["conversion"] !== 1 / 0
                ? footerValues["revenue"] / footerValues["conversion"]
                : 0) || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'revenue'">
            {{
              footerValues["revenue"] || 0 | currency: "USD":"symbol":"1.2-2"
            }}
          </span>
          <span *ngIf="col.field === 'hard_bounces_percent'">
            {{
              (footerValues["hard_bounces"] / footerValues["sent_count"] !==
              1 / 0
                ? footerValues["hard_bounces"] / footerValues["sent_count"]
                : 0) || 0 | percent: "1.2-2"
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(dt._value | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from {{ (data !== undefined ? data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
