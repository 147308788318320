import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MappingService } from './../../mapping/mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';
import { Papa } from 'ngx-papaparse';
import { isString } from 'util';
@Component({
  selector: 'app-msp-delivered-target',
  templateUrl: './msp-delivered-target.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class MspDeliveredTargetComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  regiform: FormGroup; // for Form Reference
  options: any[] = [];   // for Creating Options for Ongage List
  offerOptions: any[] = [];   // for Creating Options for Ongage List
  networkId: any;  // for storing network id value
  offerId: any;  // for storing network id vaslue
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {};  // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = '';
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  minDate: Date = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate: Date = moment().subtract(1, 'day').toDate();
  user_id: any = 0;
  fileName: any;
  teamId: any;
  tableName: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  footerValues: any = {}; // Data Storing for Column Footer
  revenue_id:any;
  num1:any;
  num2:any;
  userId: any = 0;
  deleteReason: any = '';
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  exporting: boolean;
  mspDeliveredId:any;

  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private Papa: Papa
  ) {      // Dates Array for Date Selection
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD'); }

    ngOnInit(): void {
      this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
      this.userId = this.utilService.loginData.user_id;
      this.startDate = moment().subtract(1,'day').format('YYYY-MM-DD');
      this.endDate = moment().subtract(1,'day').format('YYYY-MM-DD');
      this.regiform = this.fb.group({
        providerId: ["", Validators.required],
        delivered: ["", Validators.required],
        date:["",Validators.required]
      });

      this.userId = this.utilService.loginData.user_id;
      this.getInputData();
      this.filterData();

      FilterUtils['filterString'] = (value, filter): boolean =>
      {
        let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
        let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

        if (value === undefined || value === null)
        {
          return false;
        }
        if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
        {
          return value;
        }
        if (startString && endString)
        {
          return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
        } else if (startString)
        {
          return FilterUtils.startsWith(value, startString);
        } else if (endString)
        {
          return FilterUtils.endsWith(value, endString);
        } else
        {
          return value;
        }
      };
      // this.getInputOfferData();
    }
    getInputData()
    {
      this.subscription.push(this.mappingService.getMspName().subscribe((ongagelist: any[]) =>
      {
        if (ongagelist)
        {
          this.options = ongagelist.map(e =>
          {
            return {
              label: `${e["provider_name"]}`,
              value:  e["providerId"]
              //label: `${e["advertisers_name"]} (${e["portal_name"]})`,
             // value: { network_id: e["network_id" ], portal_id: e["network_id"] }
            };
          });
        }
      }));

    }
    editMspDelivered(mspDeliveredDetails: any)
    { //debugger;
      this.mspDeliveredId = mspDeliveredDetails.id;
      this.revenue_id = mspDeliveredDetails.id;
      this.providerId.setValue(mspDeliveredDetails.providerId);
      this.delivered.setValue(mspDeliveredDetails.delivered);
      this.date.setValue(moment(mspDeliveredDetails.report_date,'YYYY-MM-DD').toDate());
    }
    get providerId()
    {
      return this.regiform.get("providerId");
    }
    get delivered()
    {
      return this.regiform.get("delivered");
    }
    get date()
    {
      return this.regiform.get("date");
    }
    submit()
    { //debugger;
      if (this.providerId.invalid)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'MSP Name not selected!' });
        return;
      }

      if (this.delivered.invalid)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Delivered not selected!' });
        return;
      }
      if (this.date.invalid)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Date Not selected!' });
        return;
      }

      //this.Revenue.enable();
      const body = {
        providerId: this.providerId.value,
        delivered: this.delivered.value,
        date: moment(this.date.value).format("YYYY-MM-DD"),
        userId: this.utilService.loginData.user_id,
        id: this.mspDeliveredId
      };
      //debugger;
      this.mappingService.putMspDeliveredTarget(body).subscribe(res =>
      {
        if (res["code"] === 200)
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data updated successfully!!!' });
          this.regiform.reset();
          this.filterData();
        }
        if(res["code"] === 201)
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data Saved successfully!!!' });
          this.regiform.reset();
          this.filterData();
        }
        if (res["code"] === 400)
        {
          this.messageService.add({ key: 'tc', severity: 'error', summary: 'Network Name already exist', detail: 'Please edit to update details!!!' });
        }
      });
    }
    reset()
    {
      //debugger;
      this.regiform.reset();
      // this.providerId = '';
      this.mspDeliveredId='';
      this.getInputData();
    }
    filterData(type?: any, cols?: any)
    { //debugger;
      if (cols !== undefined && moment.isDate(cols))
      {
        this.startDate = moment(cols).format('YYYY-MM-DD');
      }
      if (cols !== undefined && isString(cols))
      {
        this.teamId = cols;
      }

      this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
      this.tableName = "Websites_" + this.fileName;
      if (this.endDate && !this.isFilter)
      {

        this.loading = true;
        // Passing Updated/Filtered Date
        this.bodyObject = {
          start_date: this.startDate,
          end_date: this.endDate,
        };


       if (!this.isFilter)
       {
        this.loading = true;

        // Getting Data From Server
        if (!this.isFilter)
        {
        if (this.bodyObject && !this.isFilter)
        {
          this.subscription.push(this.mappingService.mspDeliveredTarget({start_date:this.startDate,end_date:this.endDate}).subscribe(responseData =>
            {
              if (responseData['error'] === 'Expired token')
              {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
                return this.utilService.logedOut();
              }
              if (responseData['code'])
              {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
                return null;
              }
              this.data = responseData as { headers: any[], data: any[],config:any };
              // All Columns of Table
              this.allCols = this.data.headers;

              // Frized Columns
              this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
              this.tableConfig = this.data.config;

              // All Columns for Filter Options
              this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

              // First Time Showed Columns
              this.cols = localStorage.addSmsRevenue ? JSON.parse(localStorage.addSmsRevenue) : this.data.headers.filter(col => col.type !== 'primary');
              this.loading = false;
              this.footerValues = { undefined };
              if (this.data.data.length !== 0)
              {
                this.getFooterData(this.data.data);
                this.filterOptions = this.utilService.getRequiredOptions(
                  this.data.data,
                  this.data.data[0]
                );
              } else
              {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
              }
            }, err =>
            {
              if (err)
              {
                this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
                this.loading = false;
                return null;
              }
            }));
          }
        }

      }

      }




    }
    updateFilterOptions(tableData, colName)
    {
      if (tableData !== null)
      {
        this.getFooterData(tableData);
        this.filterOptions = this.utilService.getRequiredOptions(
          tableData,
          tableData[0]
        );
        this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
      }
    }
      // Getting Dates from Dates Component
    getDates(datesObj)
    {
      if (datesObj)
      {
        this.startDate = datesObj.start_date;
        this.endDate = datesObj.end_date;
        this.filterOptions = [];
        this.filterData();
      }
    }
        // Update Footer Values
    getFooterData(tableData)
    {
      this.footerValues = this.utilService.getRequiredFooterData(tableData);
    }
    // For getting string length(Tooltip)
    showTooltip(text: any)
    {
      return text && (String(text).length > 9) ? text : '';
    }
      // For setting fields value

    // For color coding
    dropOffBackgroundColorCoding(dropRatio: number)
    {
      if (dropRatio <= 5)
      {
        return '#1be56c';
      }
      else if (dropRatio > 5 && dropRatio <= 10)
      {
        return '#36e87e';
      }
      else if (dropRatio > 10 && dropRatio <= 15)
      {
        return '#4ceb8b';
      }
      else if (dropRatio > 15 && dropRatio <= 20)
      {
        return '#65ed9c';
      }
      else if (dropRatio > 20 && dropRatio <= 25)
      {
        return '#7cf0aa';
      }
      else if (dropRatio > 25 && dropRatio <= 30)
      {
        return '#9df4c0';
      }
      else if (dropRatio > 30 && dropRatio <= 35)
      {
        return '#b1f6cc';
      }
      else if (dropRatio > 35 && dropRatio <= 40)
      {
        return '#cffadf';
      }
      else if (dropRatio > 40 && dropRatio <= 45)
      {
        return '#dbffea';
      }
      else if (dropRatio > 45 && dropRatio <= 50)
      {
        return '#eafdf1';
      }
      else if (dropRatio > 50 && dropRatio <= 55)
      {
        return '#ffe3e3';
      }
      else if (dropRatio > 55 && dropRatio <= 60)
      {
        return '#ffdfdf';
      }
      else if (dropRatio > 60 && dropRatio <= 65)
      {
        return '#ffd2d2';
      }
      else if (dropRatio > 65 && dropRatio <= 70)
      {
        return '#ffc4c4';
      }
      else if (dropRatio > 70 && dropRatio <= 75)
      {
        return '#ffb1b1';
      }
      else if (dropRatio > 75 && dropRatio <= 80)
      {
        return '#ff9797';
      }
      else if (dropRatio > 80 && dropRatio <= 85)
      {
        return '#ff8484';
      }
      else if (dropRatio > 85 && dropRatio <= 90)
      {
        return '#ff7778';
      }
      else
      {
        return '#ff6161';
      }
    }
    dropOffColorCoding(dropRatio: number)
    {
      if (dropRatio <= 5)
      {
        return '#ffffff';
      }
      else if (dropRatio > 5 && dropRatio <= 10)
      {
        return '#ffffff';
      }
      else if (dropRatio > 10 && dropRatio <= 15)
      {
        return '#ffffff';
      }
      else if (dropRatio > 15 && dropRatio <= 20)
      {
        return '#ffffff';
      }
      else if (dropRatio > 20 && dropRatio <= 25)
      {
        return '#ffffff';
      }
      else if (dropRatio > 25 && dropRatio <= 30)
      {
        return '#444444';
      }
      else if (dropRatio > 30 && dropRatio <= 35)
      {
        return '#333333';
      }
      else if (dropRatio > 35 && dropRatio <= 40)
      {
        return '#222222';
      }
      else if (dropRatio > 40 && dropRatio <= 45)
      {
        return '#111111';
      }
      else if (dropRatio > 45 && dropRatio <= 50)
      {
        return '#000000';
      }
      else if (dropRatio > 50 && dropRatio <= 55)
      {
        return '#111111';
      }
      else if (dropRatio > 55 && dropRatio <= 60)
      {
        return '#222222';
      }
      else if (dropRatio > 60 && dropRatio <= 65)
      {
        return '#333333';
      }
      else if (dropRatio > 65 && dropRatio <= 70)
      {
        return '#444444';
      }
      else if (dropRatio > 70 && dropRatio <= 75)
      {
        return '#ffffff';
      }
      else if (dropRatio > 75 && dropRatio <= 80)
      {
        return '#ffffff';
      }
      else if (dropRatio > 80 && dropRatio <= 85)
      {
        return '#ffffff';
      }
      else if (dropRatio > 85 && dropRatio <= 90)
      {
        return '#ffffff';
      }
      else
      {
        return '#ffffff';
      }
    }
      // Add New Tab in Tab Array
    addNewTab(type, header, startDate, endDate, data)
    {
      this.AddTabController.emit({ type, header, startDate, endDate, data });
    }
        // Columns State Save
    saveColumnState(dt: any)
    {
      localStorage.addSmsRevenue = JSON.stringify(dt.columns);
    }
    deleteMspDeliveredTarget()
    {

    }

}
