import { Component, OnInit } from '@angular/core';
import { LeadsService } from '../leadsservices/leads.service';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit
{
  tabId = 0;
  tabs: any[] = [];
  activeIndex: number;
  checkPreviousTab = false;
  constructor(private service: LeadsService, private utilService: UtilService) { }
  ngOnInit(): void
  { //debugger;
    this.activeIndex = 0;
    this.tabs = this.service.tabs;
    // this.changeDetector.detectChanges();
  }
  addTabInArray(tabObject)
  {
    if (tabObject)
    {
      // Checking Whether The Tab is in the Array or Not
      const index = this.service.tabs.findIndex(x => (x.header === tabObject.header && x.type === tabObject.type));
      if (index !== -1)
      {
        // Update Tab in Tab Array
        this.service.tabs.splice(index, 1, {
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        this.activeIndex = index;
      } else
      {
        // Adding New Tab in Tab Array
        this.service.tabs.push({
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        localStorage.setItem("leadsTabs", JSON.stringify(this.service.tabs));
        this.activeIndex = this.service.tabs.length - 1;
      }
    }
  }
  // Close Tab and remove from the Tab Array
  closeTab(tabIndex)
  {
    this.service.tabs.splice(tabIndex, 1);
    localStorage.setItem("leadsTabs", JSON.stringify(this.service.tabs));
    this.activeIndex = this.service.tabs.length - 1;
  }
  // Adjust Viewport According to Sidenav Expand and Collapse
  isExpanded()
  {
    return this.utilService.isExpanded;
  }

}
