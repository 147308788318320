import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';


@Injectable({
  providedIn: 'root'
})
export class IndSmsDomainService {
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Domain Report",
      isClosable: false
    });
  }
  getDomainData(body: any) {
    return this.http.post(this.baseURL + "sms/getDomainReport", body);
  }
}
