import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../login/logincomponents/login/login.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  //user related properties...
  baseURL = environment.baseURL;
  errorMessage = '';

  constructor(private httpClient: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  login(body: any) {

    return this.httpClient.post<any>(this.baseURL + 'login', body).pipe(map(user => {
      // login successful if there's a jwt token in the response
      this.errorMessage = user ? '' : 'Please enter valid email id and password!';
      if (user && user.jwt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      } else {
        this.errorMessage = 'Please enter valid email id and password!';
      }
      return user;
    }));
  }


  logout() {
    // remove user from local storage to logout
    this.currentUserSubject.next(null);
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
