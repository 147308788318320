import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from 'src/app/utils/util.service';
import { DailyService } from '../../dailyservices/daily.service';

@Component({
  selector: 'app-creative',
  templateUrl: './creative.component.html',
  styleUrls: ['../../../../../app.component.css']
})
export class CreativeComponent implements OnInit {
  tabId = 0;
  tabs: any[] = [];
  activeIndex: number;
  checkPreviousTab = false;
  constructor(private service: DailyService, private utilService: UtilService) { }
  ngOnInit(): void {
    this.activeIndex = 0;
    this.tabs = this.service.creativeTabs;
    // this.changeDetector.detectChanges();
  }
  addTabInArray(tabObject) {
    if (tabObject) {
      // Checking Whether The Tab is in the Array or Not
      const index = this.service.creativeTabs.findIndex(x => (x.header === tabObject.header && x.type === tabObject.type));
      if (index !== -1) {
        // Update Tab in Tab Array
        this.service.creativeTabs.splice(index, 1, {
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        this.activeIndex = index;
      } else {
        // Adding New Tab in Tab Array
        this.service.creativeTabs.push({
          id: this.tabId + 1,
          header: tabObject.header,
          type: tabObject.type,
          isClosable: true,
          startDate: tabObject.startDate,
          endDate: tabObject.endDate,
          data: tabObject.data
        });
        // localStorage.setItem("platformTabs", JSON.stringify(this.service.creativeTabs));
        this.activeIndex = this.service.creativeTabs.length - 1;
      }
    }
  }
  // Close Tab and remove from the Tab Array
  closeTab(tabIndex) {
    this.service.creativeTabs.splice(tabIndex, 1);
    // localStorage.setItem("platformTabs", JSON.stringify(this.service.creativeTabs));
    this.activeIndex = this.service.creativeTabs.length - 1;
  }

  // Adjust Viewport According to Sidenav Expand and Collapse
  isExpanded() {
    return this.utilService.isExpanded;
  }

}
