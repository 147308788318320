import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MappingService } from '../mappingservices/mapping.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: 'app-offer-mapping',
  templateUrl: './offer-mapping.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class OfferMappingComponent implements OnInit
{
  regiform: FormGroup; // for Form Reference
  options: any[] = [];   // for Creating Options for Ongage List
  smsOfferMapOptions:any[] = [];   // for Creating Options for Ongage List
  offerGroupId: any;  // for storing network id value
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {};  // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  selectedLists: any[] = [];
  selectedListsValue: any[] = [];
  editMultiple = false;
  scrollHeight: any;
  showModel: any = '';
  offerGroupName: any = '';
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  exporting: boolean;
  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';

    this.regiform = this.fb.group({
      offer_name: ["", Validators.required],
      offer_id: ["", Validators.required]
    });


    this.getInputData();
    this.filterData();

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }

  get offer_id()
  {
    return this.regiform.get("offer_id");
  }
  get offer_name()
  {
    return this.regiform.get("offer_name");
  }

  getInputData()
  {
    if(this.portalStatus() === "Email")
    {
      this.subscription.push(this.mappingService.getOfferOptions().subscribe((ongagelist: any[]) =>
      {
        if (ongagelist)
        {
          this.options = ongagelist.map(e =>
          {
            return {
              label: `${e["offer_name"]} (${e["advertisers_name"]}, ${e["portal_name"]})`,
              value: { offer_id: e["offer_id"], advertisers_id: e["advertisers_id"], portal_id: e["portal_id"] }
            };
          });
        }
      }));
    }
    if(this.portalStatus() === "Sms")
    {
      this.subscription.push(this.mappingService.getSmsOfferMapOptions().subscribe((ongagelist: any[]) =>
    {
      if (ongagelist)
      {
        this.smsOfferMapOptions = ongagelist.map(e =>
        {
          return {
            label: `${e["offer_name"]} (${e["advertisers_name"]})`,
            value: { offer_id: e["offer_id"], advertisers_id: e["advertisers_id"]}
          };
        });
      }
    }));
    }

  }
  filterData()
  {

    if (!this.isFilter)
    {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.mappingService.getOfferMappingDetails().subscribe(responseData =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          if (responseData['code']==='400')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Offer Group name already exist' });
            return null;
          }
          this.data = responseData as { headers: any[], data: any[] ,config:any };
          // All Columns of Table
          this.allCols = this.data.headers;
          //debugger;
          // Frized Columns
          this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
          this.tableConfig = this.data.config;
          //debugger;
          // All Columns for Filter Options
          this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

          // First Time Showed Columns
          if(this.portalStatus() === "Email")
          {
            this.cols = localStorage.networkMapping ? JSON.parse(localStorage.networkMapping) : this.data.headers;
          }
          if(this.portalStatus() === "Sms")
          {
            this.cols = localStorage.smsOfferMapping ? JSON.parse(localStorage.smsOfferMapping) : this.data.headers;
          }
          //debugger;
          this.loading = false;
          if (this.data.data.length !== 0)
          {
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data.data,
              this.data.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }));
      }

    }
  }


  // For setting fields value
  editOfferDetails(offerDetails: any)
  {
    this.mappingService.getAdvertisersListInOffer({ offer_group_id: offerDetails.offer_group_id }).subscribe((response: any[]) =>
    {
      if (response)
      { //debugger;
        this.offer_id.setValue(response);
        this.offer_name.setValue(offerDetails.offer_group_name);
        this.offerGroupId = offerDetails.offer_group_id;
        this.offer_name.disable();
        return this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: ' Offer Name Selected' });
      }
      return this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Something went wrong!!!' });
    })

  }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(tableData, colName);
    }
  }


  // Columns State Save
  saveColumnState(dt: any)
  {
    if(this.portalStatus() ==="Email")
    {
      localStorage.networkMapping = JSON.stringify(dt.columns);
    }
    if(this.portalStatus() === "Sms")
    {
      localStorage.smsOfferMapping = JSON.stringify(dt.columns);
    }

  }

  // Adding Column For Exporting in Csv
  // exportData(dt)
  // {
  //   dt.columns.splice(dt.columns.length - 1, 1);
  //   dt.exportCSV();
  //   return dt.columns.splice(dt.columns.length - 1, 1, { field: 'edit', header: 'Edit' });
  // }

  exportData(dt)
  {
    if(this.portalStatus() === "Email")
      {
        dt.columns.splice(0, 0, this.frozenCols[0]);
        dt.exportCSV();
        return dt.columns.splice(0, 1);
      }
      if(this.portalStatus() === "Sms")
      {
        this.exporting = true;
        const body = {
          ...this.tableConfig,
          reportName:this.tableConfig.export.reportName,
          user_id: this.utilService.loginData.user_id


      };
      this.utilService.requestForExportFile(this.tableConfig.export.exportLink,body).subscribe(res =>
        {
          this.exporting = false
          if (res["code"] === 200)
          {
            this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Your File Export Request has been generated successfully!!!' });
          }
        });
      }
  }

  // For renaming network name
  renameOfferGroupName(dataObject: any)
  {
    this.mappingService.updateOfferGroupName({ offer_group_name: this.offerGroupName, offer_group_id: dataObject.offer_group_id, user_id: this.utilService.loginData.user_id }).subscribe((response: any) =>
    {
      if (response['code'] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Offer Group name has been successfully changed!!!' });
        this.offerGroupName = '';
        this.filterData();
        return;
      }
      if ((response['code'] === 400) || (response['code'] === 401))
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Something went wrong', detail: 'Please try again!!!' });
        return null;
      }
    })

  }

  submit()
  {
    if (this.offer_id.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Offers not selected!' });
      return;
    }
    if (this.offer_name.invalid)
    {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Warning', detail: 'Offer Name required!!!' });
      return;
    }

    this.offer_name.enable();
    const body = {
      offer_group_name: this.offer_name.value,
      created_by: this.utilService.loginData.user_id,
      offer_id: this.offer_id.value
    };
    this.mappingService.updateOfferMapping(body).subscribe(res =>
    {
      if (res["code"] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Data updated successfully!!!' });
        this.regiform.reset();
        this.offerGroupId = '';
        this.filterData();
      }
      if (res["code"] === 400)
      {
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Offer Group Name already exist', detail: 'Please edit to update details!!!' });
      }
    });
  }

  reset()
  {
    this.regiform.reset();
    this.offer_name.enable();
    this.getInputData();
  }
  getActiveStatus(binary: any)
  { //debugger;
    if (binary === 1)
    {
      return 'Online';
    } else
    {
      return 'Offline';
    }
  }
  updateActiveStatus(data: any)
  {
    //debugger;
    const body = {
      offer_id: data.offer_id,
      // updated_by: this.utilService.loginData.user_id,
      method: data.method === 1 ? 0 : 1
    }; //debugger;
    this.subscription.push(this.mappingService.updateOfferActiveStatus(body).subscribe(responseData =>
    {  //debugger;
      if (responseData['code'] === 200)
      {

        if (data.method === 1)
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Offer method Changed to online' });
        } else
        {
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'Offer method Changed to offline' });
        }
        this.filterData();
        return;
      } else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
        return;
      }
    }));
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }

  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }
}
