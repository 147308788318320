<span *ngIf="data && data.data && data.data.length">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data.data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="
      tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
    "
    [frozenColumns]="frozenCols"
    frozenWidth="220px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-2">
              <button
                class="scoring-hint"
                mat-button
                pTooltip="Scoring Hint"
                (click)="scoreHint.show($event)"
              >
                <img
                  src="assets/icons/hint_w.svg"
                  [style]="{ width: '1.75rem' }"
                />
              </button>
            </div>

            <div class="ui-g-4 extra_input">
              <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect>
            </div>

            <div class="ui-g-3 filter_c">
              <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button>
            </div>

            <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.type === 'primary' ? '220px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
          }"
        >
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="!col.type">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
          }"
        >
          <!-- <span *ngIf="col.type === 'primary'">
            <button
              mat-button
              pTooltip="Drop Leads"
              (click)="
                addNewTab(
                  'Drop Leads - ',
                  rowData[col.field],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img
                src="assets/icons/domain_w.svg"
                [style]="{ width: '.8rem' }"
              />
            </button>
            <button
              mat-button
              pTooltip="Conversion Leads"
              (click)="
                addNewTab(
                  'Conversion Leads - ',
                  rowData[col.field],
                  startDate,
                  endDate,
                  rowData
                )
              "
            >
              <img src="assets/icons/list_w.svg" [style]="{ width: '.8rem' }" />
            </button>
          </span> -->

          <span>
            <span *ngIf="col.type !== 'currency' && col.type !== 'percent'">{{
              rowData[col.field] || 0
            }}</span>
            <span *ngIf="col.type === 'currency'">{{
              (rowData[col.field] | currency: "$":"symbol":"0.2-2") ||
                (0 | currency: "$":"symbol":"0.2-2")
            }}</span>
            <span *ngIf="col.type === 'percent'"
              >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
            >
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
          }"
        >
          <span
            *ngIf="
              col.type !== 'primary' &&
              col.type !== 'string' &&
              col.type !== 'actions'
            "
          >
            <span
              *ngIf="
                col.type !== 'currency' &&
                col.type !== 'percent' &&
                col.field !== 'scoring' &&
                col.field !== 'ctr_diff'
              "
              >{{ footerValues[col.field] || 0 }}</span
            >
            <span *ngIf="col.field === 'Revenue'">{{
              footerValues[col.field] || 0 | currency: "USD":"symbol":"1.2-2"
            }}</span>
            <span *ngIf="col.field === 'open_rate'">
              {{
                (footerValues["opens"] / footerValues["sent"] !== 1 / 0
                  ? footerValues["opens"] / footerValues["sent"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'seg_open_rate'">
              {{
                (footerValues["seg_opens"] / footerValues["seg_sent"] !== 1 / 0
                  ? footerValues["seg_opens"] / footerValues["seg_sent"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'click_rate'">
              {{
                (footerValues["clicks"] / footerValues["opens"] !== 1 / 0
                  ? footerValues["clicks"] / footerValues["opens"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'seg_click_rate'">
              {{
                (footerValues["seg_clicks"] / footerValues["seg_opens"] !==
                1 / 0
                  ? footerValues["seg_clicks"] / footerValues["seg_opens"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'unsub_rate'">
              {{
                (footerValues["unsubscribes"] / footerValues["sent"] !== 1 / 0
                  ? footerValues["unsubscribes"] / footerValues["sent"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'seg_unsub_rate'">
              {{
                (footerValues["seg_unsub"] / footerValues["seg_sent"] !== 1 / 0
                  ? footerValues["seg_unsub"] / footerValues["seg_sent"]
                  : 0) || 0 | percent: "1.2-2"
              }}
            </span>
            <span *ngIf="col.field === 'scoring'">
              {{ getScore(footerValues) }}
            </span>
            <span *ngIf="col.field === 'ctr_diff'">
              {{ getDifference(footerValues) | number: "1.2-2" }}
            </span>
          </span>

          <span class="footerGrandTotal" *ngIf="col.type === 'primary'">
            Grand Total
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span *ngIf="(data.data | json) !== '[]'">
        Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
          (dt._first + dt._rows < dt._totalRecords
            ? dt._rows + dt._first
            : dt._totalRecords
          ) | json
        }}
        entries
        {{
          isFilter || isGlobalFilter
            ? "(" + dt._totalRecords + " filtered records)"
            : ""
        }}
        from
        {{ (data.data !== undefined ? data.data.length : 0) | json }} records
      </span>
    </ng-template>
  </p-table>
</span>
<div *ngIf="data == undefined" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>

<p-overlayPanel
  #scoreHint
  [showCloseIcon]="true"
  [dismissable]="true"
  appendTo="body"
>
  <ng-template pTemplate>
    <div class="creative-scoring-hint">
      <div class="score-heading">
        This scoring is based on the difference between
        <span class="difference">Creative Average CTR</span> and
        <span class="difference">Segment Average CTR</span>
      </div>
      <table class="scoring-table">
        <tr>
          <th class="below">Below</th>
          <th class="above">Above</th>
          <th class="score">Score</th>
        </tr>
        <tr *ngFor="let item of scoringHint">
          <td class="below">{{ item.below }}</td>
          <td class="above">{{ item.above }}</td>
          <td class="score">{{ item.score }}</td>
        </tr>
      </table>
    </div>
  </ng-template>
</p-overlayPanel>
