<ng-container *ngIf="mailingData">
  <div style="height: 4rem;">
    <div
      class="domainTableHeading"
      style="
        background-color: #4687db;
        color: #fefefe;
        padding: 1rem;
        border-radius: 0rem 1.5rem 1.5rem 0rem;
      "
    >
      Mailing ID : {{ mailingData.mailing_id }}
    </div>
    <div class="domainTableCloseButton">
      <button
        mat-fab
        (click)="hideDomainWindow()"
        style="
          background-color: #fefefe;
          position: absolute;
          right: 1rem;
          top: 1.35rem;
        "
      >
        <mat-icon style="color: #434343;" class="mat-18">close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
<p-table
  [ngClass]="{ domainTableModal: mailingData }"
  #dt
  [columns]="cols"
  [value]="data"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  scrollHeight="500px"
  [loading]="loading"
  (onColReorder)="saveColumnState(dt)"
  [exportFilename]="
    tabInformation.tabType + tabInformation.tabHeader + fileName || tableName
  "
  [frozenColumns]="frozenCols"
  frozenWidth="170px"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="30"
            placeholder="Search"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter = !isGlobalFilter"
            (focusout)="isGlobalFilter = !isGlobalFilter"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>
        <ng-container *ngIf="!mailingData">
          <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates>
          <!-- <div class="ui-g-2">
            <p-dropdown
              *ngIf="isAdmin"
              class="espTeamSelector"
              [options]="teamOptions"
              placeholder="All Teams"
              (onChange)="filterData($event.value, 'team_id')"
              [filter]="true"
            ></p-dropdown>
            <p-dropdown
              [ngClass]="{
                espTeamSelector: !isAdmin,
                espListSelector: isAdmin
              }"
              [options]="listOptions"
              placeholder="All Lists"
              (onChange)="filterData($event.value, 'list_id')"
              [filter]="true"
            ></p-dropdown>
          </div> -->
        </ng-container>
        <div class="ui-g-5 filterExportToggle">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="columnFilter"
              [options]="colFilterOptions"
              [(ngModel)]="cols"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
              (onClick)="saveColumnState(dt)"
            >
            </p-multiSelect>
          </div>

          <div class="ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export"
              (click)="exportData(dt)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
        [ngStyle]="{
          width: col.field === 'isp_name_or_others' ? '170px' : '100px'
        }"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign:
            col.field === 'isp_name_or_others' ||
            col.field === 'account_name' ||
            col.field === 'portal_name'
              ? 'left'
              : 'right'
        }"
      >
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter"
              [options]="filterOptions[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'isp_name_or_others' ||
            col.field === 'account_name' ||
            col.field === 'portal_name'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'open_rate' &&
            col.field !== 'ctr' &&
            col.field !== 'click_rate' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue' &&
            col.field !== 'hard_bounces_percent' &&
            col.field !== 'soft_bounces_percent'
          "
        >
          {{ rowData[col.field] }}
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (rowData["unique_opens"] / rowData["sent"] !== 1 / 0
              ? rowData["unique_opens"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <!-- <span *ngIf="col.field === 'ctr'">
          {{
            (rowData["portal_click"] / rowData["unique_opens"] !== 1 / 0
              ? rowData["portal_click"] / rowData["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span> -->
        <span *ngIf="col.field === 'click_rate'">
          {{
            (rowData["unique_clicks"] / rowData["unique_opens"] !== 1 / 0
              ? rowData["unique_clicks"] / rowData["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (rowData["unsubscribe"] / rowData["sent"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (rowData["unsubscribe"] / rowData["unique_opens"] !== 1 / 0
              ? rowData["unsubscribe"] / rowData["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (rowData["conversion_count"] / rowData["unique_clicks"] !== 1 / 0
              ? rowData["conversion_count"] / rowData["unique_clicks"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (rowData["revenue"] / rowData["unique_clicks"] !== 1 / 0
              ? rowData["revenue"] / rowData["unique_clicks"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((rowData["revenue"] / rowData["sent"]) * 1000 !== 1 / 0
              ? (rowData["revenue"] / rowData["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (rowData["revenue"] / rowData["conversion_count"] !== 1 / 0
              ? rowData["revenue"] / rowData["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ rowData["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.field === 'hard_bounces_percent'">
          {{
            (rowData["hard_bounces"] / rowData["sent"] !== 1 / 0
              ? rowData["hard_bounces"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'soft_bounces_percent'">
          {{
            (rowData["soft_bounces"] / rowData["sent"] !== 1 / 0
              ? rowData["soft_bounces"] / rowData["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign:
            col.field === 'isp_name_or_others' ||
            col.field === 'account_name' ||
            col.field === 'portal_name'
              ? 'left'
              : 'right'
        }"
      >
        <span
          *ngIf="
            col.field !== 'portal_name' &&
            col.field !== 'isp_name_or_others' &&
            col.field !== 'open_rate' &&
            col.field !== 'ctr' &&
            col.field !== 'click_rate' &&
            col.field !== 'unsub_persent' &&
            col.field !== 'utr' &&
            col.field !== 'conversion_persent' &&
            col.field !== 'epc' &&
            col.field !== 'ecpm' &&
            col.field !== 'epl' &&
            col.field !== 'revenue' &&
            col.field !== 'hard_bounces_percent' &&
            col.field !== 'soft_bounces_percent' &&
            col.field !== 'account_name'
          "
        >
          {{ footerValues[col.field] || 0 }}
        </span>

        <span
          class="footerGrandTotal"
          *ngIf="col.field === 'isp_name_or_others'"
        >
          Grand Total
        </span>

        <span *ngIf="col.field === 'open_rate'">
          {{
            (footerValues["unique_opens"] / footerValues["sent"] !== 1 / 0
              ? footerValues["unique_opens"] / footerValues["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ctr'">
          {{
            (footerValues["portal_click"] / footerValues["unique_opens"] !==
            1 / 0
              ? footerValues["portal_click"] / footerValues["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'click_rate'">
          {{
            (footerValues["unique_clicks"] / footerValues["unique_opens"] !==
            1 / 0
              ? footerValues["unique_clicks"] / footerValues["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'unsub_persent'">
          {{
            (footerValues["unsubscribe"] / footerValues["sent"] !== 1 / 0
              ? footerValues["unsubscribe"] / footerValues["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'utr'">
          {{
            (footerValues["unsubscribe"] / footerValues["unique_opens"] !==
            1 / 0
              ? footerValues["unsubscribe"] / footerValues["unique_opens"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'conversion_persent'">
          {{
            (footerValues["conversion_count"] /
              footerValues["unique_clicks"] !==
            1 / 0
              ? footerValues["conversion_count"] / footerValues["unique_clicks"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>

        <span *ngIf="col.field === 'epc'">
          {{
            (footerValues["revenue"] / footerValues["unique_clicks"] !== 1 / 0
              ? footerValues["revenue"] / footerValues["unique_clicks"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'ecpm'">
          {{
            ((footerValues["revenue"] / footerValues["sent"]) * 1000 !== 1 / 0
              ? (footerValues["revenue"] / footerValues["sent"]) * 1000
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'epl'">
          {{
            (footerValues["revenue"] / footerValues["conversion_count"] !==
            1 / 0
              ? footerValues["revenue"] / footerValues["conversion_count"]
              : 0) || 0 | currency: "USD":"symbol":"1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'revenue'">
          {{ footerValues["revenue"] || 0 | currency: "USD":"symbol":"1.2-2" }}
        </span>
        <span *ngIf="col.field === 'hard_bounces_percent'">
          {{
            (footerValues["hard_bounces"] / footerValues["sent"] !== 1 / 0
              ? footerValues["hard_bounces"] / footerValues["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
        <span *ngIf="col.field === 'soft_bounces_percent'">
          {{
            (footerValues["soft_bounces"] / footerValues["sent"] !== 1 / 0
              ? footerValues["soft_bounces"] / footerValues["sent"]
              : 0) || 0 | percent: "1.2-2"
          }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(dt._value | json) !== '[]'">
      Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
        (dt._first + dt._rows < dt._totalRecords
          ? dt._rows + dt._first
          : dt._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter || isGlobalFilter
          ? "(" + dt._totalRecords + " filtered records)"
          : ""
      }}
      from {{ (data !== undefined ? data.length : 0) | json }} records
    </span>
  </ng-template>
</p-table>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
