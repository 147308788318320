<div class="heading">
  <div class="listMapper tab">
    Monthly Target
  </div>
</div>


<div class="form_frm ui-g-12" style="padding-left: 150px;">
  <form [formGroup]="regiform">
    <div class="p-grid p-field">
      <div class="p-col-12 p-md-3"><span><b>Select Month</b></span></div>
      <div class="p-col-12 p-md-3">
        <p-calendar formControlName="monthYear" view="month" placeholder="Select Month" dateFormat="mm/yy"
          [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true"></p-calendar>
      </div>
    </div>

    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3"></div>
      <div class="p-col-12 p-md-3" ><label><b>DND Target</b> </label></div>
      <div class="p-col-12 p-md-3"><label> <b>Non DND Target</b></label></div>
      <div class="p-col-12 p-md-3"><label><b>Total</b></label></div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>Sent</label>
      </div>
      <div class="p-col-12 p-md-3 "  >
        <input
        id="input"
        formControlName="sentDnd"
        type="text"
        size="10"
        pInputText
        class="input-number"


      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="sentNonDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3" (click)='toggleDisable()'>
        <input
        id="input"
        formControlName="sentTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>Delivered</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="deliveredDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="deliveredNonDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="deliveredTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>Cost</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="costDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="costNonDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="costTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>Clicks</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="clicksDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="clicksNonDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="clicksTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>Revenue</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="revenueDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="revenueNonDnd"
        type="text"
        class="input-number"
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="revenueTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>PNL</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlDnd"
        type="text"
        class="input-number"
        pInputText
        disabled
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlNonDnd"
        type="text"
        class="input-number"
        pInputText
        disabled
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
        disabled
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>PNL%</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlrDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlrNonDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="pnlrTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>
    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>CTR</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="ctrDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="ctrNonDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="ctrTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>

    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>CPC</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="cpcDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="cpcNonDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="cpcTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>

    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>EPC</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="epcDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="epcNonDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="epcTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
    </div>

    <div class="p-grid up-field">
      <div class="p-col-12 p-md-3">
        <label>EPRS</label>
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="eprsDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="eprsNonDnd"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="p-col-12 p-md-3">
        <input
        id="input"
        formControlName="eprsTotal"
        type="text"
        class="input-number"
        disabled
        pInputText
      />
      </div>
      <div class="listMapper ui-g-1 ui-g-offset-9">
        <button class="submitButton" pButton type="button" (click)="submit()">
          Submit
        </button>
      </div>
      <div class="listMapper ui-g-1 ui-g-offset-0">
        <button class="resetButton" pButton type="button" (click)="reset()">
          Reset
        </button>
      </div>
    </div>


  </form>
</div>
<br><br><br><br><br><br><br><br><br><br>
<!-- Table  -->
<div style="margin-top: 25rem;">
  <span *ngIf="data && data.headers && data.headers.length">
  <p-table
    #dt
    [(columns)]="cols"
    [value]="data.data"
    [paginator]="true"
    [rows]="20"
    paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
    [showCurrentPageReport]="true"
    [globalFilterFields]="allCols"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [loading]="loading"
    (onColReorder)="saveColumnState(dt)"
    [exportFilename]="'Revenue_Report' + fileName || tableName"
    [frozenColumns]="frozenCols"
    frozenWidth="200px"
  >
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="p-grid">
          <div class="ui-g-2">
            <input
              field="text"
              pInputText
              size="50"
              placeholder="Search"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              class="tableGlobalSearch"
              (focus)="isGlobalFilter = !isGlobalFilter"
              (focusout)="isGlobalFilter = !isGlobalFilter"
              style="width: auto;"
            />
            <i class="tableGlobalSearch pi pi-search" style=""></i>
          </div>
          <!-- <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates> -->

          <div class="ui-g-5 filterExportToggle">
            <div class="ui-g-6 extra_input">
              <!-- <p-multiSelect
                class="columnFilter"
                [options]="colFilterOptions"
                [(ngModel)]="cols"
                optionLabel="header"
                selectedItemsLabel="{0} _ selected"
                [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
                defaultLabel="Choose Columns"
                (onClick)="saveColumnState(dt)"
              >
              </p-multiSelect> -->
            </div>

            <div class="ui-g-3 filter_c">
              <!-- <button
                field="button"
                pButton
                icon="pi pi-filter"
                iconPos="left"
                label="Filter"
                (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
              ></button> -->
            </div>

            <!-- <div class="ui-g-3 export_c">
              <button
                field="button"
                pButton
                iconPos="left"
                [disabled]="exporting"
                label="Export"
                (click)="exportData(dt)"
              ></button>
            </div> -->
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [ngStyle]="{
            width: col.type === 'primary' ? '200px' : '110px'
          }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [pTooltip]="showTooltip(col.header)"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pReorderableColumn
          pResizableColumn
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary' ? 'left' : 'right'
          }"
        >
          <span>{{ col.header }}</span>
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
      <tr *ngIf="isFilter">
        <th class="columnFilterTH" *ngFor="let col of columns">
          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            [dismissable]="true"
            appendTo="th"
          >
            <ng-container *ngIf="col.type==='number'"
              ><div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_greater_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Greater Than"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_less_than'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Less Than"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="col?.type==='string'||col.type==='primary'">
              <div [ngStyle]="{ float: 'left', display: 'contents' }">
                {{ col.header }}
              </div>
              <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
                <input
                  [attr.id]="col.field + '_starts_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Starts With"
                />
              </div>
              <div style="margin-bottom: 0.25rem;">
                <input
                  [attr.id]="col.field + '_ends_with'"
                  *ngIf="isFilter"
                  (keyup)="dt.filter(col.field, col.field, 'filterString')"
                  (change)="
                    getFooterData(dt.filteredValue);
                    updateFilterOptions(dt.filteredValue, col.field)
                  "
                  pInputText
                  type="text"
                  placeholder="Ends With"
                />
              </div>
            </ng-container>
            <div>
              <p-multiSelect
                appendTo="body"
                [filter]="isFilter"
                [options]="filterOptions[col.field]"
                [style]="{ width: '100%' }"
                defaultLabel=""
                (onChange)="dt.filter($event.value, col.field, 'in')"
                (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
              ></p-multiSelect>
            </div>
          </p-overlayPanel>
          <p-button
            class="openAdvanceFilterPanel"
            label="⟱"
            (click)="op.toggle($event)"
          ></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign:
              col.type === 'string' || col.type === 'primary'
                ? 'left'
                : col.type === 'any'
                ? 'center'
                : 'right'
          }"
        >
          <span *ngIf="col.type === 'any'" >
            <button
              mat-button
              pTooltip="Edit"
              (click)="editDndNonDndDetails(rowData)"
            >
              <img
                src="assets/icons/edit_w.svg"
                [style]="{ width: '14px', 'margin-left': '.3rem' }"
              />
            </button>
             <!-- <button
            *ngIf="userId === 2 || userId === 127"
            (click)="dc.show($event)"
            mat-button
            pTooltip="Delete"
            style="margin-left: 0.5rem;"
          >
            <img
              src="assets/icons/delete_w.svg"
              [style]="{ width: '.8rem' }"
            />
          </button> -->
           <!-- <p-overlayPanel
          #dc
          [autoZIndex]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
            Delete Reason
          </div>
           <div [ngStyle]="{ 'margin-bottom': '.5rem' }">
            <textarea
              [rows]="1"
              [cols]="30"
              pInputTextarea
              [(ngModel)]="deleteReason"
              autoResize="autoResize"
            ></textarea>
          </div>
          <div>
            <button
              pButton
              [ngStyle]="{
                'background-color': '#e25750',
                'border-color': '#e25750',
                'margin-right': '1rem'
              }"
              (click)="deleteRevenue(rowData)"
              type="button"
              label="Delete"
            ></button>
            <button
              pButton
              (click)="dc.hide()"
              type="button"
              label="Close"
            ></button>
          </div>
        </p-overlayPanel> -->
          </span>
          <span *ngIf="col.type !== 'currency' && col.type !== 'percent' && col.type !== 'number' && col.type !== 'string'">{{
            rowData[col.field] || ""
          }}</span>
              <span *ngIf="col.type === 'currency'">{{
                (rowData[col.field] | currency: "INR":"symbol":"0.2-2") ||
                  (0 | currency: "INR":"symbol":"0.2-2")
              }}</span>
              <span *ngIf="col.type === 'percent'"
                >{{ rowData[col.field] || 0 | number: "0.2-2" }}%</span
              >
              <span *ngIf="col.type === 'number'"
                  >{{ rowData[col.field] || 0 | number: "0.0-0":"en-IN" }}</span
                >
                <span *ngIf="col.type === 'string'"
                  >{{ rowData[col.field] || 'N/A' }}</span>
        </td>
      </tr>
    </ng-template>


  </p-table>
</span></div>

<div *ngIf="data == undefined" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>
<p-toast [style]="{ marginTop: '50px', marginRight: '-15px' }" styleClass="custom-toast" position="top-right" key="tc">
</p-toast>
