import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaBuyingService } from '../media-buying.service';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./../../../../../app.component.css']
})
export class LandingPageComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: { headers: any[], data: any[] }; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription;
  fileName: any;
  tableName: any;
  regionId: any;
  showModel: any = '';
  affiliateId: any = '';
  constructor(
    private mediaBuyingService: MediaBuyingService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {
    this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
   }

   ngOnInit(): void {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
        // Setting Default Date for Data
    if (this.requiredData.data !== undefined)
    {
      this.startDate = this.requiredData.date1;
      this.endDate = this.requiredData.date2;
      this.affiliateId = this.requiredData.data.affiliateId || '';

      this.filterData();
    } else
    {
      this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
      this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
      this.filterData();
    }

    FilterUtils['filterNumber'] = (value, filter): boolean =>
    {
      //
      let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
      let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null))
      {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan))
      {
        return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
      } else if (Number(greaterThan))
      {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan))
      {
        return FilterUtils.lt(value, lessThan);
      } else
      {
        return value;
      }
    };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }
    // For Showing Filter Model
    letShowModel(column: any, value: any)
    {
      return value === column ? false : true;
    }
    // For Showing Filter Model
    setShowModelValue(columnValue: any)
    {
      return this.showModel = columnValue;
    }
    // For Hiding Filter Model
    hideDialog()
    {
      return this.showModel = '';
    }

    // For getting string length
    getLength(text: any)
    {
      return String(text).length > 10 ? text : '';
    }
    // Filter Data Based on Date
    filterData(cols?: any)
    {

      // this.updateStateCols(cols);
      this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
      this.tableName = "Leads_" + this.fileName;
      if (this.endDate && !this.isFilter)
      {
        this.loading = true;
        // Passing Updated/Filtered Date
        this.bodyObject = {
          start_date: this.startDate,
          end_date: this.endDate,
          affiliateId :this.affiliateId
        };
        // Getting Data From Server
        if (this.bodyObject && !this.isFilter)
        {
          this.subscription = this.mediaBuyingService.getLandingPageDetails(this.bodyObject).subscribe(responseData =>
          {
            if (responseData['error'] === 'Expired token')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
              return this.utilService.logedOut();
            }
            if (responseData['code'])
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              return null;
            }
            this.data = responseData as { headers: any[], data: any[] };
            // All Columns of Table
            this.allCols = this.data.headers;

            // Frized Columns
            this.frozenCols = this.data.headers.filter(col => col.type === 'primary');

            // All Columns for Filter Options
            this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

            // First Time Showed Columns
            this.cols = localStorage.landingPage ? JSON.parse(localStorage.landingPage) : this.data.headers.filter(col => col.type !== 'primary');

            this.loading = false;
            this.footerValues = { undefined };
            if (this.data.data.length !== 0)
            {
              this.getFooterData(this.data.data);
              this.filterOptions = this.utilService.getRequiredOptions(
                this.data.data,
                this.data.data[0]
              );
            } else
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
            }
          }, err =>
          {
            if (err)
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              this.loading = false;
              return null;
            }
          });
        }

      }
    }
          // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
    }
  }
  // Update Footer Values
  getFooterData(tableData)
  {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }


  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }
    // Getting Dates from Dates Component
    getDates(datesObj)
    {
      if (datesObj)
      {
        this.startDate = datesObj.start_date;
        this.endDate = datesObj.end_date;
        this.filterOptions = [];
        this.filterData();
      }
    }
    // Columns State Save
    saveColumnState(dt: any)
    {
      localStorage.landingPage = JSON.stringify(dt.columns);
    }

    // Adding Column For Exporting in Csv
    exportData(dt)
    {
      dt.columns.splice(0, 0, this.frozenCols[0]);
      dt.exportCSV();
      return dt.columns.splice(0, 1);
    }

    // For getting string length(Tooltip)
    showTooltip(text: any)
    {
      return text && (String(text).length > 9) ? text : '';
    }
        // Destroy all subscriptons
    ngOnDestroy(): void
    {
      this.subscription.unsubscribe();
    }


}
