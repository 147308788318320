<p-table
  #dt
  [(columns)]="cols"
  [value]="data"
  [paginator]="true"
  [rows]="20"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"
  [showCurrentPageReport]="true"
  [globalFilterFields]="allCols"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [loading]="loading"
  [exportFilename]="
    tabInformation.tabType + tabInformation.tabHeader || tableName
  "
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix">
      <div class="p-grid">
        <div class="ui-g-2">
          <input
            field="text"
            pInputText
            size="50"
            placeholder="Search"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            class="tableGlobalSearch"
            (focus)="isGlobalFilter = !isGlobalFilter"
            (focusout)="isGlobalFilter = !isGlobalFilter"
            style="width: auto;"
          />
          <i class="tableGlobalSearch pi pi-search" style=""></i>
        </div>
        <!-- <app-dates
            class="ui-g-5"
            [getDates]="{ start_date: startDate, end_date: endDate }"
            (pullDates)="getDates($event)"
          ></app-dates> -->

        <div class="ui-g-10 filterExportToggle">
          <div class="ui-g-6 extra_input">
            <p-multiSelect
              class="columnFilter"
              [options]="colFilterOptions"
              [(ngModel)]="cols"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ minWidth: '200px', 'margin-right': '1.5rem' }"
              defaultLabel="Choose Columns"
            >
            </p-multiSelect>
          </div>

          <div class="ui-g-3 filter_c">
            <button
              field="button"
              pButton
              icon="pi pi-filter"
              iconPos="left"
              label="Filter"
              (click)="isFilter = !isFilter; dt.reset(); filterData(dt)"
            ></button>
          </div>

          <div class="ui-g-3 export_c">
            <button
              field="button"
              pButton
              iconPos="left"
              label="Export"
              (click)="exportData(dt)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        [pSortableColumn]="col.field"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pTooltip]="showTooltip(col.header)"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        pResizableColumn
        [ngStyle]="{
          textAlign: col.field === 'version' ? 'right' : 'left'
        }"
      >
        <span *ngIf="col.field !== 'remarks'">{{ col.header }}</span>
        <div
          *ngIf="col.field === 'remarks'"
          style="text-align: center; width: 100%; height: 100%;"
        >
          {{ col.header }}
        </div>
        <p-sortIcon
          *ngIf="col.field !== 'remarks'"
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="isFilter">
      <th class="columnFilterTH" *ngFor="let col of columns">
        <p-overlayPanel
          #op
          [showCloseIcon]="true"
          [dismissable]="true"
          appendTo="th"
        >
          <ng-container *ngIf="col.type"
            ><div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_greater_than'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Greater Than"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_less_than'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterNumber')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Less Than"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!col.type">
            <div [ngStyle]="{ float: 'left', display: 'contents' }">
              {{ col.header }}
            </div>
            <div style="margin-bottom: 0.25rem; margin-top: 0.5rem;">
              <input
                [attr.id]="col.field + '_starts_with'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Starts With"
              />
            </div>
            <div style="margin-bottom: 0.25rem;">
              <input
                [attr.id]="col.field + '_ends_with'"
                *ngIf="isFilter"
                (keyup)="dt.filter(col.field, col.field, 'filterString')"
                (change)="
                  getFooterData(dt.filteredValue);
                  updateFilterOptions(dt.filteredValue, col.field)
                "
                pInputText
                type="text"
                placeholder="Ends With"
              />
            </div>
          </ng-container>
          <div>
            <p-multiSelect
              appendTo="body"
              [filter]="isFilter"
              [options]="filterOptions[col.field]"
              [style]="{ width: '100%' }"
              defaultLabel=""
              (onChange)="dt.filter($event.value, col.field, 'in')"
              (onPanelHide)="updateFilterOptions(dt.filteredValue, col.field)"
            ></p-multiSelect>
          </div>
        </p-overlayPanel>
        <p-button
          class="openAdvanceFilterPanel"
          label="⟱"
          (click)="op.toggle($event)"
        ></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [pTooltip]="showTooltip(rowData[col.field])"
        *ngFor="let col of columns"
        [ngStyle]="{
          textAlign: col.field === 'version' ? 'right' : 'left'
        }"
      >
        <span
          *ngIf="
            rowData[col.field] !== 'Undefined' && col.header === 'Creative Name'
          "
        >
          <button
            mat-button
            pTooltip="View Creative"
            (click)="
              addNewTab('Creative_View - ', rowData['creative_name'], rowData)
            "
          >
            <img
              src="assets/icons/creative_view_w.svg"
              [style]="{ width: '.8rem' }"
            />
          </button>
        </span>
        <span
          *ngIf="
            col.field !== 'created_date' &&
            col.field !== 'modified_date' &&
            col.field !== 'created_by' &&
            col.field !== 'modified_by' &&
            col.field !== 'active' &&
            col.field !== 'reason' &&
            col.field !== 'remarks'
          "
        >
          {{ rowData[col.field] || "" }}
        </span>
        <span
          *ngIf="col.field === 'created_date' || col.field === 'modified_date'"
        >
          <span *ngIf="rowData[col.field]">{{
            getDateFormat(rowData[col.field])
          }}</span>
          <span *ngIf="!rowData[col.field]">{{
            getDateFormat(rowData["created_date"])
          }}</span>
        </span>
        <span *ngIf="col.field === 'created_by' || col.field === 'modified_by'">
          <span *ngIf="rowData[col.field]">{{ rowData[col.field] }}</span>
          <span *ngIf="!rowData[col.field]">{{ rowData["created_by"] }}</span>
        </span>
        <span *ngIf="col.field === 'reason'">
          {{ rowData[col.field] }}
        </span>
        <span *ngIf="col.field === 'active'">
          {{ rowData[col.field] === '"0"' ? "Inactive" : "Active" }}
        </span>
        <div *ngIf="col.field === 'remarks'" style="text-align: center;">
          <button mat-button pTooltip="Download" tooltipPosition="left">
            <a
              href="https://wts.rightfullabs.com/apis/creatives/{{
                rowData['file_path']
              }}.zip"
            >
              <img
                src="assets/icons/download_w.svg"
                [style]="{ width: '.8rem' }"
            /></a>
          </button>
          <!-- <button
            (click)="updateCreativeDetails(rowData)"
            mat-button
            pTooltip="Edit"
            style="margin-left: .5rem"
          >
            <img src="assets/icons/edit_w.svg" [style]="{ width: '.8rem' }" />
          </button> -->
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- <ng-template pTemplate="footer" let-columns>
      <tr>
        <td
          [pTooltip]="showTooltip(rowData[col.field])"
          *ngFor="let col of columns"
          [ngStyle]="{
            textAlign: col.field === 'version' ? 'right' : 'left'
          }"
        >
          <span *ngIf="col.field !== 'creative_name'">
            {{ footerValues[col.field] || 0 }}
          </span>

          <span class="footerGrandTotal" *ngIf="col.field === 'creative_name'">
            Grand Total
          </span>
        </td>
      </tr>
    </ng-template> -->
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="(data | json) !== '[]'">
      Showing {{ (dt._totalRecords !== 0 ? dt._first : 0) | json }}&#8210;{{
        (dt._first + dt._rows < dt._totalRecords
          ? dt._rows + dt._first
          : dt._totalRecords
        ) | json
      }}
      entries
      {{
        isFilter || isGlobalFilter
          ? "(" + dt._totalRecords + " filtered records)"
          : ""
      }}
      from {{ (data !== undefined ? data.length : 0) | json }} records
    </span>
  </ng-template>
</p-table>
<p-toast
  [style]="{ marginTop: '50px', marginRight: '-15px' }"
  styleClass="custom-toast"
  position="top-right"
  key="tc"
></p-toast>
