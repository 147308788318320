import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../../../../utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class OfferGroupService
{
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient,private utilService: UtilService)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Offer Group Performance",
      isClosable: false
    });
  }
  portalStatus()
  {
    return this.utilService.portalStatus();
  }

  getOfferGroupPerformance(body: any)
  {
    if(this.portalStatus() === "Email"){
      return this.http.post(this.baseURL + "ongage/offerGroupPerformance", body);
    }
    if(this.portalStatus() === "Sms")
    {
      return this.http.post(this.baseURL + "sms/getOfferGroupPerformance", body);
    }

  }
  getofferGroupMspReport(body: any)
  {
    return this.http.post(this.baseURL + "sms/getofferGroupMspReport", body);
  }

}
