import { teamModel } from 'src/app/homepage/reports/team/teammodels/model';
import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ComplianceService } from '../../complianceservices/compliance.service';
import { UtilService } from 'src/app/utils/util.service';
import { FilterUtils } from 'primeng/utils';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-network-updates',
  templateUrl: './network-updates.component.html',
  styleUrls: ['./../../../../../app.component.css']
})
export class NetworkUpdatesComponent implements OnInit
{

  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  @ViewChild('upload') upload: any;
  // platformOptions: any[] = [];
  descriptionOptions: any[] = [];
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: teamModel[]; // Data Storing for Table
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions: any = {};  // Data Storing for Column Unique Values
  footerValues: any = {}; // Data Storing for Column Footer
  frozenCols: any[]; // for Frized Columns
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  fileName: any;
  tableName: any;
  teamId: any;
  searchValue: any = '';
  complianceId: any = ''
  networkName: any = '';
  networkOptions: any[] = [];
  complianceForm: FormGroup;
  showModel: any = '';
  userId: any = '';
  constructor(private service: ComplianceService, private utilService: UtilService, private messageService: MessageService, private fb: FormBuilder) { }

  ngOnInit()
  {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    this.complianceForm = this.fb.group({
      creativeName: ['', [Validators.required]],
      network: [''],
      description: ['']
      // platform: ['', Validators.required],
      // creativeVersion: ['', Validators.required]
    });

    // All Columns of Table
    this.allCols = this.service.networkUpdates.allCols;

    // Frized Columns
    this.frozenCols = [{ field: "network_name", header: "Network Name" }];

    // All Columns for Filter Options
    this.colFilterOptions = this.service.networkUpdates.colFilterOptions;

    // First Time Showed Columns
    this.cols = localStorage.networkUpdates ? JSON.parse(localStorage.networkUpdates) : this.service.networkUpdates.cols;

    // Setting Default Date for Data
    // if (this.requiredData.data !== undefined) {
    //   this.teamId = this.requiredData.data.team_id || '';
    // this.networkId = this.requiredData.data.network_id || '';
    // this.offerId = this.requiredData.data.offer_id || '';
    // this.startDate = this.requiredData.date1;
    // this.endDate = this.requiredData.date2;
    //   this.filterData();
    // } else {
    // this.teamId = this.utilService.loginData.team_id || '';
    //   this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    //   this.endDate = moment().format('YYYY-MM-DD');
    //   this.filterData();
    // }
    this.teamId = this.utilService.loginData.team_id;
    if (!(this.teamId === 8 || this.teamId === 2020))
    {
      this.colFilterOptions.splice(this.allCols.length - 1, 1);
      this.cols.splice(this.cols.length - 1, 1);
    }
    this.userId = this.utilService.loginData.user_id;
    this.filterData();
    this.getNetworkOptions();
    // FilterUtils['filterNumber'] = (value, filter): boolean => {
    // 
    //   let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
    //   let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

    //   if (value === undefined || value === null) {
    //     return false;
    //   }
    //   if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null)) {
    //     return value;
    //   }
    //   if (Number(greaterThan) && Number(lessThan)) {
    //     return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
    //   } else if (Number(greaterThan)) {
    //     return FilterUtils.gt(value, greaterThan);
    //   } else if (Number(lessThan)) {
    //     return FilterUtils.lt(value, lessThan);
    //   } else {
    //     return value;
    //   }
    // };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }

  // For showing editing or update options
  getTeamId(): boolean
  {
    return (this.teamId === 8 || this.teamId === 2020 || this.userId === 46 || this.userId === 127)
  }

  get network()
  {
    return this.complianceForm.get('network');
  }
  get description()
  {
    return this.complianceForm.get('description');
  }
  // get platform() {
  //   return this.complianceForm.get('platform');
  // }
  // get creativeVersion() {
  //   return this.complianceForm.get('creativeVersion');
  // }
  // get creativeFiles() {
  //   return this.complianceForm.get('creativeFiles');
  // }

  // Filter Data Based on Date
  filterData(cols?: any)
  {

    // this.updateStateCols(cols);
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "Creatives_" + this.fileName;
    if (!this.isFilter)
    {
      this.loading = true;
      // Passing Updated/Filtered Date
      // this.bodyObject = {
      //   start_date: this.startDate,
      //   end_date: this.endDate,
      // };
      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.service.getNetworkUpdatesData().subscribe((responseData: any) =>
        {
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          // console.log(responseData);
          this.data = responseData as teamModel[];
          this.loading = false;
          this.footerValues = { undefined };
          if (this.data.length !== 0)
          {
            // this.getFooterData(this.data);
            this.filterOptions = this.utilService.getRequiredOptions(
              this.data,
              this.data[0]
            );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        }));
      }

    }
  }

  // For Updating Data According to Search Value
  // updateData() {
  //   if (this.searchValue) {
  //     this.filterData();
  //   }
  // }

  // Update Filter Options
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      // this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data, colName);
    }
  }




  // Update Footer Values
  // getFooterData(tableData) {
  //   this.footerValues = this.utilService.getRequiredFooterData(tableData);
  // }


  // Add New Tab in Tab Array
  addNewTab(type, header, data)
  {
    this.AddTabController.emit({ type, header, data });
  }

  // Getting Dates from Dates Component
  // getDates(datesObj) {
  //   if (datesObj) {
  //     this.startDate = datesObj.start_date;
  //     this.endDate = datesObj.end_date;
  //     this.isFilter1 = false;
  //     this.isFilter2 = false;
  //     this.filterData();
  //   }

  // }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.networkUpdates = JSON.stringify(dt.columns);
  }

  // Adding Column For Exporting in Csv
  exportData(dt)
  {
    dt.columns.splice(0, 0, this.frozenCols[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }

  // For Converting Date Format
  getDateFormat(date: any)
  {
    return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM, YYYY (hh:mm a)');
  }

  // For Getting Network Options
  getNetworkOptions()
  {
    this.service.getNetworkOptions().subscribe((data: any[]) =>
    {
      if (data.length != undefined)
      {
        data.forEach(optionRow =>
          this.networkOptions.push({
            label: optionRow.network_name, value: optionRow.network_id
          })
        );
      }
    });
  }

  // For Inserting/Updating compliance details
  insertNetworkUpdates()
  {
    if (!this.network.value)
    {
      return this.messageService.add({ key: 'tc', severity: 'error', summary: 'Network Required', detail: 'Please select a network!' });
    }
    if (!this.description.value)
    {
      return this.messageService.add({ key: 'tc', severity: 'error', summary: 'Description Required', detail: 'Please input valid description' });
    }
    const body = {
      id: this.complianceId,
      network_id: this.network.value,
      network_name: this.networkName,
      updates: this.description.value,
      created_by: this.utilService.loginData.user_id
    };
    this.subscription.push(this.service.insertNetworkUpdatesData(body).subscribe((response: any) =>
    {
      if (response.code === 200)
      {
        this.filterData();
        if (this.complianceId)
        {
          this.reset();
          return this.messageService.add({ key: 'tc', severity: 'success', summary: 'Record Updated', detail: 'Network compliance updated successfully!' });
        } else
        {
          this.reset();
          return this.messageService.add({ key: 'tc', severity: 'success', summary: 'Record Saved', detail: 'Network compliance inserted successfully!' });
        }
      } else
      {
        return this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
      }
    }, err =>
    {
      if (err)
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
        return null;
      }
    }));
  }

  // Get network name
  getNetworkNameText(event: any)
  {
    this.networkName = event.originalEvent.currentTarget.innerText;
  }

  // For filling pre values in input fields
  updateComplianceDetails(rowData: any)
  {
    if (rowData)
    {
      this.network.setValue(rowData.network_id);
      this.networkName = rowData.network_name;
      this.description.setValue(rowData.updates);
      this.complianceId = rowData.id;
      // this.creativeVersion.setValue(rowData.version);
      // this.descriptionOptions = [{ label: rowData.description_name, value: rowData.description_id }];
      // this.platformOptions = [{ label: rowData.esp_connection_name, value: rowData.esp_connection_id }];
      // this.description.setValue(rowData.description_id);
      // this.platform.setValue(rowData.esp_connection_id);
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Selected', detail: 'Values have selected successfully!' });
    }
  }

  // For Clearing Form Fields
  reset()
  {
    // this.complianceForm.reset();
    this.network.setValue('');
    this.description.setValue('');
    this.complianceId = '';
    this.networkName = '';
  }


  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }

}
