import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
@Injectable({
  providedIn: 'root'
})
export class CreativelibraryService
{
  baseURL = environment.baseURL;
  isExpanded = true;
  tabs: any[] = [];
  constructor(private http: HttpClient)
  {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Creatives",
      isClosable: false
    });
  }

  getVerticalOptions()
  {
    return this.http.get(this.baseURL + "getCakeData/get_vertical");
  }
  getRegionOptions()
  {
    return this.http.get(this.baseURL + "getCakeData/get_region");
  }

  // getPlatformOptions() {
  //   return this.http.get(this.baseURL + "ongage/get_esp_connections");
  // }

  getNetworkOptions()
  {
    return this.http.get(this.baseURL + "getCakeData/get_advertiser");
  }

  postCreativeData(body: any)
  {
    return this.http.post(this.baseURL + 'creatives/add', body);
  }

  updateCreativeData(body: any)
  {
    return this.http.post(this.baseURL + 'creatives/update', body);
  }

  getCreativeData(body: any)
  {
    return this.http.get(this.baseURL + "creatives/creative_list/" + body.startDate + "/" + body.endDate + "/" + body.search_value);
  }

  getCreativeScoring(body: any)
  {
    return this.http.post(this.baseURL + "getData/creativeScoring/", body);
  }

  getCreativeLogsData(creativeId: any)
  {
    return this.http.get(this.baseURL + "creatives/logs/" + creativeId);
  }

  getCreativeHtml(creativeId: string)
  {
    return this.http.get(this.baseURL + "creatives/creative_detail/" + creativeId);
  }

  changeCreativeStatus(body: string)
  {
    return this.http.post(this.baseURL + "creatives/changeStatus/", body);
  }

  deleteCreative(body: string)
  {
    return this.http.post(this.baseURL + "creatives/deleteCreative/", body);
  }

}
