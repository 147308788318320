<!-- <p-tabView [(activeIndex)]="activeIndex" (onClose)="closeTab($event)">
  <p-tabPanel
    [header]="tab.header"
    *ngFor="let tab of tabs; let i = index"
    [closable]="tab.isClosable"
    [selected]="i === activeIndex"
  >
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Team Table'">
        <app-teamtable
          [requiredData]="{tab.startDate,tab.endDate,tab.data}"
          (AddTabController)="addTabInArray($event)"
        ></app-teamtable>
      </p>
      <p *ngSwitchCase="'Offer Table'">
        <app-offertable
          [requiredData]="{tab.startDate,tab.endDate,tab.data}"
          (AddTabController)="addTabInArray($event)"
        ></app-offertable>
      </p>
      <p *ngSwitchCase="'Dashboard'">
        <app-teamtable
          [requiredData]="{tab.startDate,tab.endDate,tab.data}"
          (AddTabController)="addTabInArray($event)"
        ></app-teamtable>
      </p>

    </div>

  </p-tabPanel>
</p-tabView> -->

<mat-tab-group
  [style.width]="isExpanded() ? '100%' : '100%'"
  [(selectedIndex)]="activeIndex"
>
  <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.header">
    <ng-template mat-tab-label
      >{{ tab.type }}{{ tab.header }}
      <mat-icon *ngIf="tab.isClosable" (click)="closeTab(i)">close</mat-icon>
    </ng-template>
    <div [ngSwitch]="tab.type">
      <p *ngSwitchCase="'Teams'">
        <app-teamtable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-teamtable>
      </p>
      <p *ngSwitchCase="'Offers - '">
        <app-offertable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-offertable>
      </p>
      <p *ngSwitchCase="'Lists - '">
        <app-listtable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-listtable>
      </p>
      <p *ngSwitchCase="'Segments - '">
        <app-segmenttable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-segmenttable>
      </p>
      <p *ngSwitchCase="'Campaigns - '">
        <app-campaigntable
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-campaigntable>
      </p>
      <p *ngSwitchCase="'Creatives - '">
        <app-creative-performance
          [tabInformation]="{ tabType: tab.type, tabHeader: tab.header }"
          [requiredData]="{
            date1: tab.startDate,
            date2: tab.endDate,
            data: tab.data
          }"
          (AddTabController)="addTabInArray($event)"
        ></app-creative-performance>
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
