export class PublisherCreative {
    allCols: any[] = [];
    colFilterOptions: any[] = [];
    cols: any[] = [];
    constructor() {
        // for Daily Creative Sheet Main Columns
        this.allCols = [
            { field: "creative_name", header: "Creative Name", type: false },
            { field: "publisher_name", header: "Publisher Name", type: false },
            { field: "report_date", header: "Date", type: false },
            { field: "report_day", header: "Day", type: false },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "offer_name", header: "Offer Name", type: false },
            { field: "network_name", header: "Network Name", type: false },
            { field: "from_line", header: "From Line", type: false },
            { field: "subject_line", header: "Subject Line", type: false },
            { field: "sent_count", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "open_rate", header: "Open%", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "click_rate", header: "CTR", type: true },
            { field: "network_clicks", header: "N/W Click", type: true },
            { field: "unsub", header: "Unsub", type: true },
            { field: "unsub_rate", header: "Unsub%", type: true },
            { field: "utr", header: "UTR", type: true },
            { field: "conversion", header: "Conversions", type: true },
            { field: "conversion_rate", header: "Conversion%", type: true },
            { field: "revenue", header: "Revenue", type: true },
            { field: "epc", header: "EPC", type: true },
            { field: "epl", header: "EPL", type: true },
            { field: "ecpm", header: "ECPM", type: true },
            { field: "bounce", header: "Bounce", type: true },
            { field: "spam", header: "Spams", type: true },
            { field: "created_by", header: "Uploaded By", type: false }
        ];
        // for Daily Creative Sheet Main Columns Filter Options
        this.colFilterOptions =
            [
                { field: "publisher_name", header: "Publisher Name", type: false },
                { field: "report_date", header: "Date", type: false },
                { field: "report_day", header: "Day", type: false },
                { field: "segment_name", header: "Segment Name", type: false },
                { field: "offer_name", header: "Offer Name", type: false },
                { field: "network_name", header: "Network Name", type: false },
                { field: "from_line", header: "From Line", type: false },
                { field: "subject_line", header: "Subject Line", type: false },
                { field: "sent_count", header: "Sent", type: true },
                { field: "opens", header: "Opens", type: true },
                { field: "open_rate", header: "Open%", type: true },
                { field: "clicks", header: "Clicks", type: true },
                { field: "click_rate", header: "CTR", type: true },
                { field: "network_clicks", header: "N/W Click", type: true },
                { field: "unsub", header: "Unsub", type: true },
                { field: "unsub_rate", header: "Unsub%", type: true },
                { field: "utr", header: "UTR", type: true },
                { field: "conversion", header: "Conversions", type: true },
                { field: "conversion_rate", header: "Conversion%", type: true },
                { field: "revenue", header: "Revenue", type: true },
                { field: "epc", header: "EPC", type: true },
                { field: "epl", header: "EPL", type: true },
                { field: "ecpm", header: "ECPM", type: true },
                { field: "bounce", header: "Bounce", type: true },
                { field: "spam", header: "Spams", type: true },
                { field: "created_by", header: "Uploaded By", type: false }
            ];
        // for Daily Creative Sheet Main Columns First Show
        this.cols = [
            { field: "publisher_name", header: "Publisher Name", type: false },
            { field: "report_date", header: "Date", type: false },
            { field: "report_day", header: "Day", type: false },
            { field: "segment_name", header: "Segment Name", type: false },
            { field: "offer_name", header: "Offer Name", type: false },
            { field: "network_name", header: "Network Name", type: false },
            { field: "from_line", header: "From Line", type: false },
            { field: "subject_line", header: "Subject Line", type: false },
            { field: "sent_count", header: "Sent", type: true },
            { field: "opens", header: "Opens", type: true },
            { field: "clicks", header: "Clicks", type: true },
            { field: "network_clicks", header: "N/W Click", type: true },
            { field: "unsub", header: "Unsub", type: true },
            { field: "conversion", header: "Conversions", type: true },
            { field: "revenue", header: "Revenue", type: true },
        ];
    }
}