import { teamModel } from "src/app/homepage/reports/team/teammodels/model";
import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators,FormControl } from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { MappingService } from "./../../../reports/mapping/mappingservices/mapping.service";
import { UtilService } from "src/app/utils/util.service";
import { Papa } from "ngx-papaparse";
import { MessageService } from "primeng";
import { isString } from 'util';
import { FilterUtils } from 'primeng/utils';
import { NgControl } from '@angular/forms';
@Component({
  selector: "app-dnd-non-dnd-target",
  templateUrl: "./dnd-non-dnd-target.component.html",
  styleUrls: ["../../../../app.component.css"],
})
export class DndNonDndTargetComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  @Input() disabled = true;
  regiform: FormGroup; // for Form Reference
  form: any;
  options: any[] = [];   // for Creating Options for Ongage List
  offerOptions: any[] = [];   // for Creating Options for Ongage List
  networkId: any;  // for storing network id value
  offerId: any;  // for storing network id vaslue
  cols: any[];   // Array for Showed Columns
  allCols: any[];  // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: any = {};  // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  loading: boolean;  // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {};  // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = '';
  networkName: any = '';
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  minDate: Date = moment('01-05-2019', 'DD-MM-YYYY').toDate();
  maxDate: Date = moment().subtract(1, 'day').toDate();
  user_id: any = 0;
  dndNonDndId:any;
  fileName: any;
  teamId: any;
  tableName: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  footerValues: any = {}; // Data Storing for Column Footer
  revenue_id:any;
  num1:any;
  num2:any;
  userId: any = 0;
  deleteReason: any = '';
  fileType:string;
  permissibleMember:string;
  tableConfig:any;
  disableTextbox =  false;
  exporting: boolean;

  constructor(
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private Papa: Papa

  ) {
      // Dates Array for Date Selection
      this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
      this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  ngOnInit(): void {

    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    this.startDate = moment().subtract(1,'day').format('YYYY-MM-DD');
    this.endDate = moment().subtract(1,'day').format('YYYY-MM-DD');
    this.regiform = this.fb.group({
      monthYear: [null, Validators.required],
      sentDnd: [null, Validators.required],
      sentNonDnd: [null, Validators.required],
      sentTotal: [null, Validators.required],
      deliveredDnd: [null, Validators.required],
      deliveredNonDnd: [null, Validators.required],
      deliveredTotal: [null, Validators.required],
      costDnd: [null, Validators.required],
      costNonDnd: [null, Validators.required],
      costTotal: [null, Validators.required],
      clicksDnd: [null, Validators.required],
      clicksNonDnd: [null, Validators.required],
      clicksTotal: [null, Validators.required],
      ctrDnd: [null, Validators.required],
      ctrNonDnd: [null, Validators.required],
      ctrTotal: [null, Validators.required],
      cpcDnd: [null, Validators.required],
      cpcNonDnd: [null, Validators.required],
      cpcTotal: [null, Validators.required],
      revenueDnd: [null, Validators.required],
      revenueNonDnd: [null, Validators.required],
      revenueTotal: [null, Validators.required],
      epcDnd: [null, Validators.required],
      epcNonDnd: [null, Validators.required],
      epcTotal: [null, Validators.required],
      pnlDnd: [null, Validators.required],
      pnlNonDnd: [null, Validators.required],
      pnlTotal: [null, Validators.required],
      pnlrDnd: [null, Validators.required],
      pnlrNonDnd: [null, Validators.required],
      pnlrTotal: [null, Validators.required],
      eprsDnd: [null, Validators.required],
      eprsNonDnd: [null, Validators.required],
      eprsTotal: [null, Validators.required],
    });

    this.userId = this.utilService.loginData.user_id;

    this.sentDnd.valueChanges.subscribe((value) =>
      this.sentTotal.setValue(Number(this.sentNonDnd.value) + Number(value))
    );
    this.sentNonDnd.valueChanges.subscribe((value) =>
      this.sentTotal.setValue(Number(this.sentDnd.value) + Number(value))
    );

    this.deliveredDnd.valueChanges.subscribe((value) =>
      this.deliveredTotal.setValue(
        Number(this.deliveredNonDnd.value) + Number(value)
      )
    );
    this.deliveredNonDnd.valueChanges.subscribe((value) =>
      this.deliveredTotal.setValue(
        Number(this.deliveredDnd.value) + Number(value)
      )
    );

    this.costDnd.valueChanges.subscribe((value) =>
      this.costTotal.setValue(Number(this.costNonDnd.value) + Number(value))
    );
    this.costNonDnd.valueChanges.subscribe((value) =>
      this.costTotal.setValue(Number(this.costDnd.value) + Number(value))
    );

    this.clicksDnd.valueChanges.subscribe((value) =>
      this.clicksTotal.setValue(Number(this.clicksNonDnd.value) + Number(value))
    );
    this.clicksNonDnd.valueChanges.subscribe((value) =>
      this.clicksTotal.setValue(Number(this.clicksDnd.value) + Number(value))
    );
        // CTR DND Target  ..........
    this.sentDnd.valueChanges.subscribe((value) =>
      this.ctrDnd.setValue(Number(this.clicksDnd.value) / (this.deliveredDnd.value))
    );
    this.clicksDnd.valueChanges.subscribe((value) =>
      this.ctrDnd.setValue(Number(this.clicksDnd.value) / (this.deliveredDnd.value))
    );
    // CTR Non DND Target  ..........

    this.sentNonDnd.valueChanges.subscribe((value) =>
    this.ctrNonDnd.setValue(Number(this.clicksNonDnd.value) / (this.deliveredNonDnd.value))
  );
  this.clicksNonDnd.valueChanges.subscribe((value) =>
    this.ctrNonDnd.setValue(Number(this.clicksNonDnd.value) / (this.deliveredNonDnd.value))
  );

    // CTR Total Target  ..........
    this.sentTotal.valueChanges.subscribe((value) =>
    this.ctrTotal.setValue(Number(this.clicksTotal.value) / (this.deliveredTotal.value))
  );
  this.clicksTotal.valueChanges.subscribe((value) =>
    this.ctrTotal.setValue(Number(this.clicksTotal.value) / (this.deliveredTotal.value))
  );

  // CPC Dnd Target  ..........
  this.costDnd.valueChanges.subscribe((value) =>
  this.cpcDnd.setValue(Number(this.costDnd.value) / (this.clicksDnd.value))
  );
  this.clicksDnd.valueChanges.subscribe((value) =>
  this.cpcDnd.setValue(Number(this.costDnd.value) / (this.clicksDnd.value))
  );

    // CPC Non Dnd Target  ..........
    this.costNonDnd.valueChanges.subscribe((value) =>
    this.cpcNonDnd.setValue(Number(this.costNonDnd.value) / (this.clicksNonDnd.value))
    );
    this.clicksNonDnd.valueChanges.subscribe((value) =>
    this.cpcNonDnd.setValue(Number(this.costNonDnd.value) / (this.clicksNonDnd.value))
    );

    // CPC Total Target  ..........
    this.costTotal.valueChanges.subscribe((value) =>
    this.cpcTotal.setValue(Number(this.costTotal.value) / (this.clicksTotal.value))
    );
    this.clicksNonDnd.valueChanges.subscribe((value) =>
    this.cpcTotal.setValue(Number(this.costTotal.value) / (this.clicksTotal.value))
    );

    this.revenueDnd.valueChanges.subscribe((value) =>
      this.revenueTotal.setValue(
        Number(this.revenueNonDnd.value) + Number(value)
      )
    );
    this.revenueNonDnd.valueChanges.subscribe((value) =>
      this.revenueTotal.setValue(Number(this.revenueDnd.value) + Number(value))
    );


    // EPC Dnd Target  ..........

    this.revenueDnd.valueChanges.subscribe((value) =>
    this.epcDnd.setValue(Number(this.revenueDnd.value) / (this.clicksDnd.value))
    );
    this.clicksDnd.valueChanges.subscribe((value) =>
    this.epcDnd.setValue(Number(this.revenueDnd.value) / (this.clicksDnd.value))
    );

    // EPC Non Dnd Target  ..........

    this.revenueNonDnd.valueChanges.subscribe((value) =>
    this.epcNonDnd.setValue(Number(this.revenueNonDnd.value) / (this.clicksNonDnd.value))
    );
    this.clicksNonDnd.valueChanges.subscribe((value) =>
    this.epcNonDnd.setValue(Number(this.revenueNonDnd.value) / (this.clicksNonDnd.value))
    );

    // EPC Total Target  ..........

    this.revenueTotal.valueChanges.subscribe((value) =>
    this.epcTotal.setValue(Number(this.revenueTotal.value) / (this.clicksTotal.value))
    );
    this.clicksTotal.valueChanges.subscribe((value) =>
    this.epcTotal.setValue(Number(this.revenueTotal.value) / (this.clicksTotal.value))
    );

    this.pnlDnd.valueChanges.subscribe((value) =>
      this.pnlTotal.setValue(Number(this.pnlNonDnd.value) + Number(value))
    );
    this.pnlNonDnd.valueChanges.subscribe((value) =>
      this.pnlTotal.setValue(Number(this.pnlDnd.value) + Number(value))
    );

    // PNL% Dnd Target  ..........

    this.pnlDnd.valueChanges.subscribe((value) =>
    this.pnlrDnd.setValue(Number(this.pnlDnd.value) / (this.costDnd.value)*100)
    );
    this.costDnd.valueChanges.subscribe((value) =>
    this.pnlrDnd.setValue(Number(this.pnlDnd.value) / (this.costDnd.value)*100)
    );

    // PNL% Non Dnd Target  ..........

    this.pnlNonDnd.valueChanges.subscribe((value) =>
    this.pnlrNonDnd.setValue(Number(this.pnlNonDnd.value) / (this.costNonDnd.value)*100)
    );
    this.costNonDnd.valueChanges.subscribe((value) =>
    this.pnlrNonDnd.setValue(Number(this.pnlNonDnd.value) / (this.costNonDnd.value)*100)
    );

    // PNL% Total Target  ..........

    this.pnlTotal.valueChanges.subscribe((value) =>
    this.pnlrTotal.setValue(Number(this.pnlTotal.value) / (this.costTotal.value)*100)
    );
    this.costTotal.valueChanges.subscribe((value) =>
    this.pnlrTotal.setValue(Number(this.pnlTotal.value) / (this.costTotal.value)*100)
    );

    // PNL Dnd Target  ..........

    this.revenueDnd.valueChanges.subscribe((value) =>
    this.pnlDnd.setValue(Number(this.revenueDnd.value) - (this.costDnd.value))
    );
    this.costDnd.valueChanges.subscribe((value) =>
    this.pnlDnd.setValue(Number(this.revenueDnd.value) - (this.costDnd.value))
    );

    // PNL Non Dnd Target  ..........

    this.revenueNonDnd.valueChanges.subscribe((value) =>
    this.pnlNonDnd.setValue(Number(this.revenueNonDnd.value) - (this.costNonDnd.value))
    );
    this.costNonDnd.valueChanges.subscribe((value) =>
    this.pnlNonDnd.setValue(Number(this.revenueNonDnd.value) - (this.costNonDnd.value))
    );
    // PNL Total Target  ..........

    this.revenueTotal.valueChanges.subscribe((value) =>
    this.pnlTotal.setValue(Number(this.revenueTotal.value) - (this.costTotal.value))
    );
    this.costTotal.valueChanges.subscribe((value) =>
    this.pnlTotal.setValue(Number(this.revenueTotal.value) - (this.costTotal.value))
    );

     // EPRS Dnd Target  ..........

     this.revenueDnd.valueChanges.subscribe((value) =>
     this.eprsDnd.setValue(Number(this.revenueDnd.value) / (this.sentDnd.value))
     );
     this.sentDnd.valueChanges.subscribe((value) =>
     this.eprsDnd.setValue(Number(this.revenueDnd.value) / (this.sentDnd.value))
     );

    // EPRS Non Dnd Target  ..........

    this.revenueNonDnd.valueChanges.subscribe((value) =>
    this.eprsNonDnd.setValue(Number(this.revenueNonDnd.value) / (this.sentNonDnd.value))
    );
    this.sentNonDnd.valueChanges.subscribe((value) =>
    this.eprsNonDnd.setValue(Number(this.revenueNonDnd.value) / (this.sentNonDnd.value))
    );

    // EPRS Total Target  ..........

    this.revenueTotal.valueChanges.subscribe((value) =>
    this.eprsTotal.setValue(Number(this.revenueTotal.value) / (this.sentTotal.value))
    );
    this.sentTotal.valueChanges.subscribe((value) =>
    this.eprsTotal.setValue(Number(this.revenueTotal.value) / (this.sentTotal.value))
    );
    this.filterData();

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }

  get sentDnd() {
    return this.regiform.get("sentDnd");
  }
  get sentNonDnd() {
    return this.regiform.get("sentNonDnd");
  }
  get sentTotal() {
    return this.regiform.get("sentTotal");
  }
  get deliveredDnd() {
    return this.regiform.get("deliveredDnd");
  }
  get deliveredNonDnd() {
    return this.regiform.get("deliveredNonDnd");
  }
  get deliveredTotal() {
    return this.regiform.get("deliveredTotal");
  }
  get costDnd() {
    return this.regiform.get("costDnd");
  }
  get costNonDnd() {
    return this.regiform.get("costNonDnd");
  }
  get costTotal() {
    return this.regiform.get("costTotal");
  }
  get clicksDnd() {
    return this.regiform.get("clicksDnd");
  }
  get clicksNonDnd() {
    return this.regiform.get("clicksNonDnd");
  }
  get clicksTotal() {
    return this.regiform.get("clicksTotal");
  }
  get ctrDnd() {
    return this.regiform.get("ctrDnd");
  }
  get ctrNonDnd() {
    return this.regiform.get("ctrNonDnd");
  }
  get ctrTotal() {
    return this.regiform.get("ctrTotal");
  }
  get cpcDnd() {
    return this.regiform.get("cpcDnd");
  }
  get cpcNonDnd() {
    return this.regiform.get("cpcNonDnd");
  }
  get cpcTotal() {
    return this.regiform.get("cpcTotal");
  }
  get revenueDnd() {
    return this.regiform.get("revenueDnd");
  }
  get revenueNonDnd() {
    return this.regiform.get("revenueNonDnd");
  }
  get revenueTotal() {
    return this.regiform.get("revenueTotal");
  }
  get epcDnd() {
    return this.regiform.get("epcDnd");
  }
  get epcNonDnd() {
    return this.regiform.get("epcNonDnd");
  }
  get epcTotal() {
    return this.regiform.get("epcTotal");
  }
  get pnlDnd() {
    return this.regiform.get("pnlDnd");
  }
  get pnlNonDnd() {
    return this.regiform.get("pnlNonDnd");
  }
  get pnlTotal() {
    return this.regiform.get("pnlTotal");
  }
  get pnlrDnd() {
    return this.regiform.get("pnlrDnd");
  }
  get pnlrNonDnd() {
    return this.regiform.get("pnlrNonDnd");
  }
  get pnlrTotal() {
    return this.regiform.get("pnlrTotal");
  }
  get eprsDnd() {
    return this.regiform.get("eprsDnd");
  }
  get eprsNonDnd() {
    return this.regiform.get("eprsNonDnd");
  }
  get eprsTotal() {
    return this.regiform.get("eprsTotal");
  }
  get monthYear() {
    return this.regiform.get("monthYear");
  }
  toggleDisable() {
    this.disableTextbox = !this.disableTextbox;
  }
  valueChange(event) {
    event.active = event.active ? false : true;
  }
  updateFilterOptions(tableData, colName)
  {
    if (tableData !== null)
    {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
    }
  }
  // Getting Dates from Dates Component
  getDates(datesObj)
  {
    if (datesObj)
    {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }
  // Update Footer Values
  getFooterData(tableData)
  {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }
  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  submit() {
   debugger;
    if (this.monthYear.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Month is not selected!",
      });
      return;
    }
    if (this.sentDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "DND Sent Number not selected!",
      });
      return;
    }
    if (this.sentNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Non Sent DND Number not selected!",
      });
      return;
    }
    if (this.sentTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Total Sent Number not selected!",
      });
      return;
    }
    if (this.deliveredDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "DND Delevered  Number not selected!",
      });
      return;
    }
    if (this.deliveredNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Non dnd Delivered Number not selected!",
      });
      return;
    }
    if (this.deliveredTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Total Delivered  Number not selected!",
      });
      return;
    }
    if (this.costDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Cost DND Number not selected!",
      });
      return;
    }
    if (this.costNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Cost Non DND Number not selected!",
      });
      return;
    }
    if (this.costTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Total Cost Number not selected!",
      });
      return;
    }
    if (this.ctrDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Ctr DND Number not selected!",
      });
      return;
    }
    if (this.ctrNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Ctr Non DND Number not selected!",
      });
      return;
    }
    if (this.ctrTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Ctr Total Number not selected!",
      });
      return;
    }
    if (this.clicksDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Click DND Number not selected!",
      });
      return;
    }
    if (this.clicksNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Click Non DND Number not selected!",
      });
      return;
    }
    if (this.clicksTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Click Total Number not selected!",
      });
      return;
    }
    if (this.cpcDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "CPC DND Number not selected!",
      });
      return;
    }
    if (this.cpcNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "CPC Non DND Number not selected!",
      });
      return;
    }
    if (this.cpcTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Total Number of CPC not selected!",
      });
      return;
    }
    if (this.revenueDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Revenue DND Number not selected!",
      });
      return;
    }
    if (this.revenueNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Revenye Non DND Number not selected!",
      });
      return;
    }
    if (this.revenueTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "Revenue Total Number not selected!",
      });
      return;
    }
    if (this.epcDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPC DND Number not selected!",
      });
      return;
    }
    if (this.epcNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPC Non DND Number not selected!",
      });
      return;
    }
    if (this.epcTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPC Total Number not selected!",
      });
      return;
    }
    if (this.pnlDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL DND Number not selected!",
      });
      return;
    }
    if (this.pnlNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL Non DND Number not selected!",
      });
      return;
    }
    if (this.pnlTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL Total Number not selected!",
      });
      return;
    }
    if (this.pnlrDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL% DND Number not selected!",
      });
      return;
    }
    if (this.pnlrNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL% Non DND Number not selected!",
      });
      return;
    }
    if (this.pnlrTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "PNL% Total Number not selected!",
      });
      return;
    }
    if (this.eprsDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPRS DND Number not selected!",
      });
      return;
    }
    if (this.eprsNonDnd.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPRS Non DND Number not selected!",
      });
      return;
    }
    if (this.eprsTotal.invalid) {
      this.messageService.add({
        key: "tc",
        severity: "error",
        summary: "Warning",
        detail: "EPRS Total Number not selected!",
      });
      return;
    }

    const body = {
      monthYear: moment(this.monthYear.value).format("YYYY-MM"),
      sentDnd: this.sentDnd.value,
      sentNonDnd: this.sentNonDnd.value,
      sentTotal: this.sentTotal.value,
      deliveredDnd: this.deliveredDnd.value,
      deliveredNonDnd: this.deliveredNonDnd.value,
      deliveredTotal: this.deliveredTotal.value,
      costDnd: this.costDnd.value,
      costNonDnd: this.costNonDnd.value,
      costTotal: this.costTotal.value,
      ctrDnd: this.ctrDnd.value,
      ctrNonDnd: this.ctrNonDnd.value,
      ctrTotal: this.ctrTotal.value,
      clicksDnd: this.clicksDnd.value,
      clicksNonDnd: this.clicksNonDnd.value,
      clicksTotal: this.clicksTotal.value,
      cpcDnd: this.cpcDnd.value,
      cpcNonDnd: this.cpcNonDnd.value,
      cpcTotal: this.cpcTotal.value,
      revenueDnd: this.revenueDnd.value,
      revenueNonDnd: this.revenueNonDnd.value,
      revenueTotal: this.revenueTotal.value,
      epcDnd: this.epcDnd.value,
      epcNonDnd: this.epcNonDnd.value,
      epcTotal: this.epcTotal.value,
      pnlDnd: this.pnlDnd.value,
      pnlNonDnd: this.pnlNonDnd.value,
      pnlTotal: this.pnlTotal.value,
      pnlrDnd: this.pnlrDnd.value,
      pnlrNonDnd: this.pnlrNonDnd.value,
      pnlrTotal: this.pnlrTotal.value,
      eprsDnd: this.eprsDnd.value,
      eprsNonDnd: this.eprsNonDnd.value,
      eprsTotal: this.eprsTotal.value,
      userId: this.utilService.loginData.user_id,
      id: this.dndNonDndId
    }; //debugger;

    this.mappingService.putMonthlyDndNonDndTarget(body).subscribe((res) => {
      if (res["code"] === 200) {
        this.messageService.add({
          key: "tc",
          severity: "success",
          summary: "Success",
          detail: "Data updated successfully!!!",
        });
        this.regiform.reset();
        this.filterData();
      }
      if (res["code"] === 201) {
        this.messageService.add({
          key: "tc",
          severity: "success",
          summary: "Success",
          detail: "Data Saved successfully!!!",
        });
        this.filterData();
        this.regiform.reset();
      }
    });
  }
  reset()
  {
    this.regiform.reset();
  }
  filterData(type?: any, cols?: any)
  {
  //debugger;

     if (!this.isFilter)
     {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter)
      {
        this.subscription.push(this.mappingService.getMonthlyDndNonDndTarget().subscribe(responseData =>
          {
            if (responseData['error'] === 'Expired token')
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
              return this.utilService.logedOut();
            }
            if (responseData['code'])
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              return null;
            }
            this.data = responseData as { headers: any[], data: any[],config:any };
            // All Columns of Table
            this.allCols = this.data.headers;
            this.colFilterOptions= this.data.headers.filter(header=>header.type!=='primary');
            // Frized Columns
            this.frozenCols = this.data.headers.filter(col => col.type === 'primary');
            this.tableConfig = this.data.config;

            // All Columns for Filter Options
            //this.colFilterOptions = this.data.headers.filter(col => col.type !== 'primary');

            // First Time Showed Columns
            this.cols = localStorage.dndNonDndTarget ? JSON.parse(localStorage.dndNonDndTarget) : this.data.headers.filter(col => col.type !== 'primary');
            this.loading = false;
            this.footerValues = { undefined };
            if (this.data.data.length !== 0)
            {
              this.getFooterData(this.data.data);
              this.filterOptions = this.utilService.getRequiredOptions(
                this.data.data,
                this.data.data[0]
              );
            } else
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
            }
          }, err =>
          {
            if (err)
            {
              this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
              this.loading = false;
              return null;
            }
          }));
        }

    }
  }
  dropOffBackgroundColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#1be56c';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#36e87e';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#4ceb8b';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#65ed9c';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#7cf0aa';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#9df4c0';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#b1f6cc';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#cffadf';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#dbffea';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#eafdf1';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#ffe3e3';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#ffdfdf';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#ffd2d2';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#ffc4c4';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffb1b1';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ff9797';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ff8484';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ff7778';
    }
    else
    {
      return '#ff6161';
    }
  }
  dropOffColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#ffffff';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#ffffff';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#ffffff';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#ffffff';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#ffffff';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#444444';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#333333';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#222222';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#111111';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#000000';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#111111';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#222222';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#333333';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#444444';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffffff';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ffffff';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ffffff';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ffffff';
    }
    else
    {
      return '#ffffff';
    }
  }

  // Columns State Save
  saveColumnState(dt: any)
  {
    localStorage.dndNonDndTarget = JSON.stringify(dt.columns);
  }
  addNewTab(type, header, startDate, endDate, data)
  {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }

  // Destroy all subscriptons
  ngOnDestroy(): void {
    this.subscription.forEach((e) => e.unsubscribe());
  }
  editDndNonDndDetails(dndNonDndDetails:any)
  {
        //debugger;
        this.dndNonDndId = dndNonDndDetails.id;
        this.sentDnd.setValue(dndNonDndDetails.dnd_sent);
        this.sentNonDnd.setValue(dndNonDndDetails.non_dnd_sent);
        this.sentTotal.setValue(dndNonDndDetails.total_sent);
        this.deliveredDnd.setValue(dndNonDndDetails.dnd_delivered);
        this.deliveredNonDnd.setValue(dndNonDndDetails.non_dnd_delivered);
        this.deliveredTotal.setValue(dndNonDndDetails.total_delivered);
        this.costDnd.setValue(dndNonDndDetails.dnd_cost);
        this.costNonDnd.setValue(dndNonDndDetails.non_dnd_cost);
        this.costTotal.setValue(dndNonDndDetails.total_cost);
        this.ctrDnd.setValue(dndNonDndDetails.dnd_ctr);
        this.ctrNonDnd.setValue(dndNonDndDetails.non_dnd_ctr);
        this.ctrTotal.setValue(dndNonDndDetails.total_ctr);
        this.costDnd.setValue(dndNonDndDetails.dnd_cost);
        this.costNonDnd.setValue(dndNonDndDetails.non_dnd_cost);
        this.costTotal.setValue(dndNonDndDetails.total_cost);
        this.clicksDnd.setValue(dndNonDndDetails.dnd_clicks);
        this.clicksNonDnd.setValue(dndNonDndDetails.non_dnd_clicks);
        this.clicksTotal.setValue(dndNonDndDetails.total_clicks);
        this.cpcDnd.setValue(dndNonDndDetails.dnd_cpc);
        this.cpcNonDnd.setValue(dndNonDndDetails.non_dnd_cpc);
        this.cpcTotal.setValue(dndNonDndDetails.total_cpc);
        this.revenueDnd.setValue(dndNonDndDetails.dnd_revenue);
        this.revenueNonDnd.setValue(dndNonDndDetails.non_dnd_revenue);
        this.revenueTotal.setValue(dndNonDndDetails.total_revenue);
        this.epcDnd.setValue(dndNonDndDetails.dnd_epc);
        this.epcNonDnd.setValue(dndNonDndDetails.non_dnd_epc);
        this.epcTotal.setValue(dndNonDndDetails.total_epc);
        this.pnlDnd.setValue(dndNonDndDetails.dnd_pnl);
        this.pnlNonDnd.setValue(dndNonDndDetails.non_dnd_pnl);
        this.pnlTotal.setValue(dndNonDndDetails.total_pnl);
        this.pnlrDnd.setValue(dndNonDndDetails.dnd_pnlRate);
        this.pnlrNonDnd.setValue(dndNonDndDetails.non_dnd_pnlRate);
        this.pnlrTotal.setValue(dndNonDndDetails.total_pnlRate);
        this.eprsDnd.setValue(dndNonDndDetails.dnd_eprs);
        this.eprsNonDnd.setValue(dndNonDndDetails.non_dnd_eprs);
        this.eprsTotal.setValue(dndNonDndDetails.total_eprs);
        this.monthYear.setValue(moment(dndNonDndDetails.monthYear,'YYYY-MM').toDate());
  }
}
