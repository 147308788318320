import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EspService {
  baseURL = environment.baseURL;
  tabs: any[] = [];
  constructor(private http: HttpClient) {
    this.tabs.push({
      id: 0,
      header: "",
      type: "Deliveribility",
      isClosable: false
    });
  }

  getEspData(body: any) {
    const todayDate = moment().format('YYYY-MM-DD')
    if(body?.start_date === todayDate && body?.end_date === todayDate){
      return this.http.post(this.baseURL + "ongageReport/liveEspAccountData", body);
    }else{
      return this.http.post(this.baseURL + "ongageReport/esp_accounts_report", body);
    }
  }

  getSubEspData(body: any) {
    const todayDate = moment().format('YYYY-MM-DD')
    if(body?.start_date === todayDate && body?.end_date === todayDate){
      return this.http.post(this.baseURL + "ongageReport/liveEspSubAccountData", body);
    }
    else{
      return this.http.post(this.baseURL + "ongageReport/esp_subaccounts_report", body);
    }

  }

}
