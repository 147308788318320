export class SubjectLinePerformanceModel {
    allCols: any[] = [];
    colFilterOptions: any[] = [];
    cols: any[] = [];
    constructor() {
        // for Daily Subject Line Sheet Main Columns
        this.allCols = [
            { field: "mailing_id", header: "Mailing ID" ,type:false},
            { field: "mailing_name", header: "Offer Name" ,type:false},
            { field: "list_name", header: "List Name" ,type:false},
            { field: "segment_name", header: "Segment Name" ,type:false},
            { field: "from_name", header: "From Name" ,type:false},
            { field: "sent", header: "Sent" ,type:true},
            { field: "opens", header: "Opens" ,type:true},
            { field: "clicks", header: "Clicks" ,type:true},
            { field: "conversion", header: "Conversions" ,type:true},
            { field: "unsubscribes", header: "Unsub" ,type:true},
            { field: "failed", header: "Failed" ,type:true},
            { field: "complaints", header: "Complaints" ,type:true},
            { field: "open_rate", header: "Open%" ,type:true},
            { field: "click_rate", header: "CTR" ,type:true},
            { field: "hard_bounces", header: "Hard Bounce" ,type:true},
            { field: "hard_bounces_percent", header: "Hard Bounce%" ,type:true},
            { field: "soft_bounces", header: "Soft Bounce" ,type:true},
            { field: "soft_bounces_percent", header: "Soft Bounce%" ,type:true}
        ];
        // for Daily Subject Line Sheet Main Columns Filter Options
        this.colFilterOptions = [
            { field: "mailing_name", header: "Offer Name" ,type:false},
            { field: "list_name", header: "List Name" ,type:false},
            { field: "segment_name", header: "Segment Name" ,type:false},
            { field: "from_name", header: "From Name" ,type:false},
            { field: "sent", header: "Sent" ,type:true},
            { field: "opens", header: "Opens" ,type:true},
            { field: "clicks", header: "Clicks" ,type:true},
            { field: "unsubscribes", header: "Unsub" ,type:true},
            { field: "failed", header: "Failed" ,type:true},
            { field: "complaints", header: "Complaints" ,type:true},
            { field: "open_rate", header: "Open%" ,type:true},
            { field: "click_rate", header: "CTR" ,type:true},
            { field: "hard_bounces", header: "Hard Bounce" ,type:true},
            { field: "hard_bounces_percent", header: "Hard Bounce%" ,type:true},
            { field: "soft_bounces", header: "Soft Bounce" ,type:true},
            { field: "soft_bounces_percent", header: "Soft Bounce%" ,type:true}
        ];
        // for Daily Subject Line Sheet Main Columns First Show
        this.cols = [
            { field: "mailing_name", header: "Offer Name" ,type:false},
            { field: "list_name", header: "List Name" ,type:false},
            { field: "segment_name", header: "Segment Name" ,type:false},
            { field: "from_name", header: "From Name" ,type:false},
            { field: "sent", header: "Sent" ,type:true},
            { field: "opens", header: "Opens" ,type:true},
            { field: "clicks", header: "Clicks" ,type:true},
            { field: "unsubscribes", header: "Unsub" ,type:true},
            { field: "failed", header: "Failed" ,type:true},
            { field: "complaints", header: "Complaints" ,type:true},
            { field: "open_rate", header: "Open%" ,type:true},
            { field: "click_rate", header: "CTR" ,type:true}
        ];
    }
}