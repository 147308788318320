import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { MappingService } from "./../reports/mapping/mappingservices/mapping.service";
import { UtilService } from "src/app/utils/util.service";
import { MessageService } from "primeng/api";
import { FilterUtils } from "primeng/utils";
import { Papa } from "ngx-papaparse";
import { isString } from "util";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./../../app.component.css"],
})
export class DashboardComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  options: any[] = []; // for Creating Options for Ongage List
  fieldOptions: any[] = []; //for Creating Options for Field list
  fields: any[] = [];
  group: any[] = [];
  offerOptions: any[] = []; // for Creating Options for Ongage List
  baseReport: any; // for storing network id value
  checkFields: any;
  offerId: any; // for storing network id vaslue
  cols: any[]; // Array for Showed Columns
  allCols: any[]; // Array for All Columns
  colFilterOptions: any[]; // Array for Each Columns including with frozen
  data: { headers: any[]; data: any[]; config: any }; // for data in the List Details Table
  frozenCols: any[]; // for Frized Columns
  isFilter = false; // for Column Filter Toggle
  isGlobalFilter = false; // for Global Filter Input
  loading: boolean; // for Lazy Loding
  subscription: Subscription[] = [];
  filterOptions: any = {}; // Data Storing for Column Unique Values
  scrollHeight: any;
  showModel: any = "";
  networkName: any = "";
  startDate: any; // Store Start Date
  endDate: any; // Store End Date
  dates: any[]; // for Dates Filter by Today, Yesterday etc
  minDate: Date = moment("01-05-2019", "DD-MM-YYYY").toDate();
  maxDate: Date = moment().subtract(1, "day").toDate();
  user_id: any = 0;
  fileName: any;
  teamId: any;
  tableName: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  footerValues: any = {}; // Data Storing for Column Footer
  revenue_id: any;
  num1: any;
  num2: any;
  userId: any = 0;
  deleteReason: any = "";
  fileType: string;
  permissibleMember: string;
  tableConfig: any;
  selectField: any = [];
  groupField: any = [];
  checked: boolean;
  exporting: boolean;
  //portalStatus = JSON.parse(localStorage.getItem('currentUser')).authorization;
  portalStatus() {
    return this.utilService.portalStatus();
  }
  //portalStatus =()=> localStorage.portalStatus;
  dataSource: any;
  optionsObject: any;
  data$: any;
  constructor(
    private httpClient: HttpClient,
    private mappingService: MappingService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private Papa: Papa
  ) {}

  ngOnInit() {
    this.userId = this.utilService.loginData.user_id;
    this.filterData();
    FilterUtils["filterString"] = (value, filter): boolean => {
      let startString = document.getElementById(filter + "_starts_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_starts_with"))
            .value
        : null;
      let endString = document.getElementById(filter + "_ends_with")
        ? (<HTMLInputElement>document.getElementById(filter + "_ends_with"))
            .value
        : null;

      if (value === undefined || value === null) {
        return false;
      }
      if (
        (startString === undefined && endString === undefined) ||
        (startString === null && endString === null)
      ) {
        return value;
      }
      if (startString && endString) {
        return (
          FilterUtils.startsWith(value, startString) &&
          FilterUtils.endsWith(value, endString)
        );
      } else if (startString) {
        return FilterUtils.startsWith(value, startString);
      } else if (endString) {
        return FilterUtils.endsWith(value, endString);
      } else {
        return value;
      }
    };

    this.data$ = this.httpClient.get(
      this.portalStatus() === "Email"
        ? "https://wts.rightfullabs.com/apis/Dashboard/Dashboard"
        : "https://wts.rightfullabs.com/apis/sms/dashboard"
    );
    this.getDashboardNotifications();
  }
  filterData(cols?: any) {
    //debugger;
    if (!this.isFilter) {
      this.loading = true;

      // Getting Data From Server
      if (!this.isFilter) {
        //debugger;
        this.subscription.push(
          this.mappingService.mspTarget().subscribe(
            (responseData) => {
              if (responseData["error"] === "Expired token") {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Loged out",
                  detail: "Session expired, please login again!!!",
                });
                return this.utilService.logedOut();
              }
              if (responseData["code"]) {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Something went wrong! Server not responding!",
                });
                return null;
              }
              if (responseData["code"] === "400") {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Offer Group name already exist",
                });
                return null;
              }
              this.data = responseData as {
                headers: any[];
                data: any[];
                config: any;
              };
              // All Columns of Table
              this.allCols = this.data.headers;

              // Frized Columns
              this.frozenCols = this.data.headers.filter(
                (col) => col.type === "primary"
              );
              this.tableConfig = this.data.config;

              // All Columns for Filter Options
              this.colFilterOptions = this.data.headers.filter(
                (col) => col.type !== "primary"
              );

              // First Time Showed Columns
              if (this.portalStatus() === "Email") {
                this.cols = localStorage.networkMapping
                  ? JSON.parse(localStorage.networkMapping)
                  : this.data.headers;
              }
              if (this.portalStatus() === "Sms") {
                // this.cols = localStorage.mspTarget
                //   ? JSON.parse(localStorage.mspTarget)
                //   : this.data.headers;
                this.cols = localStorage.mspTarget
                  ? JSON.parse(localStorage.mspTarget)
                  : this.data.headers;
              }
              this.loading = false;
              if (this.data.data.length !== 0) {
                this.filterOptions = this.utilService.getRequiredOptions(
                  this.data.data,
                  this.data.data[0]
                );
              } else {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Data not found in the given date range",
                });
              }
            },
            (err) => {
              if (err) {
                this.messageService.add({
                  key: "tc",
                  severity: "warn",
                  summary: "Warning",
                  detail: "Something went wrong! Server not responding!",
                });
                this.loading = false;
                return null;
              }
            }
          )
        );
      }
    }
  }
  updateFilterOptions(tableData, colName) {
    if (tableData !== null) {
      this.getFooterData(tableData);
      this.filterOptions = this.utilService.getRequiredOptions(
        tableData,
        tableData[0]
      );
      this.filterOptions[
        colName
      ] = this.utilService.getUniqueValuesObjectFromArray(
        this.data.data,
        colName
      );
    }
  }
  // Getting Dates from Dates Component
  getDates(datesObj) {
    if (datesObj) {
      this.startDate = datesObj.start_date;
      this.endDate = datesObj.end_date;
      this.filterOptions = [];
      this.filterData();
    }
  }
  // Update Footer Values
  getFooterData(tableData) {
    this.footerValues = this.utilService.getRequiredFooterData(tableData);
  }
  // For getting string length(Tooltip)
  showTooltip(text: any) {
    return text && String(text).length > 9 ? text : "";
  }
  // For setting fields value

  // For color coding
  dropOffBackgroundColorCoding(dropRatio: number) {
    if (dropRatio <= 5) {
      return "#1be56c";
    } else if (dropRatio > 5 && dropRatio <= 10) {
      return "#36e87e";
    } else if (dropRatio > 10 && dropRatio <= 15) {
      return "#4ceb8b";
    } else if (dropRatio > 15 && dropRatio <= 20) {
      return "#65ed9c";
    } else if (dropRatio > 20 && dropRatio <= 25) {
      return "#7cf0aa";
    } else if (dropRatio > 25 && dropRatio <= 30) {
      return "#9df4c0";
    } else if (dropRatio > 30 && dropRatio <= 35) {
      return "#b1f6cc";
    } else if (dropRatio > 35 && dropRatio <= 40) {
      return "#cffadf";
    } else if (dropRatio > 40 && dropRatio <= 45) {
      return "#dbffea";
    } else if (dropRatio > 45 && dropRatio <= 50) {
      return "#eafdf1";
    } else if (dropRatio > 50 && dropRatio <= 55) {
      return "#ffe3e3";
    } else if (dropRatio > 55 && dropRatio <= 60) {
      return "#ffdfdf";
    } else if (dropRatio > 60 && dropRatio <= 65) {
      return "#ffd2d2";
    } else if (dropRatio > 65 && dropRatio <= 70) {
      return "#ffc4c4";
    } else if (dropRatio > 70 && dropRatio <= 75) {
      return "#ffb1b1";
    } else if (dropRatio > 75 && dropRatio <= 80) {
      return "#ff9797";
    } else if (dropRatio > 80 && dropRatio <= 85) {
      return "#ff8484";
    } else if (dropRatio > 85 && dropRatio <= 90) {
      return "#ff7778";
    } else {
      return "#ff6161";
    }
  }
  dropOffColorCoding(dropRatio: number) {
    if (dropRatio <= 5) {
      return "#ffffff";
    } else if (dropRatio > 5 && dropRatio <= 10) {
      return "#ffffff";
    } else if (dropRatio > 10 && dropRatio <= 15) {
      return "#ffffff";
    } else if (dropRatio > 15 && dropRatio <= 20) {
      return "#ffffff";
    } else if (dropRatio > 20 && dropRatio <= 25) {
      return "#ffffff";
    } else if (dropRatio > 25 && dropRatio <= 30) {
      return "#444444";
    } else if (dropRatio > 30 && dropRatio <= 35) {
      return "#333333";
    } else if (dropRatio > 35 && dropRatio <= 40) {
      return "#222222";
    } else if (dropRatio > 40 && dropRatio <= 45) {
      return "#111111";
    } else if (dropRatio > 45 && dropRatio <= 50) {
      return "#000000";
    } else if (dropRatio > 50 && dropRatio <= 55) {
      return "#111111";
    } else if (dropRatio > 55 && dropRatio <= 60) {
      return "#222222";
    } else if (dropRatio > 60 && dropRatio <= 65) {
      return "#333333";
    } else if (dropRatio > 65 && dropRatio <= 70) {
      return "#444444";
    } else if (dropRatio > 70 && dropRatio <= 75) {
      return "#ffffff";
    } else if (dropRatio > 75 && dropRatio <= 80) {
      return "#ffffff";
    } else if (dropRatio > 80 && dropRatio <= 85) {
      return "#ffffff";
    } else if (dropRatio > 85 && dropRatio <= 90) {
      return "#ffffff";
    } else {
      return "#ffffff";
    }
  }
  // Add New Tab in Tab Array
  addNewTab(type, header, startDate, endDate, data) {
    this.AddTabController.emit({ type, header, startDate, endDate, data });
  }
  // Columns State Save
  saveColumnState(dt: any) {
    localStorage.mtdReport = JSON.stringify(dt.columns);
  }

  getDashboardNotifications() {}
  getDateFormat(dateString: string) {
    return moment(dateString, "YYYY-MM-DD hh:mm:ss").format("DD MMM, YYYY");
  }
  getDataLength(dataObject: object) {
    return Object.keys(dataObject).length;
  }
}
