import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MappingService } from '../mappingservices/mapping.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-listordering',
  templateUrl: './listordering.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class ListorderingComponent implements OnInit, OnDestroy
{
  unorderedList: any[] = [];   // For Getting and Showing All Unordered List
  orderedList: any[] = [];     // For Getting and Showing All Ordered List
  subscription: Subscription[] = [];
  teamId: any;
  sortedList: any[] = [];
  constructor(private utilService: UtilService, private mappingService: MappingService, private messageService: MessageService) { }

  ngOnInit()
  {
    this.teamId = this.utilService.loginData.team_id || '';
    this.getUnorderedList();
  }

  getUnorderedList()
  {
    const body = {
      team_id: this.teamId
    };
    this.subscription.push(this.mappingService.getListOrderingDetails(body).subscribe((listArray: any) =>
    {
      if (listArray.length !== 0)
      {
        this.unorderedList = listArray;
      }
    }));
  }

  updateOrderedList()
  {
    if (this.unorderedList.length !== 0)
    {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Please select all list!' });
      return null;
    }
    let index = -1;
    this.sortedList = this.orderedList.map(dataArray => { return { list_id: dataArray.list_id, sort: ++index } });
    const body = {
      team_id: this.teamId,
      sorted_list: this.sortedList
    };
    this.subscription.push(this.mappingService.updateListOrderingDetails(body).subscribe((responseData: any) =>
    {
      if (responseData['code'] === 200)
      {
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Success', detail: 'List sorting updated successfully!' });
        return null;
      } else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
      }
    }, err =>
    {
      if (err)
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
        return null;
      }
    }));
  }

  ngOnDestroy(): void
  {
    this.subscription.forEach(e => e.unsubscribe());
  }
}
