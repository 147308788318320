import { PublisherService } from './../publisherservices/publisher.service';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/utils/util.service';
import { MessageService } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { FilterUtils } from 'primeng/utils';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
@Component({
  selector: 'app-offer-publisher-report',
  templateUrl: './offer-publisher-report.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class OfferPublisherReportComponent implements OnInit {
  @Output() AddTabController = new EventEmitter();
  @Input() requiredData: any;
  @Input() tabInformation: any;
  cols1: any[];   // Array for Showed Columns
  cols2: any[];   // Array for Showed Columns
  cols3: any[];   // Array for Showed Columns
  allCols1: any[];  // Array for All Columns
  allCols2: any[];  // Array for All Columns
  allCols3: any[];  // Array for All Columns
  colFilterOptions1: any[]; // Array for Each Columns including with frozen
  colFilterOptions2: any[]; // Array for Each Columns including with frozen
  colFilterOptions3: any[]; // Array for Each Columns including with frozen
  data: { headers: any[],  data: any[] }; // Data Storing for Table
  // data: any = {}; // Data Storing for Table
  isFilter1 = false;  // for Column Filter Toggle
  isFilter2 = false;  // for Column Filter Toggle
  isFilter3 = false;  // for Column Filter Toggle
  isGlobalFilter = false;  // for Global Filter Input
  filterOptions1: any = {};  // Data Storing for Column Unique Values
  filterOptions2: any = {};  // Data Storing for Column Unique Values
  filterOptions3: any = {};  // Data Storing for Column Unique Values
  footerValues1: any = {}; // Data Storing for Column Footer
  footerValues2: any = {}; // Data Storing for Column Footer
  footerValues3: any = {}; // Data Storing for Column Footer
  frozenCols1: any[]; // for Frized Columns
  frozenCols2: any[]; // for Frized Columns
  frozenCols3: any[]; // for Frized Columns
  isGlobalFilter1 = false;  // for Global Filter Input
  isGlobalFilter2 = false;  // for Global Filter Input
  isGlobalFilter3 = false;  // for Global Filter Input
  dates: any[];   // for Dates Filter by Today, Yesterday etc
  scrollHeight: any;
  bodyObject: any = {}; // for Body Object Passing to The Server
  startDate: any;   // Store Start Date
  endDate: any;   // Store End Date
  loading: boolean;  // for Lazy Loding
  subscription: Subscription;
  fileName: any;
  tableName: any;
  teamId: any;
  listId: any;
  networkId: any;
  showModel: any = '';
  constructor(
    private publisherService: PublisherService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {

  }

  ngOnInit(): void {
    this.scrollHeight = this.utilService.scrollableTableHeight + 'px';
    // Setting Default Date for Data
      this.startDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
      this.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.filterData();
    FilterUtils['filterNumber'] = (value, filter): boolean =>
    {
      //
      let lessThan = document.getElementById(filter + '_less_than') ? (<HTMLInputElement>document.getElementById(filter + '_less_than')).value : null;
      let greaterThan = document.getElementById(filter + '_greater_than') ? (<HTMLInputElement>document.getElementById(filter + '_greater_than')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((Number(greaterThan) === undefined && Number(lessThan) === undefined) || (Number(greaterThan) === null && Number(lessThan) === null))
      {
        return value;
      }
      if (Number(greaterThan) && Number(lessThan))
      {
        return FilterUtils.gt(value, greaterThan) && FilterUtils.lt(value, lessThan);
      } else if (Number(greaterThan))
      {
        return FilterUtils.gt(value, greaterThan);
      } else if (Number(lessThan))
      {
        return FilterUtils.lt(value, lessThan);
      } else
      {
        return value;
      }
    };

    FilterUtils['filterString'] = (value, filter): boolean =>
    {
      //debugger;
      let startString = document.getElementById(filter + '_starts_with') ? (<HTMLInputElement>document.getElementById(filter + '_starts_with')).value : null;
      let endString = document.getElementById(filter + '_ends_with') ? (<HTMLInputElement>document.getElementById(filter + '_ends_with')).value : null;

      if (value === undefined || value === null)
      {
        return false;
      }
      if ((startString === undefined && endString === undefined) || (startString === null && endString === null))
      {
        return value;
      }
      if (startString && endString)
      {
        return FilterUtils.startsWith(value, startString) && FilterUtils.endsWith(value, endString);
      } else if (startString)
      {
        return FilterUtils.startsWith(value, startString);
      } else if (endString)
      {
        return FilterUtils.endsWith(value, endString);
      } else
      {
        return value;
      }
    };
  }
  // For Showing Filter Model
  letShowModel(column: any, value: any)
  {
    return value === column ? false : true;
  }
  // For Showing Filter Model
  setShowModelValue(columnValue: any)
  {
    return this.showModel = columnValue;
  }
  // For Hiding Filter Model
  hideDialog()
  {
    return this.showModel = '';
  }
  // Filter Data Based on Date
  filterData(cols?: any)
  {
    //debugger;
    this.fileName = "_(" + this.startDate + "-" + this.endDate + ")";
    this.tableName = "List_Data_" + this.fileName;
    if (this.endDate && !this.isFilter1 && !this.isFilter2 && !this.isFilter3)
    {
      this.loading = true;
      // Passing Updated/Filtered Date
      this.bodyObject = {
        start_date: this.startDate,
        end_date: this.endDate
      }

      // Getting Data From Server
      if (this.bodyObject && !this.isFilter1 && !this.isFilter2 && !this.isFilter3)
      {
        this.subscription = this.publisherService.offerWisePortalReport(this.bodyObject).subscribe(responseData =>
        { //debugger;
          if (responseData['error'] === 'Expired token')
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Loged out', detail: 'Session expired, please login again!!!' });
            return this.utilService.logedOut();
          }
          if (responseData['code'])
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            return null;
          }
          this.data = responseData as { headers: any[], data: any[] };
          // All Columns of Table
          this.allCols1 = this.data.headers;
          this.allCols2 = this.data.headers;
          this.allCols3 = this.data.headers;
          this.colFilterOptions1= this.data.headers.filter(header=>header.type!=='primary');
          this.colFilterOptions2 = this.data.headers.filter(header=>header.type!=='primary');
          this.colFilterOptions3 = this.data.headers.filter(header=>header.type!=='primary');

          // Frized Columns
          this.frozenCols1 = this.data.headers.filter(col => col.type === 'primary');
          this.frozenCols2 = this.data.headers.filter(col => col.type === 'primary');
          this.frozenCols3 = this.data.headers.filter(col => col.type === 'primary');
          // this.frozenCols = [{ field: "advertiser_name", header: "Account Name", type: false }];

          // First Time Showed Columns
          this.cols1 = localStorage.offerTableEmail ? JSON.parse(localStorage.offerTableEmail) : this.data.headers.filter(col => col.type !== 'primary');
          this.cols2 = this.data.headers.filter(col => col.type !== 'primary');
          this.cols3 = this.data.headers.filter(col => col.type !== 'primary');
          //debugger;
          this.loading = false;
          this.footerValues1 = { undefined };
          this.footerValues2 = { undefined };
          this.footerValues3 = { undefined };
          if (this.data.data.length !== 0)
          {
            this.getFooterData1(this.data.data);
            this.filterOptions1 = this.utilService.getRequiredOptions(
              this.data.data,
              this.data.data[0]
            );
            // this.getFooterData2(this.data.hasoffer);
            // this.filterOptions2 = this.utilService.getRequiredOptions(
            //   this.data.hasoffer,
            //   this.data.hasoffer[0]
            // );
            // this.getFooterData3(this.data.everflow);
            // this.filterOptions3 = this.utilService.getRequiredOptions(
            //   this.data.everflow,
            //   this.data.everflow[0]
            // );
          } else
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Data not found in the given date range' });
          }
        }, err =>
        {
          if (err)
          {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Warning', detail: 'Something went wrong! Server not responding!' });
            this.loading = false;
            return null;
          }
        });
      }

    }
  }
  // For Date Show
  convertedDate(date)
  {
    return moment(date, 'YYYY-MM-DD h:mm:ss').format('h:mm a');
  }

    // Update Filter Options
    updateFilterOptions1(tableData, colName)
    {
      if (tableData !== null)
      {
        this.getFooterData1(tableData);
        this.filterOptions1 = this.utilService.getRequiredOptions(
          tableData,
          tableData[0]
        );
        this.filterOptions1[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.data, colName);
        this.filterOptions1.delivery_timestamp.forEach(e => { e.label = moment(e.label, 'YYYY-MM-DD h:mm:ss').format('h:mm:ss a'); });
      }
    }
    // updateFilterOptions2(tableData, colName)
    // {
    //   if (tableData !== null)
    //   {
    //     this.getFooterData2(tableData);
    //     this.filterOptions2 = this.utilService.getRequiredOptions(
    //       tableData,
    //       tableData[0]
    //     );
    //     this.filterOptions2[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.hasoffer, colName);

    //   }
    // }
    // updateFilterOptions3(tableData, colName)
    // {
    //   if (tableData !== null)
    //   {
    //     this.getFooterData3(tableData);
    //     this.filterOptions3 = this.utilService.getRequiredOptions(
    //       tableData,
    //       tableData[0]
    //     );
    //     this.filterOptions3[colName] = this.utilService.getUniqueValuesObjectFromArray(this.data.everflow, colName);
    //     this.filterOptions3.delivery_timestamp.forEach(e => { e.label = moment(e.label, 'YYYY-MM-DD h:mm:ss').format('h:mm:ss a'); });
    //   }
    // }
  // Update Footer Values
  getFooterData1(tableData)
  { //debugger;
    if (tableData)
    {
      this.footerValues1 = this.utilService.getRequiredFooterData(tableData);
    }
  }
    getFooterData2(tableData)
    {
      this.footerValues2 = this.utilService.getRequiredFooterData(tableData);
    }
    getFooterData3(tableData)
    {
      this.footerValues3 = this.utilService.getRequiredFooterData(tableData);
    }


    // Add New Tab in Tab Array
    addNewTab(type, header, startDate, endDate, data)
    {
      this.AddTabController.emit({ type, header, startDate, endDate, data });
    }

    // Getting Dates from Dates Component
    getDates(datesObj)
    {
      if (datesObj)
      {
        this.startDate = datesObj.start_date;
        this.endDate = datesObj.end_date;
        // this.filterOptions = [];
        this.filterData();
      }
    }
      // For color coding
  dropOffBackgroundColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#1be56c';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#36e87e';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#4ceb8b';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#65ed9c';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#7cf0aa';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#9df4c0';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#b1f6cc';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#cffadf';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#dbffea';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#eafdf1';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#ffe3e3';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#ffdfdf';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#ffd2d2';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#ffc4c4';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffb1b1';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ff9797';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ff8484';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ff7778';
    }
    else
    {
      return '#ff6161';
    }
  }
  dropOffColorCoding(dropRatio: number)
  {
    if (dropRatio <= 5)
    {
      return '#ffffff';
    }
    else if (dropRatio > 5 && dropRatio <= 10)
    {
      return '#ffffff';
    }
    else if (dropRatio > 10 && dropRatio <= 15)
    {
      return '#ffffff';
    }
    else if (dropRatio > 15 && dropRatio <= 20)
    {
      return '#ffffff';
    }
    else if (dropRatio > 20 && dropRatio <= 25)
    {
      return '#ffffff';
    }
    else if (dropRatio > 25 && dropRatio <= 30)
    {
      return '#444444';
    }
    else if (dropRatio > 30 && dropRatio <= 35)
    {
      return '#333333';
    }
    else if (dropRatio > 35 && dropRatio <= 40)
    {
      return '#222222';
    }
    else if (dropRatio > 40 && dropRatio <= 45)
    {
      return '#111111';
    }
    else if (dropRatio > 45 && dropRatio <= 50)
    {
      return '#000000';
    }
    else if (dropRatio > 50 && dropRatio <= 55)
    {
      return '#111111';
    }
    else if (dropRatio > 55 && dropRatio <= 60)
    {
      return '#222222';
    }
    else if (dropRatio > 60 && dropRatio <= 65)
    {
      return '#333333';
    }
    else if (dropRatio > 65 && dropRatio <= 70)
    {
      return '#444444';
    }
    else if (dropRatio > 70 && dropRatio <= 75)
    {
      return '#ffffff';
    }
    else if (dropRatio > 75 && dropRatio <= 80)
    {
      return '#ffffff';
    }
    else if (dropRatio > 80 && dropRatio <= 85)
    {
      return '#ffffff';
    }
    else if (dropRatio > 85 && dropRatio <= 90)
    {
      return '#ffffff';
    }
    else
    {
      return '#ffffff';
    }
  }
  // Columns State Save
  saveColumnState(dt: any)
  {
      localStorage.offerTableEmail = JSON.stringify(dt.columns);
  }
  exportData(dt)
  {        dt.columns.splice(0, 0, this.frozenCols1[0]);
    dt.exportCSV();
    return dt.columns.splice(0, 1);
  }
  // For getting string length(Tooltip)
  showTooltip(text: any)
  {
    return text && (String(text).length > 9) ? text : '';
  }

  // Destroy all subscriptons
  ngOnDestroy(): void
  {
    if(this.subscription)
    {
      this.subscription.unsubscribe();
    }

  }


}
